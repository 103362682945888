import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import translate from 'src/helpers/translate';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import ReplayIcon from '@mui/icons-material/Replay';
import EastIcon from '@mui/icons-material/East';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PreviewIcon from '@mui/icons-material/Preview';
import TestSolvingHistory from './TestSolvingHistory';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/reducers/student/tests';
import { actionCreators as testProblemsActionCreators } from 'src/reducers/student/testProblems';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TestInfoDialog({ open, close, isOpened, test }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const testProblems = useSelector(state => state.student.testProblems.filter(tp => tp.testId === test.id));

  useEffect(() => {
    dispatch(testProblemsActionCreators.requestTestsProblems(test.id));
    setLoading(false);
  }, [dispatch, test.id])

  const handleBeginTest = () => {
    if (test.canBegin) {
      setLoading(true);
      dispatch(actionCreators.begin(test.id));
      navigate('/app/test/' + test.id, { replace: false });
    }
  }

  const handleRetakeTest = () => {
    if (test.canRetake) {
      setLoading(true);
      dispatch(actionCreators.retake(test.id));
      navigate('/app/test/' + test.id, { replace: false });
    }
  }

  const handleSeeTest = () => {
    if (!test.hasNotTakenYet) {
      dispatch(actionCreators.showResult(test.id));
      navigate('/app/test/' + test.id, { replace: false });
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={isOpened}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
          {test.name}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {translate('TestTopics')} : {test.joinedTopics}
          </Typography>
          <Typography gutterBottom>
            {translate('TestProblemsCount')} : {test.problems.length}
          </Typography>
          <Typography gutterBottom>
            {translate('TestSolvingTime')} : {test.time} {translate('CreateWorkResources_Minutes')}
          </Typography>
          <Typography gutterBottom>
            {translate('TestMaxScore')} : {test.maxScore}
          </Typography>
        </DialogContent>
        {!test.hasNotTakenYet && testProblems && testProblems.length > 0 && (
          <DialogContent dividers>
            <Typography gutterBottom>
              {translate('SolvingHistory')}
            </Typography>
            <TestSolvingHistory test={test} data={testProblems} />
          </DialogContent>
        )}
        <DialogActions>
          {!test.isSolving && (
            <React.Fragment>
              {!test.hasNotTakenYet && (
                <LoadingButton
                  onClick={handleSeeTest}
                  endIcon={<PreviewIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  color="primary"
                >
                  {translate('MyWorkResources_ShowSolvedTest')}
                </LoadingButton>
              )}
              {test.canBegin && (
                <LoadingButton
                  onClick={handleBeginTest}
                  endIcon={<EastIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  color="secondary"
                >
                  {translate('MyWorkResources_BeginTest')}
                </LoadingButton>
              )}
              {test.canRetake && (
                <LoadingButton
                  onClick={handleRetakeTest}
                  endIcon={<ReplayIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  color="secondary"
                >
                  {translate('TestResources_RetakeTest')}
                </LoadingButton>
              )}
            </React.Fragment>
          )}
          {
            test.isSolving && (
              <Button variant="contained" color="secondary" endIcon={<PlayArrowIcon />}>
                {translate('MyWorkResources_ContinueTest')}
              </Button>
            )
          }
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
