import * as apiCaller from 'src/helpers/apiCaller';
import { actionCreators as layoutActionCreators } from 'src/reducers/layout';
import translate from 'src/helpers/translate';
const testsReceivedType = "TESTS_PROBLEMS_RECEIVED";
const answerInTestConfirmedType = "ANSWER_IN_TEST_CONFIRMED";

function requestTestsProblems(testId) {
    return async dispatch => {

        await apiCaller.get('/tests/problems?testId='+testId).then(
            (testProblems) => {
                dispatch(success(testProblems));
            }
        );
        
        function success(testProblems) {
            return { type: testsReceivedType, testId, testProblems };
        }
    };
}

function confirmAnswerInTest(testId, generatedProblemId, answer) {
    return async dispatch => {

        await apiCaller.post(`/test/confirmAnswer`, {id: generatedProblemId, testId, answer}).then(
            (response) => {
                dispatch(success());
                if(response){
                    dispatch(layoutActionCreators.showSuccessAlert(translate('TestAnswerWasAcceptedSnackBar'), 'top', 'center'));
                }else{
                    dispatch(layoutActionCreators.showErrorAlert(translate('TestAnswerWasNotAcceptedSnackBar'), 'top', 'center'));
                }
            }
        );
        
        function success() {
            return { type: answerInTestConfirmedType, testId, generatedProblemId, answer };
        }
    };
}

export const actionCreators = {
    confirmAnswerInTest,
    requestTestsProblems
}

const initialState = [];

const testProblems = (state = initialState, action) => {
    
    if (action.type === testsReceivedType) {
        return (state.filter(tp => tp.testId !== action.testId).concat(action.testProblems));
    }

    if(action.type === answerInTestConfirmedType){
        return state.map(tp => {
            if(tp.testId === action.testId && tp.generatedProblem && tp.generatedProblem.generatedProblemId === action.generatedProblemId)
            {
                return {...tp, generatedProblem: {...tp.generatedProblem, userAnswer: action.answer}}
            }
            return tp;
        })
    }

    return state;
};

export default testProblems;