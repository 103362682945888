import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
// import HelpIcon from '@mui/icons-material/Help';
import {
  Book,
  Home,
  User as UserIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  CheckSquare,
  Edit as EditIcon,
  HelpCircle,
  Layout,
  AlertTriangle
} from 'react-feather';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import NavItem from './NavItem';
import { actionCreators as topicsActionCreators } from 'src/reducers/common/topics';
import { actionCreators as problemsActionCreators } from 'src/reducers/common/problems';
import translate from 'src/helpers/translate';

const items = (userRole) => [
  {
    href: '/app/subjects',
    icon: Book,
    priority: 1,
    title: 'MyWorkResources_TaskIds'
  },
  {
    href: `/app/homeworks`,
    icon: Home,
    priority: 2,
    linkToOldPage: false,
    title: 'GlobalResources_Slider_Header_Homework'
  },
  {
    href: `/app/tests`,
    icon: CheckSquare,
    priority: 3,
    linkToOldPage: false,
    title: 'GlobalResources_Tests'
  },
  {
    href: `/app/boards`,
    icon: EditIcon,
    priority: 6,
    linkToOldPage: false,
    title: 'WhiteBoards'
  },
  {
    href: '/app/settings',
    priority: 100,
    icon: SettingsIcon,
    title: 'Settings'
  },
  {
    href: '/app/help',
    priority: 100,
    icon: HelpCircle,
    title: 'Help'
  },
  {
    href: '/app/trainings',
    priority: 6,
    icon: FitnessCenterIcon,
    title: 'ProblemResources_Trainings'
  }
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const teacherItems = [
  {
    href: `/app/students`,
    icon: UserIcon,
    badgeContentKey: 'studentsAwaitingConfirmation',
    priority: 4,
    linkToOldPage: false,
    title: 'Students'
  },
  {
    href: '/app/groups',
    icon: UsersIcon,
    priority: 5,
    linkToOldPage: false,
    title: 'Groups'
  },
  {
    href: '/app/journal/0',
    icon: Layout,
    priority: 5,
    linkToOldPage: false,
    title: 'Journal'
  }
]

const studentItems = [
  {
    href: '/app/teachers',
    icon: UsersIcon,
    priority: 4,
    linkToOldPage: false,
    title: 'Teachers'
  }
]

const adminItems = [
  {
    href: '/app/mistakes',
    icon: AlertTriangle,
    priority: 500,
    linkToOldPage: false,
    title: 'Mistakes'
  }
]

const styles = theme => ({
  mobileDrawer: {
    width: 256,
    top: 48,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
});

class NavBar extends React.Component {
  componentDidMount() {
    this.props.requestTopics();
    this.props.requestProblems();
  }

  render() {
    const { closeNav, isNavOpen, classes, user, culture } = this.props;
    let menuItems = items(user.role);

    if (user.roles && user.roles.includes("Teacher")) {
      menuItems = menuItems.concat(teacherItems);
    }

    if (user.roles && user.roles.includes("Student")) {
      menuItems = menuItems.concat(studentItems);
    }

    if (user.roles && user.roles.includes("Admin")) {
      menuItems = menuItems.concat(adminItems);
    }

    menuItems = menuItems.map(mi => {
      if (mi.badgeContentKey) {
        return { ...mi, badgeContent: this.props[mi.badgeContentKey] }
      } else {
        return mi;
      }
    })

    const content = (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          p={2}
        >
          {user && (
            <Avatar
              className={classes.avatar}
              component={RouterLink}
              src={`data:image/jpg;base64, ${user.avatar?.avatarImage}`}
              to="/app/account"
            />
          )}
          {(user.surname || user.name) && (
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h5"
            >
              {user.surname} {user.name}
            </Typography>
          )}
          {(!user.surname && !user.name) && (
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h5"
            >
              {user.userName}
            </Typography>
          )}
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            {menuItems.filter(mi => mi.priority < 100).sort((a, b) => a.priority - b.priority).map((item) => (
              item && <NavItem
                href={item.href}
                key={item.title}
                title={translate(item.title)}
                icon={item.icon}
                badgeContent={item.badgeContent}
                linkToOldPage={item.linkToOldPage}
              />
            ))}
            {menuItems.filter(mi => mi.priority == 100).length > 0 && (
              <Divider />
            )}
            {menuItems.filter(mi => mi.priority == 100).sort((a, b) => a.priority - b.priority).map((item) => (
              item && <NavItem
                href={item.href}
                key={item.title}
                title={translate(item.title)}
                icon={item.icon}
                badgeContent={item.badgeContent}
                linkToOldPage={item.linkToOldPage}
              />
            ))}
            {menuItems.filter(mi => mi.priority > 100).length > 0 && (
              <Divider />
            )}
            {menuItems.filter(mi => mi.priority > 100).sort((a, b) => a.priority - b.priority).map((item) => (
              item && <NavItem
                href={item.href}
                key={item.title}
                title={translate(item.title)}
                icon={item.icon}
                badgeContent={item.badgeContent}
                linkToOldPage={item.linkToOldPage}
              />
            ))}
          </List>
        </Box>
        <Box flexGrow={1} />
      </Box >
    );

    return (
      <>
        <Drawer
          dir={culture.dir} 
          anchor={culture.dir == "ltr" ? "left" : "right"}
          classes={{ paper: classes.mobileDrawer }}
          onClose={closeNav}
          open={isNavOpen}
        >
          {content}
        </Drawer>
      </>
    );
  }
};

NavBar.propTypes = {
  closeMobileNav: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  closeMobileNav: () => { },
  openMobile: false
};

const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
    user: state.userContext.user,
    culture: state.culture,
    studentsAwaitingConfirmation: state.teacher.studentsAwaitingConfirmation?.length
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    requestTopics: () => dispatch(topicsActionCreators.requestTopics()),
    requestProblems: () => dispatch(problemsActionCreators.requestProblems()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavBar));
