import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from 'src/reducers/common/trainings';
import { actionCreators as topicsActionCreators } from 'src/reducers/common/topics';
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Delete } from '@mui/icons-material';
import translate from 'src/helpers/translate';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    //paddingBottom: theme.spacing(3),
    //paddingTop: theme.spacing(3)
  },
  trainingForm: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    marginLeft: 20
  }
}));

const TrainingsView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(state => state.userContext.user);
  const canEdit = user && user.roles && user.roles.includes('Admin');
  const topics = useSelector(state => state.common.topics);
  const allTopics = [{ id: 0 }, ...topics];
  const trainings = useSelector(state => state.common.trainings);

  const [backdropOpen, setBackdropOpen] = useState(trainings.length == 0);
  const [selectedTopicId, setSelectedTopicId] = useState(0);

  let trainingsForTopic = trainings;
  if (selectedTopicId != 0) {
    trainingsForTopic = trainings.filter(tr => selectedTopicId && tr.topicId.toString().startsWith(selectedTopicId));
  }

  const [description, setDescription] = useState('');

  const [link, setLink] = useState('');

  const canBeSubmitted = description != '' && link != '';

  const handleAdd = () => {
    if (canBeSubmitted) {
      setLink('');
      setDescription('');
      dispatch(actionCreators.addTraining(description, link, selectedTopicId));
    }
  }

  const handleCloseBackdrop = () => {
    setBackdropOpen(false);
  };

  const handleDelete = (id) => {
    dispatch(actionCreators.deleteTraining(id));
  }

  const onInnerTopicChange = (event) => {
    setSelectedTopicId(event.target.value);
  }

  useEffect(() => {
    dispatch(actionCreators.requestTrainings());
    dispatch(topicsActionCreators.requestTopics()).then(async () => await setBackdropOpen(false));
  }, [dispatch])

  return (
    <Page
      className={classes.root}
      title="Trainings"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <Backdrop className={classes.backdrop} open={backdropOpen} onClick={handleCloseBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {topics && topics.length > 0 && user && (
            <React.Fragment>
              <Card>
                <form className={classes.trainingForm}>
                  {canEdit && (
                    <React.Fragment>
                      <TextField required id="standard-required" value={description} label={translate("Description")} onChange={(event) => setDescription(event.target.value)} />
                      <TextField required id="standard-required" value={link} label={translate("Link")} onChange={(event) => setLink(event.target.value)} />
                    </React.Fragment>
                  )}
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedTopicId}
                      onChange={onInnerTopicChange}
                    >
                      {allTopics.map(innerTopic => (
                        <MenuItem key={`innerTopic_${innerTopic.id}`} value={innerTopic.id}>{translate(`TopicResources_${innerTopic.id}`)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {canEdit && (
                    <Button disabled={!canBeSubmitted} onClick={handleAdd} style={{ marginTop: 20 }} variant="standard" color="primary" href="#outlined-buttons">
                      {translate("Add")}
                    </Button>
                  )}
                </form>
                <List component="nav">
                  {trainingsForTopic.map(training => (
                    <ListItem key={`TrainingFor_${training.id}`} >
                      <Button href={training.url} key={training.id}>
                        <ListItemText primary={training.name} />
                      </Button>
                      {canEdit && (
                        <IconButton onClick={() => handleDelete(training.id)} size="large">
                          <Delete />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Card>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default TrainingsView;
