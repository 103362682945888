import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/reducers/teacher';
import { Add as AddIcon, Done, DoneAll, Mood } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Grid, Button, Switch, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import AddNewTestDialog from '../AddNewTestDialog';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    },
    createNewButton: {
        marginRight: 5
    },
    filterByGroupButton: {
        marginBottom: 10,
    },
    cancelButton: {
        color: red[500],
        borderColor: red[500]
    },
    newRequestButton: {
        backgroundColor: green[200]
    },
    addNewButton: {
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    }
}));

export default function TestsTopBar({shouldShowSwitch, showForCurrentEntity, setShowForCurrentEntity, currentWorkEntity}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [addingNewTestDialogIsOpen, setAddingNewTestDialogIsOpen] = useState(false);

    const addTest = () => {
        setAddingNewTestDialogIsOpen(true);
    }
    
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={3} sm={3} md={2}>
                    <Button
                        className={classes.addNewButton}
                        variant="contained"
                        color="primary"
                        onClick={() => addTest()}
                        >
                        <AddIcon className={classes.createNewButton} />
                        {translate('CreateWorkResources_AddNewGroupStudent')}
                    </Button>
                </Grid>
                <Grid item xs={4} sm={5} md={7} />
                <Grid item xs={5} sm={4} md={3} >
                    {shouldShowSwitch && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showForCurrentEntity}
                                    onChange={() => setShowForCurrentEntity(!showForCurrentEntity)}
                                    color="primary"
                                />
                            }
                            label={currentWorkEntity}
                        />
                    )}
                </Grid>
            </Grid>
            <AddNewTestDialog 
                open={addingNewTestDialogIsOpen} 
                setOpen={(open) => setAddingNewTestDialogIsOpen(open)} 
                onSubmit={(description) => dispatch(actionCreators.addNewTest(description, showForCurrentEntity))}
            />
        </React.Fragment>
    );
}