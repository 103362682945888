import React from 'react';

export default function Graph488(props) {

    let {a, b, c, d, e, f, g, h, i} = props.data;
    
    props.board.create('functiongraph',[function(x){return a*x*x + b*x + c}],{strokeColor:'blue',highlightStrokeColor:'blue'});
    var p1 = props.board.create('point',[d,e], {size:2, fixed: true, name:'A'});
    var p2 = props.board.create('point',[f,g], {size:2, fixed: true, name:'B'});
    var p3 = props.board.create('point',[h,i], {size:2, fixed: true, name:'C'});

    var lineParams = {
        strokeColor:'green',
        highlightStrokeColor:'green',
        straightFirst:false, 
        straightLast:false, 
        strokeWidth:2, 
        fixed:true
    }

    props.board.create('line',[p1,p2], lineParams);
    props.board.create('line',[p1,p2], lineParams);
    props.board.create('line',[p2,p3], lineParams);
    
    return <></>
}