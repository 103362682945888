import React from 'react';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { makeStyles, withStyles } from '@mui/styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    marginRight: '2px',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  }
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));

export default function AvatarWithStatus({id, studentName, src}) {
  const classes = useStyles();
  console.log(src);
  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circular"
        key={`badge_${id}`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar key={`avatar_${id}`} alt={studentName} src={src} className={classes.small} />
      </StyledBadge>
    </div>
  );
}
