import React from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';
import CustomerListView from 'src/views/customer/CustomerListView';
import MistakesView from 'src/views/admin/mistakes/MistakesView';

const adminRoutes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'customers', element: <CustomerListView /> },
      { path: 'mistakes', element: <MistakesView /> },
    ]
  }
];

export default adminRoutes;
