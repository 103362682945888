import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { actionCreators } from 'src/reducers/teacher';
import StudentsSelectedList from '../students/studentsSelectedList';
import GroupsSelectedList from '../groups/groupsSelectedList';
import { connect } from 'react-redux';
import translate from 'src/helpers/translate';

const styles = (theme) => ({
  root: {
    width: '400px',
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const ChooseWorkEntityDialog = ({ 
    students, 
    groups,
    currentGroup,
    currentStudent,
    studentGroups, 
    changeCurrentStudent,
    changeCurrentGroup, 
    changeWorkState, 
    ...props })  =>  {
  const { isOpen, toogleDialogOpen } = props;
  const [value, setValue] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(0);

  useEffect(() => {
    props.requestStudents();
    props.requestGroups();
    props.requestStudentGroups();
    props.requestCurrentWorkState();
    let tabToOpen = props.workState.isGroup ? 1 : 0;
    setValue(tabToOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let tabToOpen = props.workState.isGroup ? 1 : 0;
    setValue(tabToOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workState])

  useEffect(() => {
    if (currentStudent) {
      setSelectedStudent(currentStudent.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students])

  useEffect(() => {
    if (currentGroup) {
      setSelectedGroup(currentGroup.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    let workStateIsStudent = value === 0;
    let workStateIsGroup = !workStateIsStudent;
    
    if(props.workState.isStudent !== workStateIsStudent || props.workState.isGroup !== workStateIsGroup){
      if((workStateIsStudent && selectedStudent !== '')
          || (workStateIsGroup && selectedGroup !== 0)){
            changeWorkState(workStateIsStudent);
      }
    }
    if((currentStudent && currentStudent.id !== selectedStudent) || (!currentStudent && selectedStudent !== '')){
      changeCurrentStudent(selectedStudent);
    }
    if((currentGroup && currentGroup.id !== selectedGroup) || (!currentGroup && selectedGroup !== 0)){
      changeCurrentGroup(selectedGroup);
    }
    toogleDialogOpen(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {translate('ChooseStudentOrGroupToWorkWith')}
      </DialogTitle>
      <DialogContent dividers>
        <AppBar position="static">
          <Tabs indicatorColor="primary" textColor="inherit" value={value} onChange={handleChange} aria-label="wrapped label tabs example">
            <Tab
              value={0}
              label={translate('Students')}
              {...a11yProps('0')}
            />
            <Tab value={1} label={translate('Groups')} {...a11yProps('1')} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <StudentsSelectedList students={students} selected={selectedStudent} onSelect={(student) => setSelectedStudent(student)} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GroupsSelectedList groups={groups} selected={selectedGroup} onSelect={(group) => setSelectedGroup(group)} />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {translate('ProfileResources_SaveChanges')}
      </Button>
      </DialogActions>
    </Dialog>
  );
}

const MapStateToProps = (state, ownProps) => {
  return {
    students: state.teacher.students.filter(s => s.relationStatus === 1),
    groups: state.teacher.groups,
    studentGroups: state.teacher.studentGroups,
    workState: state.teacher.workState,
    currentGroup: state.teacher.groups.find(g => g.isCurrent),
    currentStudent: state.teacher.students.find(s => s.isCurrent),
    ...ownProps
  }
}

const MapDispatchToProps = (dispatch) => {
  return {
    requestStudents: () => dispatch(actionCreators.requestStudents()),
    requestGroups: () => dispatch(actionCreators.requestGroups()),
    requestStudentGroups: () => dispatch(actionCreators.requestStudentGroups()),
    requestCurrentWorkState: () => dispatch(actionCreators.requestCurrentWorkState()),
    changeCurrentStudent: (studentId) => dispatch(actionCreators.changeCurrentStudent(studentId)),
    changeCurrentGroup: (groupId) => dispatch(actionCreators.changeCurrentGroup(groupId)),
    changeWorkState: (isStudent) => dispatch(actionCreators.changeWorkState(isStudent))
  }
}

export default connect(MapStateToProps, MapDispatchToProps)(ChooseWorkEntityDialog);