import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Create from './Create';
import Update from './Update';
import AddStudentsToGroupDialog from './AddStudentsToGroupDialog';
import ConfirmationDialog from 'src/components/common/ConfirmationDialog';
import { actionCreators } from 'src/reducers/teacher';
import translate from 'src/helpers/translate';
import GroupRow from './GroupRow';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    },
  },
  topActionBar: {
    marginTop: -55,
    marginBottom: 10
  },
  createNewButton: {
    marginRight: 5
  }
});

const MapStateToStudentsOfAGroup = (groupId, state) => {
  let { students, studentGroups } = state.teacher;
  let studentsInGroup = studentGroups.filter(sg => sg.groupId === groupId);
  if (studentsInGroup && studentsInGroup.length > 0) {
    return studentsInGroup.map(sg => {
      let student = students.find(s => s.id === sg.studentId);
      if (student) {
        return {
          id: student.id,
          key: sg.id,
          name: student.wholeName,
          avatar: student.avatarImage
        }
      }
      return {};
    })
  }
  return [];
}


const GroupsGrid = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const groups = useSelector(state => state.teacher.groups.map(group => ({
    id: group.id,
    name: group.name,
    identifier: group.identifier,
    students: MapStateToStudentsOfAGroup(group.id, state)
  })));
  const [createNewDialogIsOpen, setcreateNewDialogIsOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editStudentsInGroupDialogOpen, setEditStudentsInGroupDialogOpen] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');
  const [groupIdToRemove, setGroupIdToRemove] = useState(0);

  const deleteGroup = (group) => {
    setGroupIdToRemove(group.id);
    setConfirmationDialogMessage(translate("DeleteGroup", group.name));
    setConfirmationDialogIsOpen(true);
  }

  return (
    <div>
      <Grid className={classes.topActionBar} container spacing={3}>
        <Grid item xs={2} sm={2}>
          <Fab
            variant="extended"
            size="medium"
            color="secondary"
            aria-label="add"
            className={classes.createNewButton}
            onClick={() => setcreateNewDialogIsOpen(true)}
          >
            <AddIcon className={classes.createNewButton} />
            {translate('Create')}
          </Fab>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{translate('GroupName')}</TableCell>
              <TableCell>{translate('Students')}</TableCell>
              <TableCell>{translate('GroupIdentifier')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <GroupRow
                key={group.id}
                group={group}
                translate={translate}
                setEditDialogOpen={setEditDialogOpen}
                setGroupToEdit={setGroupToEdit}
                onGroupDelete={() => deleteGroup(group)}
                onEditStudentsInGroupDialogOpen={setEditStudentsInGroupDialogOpen}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Create open={createNewDialogIsOpen} setOpen={setcreateNewDialogIsOpen} />
      <AddStudentsToGroupDialog
        open={editStudentsInGroupDialogOpen}
        setOpen={setEditStudentsInGroupDialogOpen}
        group={groupToEdit}
      />
      {groupToEdit && (<Update open={editDialogOpen} group={groupToEdit} setOpen={setEditDialogOpen} />)}
      <ConfirmationDialog
        open={confirmationDialogIsOpen}
        onCancel={() => setConfirmationDialogIsOpen(false)}
        onSubmit={() => dispatch(actionCreators.deleteGroup(groupIdToRemove))}
        message={confirmationDialogMessage}
      />
    </div>
  );
}

export default GroupsGrid;