import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import translate from 'src/helpers/translate';
import SubmitButton from 'src/utils/SubmitButton';
import { DataGrid } from '@mui/x-data-grid';
import { actionCreators } from 'src/reducers/teacher';

const columns = [
    { field: 'id', hide: true },
    { field: 'wholeName', headerName: translate('ProfileResources_Name'),  flex: 1 }
];

const AddStudentsToGroupDialog = (props) => {
    const { open, setOpen, studentsInGroup, allStudents, group, groupIsUpdating, updateStudentsInGroup } = props;
    const [selectedStudents, setSelectedStudents] = useState([]);

    useEffect(() => {
        if (studentsInGroup) {
            const students = studentsInGroup;
            setSelectedStudents(students);
        }
    }, [studentsInGroup])

    const handleClose = () => {
        setOpen(false);
    };

    const submit = () => {
        updateStudentsInGroup(group.id, selectedStudents);
        setOpen(false);
    };

    const handleSelectAllStudent = (event) => {
        setSelectedStudents(event.selectionModel ?? event);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate('ChooseStudentsForGroup')} {group && group.name}</DialogTitle>
                <DialogContent>
                    <div style={{ height: 400, minWidth: 400 }}>
                        <DataGrid 
                            rows={allStudents} 
                            selectionModel={selectedStudents}
                            density="compact"
                            onSelectionModelChange={handleSelectAllStudent}
                            columns={columns} 
                            pageSize={10} 
                            checkboxSelection 
                            disableColumnMenu 
                            hideFooterSelectedRowCount />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        disabled={groupIsUpdating}
                        color="primary">
                        {translate('Cancel')}
                    </Button>
                    <SubmitButton
                        isSubmitting={groupIsUpdating} 
                        canBeSubmitted={true}
                        onSubmit={submit}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}

const MapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        studentsInGroup: state.teacher.studentGroups
            .filter(sg => sg.groupId === ownProps.group?.id)
            .map(sg =>  sg.studentId),
        allStudents: state.teacher.students,
        groupIsUpdating: state.teacher.groupsAreUpdating
    };
}

const MapDispatchToProps = (dispatch) => {
    return {
        updateStudentsInGroup: (groupId, students) => dispatch(actionCreators.updateStudentsInGroup(groupId, students))
    }
}

export default connect(MapStateToProps, MapDispatchToProps)(AddStudentsToGroupDialog);