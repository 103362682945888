const studentsService = {
    getStudentsInCurrentGroup,
    getStudentIdsInCurrentGroup
};

function getStudentIdsInCurrentGroup(state)
{
    const studentsInGroup = getStudentGroupsForCurrentGroup(state).map(sg => sg.studentId);
    return studentsInGroup;
}

function getStudentGroupsForCurrentGroup(state)
{
    const currentGroup = state.teacher.groups.find(g => g.isCurrent);
    const studentsInGroup = state.teacher.studentGroups.filter(sg => sg.groupId === currentGroup.id);
    return studentsInGroup;
}

function getStudentsInCurrentGroup(state)
{
    const studentIds = getStudentIdsInCurrentGroup(state);
    const students = state.teacher.students.filter(s => studentIds.includes(s.id));
    return students;
}

export default studentsService;