import ru from './ru';
import en from './en';
import uk from './uk';
import he from './he';
const locales = {
    ru,
    uk,
    en,
    he
}
export default locales;
