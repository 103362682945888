import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ProblemInHomework from './ProblemInHomework';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import HomeworkProgressBar from './HomeworkProgressBar';
import { useSelector } from 'react-redux';
import translate from 'src/helpers/translate';

export default function HomeworkSolving({ homework, problems }) {
    const useStyles = makeStyles((theme) => ({
        root: {
            paddingTop: -10
        }
    }));
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(problems[0].id)
    }, [problems[0].id])

    const clientProblems = useSelector(state => state.common.clientProblems);

    const homeworkRatingsCount = homework.problems.reduce((sum, currentProblem) => {
        const problem = problems.find(p => p.id == currentProblem);
        return sum + problem.level;
    }, 0);

    const homeworkMaxRating = homework.neededRating * homeworkRatingsCount;

    const studentHomeworkRatingsCount = homework.problems.reduce((sum, currentProblem) => {
        const clientProblem = clientProblems.find(cp => cp.problemId == currentProblem);
        const clientProblemRating = clientProblem?.rating ?? 0;
        return sum + clientProblemRating;
    }, 0);

    const result = studentHomeworkRatingsCount * 100 / homeworkMaxRating;

    const rating = Math.ceil(result);

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <HomeworkProgressBar progress={rating} />
            <TabContext value={value}>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {problems.map(problem => (
                            <Tab
                                key={`tabforproblem_${problem.id}`}
                                label={translate('MyWorkResources_Task') + ' ' + problem.id}
                                value={problem.id}
                            />
                        ))}
                    </Tabs>
                </Box>
                {problems.map(problem => (
                    <TabPanel
                        key={`TabContentForProblemInHomework_${problem.id}`}
                        value={problem.id}>
                        <ProblemInHomework
                            key={`ProblemInHomework_${problem.id}`}
                            homework={homework}
                            problem={problem}
                        />
                    </TabPanel>
                ))}
            </TabContext>
        </Box>
    );
}