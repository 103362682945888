import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Translated from '../../src/localization/translations'
import translate from 'src/helpers/translate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SubmitButton = ({ isSubmitting, canBeSubmitted, onSubmit, text }) =>  {
  const classes = useStyles();
  const disabled = isSubmitting || !canBeSubmitted;
  const buttonClassname = clsx({
    [classes.buttonSuccess]: !isSubmitting,
  });

  const handleButtonClick = () => {
    if (!isSubmitting) {
        onSubmit();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          className={buttonClassname}
          disabled={disabled}
          onClick={handleButtonClick}
        >
          {text ?? translate("ProfileResources_SaveChanges")}
        </Button>
        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  canBeSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  text: PropTypes.string
}

export default SubmitButton;