import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import translate from 'src/helpers/translate';
import SubmitButton from 'src/utils/SubmitButton';

export default function AddNewTestDialog(props){
    const { open, setOpen, onSubmit } = props;
    const [ description, setDescription] = useState(''); 
    const testsAreUpdating = useSelector(state => state.teacher.testsAreUpdating);

    const handleClose = () => {
        setOpen(false);
    };

    const submit = () => {
        onSubmit(description);
        setOpen(false);
        setDescription('');
    };

    const handleHomeworkDescriptionChange = (event) => {
        let name = event.target.value;
        setDescription(name);
    };

    const handleKeyPress = (event) => {
        if (event.charCode === 13) {
            submit();
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate('CreateNewTest')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate('PleaseProvideNameForTest')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        size="small"
                        id="name"
                        label={translate('Name')}
                        type="string"
                        value={description}
                        onKeyPress={handleKeyPress}
                        onChange={handleHomeworkDescriptionChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleClose}
                        color="primary">
                        {translate('Cancel')}
                    </Button>
                    <SubmitButton 
                        isSubmitting={testsAreUpdating} 
                        canBeSubmitted={description !== ''} 
                        onSubmit={submit}
                        />
                </DialogActions>
            </Dialog>
        </div>
    );
}