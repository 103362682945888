import React from 'react';
import { Card, CardContent, CardHeader,  Divider } from '@mui/material';
import translate from 'src/helpers/translate';

const ProblemSolvingTimeStats = ({ generatedProblems, ...rest }) => {

  let data = [];
  if(generatedProblems && generatedProblems.length > 0)
  {
    data = generatedProblems.filter(p =>
      p.answeredCorrectly !== null);
  }

  const allTime = data.filter(d => d.solvingTime < 60 * 60 * 24).reduce( function(a, b){
                    return a + b.solvingTime;
                  }, 0);  
  const avarageTime = data.length > 0 ? allTime / data.length : 0;

  const avarageTimeSpan = new Date(avarageTime * 1000).toISOString().substr(11, 8);
  
  return (
    <Card>
      {/* <CardHeader title={translate('SolvingTime') + ' (~ ' + avarageTimeSpan + ')'} /> */}
      {/* <Divider /> */}
      <CardContent>
        {React.cloneElement(rest.children, { generatedProblems })}
      </CardContent>
    </Card>
  );
};

export default ProblemSolvingTimeStats;
