import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/reducers/student/homeworks';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';
import { Card, CardContent, Typography, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -35
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    }, backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    grid: {
        cursor: 'pointer'
    }
}));

export default function HomeworksGrid() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const classes = useStyles();


    const homeworks = useSelector(state => state.student.homeworks);
    const problems = useSelector(state => state.common.problems);
    const clientProblems = useSelector(state => state.common.clientProblems);
    const topics = useSelector(state => state.common.topics);

    const [backdropOpen, setBackdropOpen] = useState(homeworks.areUpdating);


    const calculateHomeworkRating = (homework) => {

        const homeworkRatingsCount = homework.problems.reduce((sum, currentProblem) => {
            const problem = problems.find(p => p.id == currentProblem);
            return sum + problem.level;
        }, 0);

        const homeworkMaxRating = homework.neededRating * homeworkRatingsCount;

        const studentHomeworkRatingsCount = homework.problems.reduce((sum, currentProblem) => {
            const clientProblem = clientProblems.find(cp => cp.problemId == currentProblem);
            const clientProblemRating = clientProblem?.rating ?? 0;
            return sum + clientProblemRating;
        }, 0);

        const result = studentHomeworkRatingsCount * 100 / homeworkMaxRating;

        return Math.ceil(result) + '%';
    }

    let result = homeworks.list.map(h => {
        return {
            ...h,
            topics: topics.filter(t => h.problems.map(hp => problems.find(p => p.id == hp)).some(p => p.endTopic == t.id)).map(t => translate(`TopicResources_${t.id}`)).filter(onlyUnique).join(', '),
            rating: calculateHomeworkRating(h)
        }
    })

    const handleCloseBackdrop = () => {
        setBackdropOpen(false);
    };

    const handleRowClick = (params) => {
        navigate(`/app/homework/${params.id}`, { replace: true });
    };

    useEffect(() => {
        dispatch(actionCreators.requestHomeworks());
        dispatch(clientProblemsActionCreators.requestClientProblems());
    }, [dispatch])

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'description',
            flex: 2,
            headerName: translate('Description'),
            sortable: false
        },
        {
            field: 'topics',
            flex: 3,
            headerName: translate('Topics'),
            renderCell: (params) => (
                <Tooltip title={params.value} className={classes.toolTip} arrow>
                    <span>
                        {params.value}
                    </span>
                </Tooltip>
            )
        },
        {
            field: 'createdOnString',
            flex: 1,
            headerName: translate('CreatedOn'),
            valueGetter: (params) => params.row.id,
            renderCell: (params) => <React.Fragment>{params.row.createdOnString}</React.Fragment>
        },
        {
            field: 'rating',
            flex: 1,
            headerName: translate('Rating')
        }
    ];

    if (homeworks.list.some(h => h.endDate)) {
        columns.push({
            field: 'endDateString',
            flex: 1,
            headerName: translate('DueDate'),
        })
    }

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Backdrop className={classes.backdrop} open={backdropOpen} onClick={handleCloseBackdrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {!homeworks.areUpdating && homeworks.list.length < 1 && (
                        <Typography>
                            {translate("NoHomeworksAssignedYet")}
                        </Typography>
                    )}
                    {result.length > 0 && (
                        <DataGrid
                            loading={homeworks.areUpdating}
                            rows={result}
                            density="compact"
                            columns={columns}
                            pageSize={15}
                            autoHeight={true}
                            disableColumnMenu
                            className={classes.grid}
                            onRowClick={handleRowClick}
                            hideFooter={result.length <= 15}
                            hideFooterSelectedRowCount />
                    )}
                </CardContent>
            </Card>
        </React.Fragment>

    );
}