const he = {
    LoginResources_ExternalLoginConfirmation_Text2: 'נא הכנס את הדוא"ל שלך למטה כדי להשלים את ההרשמה',
    LoginResources_ExternalLoginConfirmation_Text1: 'ביצעת אימות בהצלחה עם',
    LoginResources_ExternalLoginConfirmation_UnseccessfulLogin: 'כניסה לא מוצלחת מהשרת',
    LoginResources_SubmitButton: 'להיכנס',
    LoginResources_LoginError: 'הסיסמה או שם המשתמש הוזנו בצורה שגויה',
    LoginResources_ResetPassword_Header: 'שנה את סיסמתך',
    LoginResources_ForgotPassword: 'שכחת ססמה?',
    LoginResources_Header: 'השתמש בחשבון מקומי כדי להיכנס',
    LoginResources_ForgotPassword_emailButton: 'שלח קוד',
    LoginResources_ResetPasswordTopic: 'איפוס סיסמא',
    LoginResources_ForgotPassword_SuccessEmailSent: 'אנא בדוק את הדוא"ל שלך לשחזור הסיסמה',
    LoginResources_Password_Label: 'סיסמה',
    LoginResources_Login_Label: 'התחברות',
    LoginResources_ResetPassword_SubmitButton: 'שינוי',
    LoginResources_RegisterButton: 'אין לך חשבון? רשום משתמש חדש',
    LoginResources_ResetPassword_SuccessResseting: 'הסיסמה שלך שונתה בהצלחה. אנא',
    LoginResources_RememberMe_Label: 'לזכור אותי',
    LoginResources_ExternalLoginHeader: 'היכנס בלחיצה אחת באמצעות:',
    CreateWorkResources_NoStudents: 'אין לך עדיין תלמידים',
    CreateWorkResources_AddNewGroupStudent: 'לְהוֹסִיף',
    CreateWorkResources_ChooseSpecTasks: 'בחר משימות ספציפיות',
    CreateWorkResources_TimeWholeTest: 'בחר זמן לכל העבודה',
    CreateWorkResources_AddChosenTasks: 'הוסף משימות נבחרות',
    CreateWorkResources_ChooseTasksByAmount: 'בחר לפי מספר המשימות בכל רמה',
    CreateWorkResources_ChooseTasksAllTasksInTopic: 'קומפילציה עבור כל המשימות מהנושא הזה',
    CreateWorkResources_TimeInLevel: 'בחר את מספר הדקות עבור משימה אחת ברמה',
    CreateWorkResources_ChooseStudents: 'בחר תלמידים',
    CreateWorkResources_TestWithoutTimeButton: 'בלי זמן',
    CreateWorkResources_ChooseTaskValidation: 'אנא בחר משימה אחת לפחות',
    CreateWorkResources_ChoseeTasks: 'בחר משימות',
    CreateWorkResources_ChosenScore: 'נקודות נבחרות',
    CreateWorkResources_SuccessfullyAddedWork: 'נוסף בהצלחה',
    CreateWorkResources_ChosenTasks: 'משימות נבחרות',
    CreateWorkResources_ChooseTimeValidation: 'בחר זמן',
    CreateWorkResources_NoGroups: 'עדיין אין לך קבוצות',
    CreateWorkResources_RetakeAmount: 'מספר שינויים',
    CreateWorkResources_NeededRaiting: 'דירוג רצוי',
    CreateWorkResources_EndDate: 'תאריך סיום צפוי',
    CreateWorkResources_ChooseScore: 'בחר נקודות',
    CreateWorkResources_ChooseScoreValidation: 'בחר את מספר הנקודות',
    CreateWorkResources_SuccessfullyAddedHomeWork: 'נוסף בהצלחה',
    CreateWorkResources_ChooseStudentsValidation: 'אנא בחר תלמיד אחד לפחות',
    CreateWorkResources_UnlimitedTime: 'בלי זמן',
    CreateWorkResources_CreateWorkButton: 'צור עבודה',
    CreateWorkResources_ChosenStudents: 'תלמידים נבחרים',
    CreateWorkResources_NeededRaitingToolTip: 'הדירוג אליו יש לפתור כל בעיה על מנת להשלים את שיעורי הבית',
    CreateWorkResources_AnotherTopic: 'בחר נושא אחר',
    CreateWorkResources_ChosenGroups: 'קבוצות נבחרות',
    CreateWorkResources_RemoveAll: 'הסר את כל המשימות',
    CreateWorkResources_YourStudents: 'התלמידים שלך',
    CreateWorkResources_ExamName: 'שם הבחינה',
    CreateWorkResources_ChooseTime: 'בחר זמן',
    CreateWorkResources_TasksInTopic: 'משימות נושא',
    CreateWorkResources_Minutes: 'דקות',
    CreateWorkResources_CalculateButton: 'לספור',
    CreateWorkResources_YourGroups: 'הקבוצות שלך',
    CreateWorkResources_ChosenTime: 'זמן נבחר',
    CreateWorkResources_HomeWorkName: 'כותרת שיעורי הבית',
    CreateWorkResources_TestName: 'שם המבחן',
    CreateWorkResources_ChooseTasksConfirm: 'לְאַשֵׁר',
    CreateWorkResources_AddStudentsButton: 'הוסף תלמידים נבחרים',
    CreateWorkResources_ChooseScoreExplanation: 'בחר ניקוד עבור הבעיה שנפתרה בצורה נכונה בכל רמה',
    ChooseStudentOrGroupToWorkWith: 'בחר תלמיד או קבוצה',
    GeneratedProblem: 'משימה שנוצרה',
    ChooseScoreForLevel: 'בחר ניקוד עבור בעיה שנפתרה כהלכה ב-{0}',
    TestEdition: 'ערוך מבחן',
    MyBoards: 'הלוחות שלי',
    BoardWasAdded: 'לוח {0} נוסף בהצלחה',
    StudentsBoards: 'לוחות תלמידים',
    PleaseProvideNameForBoard: 'כותרת ללוח',
    CreateNewBoard: 'צור לוח חדש',
    Create: 'לִיצוֹר',
    Customers: 'משתמשים',
    Groups: 'קבוצות',
    Teachers: 'מורים',
    MyWorkResources_MaxScore: 'ציון מקסימלי',
    TestStudentRetakeWasAdded: 'בדיקה חוזרת נוספה בהצלחה לתלמיד',
    Students: 'סטודנטים',
    WhiteBoards: 'לוחות מקוונים',
    Help: 'עֶזרָה',
    Journal: 'מגזין',
    UpdateGroupName: 'שנה קבוצה',
    Language: 'שפה',
    GroupName: 'שֵׁם',
    GroupIdentifier: 'מזהה',
    CreateNewGroupTitle: 'צור קבוצה חדשה',
    YouAlreadyHaveAGroupWithSuchName: 'יש לך כבר קבוצה עם אותו שם?',
    CreateNewGroupInfo: 'נא להזין שם לקבוצה',
    Cancel: 'לְבַטֵל',
    Accept: 'לקבל',
    CancelRequest: 'לְבַטֵל',
    Requested: 'התבקש',
    Added: 'נוסף',
    RemoveFromStudents: 'הניחו',
    RemoveStudent: 'להסיר את {0} מהתלמידים שלך?',
    DeleteGroup: 'למחוק את הקבוצה {0}?',
    NewRequest: 'בקשה חדשה',
    Level1: 'שלב 1',
    Level2: 'שלב 2',
    Level3: 'רמה 3',
    Solve: 'לְהַחלִיט',
    Rating: 'דֵרוּג',
    AvarageRating: 'דירוג ממוצע',
    CurrentRating: 'דירוג נוכחי',
    CheckAnswer: 'סמן תשובה',
    NextProblem: 'הַבָּא',
    PreviousProblem: 'קודם',
    PleaseSelectAnswer: 'אנא בחר תשובה',
    PleaseProvideAnswer: 'נא להזין תשובה',
    RequestWasCanceled: 'בקשת התלמיד נדחתה',
    RequestWasAccepted: 'תלמיד נוסף',
    StudentWasRemoved: 'חניך הוסר',
    ChangeLanguage: 'שנה שפה',
    NoStudentsFound: 'לא נמצא',
    UserWithSuchLoginAlreadyRegistered: 'משתמש עם התחברות זו כבר רשום',
    ProvideEmailOrLogin: 'הזן שם משתמש או דוא"ל לתלמיד',
    SharedResources_Ukrainian: 'אוקראינית',
    SharedResources_Submit: 'לְאַשֵׁר',
    SharedResources_IAccept: 'אני מקבל',
    SharedResources_Password: 'סיסמה',
    SharedResources_Groupidentifier: 'מזהה קבוצה (אופציונלי)',
    SharedResources_English: 'אנגלית',
    SharedResources_Confirmpassword: 'אשר סיסמה',
    SharedResources_Russian: 'רוּסִי',
    SharedResources_Rememberme: 'לזכור אותי?',
    History: 'כַּתָבָה',
    SearchForProblem: 'חיפוש משימה לפי מזהה',
    WrongProblemId: 'אין משימה עם מזהה: {0}',
    Wrong: 'לא כמו שצריך',
    Correct: 'נכונה',
    Incorrect: 'לא כמו שצריך',
    SolvingHistory: 'היסטוריית פתרונות',
    TestResultFor: 'תוצאות בדיקה עבור',
    AvgSolvingTime: 'ממוצע זמן פתרון',
    Unsolved: 'אין תשובה',
    CorrectAnswerIs: 'תשובה נכונה',
    StatisticsForProblem: 'סטטיסטיקה של משימות',
    SolvingTimeDescription: 'זמן פתרון (ים)',
    SolvingTime: 'זמן פתרון',
    Solved: 'החליט',
    Date: 'התאריך',
    Link: 'קישור',
    EditionAllowed: 'מותר לערוך',
    ShareWith: 'שתף עם',
    BoardWasSharedWithStudent: 'שיתפת בהצלחה לוח עם תלמיד',
    BoardWasSharedWithGroup: 'שיתפת בהצלחה לוח עם קבוצה',
    EditionOfTheBoardAllowed: 'התלמיד הורשה לערוך את הלוח',
    EditionOfTheBoardProhibitted: 'התלמיד לא הורשה לערוך את הלוח',
    DeleteBoard: 'להסיר לוח?',
    BoardWasDeleted: 'הלוח נמחק בהצלחה',
    TrainingWasAdded: 'המאמן נוסף בהצלחה',
    TrainingWasDeleted: 'ההתקנה של המאמן הוסר בהצלחה',
    VideoWasAdded: 'הסרטון נוסף בהצלחה',
    VideoWasRemoved: 'הסרטון נמחק בהצלחה',
    HelpInRegistration: 'עזרה ברישום',
    WhatIsAProblem: 'מהי משימה',
    AvarageShort: 'ימי רביעי',
    RatingWillNotBeChangedWarning: 'הדירוג לא ישתנה מאחר שכבר ענית על משימה זו',
    GenerateNew: 'לְהִתְחַדֵשׁ',
    OpenWhiteBoard: 'פתח טיוטה',
    CloseWhiteBoard: 'סגור טיוטה',
    GenerateNewTooltip: 'הדירוג שלך יופחת לאחר 3 פעמים יצירת משימה ללא מענה',
    GenerateNewTooltipWarning: 'זה הדור השלישי שלך לבעיה ללא מענה זו. הדירוג שלך ירד!',
    EmailTemplatesResources_NewHomeworkIsSentTemplate_Subject: 'שיעורי בית חדשים',
    SolvingStepsTooltipWarning: 'לא תוכל להעלות את הדירוג של משימה זו אם תסתכל על הרמז',
    SolvingStepsTooltip: 'צפה ברמז',
    CorrectAnswerSnackBar: 'התשובה נכונה. הדירוג הועלה ב-1',
    WrongAnswerSnackBar: 'תשובה לא נכונה',
    ShowTheory: 'תיאוריה לבעיה זו',
    ShowProblemExample: 'דוגמה לפתרון בעיה דומה',
    EduVideos: 'סרטונים חינוכיים',
    ForAdmin: 'למנהל',
    ShowStatistics: 'הצג נתונים סטטיסטיים',
    Statistics: 'סטָטִיסטִיקָה',
    NeededRating: 'דירוג רצוי',
    Sent: 'נשלח',
    Description: 'שֵׁם',
    Topics: 'משימות',
    DueDate: 'טווח',
    StudentRatingHasBeenChanged: 'הדירוג של התלמיד עבור משימה זו קיבל שם',
    HomeworNeededRatingHasBeenChanged: 'הדירוג הרצוי עבור שיעורי הבית השתנה בהצלחה',
    Progress: 'התקדמות',
    CreatedOn: 'נוצר',
    Send: 'לִשְׁלוֹחַ',
    Rating: 'דֵרוּג',
    Status: 'סטָטוּס',
    Delete: 'לִמְחוֹק',
    Yes: 'כן',
    BackToBoards: 'חזרה לכל הלוחות',
    ChangeName: 'שנה שם',
    ChangeNameTitle: 'נא להזין שם חדש',
    ChooseStudentsToAdd: 'בחר תלמידים',
    ChooseGroupsToAdd: 'בחר קבוצות',
    ChooseProblemForWork: 'בחר משימות לעבוד עליהן',
    StudentsWereSuccessfullyUpdated: 'התלמידים עודכנו בהצלחה',
    ProblemsWereSuccessfullyUpdated: 'המשימות עודכנו בהצלחה',
    GroupsWereSuccessfullyUpdated: 'הקבוצות עודכנו בהצלחה',
    NameWasSuccessfullyChanged: 'השם שונה בהצלחה',
    StudentProblemInTestTooltip: 'צפה במשימה שנוצרה',
    HomeworkWasSuccessfullySent: 'שיעורי בית נשלחו בהצלחה לתלמידים',
    TestWasSuccessfullySent: 'המבחן נשלח בהצלחה לתלמידים',
    HomeworkWasDeleted: 'שיעורי בית הוסרו',
    TestWasDeleted: 'הבדיקה נמחקה בהצלחה',
    DuplicateWork: 'עבודה כפולה',
    HomeworkNeedsToBeCompletedBeforeSent: 'שיעורי בית חייבים לכלול משימות ותלמידים להגיש',
    PleaseProvideNameForHomework: 'נא להזין שם עבור שיעורי הבית החדשים',
    HomeworkWasSuccessfullyAdded: 'שיעורי בית {0} נוספו',
    TestWasSuccessfullyAdded: 'הבדיקה {0} נוספה',
    CreateNewHomework: 'צור שיעורי בית חדשים',
    PleaseProvideNameForTest: 'נא להזין שם למבחן החדש',
    CreateNewTest: 'צור מבחן חדש',
    StudentRatingInHomeworkTooltip: 'דירוג סטודנטים. לחץ כדי לראות היסטוריית פתרונות',
    WrongAnswerRatingDecreasedSnackBar: 'תשובה לא נכונה. הדירוג ירד ב-1',
    EmailTemplatesResources_NewTestIsSentTemplate_Subject: 'מבחן חדש',
    GlobalResources_Slider_Header_Homework: 'משימות ביתיות',
    GlobalResources_Layout_Header_Service: 'שירותים',
    GlobalResources_NewMessageEmailTopic: 'הודעה חדשה עבור',
    GlobalResources_AreYouSureYouWantToRemove: 'אתה בטוח שאתה רוצה להסיר',
    GlobalResources_Slider_Header_Teacher: 'חדר מורים',
    GlobalResources_NotFoundDescription: 'העמוד שאתה מחפש לא קיים. אולי הנתיב לדף צוין בצורה שגויה',
    GlobalResources_OnlineDescLabel: 'לוח מקוון',
    GlobalResources_RemoveFromStudents: 'מהתלמידים שלך?',
    GlobalResources_RemoveFromGroup: 'להסיר מהקבוצה הזו?',
    GlobalResources_ConfirmYourAccount: 'אמת את חשבונך',
    GlobalResources_Layout_Header_Home: 'בית',
    GlobalResources_Layout_Header_Lang: 'he',
    GlobalResources_Slider_Header_Test: 'בדיקה',
    GlobalResources_Slider_Header_Exam: 'מבחן',
    GlobalResources_Layout_Header_Registr: 'הַרשָׁמָה',
    GlobalResources_RemoveFromHomeTest: 'מהמבחן הזה?',
    ChooseGroupOrStudent: 'בחר קבוצה או תלמיד',
    GlobalResources_RemoveFromHomeWork: 'משיעורי הבית האלה?',
    GlobalResources_EmailConfirmText2: 'אשר את ההרשמה שלך על ידי לחיצה',
    GlobalResources_EmailConfirmText3: 'כאן',
    GlobalResources_EmailConfirmText0: 'שלום,',
    GlobalResources_EmailConfirmText1: 'תודה שנרשמת ל- MathLife.',
    GlobalResources_Layout_Header_Hello: 'היי',
    GlobalResources_Layout_Header_Login: 'להיכנס',
    GlobalResources_MessageSendToEmal: 'ההודעה תישלח אל',
    GlobalResources_RequiredFieldValidation: 'השדה לא יכול להיות ריק',
    GlobalResources_ApplicationErrorMessage: 'שגיאה בשרת. בבקשה נסה שוב מאוחר יותר. הצוות שלנו כבר בוחן את הנושא הזה. תודה',
    GlobalResources_AreYouSureYouWantTo: 'אתה בטוח שאתה רוצה',
    GlobalResources_Tests: 'מבחנים',
    GlobalResources_Today: 'היום',
    GlobalResources_DbConnectionFailed: 'משהו השתבש. אנא נסה שוב בעוד מספר דקות.',
    GlobalResources_Layout_Header_LogOff: 'צא החוצה',
    GlobalResources_ProblemMistakeTopicEmail: 'נמצאה שגיאה במשימה',
    GlobalResources_RemoveFromTeachers: 'מהמורים שלך?',
    GlobalResources_Slider_Header_Education: 'חינוך',
    GlobalResources_Slider_MoreDetails_Button: 'יותר',
    GlobalResources_NotFoundLabel: 'הדף לא נמצא',
    MyWorkResources_RightAnswer: 'תשובה נכונה',
    MyWorkResources_NewHomeWorkSubject: 'שיעורי בית חדשים',
    MyWorkResources_RetakeTest: 'גש שוב למבחן',
    MyWorkResources_EnoughHomeworkRating: 'כל המשימות נפתרו לפחות עד לדירוג $NeededRating.',
    MyWorkResources_TaskIds: 'משימות',
    MyWorkResources_WithoutTime: 'לא מוגבל',
    MyWorkResources_Teacher: 'מוֹרֶה',
    MyWorkResources_AnswerReceived: 'התשובה התקבלה',
    MyWorkResources_ContinueTest: 'המשך בבדיקה',
    MyWorkResources_TakesLeft: 'השינוי שנותר',
    MyWorkResources_Points: 'נקודות',
    MyWorkResources_ShowSolvedTest: 'צפה במבחן',
    MyWorkResources_NotEnoughHomeworkRating: 'לא כל המשימות נפתרות לפחות עד לדירוג $NeededRating.',
    MyWorkResources_StartDate: 'תאריך היצירה',
    MyWorkResources_Students: 'תלמידים',
    MyWorkResources_A: 'אחד',
    MyWorkResources_C: '2',
    AddRetake: 'הוסף צילום חוזר',
    TestTopics: 'חידון נושא',
    TestProblemsCount: 'מספר משימות',
    TestSolvingTime: 'הגיע הזמן לפתור את המבחן',
    TestMaxScore: 'ציון מקסימלי',
    EditTest: 'ערוך מבחן',
    Add: 'לְהוֹסִיף',
    Mark: 'כיתה',
    RetakesLeft: 'מחזירים שמאלה',
    MaxScore: 'מקסימום כיתה',
    Time: 'זְמַן',
    MyWorkResources_B: '3',
    MyWorkResources_Sum: 'סְכוּם',
    MyWorkResources_Level: 'רָמָה',
    MyWorkResources_Tasks: 'משימות',
    MyWorkResources_Score: 'צברו נקודות',
    MyWorkResources_SolvedProblems: 'פתרו בעיות',
    MyWorkResources_BeginTest: 'התחל בדיקה',
    MyWorkResources_HomeWorkLabel: 'שיעורי בית',
    MyWorkResources_CurrentTests: 'בדיקות נוכחיות',
    MyWorkResources_RightAnswerWithoutRounding: 'תשובה נכונה ללא עיגול',
    MyWorkResources_FinishTest: 'סיים את המבחן',
    MyWorkResources_Raiting: 'דֵרוּג',
    MyWorkResources_RaitingToAdd: 'לדירוג עבור משימה זו',
    MyWorkResources_SolvedTests: 'פתרו מבחנים',
    MyWorkResources_Task: 'מְשִׁימָה',
    MyWorkResources_SeeResults: 'צפה בתוצאות',
    NotificationResources_ClientsConnectedForStudent: 'המורה {ClientName} נוסף למורים שלך',
    NotificationResources_ClientsConnectedForTeacher: 'הסטודנט {ClientName} נוסף לתלמידים שלך',
    ProblemResources_TheoryLink: 'קישור לתיאוריה',
    ProblemResources_RightAnswer: 'תשובה נכונה',
    ProblemResources_NextTask: 'הַבָּא',
    ProblemResources_OpenDraft: 'פתח טיוטה',
    ProblemResources_PrevTask: 'קודם',
    ProblemResources_FoundAmistake: 'מצאתם שגיאה?',
    Mistakes: 'שגיאות במשימות',
    CloseMistake: 'שגיאה קרובה',
    MistakeHasBeenClosed: 'השגיאה נסגרה בהצלחה והתגובה נשלחה ללקוח',
    NoMistakesInProblemsRaisedYet: 'עד כה לא נשלחו באגים',
    NoHomeworksAssignedYet: 'לא הוקצו לך שיעורי בית',
    ProblemResources_MistakeKind1: 'התשובה הנכונה היא שגויה',
    ProblemResources_MistakeKind3: 'חלון שגיאה הופיע לאחר אישור התשובה',
    ProblemResources_MistakeKind2: 'אין תשובה נכונה',
    ProblemResources_MistakeKind4: 'עוד טעות',
    ProblemResources_RatingAfterAnswer: 'דירוג אחרי תשובה',
    ProblemResources_ExampleLink: 'הצג דוגמה',
    ProblemResources_CloseDraft: 'סגור טיוטה',
    ProblemResources_CorrectAnswer: 'התשובה נכונה!',
    ProblemResources_MistakeSendingExplanation: 'על ידי לחיצה על הלחצן למטה, אתה שולח בקשה לצוות שלנו לבדיקת השגיאה. אנו מבטיחים לבדוק את בקשתך תוך 24 שעות ולתת לך מענה. תודה שהפכת אותנו לטובים יותר.',
    ProblemResources_RatingAfterGeneration: 'דירוג אחרי יצירת משימות',
    ProblemResources_TestAssign: 'סט מבחן',
    ProblemResources_DecisionTextForMistake: 'טקסט החלטה',
    ProblemResources_ConfirmButton: 'לְאַשֵׁר',
    ProblemResources_KindOfMistake: 'בחר סוג שגיאה',
    ProblemResources_NextLevel: 'שלב הבא',
    ProblemResources_WrongAnswer: 'התשובה שגויה!',
    ProblemResources_SolvingOfTheProblem: 'פתרון הבעיה',
    ProblemResources_PrevLevel: 'רמה קודמת',
    ProblemResources_CloseMistakeButton: 'סגור ושלח פתרון',
    ProblemResources_InitialRating: 'דירוג משימות ראשוני',
    ProblemResources_RightAnswerWithoutRounding: 'תשובה נכונה ללא עיגול',
    ProblemResources_KindOfMistakeWord: 'סוג שגיאה',
    ProblemResources_TaskExample: 'דוגמה לפתרון בעיה דומה',
    ProblemResources_MistakeDetailsWord: 'פרטי שגיאה',
    ProblemResources_HomeworkAssign: 'ערכת שיעורי בית',
    ProblemResources_DetailsOfMistake: 'כמה מילים על השגיאה',
    ProblemResources_Trainings: 'סימולטורים',
    ProblemResources_CloseMistakeEmailTopic: 'שגיאה במשימה שנחשבה',
    ProblemResources_CheckingAnswer: 'בודק את התשובה שלך...',
    ProfileResources_DataWasSaved: 'הנתונים נשמרו',
    RequestSent: 'בקשת התלמידים נשלחה בהצלחה',
    MistakeHasBeenSent: 'שגיאה במשימה נשלחה בהצלחה',
    StudentsInGroupWereChanged: 'התלמידים בקבוצה שונו בהצלחה',
    ProfileResources_Title: 'פּרוֹפִיל',
    ProfileResources_Surname: 'שֵׁם מִשׁפָּחָה',
    ProfileResources_ChangeAvatarLink: 'שנה תמונת פרופיל',
    ProfileResources_ProfileInfoError: 'אירעה שגיאה במהלך שמירת הנתונים שלך. ודא שהנתונים שאתה מזין נכונים',
    ProfileResources_PictureNotMoreThan: 'לא יותר מ-4MB',
    ProfileResources_School: 'בית ספר',
    ProfileResources_SaveChanges: 'להציל',
    ProfileResources_InvalidMessage: 'השדה מלא בשגיאות',
    ProfileResources_Email: 'אימייל',
    ProfileResources_Close: 'סגור',
    ProfileResources_Login: 'התחברות',
    ProfileResources_Phone: 'טֵלֵפוֹן',
    ProfileResources_LastName: 'שם אמצעי',
    ProfileResources_EmailParents: 'אימייל להורים',
    ProfileResources_PictureIsTooBig: 'התמונה גדולה מדי. אנא בחר תמונה שאינה גדולה מ-4MB',
    ProfileResources_AvatarError: 'שגיאה בטעינת הקובץ. נסה לא להשתמש במכשירים ניידים או נסה להעלות קובץ אחר',
    ProfileResources_ChooseFile: 'בחר קובץ',
    ProfileResources_DtBirthday: 'תאריך לידה',
    ProfileResources_City: 'עִיר',
    ProfileResources_Name: 'שֵׁם',
    RegistrationResources_RegistrConfirmation_Link: 'לחץ על הקישור כדי להיכנס',
    RegistrationResources_Student: 'סטוּדֶנט',
    RegistrationResources_Error_Login_Exists: 'התחברות זו כבר רשומה. נסה אחר',
    RegistrationResources_ConfirmPasswordLabel: 'אימות סיסמה',
    RegistrationResources_SubmitButton: 'הַרשָׁמָה',
    RegistrationResources_ErrorRegistration: 'התרחשה שגיאה. אנא נסה שוב',
    RegistrationResources_LoginLabel: 'התחברות',
    RegistrationResources_Header: 'הַרשָׁמָה',
    RegistrationResources_Teacher: 'מוֹרֶה',
    RegistrationResources_Error_ConfirmPassword_Match: 'סיסמאות לא תואמות',
    RegistrationResources_PasswordLabel: 'סיסמה',
    RegistrationResources_Error_Password_Regex: 'הסיסמה חייבת להכיל לפחות אות אחת גדולה, אות קטנה ומספר',
    RegistrationResources_Error_Login_Min: 'השדה לא יכול להכיל פחות מ-2 תווים',
    RegistrationResources_Error_Empty: 'השדה לא יכול להיות ריק',
    RegistrationResources_Iam: 'אני',
    RegistrationResources_Reset: 'שנה סיסמא',
    RegistrationResources_Error_Password_Min: 'השדה לא יכול להכיל פחות מ-8 תווים',
    RegistrationResources_Error_Email_Exists: 'האימייל הזה כבר נרשם.',
    RegistrationResources_RegistrConfirmation: 'תודה שנרשמתם. אנא',
    RegistrationResources_Error_Email_LogInAs: 'התחבר בשם',
    RegistrationResources_Error_Email_Invalid: 'נא להזין אימייל חוקי',
    Settings: 'הגדרות',
    TestResources_RegenerateTest: 'חידוש בדיקות',
    TestResources_TakeNumber: 'שינוי',
    TestResources_RetakeTest: 'גש שוב למבחן',
    TestResources_RightAnswerText: 'תשובה נכונה :',
    TestResources_Closed: 'סָגוּר',
    TestResources_RegenerateStudentTest: 'צור מחדש משימות',
    TestResources_IsSent: 'נשלח לתלמידים',
    TestResources_TestInQueueText: 'בתור להפקה',
    TestResources_AnswerRecieved: 'התשובה התקבלה',
    TestResources_GenerateTest: 'הפקת בדיקות',
    TestResources_ProblemNotGenerated: 'המשימה לא נוצרה',
    TestResources_BackToTest: 'חזרה למבחן',
    TestResources_Solved: 'נפתר',
    TestResources_AnswerNotReceived: 'לא התקבלה תגובה. בבקשה נסה שוב. המורה שלך יודיע על כך.',
    TestResources_ConfirmRetake: 'האם אתה בטוח שאתה רוצה לגשת שוב למבחן?',
    TestResources_ConfirmBegin: 'האם אתה בטוח שאתה רוצה להתחיל את המבחן?',
    TestResources_Filter_ByName: 'לפי השם',
    TestResources_Filters_ToSend: 'למשלוח',
    TestResources_RefreshTestAfterGeneration: 'רענן את הדף',
    TestResources_Creating: 'המבחן נוצר',
    TestResources_GenerateStudentTest: 'יצירת משימות',
    TestResources_ConfirmFinish: 'האם אתה בטוח שאתה רוצה להשלים את המבחן?',
    TestResources_Filter_Score: 'התוצאה הטובה ביותר',
    TestResources_Filter_BestTake: 'השינוי הכי טוב',
    TestResources_New: 'חָדָשׁ',
    TestResources_DueDate: 'רוץ ל',
    TestResources_ContinueSolving: 'המשך לפתור',
    TestResources_Filters_StartingWithLast: 'מהאחרון',
    TestResources_GenerateTestTooltip: 'יצירת משימות עשויה להימשך מספר דקות',
    TestResources_CreatedOn: 'תאריך היצירה',
    TestResources_TestIsSolving: 'נפתרה',
    TestResources_BackToTests: 'חזרה למבחנים',
    TestResources_BeginTest: 'התחל בדיקה',
    TestResources_SendToStudents: 'שלח לתלמידים',
    TestResources_Filter_LastTake: 'כניעה אחרונה',
    TestResources_Generating: 'מבחן נוצר',
    ChooseSubject: 'בחר נושא',
    GoToTopic: 'עבור לנושא',
    TopicResources_0: 'כל הנושאים',
    TopicResources_5: 'תאוריית ההסתברות',
    TopicResources_4: 'קומבינטוריקה',
    TopicResources_7: 'משימות שונות במורכבות מוגברת',
    TopicResources_6: 'כלכלה ומימון',
    TopicResources_1: 'אַלגֶבּרָה',
    TopicResources_3: 'גֵאוֹמֶטרִיָה',
    TopicResources_2: 'טְרִיגוֹנוֹמֶטרִיָה',
    TopicResources_63: 'רבית דרבית',
    TopicResources_62: 'אינטרס פשוט',
    TopicResources_61: 'מושג הריבית. המשימות הפשוטות ביותר לעניין',
    TopicResources_67: 'ביקוש והיצע. גְמִישׁוּת',
    TopicResources_66: 'מסים',
    TopicResources_65: 'תזרימי מזומנים',
    TopicResources_64: 'אִינפלַצִיָה',
    TopicResources_68: 'משימות שונות של תוכן כלכלי',
    TopicResources_14: 'רצפים ומגבלות',
    TopicResources_15: 'התחלות של ניתוח',
    TopicResources_16: 'משימות עם פרמטרים',
    TopicResources_11: 'טרנספורמציות זהות של ביטויים אלגבריים',
    TopicResources_12: 'משוואות אלגבריות ואי-שוויון',
    TopicResources_13: 'פונקציות',
    TopicResources_24: 'ארקוסי',
    TopicResources_23: 'אי שוויון טריגונומטרי',
    TopicResources_22: 'משוואות טריגונומטריות',
    TopicResources_21: 'טרנספורמציות טריגונומטריות',
    TopicResources_32: 'סטריאומטריה',
    TopicResources_31: 'פלנימטריה',
    TopicResources_141: 'רצפים שרירותיים',
    TopicResources_142: 'התקדמות',
    TopicResources_143: 'גבולות',
    TopicResources_151: 'נגזר',
    TopicResources_153: 'בלתי נפרד',
    TopicResources_152: 'ערכי אקסטרים, מקסימום ומינימום של הפונקציה',
    TopicResources_126: 'אי שוויון',
    TopicResources_127: 'משוואות ואי-שוויון מסוג מעורב. חישוב שטח.',
    TopicResources_124: 'מערכות משוואות',
    TopicResources_125: 'בעיות טקסט',
    TopicResources_122: 'משוואות לא רציונליות',
    TopicResources_123: 'משוואות עם מודולים',
    TopicResources_121: 'משוואות רציונליות',
    TopicResources_137: 'מתפקד עם מודול',
    TopicResources_136: 'פונקציה מורכבת',
    TopicResources_135: 'פונקציה הפוכה',
    TopicResources_134: 'מחזוריות פונקציה',
    TopicResources_133: 'פונקציות זוגיות ומשונות',
    TopicResources_132: 'פונקציות יסודיות',
    TopicResources_131: 'תחום ההגדרה וקבוצת הערכים של פונקציה',
    TopicResources_139: 'פונקציות לוגריתמיות ואקספוננציאליות',
    TopicResources_138: 'טרנספורמציות של גרף פונקציות',
    TopicResources_324: 'זווית בין שני מישורים',
    TopicResources_325: 'מרחק מנקודה לקו',
    TopicResources_326: 'מרחק מנקודה למישור',
    TopicResources_327: 'מרחק בין שני קווים ישרים',
    TopicResources_321: 'משוואת מישור',
    TopicResources_322: 'זווית בין השורות',
    TopicResources_323: 'זווית בין קו למישור',
    TopicResources_328: 'חישוב שטחי פנים ונפחים של גופים גיאומטריים',
    TopicResources_329: 'אלגברה וקטורית ושיטת קואורדינטות במרחב',
    TopicResources_315: 'אלגברה וקטורית ושיטת קואורדינטות במישור',
    TopicResources_314: 'עיגול, מגזר, פלח. זוויות הקשורות למעגל',
    TopicResources_313: 'מְצוּלָע',
    TopicResources_312: 'מְרוּבָּע',
    TopicResources_311: 'משולש',
    TopicResources_1422: 'התקדמות גיאומטרית',
    TopicResources_1423: 'משימות מעורבות להתקדמות',
    TopicResources_1421: 'התקדמות אריתמטית',
    TopicResources_1261: 'אי שוויון ליניארי וריבועי',
    TopicResources_1262: 'אי שוויון רציונלי עם שורשים פשוטים',
    TopicResources_1263: 'אי שוויון רציונלי עם שורשים מרובים',
    TopicResources_1264: 'אי שוויון חלקי-רציונלי',
    TopicResources_1265: 'מערכות של אי-שוויון רציונלי',
    TopicResources_1266: 'אי שוויון לא הגיוני',
    TopicResources_1267: 'אי שוויון עם מודולים',
    TopicResources_1253: 'מיקסים',
    TopicResources_1252: 'עֲבוֹדָה',
    TopicResources_1251: 'תְנוּעָה',
    TopicResources_1256: 'משימות שונות',
    TopicResources_1255: 'ריבית',
    TopicResources_1254: 'מספרים',
    TopicResources_1242: 'מערכות מסוג Vietov',
    TopicResources_1243: 'מערכות של משוואות לא רציונליות',
    TopicResources_1241: 'מערכות משוואות ליניאריות',
    TopicResources_1244: 'מערכות של משוואות לא ליניאריות',
    TopicResources_1214: 'משוואות שברים-רציונליות',
    TopicResources_1213: 'משוואות רציונליות שלמות. שיטת החלפה',
    TopicResources_1212: 'משוואות רציונליות שלמות. פירוק לגורמים',
    TopicResources_1211: 'משוואות לינאריות וריבועיות',
    TopicResources_1392: 'משוואות לוגריתמיות ואקספוננציאליות',
    TopicResources_1393: 'אי שוויון לוגריתמי ואקספוננציאלי',
    TopicResources_1391: 'מאפיינים של פונקציות לוגריתמיות ואקספוננציאליות',
    WhiteBoardResources_Unnamedboard: 'ללא כותרת'
};
export default he;