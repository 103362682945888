import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, TextField, IconButton, FormControl, MenuItem, Select } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { actionCreators } from 'src/reducers/common/eduVideos';
import { useDispatch, useSelector } from 'react-redux';
import translate from 'src/helpers/translate';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

export default function EduVideosDialog({ eduVideos, open, onClose, canEdit, topicId, problemId }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [description, setDescription] = useState('');

    const [selectedTopicId, setSelectedTopicId] = useState(topicId);

    const innerTopics = useSelector(state => state.common.topics.filter(t => t.id.toString().startsWith(topicId)));

    const [link, setLink] = useState('');

    const canBeSubmitted = description != '' && link != '';

    const handleAdd = () => {
        if (canBeSubmitted) {
            setLink('');
            setDescription('');
            dispatch(actionCreators.addEduVideo(description, link, selectedTopicId, problemId));
        }
    }

    const handleDelete = (id) => {
        dispatch(actionCreators.deleteEduVideo(id));
    }

    const onInnerTopicChange = (event) => {
        setSelectedTopicId(event.target.value);
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{translate('ProblemResources_EduVideos')}</DialogTitle>
            <DialogContent>
                {canEdit && (
                    <form className={classes.root} noValidate autoComplete="off">
                        <TextField required id="standard-required" value={description} label={translate("Description")} onChange={(event) => setDescription(event.target.value)} />
                        <TextField required id="standard-required" value={link} label={translate("Link")} onChange={(event) => setLink(event.target.value)} />
                        {topicId && innerTopics && (
                            <FormControl style={{ marginTop: 23 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedTopicId}
                                    onChange={onInnerTopicChange}
                                >
                                    {innerTopics && innerTopics.map(innerTopic => (
                                        <MenuItem key={`innerTopic_${innerTopic.id}`} value={innerTopic.id}>{translate(`TopicResources_${innerTopic.id}`)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <Button disabled={!canBeSubmitted} onClick={handleAdd} style={{ marginTop: 20 }} variant="standard" color="primary" href="#outlined-buttons">
                            {translate("Add")}
                        </Button>
                    </form>
                )}
                <div style={{ maxHeight: 400, minHeight: 100, minWidth: 400 }}>
                    <List component="nav">
                        {eduVideos && eduVideos.map(eduVideo => (
                            <ListItem>
                                <Button href={eduVideo.url} key={eduVideo.id}>
                                    <ListItemText primary={eduVideo.name} />
                                </Button>
                                {canEdit && (
                                    <IconButton onClick={() => handleDelete(eduVideo.id)} size="large">
                                        <Delete />
                                    </IconButton>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary">
                    {translate('ProfileResources_Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}