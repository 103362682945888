import { combineReducers } from "redux"
import homeworks from './homeworks';
import tests from './tests';
import testProblems from './testProblems';
import teachers from './teachers';

export default combineReducers(
    {
        homeworks,
        tests,
        testProblems,
        teachers
    }
);