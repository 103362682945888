import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, FormControl, RadioGroup, FormControlLabel, FormHelperText, Button, TextField, Radio, colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import translate from 'src/helpers/translate';
import { actionCreators } from 'src/reducers/student/testProblems';
import ProblemActions from 'src/views/common/Problem/Solving/ProblemActions';
import ProblemText from 'src/views/common/Problem/Solving/ProblemText';
import ProblemLatexAnswer from 'src/views/common/Problem/Solving/ProblemLatexAnswer';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0)
    }
}));

const ProblemBodyInTest = ({ testProblem, showResult, whiteBoardOpened, onWhiteBoardToggle }) => {
    const generatedProblem = testProblem.generatedProblem;
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [solvingSteps, setSolvingSteps] = useState([]);
    const [confirmingAnswerEnabled, setConfirmingAnswerEnabled] = useState(false);
    const [answerImagesLoaded, setAnswerImagesLoaded] = useState({ 0: false, 1: false, 2: false, 3: false });
    const [graphLoaded, setGrapghLoaded] = useState(false);
    const [helperText, setHelperText] = useState('');
    const problem = useSelector(state => state.common.problems.find(p => p.id == testProblem.problemId))
    const dispatch = useDispatch();

    useEffect(() => {
        setValue(testProblem.generatedProblem.userAnswer);
    }, [generatedProblem.userAnswer])

    useEffect(() => {
        if (showResult && generatedProblem.correctAnswer && problem.type != 0 && generatedProblem.answeredCorrectly) {
            setHelperText(translate('Correct'));
        }
        if (showResult && generatedProblem.correctAnswer && problem.type != 0 && !generatedProblem.answeredCorrectly) {
            setHelperText(translate('Wrong') + '. ' + translate('CorrectAnswerIs') + ': ' + generatedProblem.correctAnswer);
        }
    }, [showResult, problem.type, generatedProblem.correctAnswer, generatedProblem.answeredCorrectly])

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        if (showResult) return;
        setConfirmingAnswerEnabled(true);
    };

    const handleAnswerChange = (answer) => {
        setValue(answer);
        if (showResult) return;
        setConfirmingAnswerEnabled(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (showResult) return;
        if (!value || value === ' ' || value === '') {
            setHelperText(translate(problem.type === 0 ? 'PleaseSelectAnswer' : 'PleaseProvideAnswer'));
            setError(true);
        } else {
            setError(false);
            dispatch(actionCreators.confirmAnswerInTest(testProblem.testId, generatedProblem.generatedProblemId, value));
            setConfirmingAnswerEnabled(false);
        }
    };


    return (
        <React.Fragment>
            <ProblemActions
                problem={problem}
                generatedProblem={generatedProblem}
                solvingSteps={showResult && solvingSteps}
                setSolvingSteps={(steps) => setSolvingSteps(steps)}
                onWhiteBoardToggle={onWhiteBoardToggle}
                whiteBoardOpened={whiteBoardOpened}
                alreadyAnswered={true}
            />
            <Grid container className={classes.formControl}>
                <ProblemText generatedProblem={generatedProblem} />
                {generatedProblem.graphicUrl && (
                    <Grid item md={generatedProblem.latexText ? 11 : 6}>
                        {!graphLoaded && (<Skeleton variant="rectangular" width={200} height={200} />)}
                        <img src={generatedProblem.graphicUrl} onLoad={() => setGrapghLoaded(true)} />
                    </Grid>
                )}
                <Grid item md={6}>
                    <form onSubmit={handleSubmit}>
                        <FormControl component="fieldset" error={error} className={classes.formControl}>
                            {problem.type === 0 && (
                                <RadioGroup aria-label="userAnswer" name="userAnswer" value={value} onChange={handleRadioChange}>
                                    {!generatedProblem.answersAsImages && generatedProblem.answers.map(answer => (
                                        <FormControlLabel
                                            key={'problemAnswer' + generatedProblem.answers.indexOf(answer)}
                                            value={(generatedProblem.answers.indexOf(answer) + 1).toString()}
                                            control={<Radio color="secondary" />}
                                            disabled={showResult}
                                            label={
                                                <Grid container>
                                                    <Grid item sm={12}>
                                                        <label dangerouslySetInnerHTML={{ __html: answer }} />
                                                        {showResult
                                                            && generatedProblem.correctAnswerChoiseFormat == generatedProblem.answers.indexOf(answer) + 1
                                                            && <label> ({translate('CorrectAnswerIs')})</label>}
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    ))}
                                    {generatedProblem.answersAsImages && generatedProblem.answers.map(answer => (
                                        <FormControlLabel
                                            key={'problemAnswer' + generatedProblem.answers.indexOf(answer)}
                                            value={(generatedProblem.answers.indexOf(answer) + 1).toString()}
                                            control={<Radio color="secondary" />}
                                            disabled={showResult}
                                            label={
                                                <Grid container>
                                                    <Grid item sm={12}>
                                                        <img src={answer} onLoad={() => setAnswerImagesLoaded({ ...answerImagesLoaded, [generatedProblem.answers.indexOf(answer)]: true })} />
                                                        {!answerImagesLoaded[generatedProblem.answers.indexOf(answer)] && <Skeleton variant="text" />}
                                                        {showResult
                                                            && generatedProblem.correctAnswerChoiseFormat == generatedProblem.answers.indexOf(answer) + 1
                                                            && <label> ({translate('CorrectAnswerIs')})</label>}
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                            {problem.type !== 0 && showResult && (
                                <TextField value={value} disabled={showResult} onChange={handleRadioChange}></TextField>
                            )}
                            {problem.type !== 0 && !showResult && (
                                <ProblemLatexAnswer value={value} onValueChange={(value) => handleAnswerChange(value)} />
                            )}
                            <FormHelperText>{helperText}</FormHelperText>
                            {!showResult &&
                                <Grid container>
                                    <Grid item sm={12}>
                                        <Button disabled={!confirmingAnswerEnabled} type="submit" variant="contained" color="primary" className={classes.button} style={{ flex: 1 }}>
                                            {translate('ConfirmAsnwer')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
            <Grid item xs={12} >
                {solvingSteps && solvingSteps.map(ss =>
                    <div key={ss.id}>
                        {ss.stepType === 1 && <Typography dangerouslySetInnerHTML={{ __html: ss.description }} />}
                        {ss.stepType !== 1 && <img src={ss.description} />}
                    </div>
                )}
            </Grid>
        </React.Fragment>
    );
}
export default ProblemBodyInTest;