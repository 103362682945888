import 'react-perfect-scrollbar/dist/css/styles.css';
import Pusher from 'pusher-js';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider} from '@mui/material/styles';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import commonRoutes from 'src/routes/commonRoutes';
import teacherRoutes from 'src/routes/teacherRoutes';
import studentRoutes from 'src/routes/studentRoutes';
import adminRoutes from 'src/routes/adminRoutes';
import * as locales from '../src/localization';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from './reducers/user';
import pusherSubscriber from 'src/helpers/pusherSubscriber';
import * as authService from 'src/services/auth.service';

const App = () => {
  const culture = useSelector(state => state.culture);
  const user = useSelector(state => state.userContext.user);
  const dispatch = useDispatch();

  let routes = commonRoutes;
  if (user && user.role) {
    if (user.role == 'Student') {
      routes = [...routes, studentRoutes[0]];
    }
    if (user.role == 'Teacher') {
      routes = [...routes, teacherRoutes[0]];
    }
    if (user.roles.includes('Admin')) {
      routes = [...routes, adminRoutes[0]];
    }
  }

  const routing = useRoutes(routes);

  useEffect(() => {
    if(window.location.hash && localStorage.getItem("IsAuthorized") !== 'true'){
      authService.authorizedCallback()
    }
    dispatch(actionCreators.requestUser())
  }, [dispatch, window.location.hash])

  useEffect(() => {
    if(localStorage.getItem("IsAuthorized") === 'true'){
      dispatch(actionCreators.requestUser());
    }
  }, [dispatch, localStorage.getItem("IsAuthorized")])

  //subscribing to pusher events
  useEffect(() => {
    if (user && user.id) {
      console.log('user is fetched. Subscribing to pusher events');
      var pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
        cluster: 'eu'
      });
      var channel = pusher.subscribe(user.id);
      dispatch(pusherSubscriber.subscribeToCommonEvents(channel));

      if (user.role == 'Teacher') {
        dispatch(pusherSubscriber.subscribeToTeacherEvents(channel));
      }
      return () => {
        console.log('Unmounting app so unsubscribing from pusher events');
        pusher.unsubscribe(user.id);
      };
    }
  }, [user, dispatch])

  return (
    <React.Fragment>
      {user && user.id && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme(culture.dir, locales[culture.locale])}>
            <GlobalStyles />
            {routing}
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </React.Fragment>
  );
};

export default App;
