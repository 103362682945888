import React, { useEffect } from 'react';

export default function Graph595(props) {

    let {a, b, c, d, e, f, g, h} = props.data;
    const board = props.board;

    var p2 = board.create('point',[c,d], {name: "", size:0, fixed: true});
    var p3 = board.create('point',[e,f], {name: "", size:0, fixed: true});

    var lineParams = {
        strokeColor:'blue',
        highlightStrokeColor:'blue',
        straightFirst:true, 
        straightLast:true, 
        strokeWidth:2, 
        fixed:true
    }

    board.create('line',[p2,p3], lineParams);

    return <></>
}