import React, { useEffect } from 'react';
import { actionCreators } from 'src/reducers/student/tests';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import StudentTestsGrid from './TestsGrid';
import TestSolvingDialog from './TestSolvingDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TestsListView = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const tests = useSelector(state => state.student.tests);

  useEffect(() => {
    dispatch(actionCreators.requestTests());
  }, [dispatch])

  return (
    <Page
      className={classes.root}
      title="Tests"
    >
      <Container maxWidth="lg">
        <Box mt={3}>
            {tests && !tests.list.some(t => t.isSolving) && <StudentTestsGrid tests={tests}/>}
            {tests && tests.list.some(t => t.isSolving) && <TestSolvingDialog test={tests.list.find(t => t.isSolving)}/>}
            {tests && tests.list.some(t => !t.isSolving && t.showResult) && <TestSolvingDialog test={tests.list.find(t => !t.isSolving && t.showResult)}/>}
        </Box>
      </Container>
    </Page>
  );
};

export default TestsListView;
