import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useParams } from 'react-router-dom';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';
import { Done } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { green, red } from '@mui/material/colors';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Avatar, Card, CardContent, Tooltip, CardHeader, Typography, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeworkTopBar from './HomeworkTopBar';
import homeworkRatingService from 'src/stateServices/homeworkRatingService';
import Delete from '@mui/icons-material/Delete';
import { colors } from '@mui/material';
import ConfirmationDialog from 'src/components/common/ConfirmationDialog';
import StudentProblemStatsView from 'src/views/teacher/problem/Stats';
import InformationalDialog from 'src/components/common/InformationalDialog';
import "./styles.css";
import { useNavigate } from "react-router-dom";
import SimpleProblemBody from 'src/views/common/Problem/InTopic/SimpleProblemBody';

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -35
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    },
    createNewButton: {
        marginRight: 5
    },
    filterByGroupButton: {
        marginBottom: 10,
    },
    cancelButton: {
        color: red[500],
        borderColor: red[500]
    },
    newRequestButton: {
        backgroundColor: green[200]
    },
    addNewButton: {
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    }, button: {
        marginLeft: '-10px',
        fontSize: 10
    },
    deleteButton: {
        marginLeft: '-10px',
        fontSize: 10,
        color: colors.red[400]
    },
    grid: {
        cursor: 'pointer'
    },
    problemTooltip: {
        maxWidth: 'none'
    }
}));


export default function HomeworkResultsGrid() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const user = useSelector(state => state.userContext.user);
    const { id } = useParams();
    const homework = useSelector(state => state.teacher.homeworks.find(h => h.id == id));
    const students = useSelector(state => state.teacher.students.filter(s => homework?.students.includes(s.id)));
    const groups = useSelector(state => state.teacher.groups.filter(s => homework?.groups.includes(s.id)));
    const problems = useSelector(state => state.common.problems.filter(p => homework?.problems.includes(p.id)));
    const clientProblems = useSelector(state => state.common.clientProblems.filter(cp => homework?.students.includes(cp.clientId) && homework?.problems.includes(cp.problemId)));
    const homeworksAreUpdating = useSelector(state => state.teacher.homeworksAreUpdating);
    const [problemIdForStats, setProblemIdForStats] = useState(0);
    const [problemStatsIsOpen, setProblemStatsIsOpen] = useState(false);
    const [studentForStats, setStudentForStats] = useState({});

    useEffect(() => {
        if (user && user.role && !homework) {
            dispatch(clientProblemsActionCreators.requestClientProblems());
        }
    }, [user, homework, dispatch])

    const studentsResults = students.map(s => {
        return {
            id: s.id,
            wholeName: s.wholeName,
            avatarImage: s.avatarImage,
            problems: clientProblems.filter(cp => cp.clientId == s.id)
        };
    });

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'wholeName',
            flex: 2,
            minWidth: 200,
            headerName: translate('Students'),
            renderCell: (params) => (
                <React.Fragment>
                    <Avatar
                        key={params.row.id}
                        src={`data:image/jpg;base64, ${params.row.avatarImage}`}
                        className={classes.small}
                    />
                    {params.value}
                </React.Fragment>
            )
        }
    ];

    const onProblemRatingClicked = (problemId, studentId, event) => {
        setProblemIdForStats(problemId);
        setStudentForStats(students.find(s => s.id == studentId))
        setProblemStatsIsOpen(true);
        event.stopPropagation();
    }

    const columns1 = [...columns]
    const columns2 = [...columns]
    const columns3 = [...columns]
    problems.forEach(problem => {
        const column = {
            field: `problem_${problem.id}`,
            flex: 0.5,
            minWidth: 60,
            headerAlign: 'center',
            headerName: problem.id,
            renderHeader: (params) => (
                <Tooltip
                    classes={{ tooltip: classes.problemTooltip }}
                    title={
                        <Paper>
                            <SimpleProblemBody problemId={problem.id}/>
                        </Paper>
                    }
                >
                    <strong>
                        {problem.id}
                    </strong>
                </Tooltip>
            ),
            sortable: false,
            valueGetter: (params) => params.row.problems.find(p => p.problemId == problem.id)?.rating ?? 0,
            renderCell: (params) =>
            (
                <Tooltip title={translate('StudentRatingInHomeworkTooltip')}>
                    <IconButton aria-label="delete" size="small">
                        <span className={classes.problemRating} onClick={(event) => onProblemRatingClicked(problem.id, params.id, event)}>
                            {params.row.problems.find(p => p.problemId == problem.id)?.rating ?? 0}
                        </span>
                    </IconButton>
                </Tooltip>
            )
        }
        if (problem.level === 1) {
            columns1.push(column);
        }
        if (problem.level === 2) {
            columns2.push(column);
        }
        if (problem.level === 3) {
            columns3.push(column);
        }
    })


    return (
        <React.Fragment>
            {homework && (
                <Card>
                    <CardHeader
                        title={homework.description}
                        subheader={homework.createdOnString}
                        avatar={
                            <Link to={"/app/homeworks"}>
                                <IconButton aria-label="delete" className={classes.margin} size="medium">
                                    <ArrowBackIcon fontSize="inherit" />
                                </IconButton>
                            </Link>
                        }
                    />
                    <CardContent>
                        <HomeworkTopBar
                            homework={homework}
                            students={students}
                            problems={problems}
                            groups={groups}
                            navigate={navigate}
                        />
                        {columns1.length > 2 && (
                            <React.Fragment>
                                <Typography>
                                    {translate('Level1')}
                                </Typography>
                                <DataGrid
                                    loading={homeworksAreUpdating}
                                    rows={studentsResults}
                                    density="compact"
                                    columns={columns1}
                                    justifyContent="center"
                                    pageSize={10}
                                    autoHeight={true}
                                    disableColumnMenu
                                    className={classes.grid}
                                    hideFooter={studentsResults.length <= 10}
                                    hideFooterSelectedRowCount />
                            </React.Fragment>
                        )}
                        {columns2.length > 2 && (
                            <React.Fragment>
                                <Typography>
                                    {translate('Level2')}
                                </Typography>
                                <DataGrid
                                    loading={homeworksAreUpdating}
                                    rows={studentsResults}
                                    density="compact"
                                    columns={columns2}
                                    justifyContent="center"
                                    pageSize={10}
                                    autoHeight={true}
                                    disableColumnMenu
                                    className={classes.grid}
                                    hideFooter={studentsResults.length <= 10}
                                    hideFooterSelectedRowCount />
                            </React.Fragment>
                        )}
                        {columns3.length > 2 && (
                            <React.Fragment>
                                <Typography>
                                    {translate('Level3')}
                                </Typography>
                                <DataGrid
                                    loading={homeworksAreUpdating}
                                    rows={studentsResults}
                                    density="compact"
                                    columns={columns3}
                                    justifyContent="center"
                                    pageSize={10}
                                    autoHeight={true}
                                    disableColumnMenu
                                    className={classes.grid}
                                    hideFooter={studentsResults.length <= 10}
                                    hideFooterSelectedRowCount />
                            </React.Fragment>
                        )}

                    </CardContent>
                    <InformationalDialog
                        open={problemStatsIsOpen}
                        onClose={() => setProblemStatsIsOpen(false)}
                        maxWidth="lg"
                        fullWidth={true}
                    >
                        <StudentProblemStatsView problemId={problemIdForStats} specificStudent={studentForStats} />
                    </InformationalDialog>
                </Card>
            )}
        </React.Fragment>
    );
}