import * as apiCaller from 'src/helpers/apiCaller';
import translate from 'src/helpers/translate';
import { actionCreators as layoutActionCreators } from '../layout';
const boardsReceivedType = "BOARDS_RECEIVED";
const editionAllowedType = "BOARD_EDITION_ALLOWED";
const editionProhibittedType = "BOARD_EDITION_PROHIBITTED";

function requestBoards() {
    return async dispatch => {
        await apiCaller.get('/whiteboard', true).then(
            (boards) => {
                dispatch(success(boards));
            }
        );
        function success(boards) {
            return { type: boardsReceivedType, boards };
        }
    };
}

function addNewBoard(name) {
    return async dispatch => {
        let formData = new FormData();

        formData.append("name", name);
        await apiCaller.postAsFormData('/whiteboard/create', formData, true).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate('BoardWasAdded', name)));
                dispatch(requestBoards());
            }
        );
    };
}

function shareWithGroup(boardId, groupId) {
    return async dispatch => {
        const body = {
            boardId,
            groupId
        }
        await apiCaller.post('/clientBoard/shareWithGroup', body).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate('BoardWasSharedWithGroup')));
                dispatch(requestBoards());
            }
        );
    };
}



function shareWithTeacher(boardId) {
    return async dispatch => {
        await apiCaller.get(`/clientBoard/shareWithTeacher?id=${boardId}`).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate('BoardWasSharedWithTeacher')));
                dispatch(requestBoards());
            }
        );
    };
}

function shareWithStudent(boardId, studentId) {
    return async dispatch => {

        const body = {
            boardId,
            studentId
        }
        await apiCaller.post('/clientBoard/shareWithStudent', body).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate('BoardWasSharedWithStudent')));
                dispatch(requestBoards());
            }
        );
    };
}

function allowEdit(boardId, studentId) {
    return async dispatch => {
        const body = {
            boardId,
            clientId: studentId,
            readOnly: false
        }
        await apiCaller.post('/whiteBoard/changeReadOnly', body, true).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate('EditionOfTheBoardAllowed')));
                dispatch(success(boardId, studentId));
            }
        );
        function success(boardId, studentId) {
            return { type: editionAllowedType, boardId, studentId };
        }
    };
}

function prohibitEdit(boardId, studentId) {
    return async dispatch => {
        const body = {
            boardId,
            clientId: studentId,
            readOnly: true
        }
        await apiCaller.post('/whiteBoard/changeReadOnly', body, true).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate('EditionOfTheBoardProhibitted')));
                dispatch(success(boardId, studentId));
            }
        );
        function success(boardId, studentId) {
            return { type: editionProhibittedType, boardId, studentId };
        }
    };
}

function deleteBoard(boardId, boardCode, navigate) {
    return async dispatch => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Api-Key': '314b1091b9f89e9770fc5ce39be13a3d' }
        };
        await fetch("https://www.whiteboard.team/api/v1/boards", requestOptions)
            .then(response => response.json())
            .then(response => {
                const board = response.data.find(d => d.code == boardCode);
                requestOptions.method = 'DELETE';
                fetch(`https://www.whiteboard.team/api/v1/boards/${board.id}`, requestOptions)
                    .then(() => {
                        apiCaller.get(`/whiteBoard/delete?id=${boardId}`, true).then(
                            () => {
                                dispatch(requestBoards());
                                navigate('/app/boards', { replace: true });
                                dispatch(layoutActionCreators.showSuccessAlert(translate('BoardWasDeleted')));
                            }
                        );
                    })
            })
    };
}


export const actionCreators = {
    requestBoards,
    addNewBoard,
    shareWithGroup,
    shareWithStudent,
    shareWithTeacher,
    allowEdit,
    prohibitEdit,
    deleteBoard
}

const boards = (state = [], action) => {
    if (action.type === boardsReceivedType) {
        return action.boards;
    }

    if (action.type === editionAllowedType) {
        const board = state.find(b => b.id == action.boardId);
        return [
            ...state.filter(b => b.id != action.boardId),
            { ...board, clients: [...board.clients.filter(c => c.id != action.studentId), { id: action.studentId, readOnly: false }] }
        ];
    }

    if (action.type === editionProhibittedType) {
        const board = state.find(b => b.id == action.boardId);
        return [
            ...state.filter(b => b.id != action.boardId),
            { ...board, clients: [...board.clients.filter(c => c.id != action.studentId), { id: action.studentId, readOnly: true }] }
        ];
    }

    return state;
};

export default boards