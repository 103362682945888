import React from 'react';
import { useSelector } from 'react-redux';
import SubjectCard from './SubjectCard';
import Grid from '@mui/material/Grid';

export default function SubjectsGrid(props) {
    const subjects = useSelector(state => state.common.topics.filter(s => s.id < 10));

    return (
        <Grid container spacing={3}>
            {subjects.map(s => (
                <Grid item lg={2} md={3} sm={6} xs={12} key={s.id}>
                    <SubjectCard subject={s} />
                </Grid>
            ))}
        </Grid>
    );
}