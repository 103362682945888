import React, {  useState } from 'react';
import {
    Button,
    TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import { actionCreators } from 'src/reducers/admin/mistakes';
import { useDispatch } from 'react-redux';


export default function CloseMistake({mistake}) {

    const [solution, setSolution] = useState('');
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(actionCreators.closeMistake(mistake.id, solution));
    }

    return (
        <React.Fragment>
            <TextField value={solution} onChange={(event) => setSolution(event.target.value)} />
            <Button onClick={onClose} disabled={solution === ''}>
                {translate('CloseMistake')}
            </Button>
        </React.Fragment>
    );
}