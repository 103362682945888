const en = {
  /*
  props: {
    MuiBreadcrumbs: {
      expandText: 'Show path',
    },
    MuiTablePagination: {
      backIconButtonText: 'Previous page',
      labelRowsPerPage: 'Rows per page:',
      labelDisplayedRows: ({ from, to, count }) =>
  `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`,
      nextIconButtonText: 'Next page',
    },
    MuiRating: {
      getLabelText: value => `${value} Star${value !== 1 ? 's' : ''}`,
      emptyLabelText: 'Empty',
    },
    MuiAutocomplete: {
      clearText: 'Clear',
      closeText: 'Close',
      loadingText: 'Loading…',
      noOptionsText: 'No options',
      openText: 'Open',
    },
    MuiAlert: {
      closeText: 'Close',
    },
    MuiPagination: {
      'aria-label': 'Pagination navigation',
      getItemAriaLabel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? '' : 'Go to '}page ${page}`;
        }
        if (type === 'first') {
          return 'Go to first page';
        }
        if (type === 'last') {
          return 'Go to last page';
        }
        if (type === 'next') {
          return 'Go to next page';
        }
        if (type === 'previous') {
          return 'Go to previous page';
        }
        return undefined;
      },
    },
  },
  */
};

const ru = {
  props: {
    // MuiBreadcrumbs: {
    //   expandText: 'Show path',
    // },
    MuiTablePagination: {
      backIconButtonText: 'Предыдущая страница',
      labelRowsPerPage: 'Строк на странице:',
      labelDisplayedRows: function labelDisplayedRows(_ref25) {
        const { from, to, count } = _ref25;
        return ''.concat(from, '-').concat(to, ' \u0438\u0437 ').concat(count !== -1 ? count : '\u0431\u043E\u043B\u0435\u0435 \u0447\u0435\u043C '.concat(to));
      },
      nextIconButtonText: 'Следующая страница'
    },
    MuiRating: {
      getLabelText: function getLabelText(value) {
        let pluralForm = 'Звёзд';
        const lastDigit = value % 10;

        if (lastDigit > 1 && lastDigit < 5) {
          pluralForm = 'Звезды';
        } else if (lastDigit === 1) {
          pluralForm = 'Звезда';
        }

        return ''.concat(value, ' ').concat(pluralForm);
      } // emptyLabelText: 'Empty',

    },
    MuiAutocomplete: {
      clearText: 'Очистить',
      closeText: 'Закрыть',
      loadingText: 'Загрузка…',
      noOptionsText: 'Нет доступных вариантов',
      openText: 'Открыть'
    },
    MuiAlert: {
      closeText: 'Закрыть'
    } // MuiPagination: {
    //   'aria-label': 'Pagination navigation',
    //   getItemAriaLabel: (type, page, selected) => {
    //     if (type === 'page') {
    //       return `${selected ? '' : 'Go to '}page ${page}`;
    //     }
    //     if (type === 'first') {
    //       return 'Go to first page';
    //     }
    //     if (type === 'last') {
    //       return 'Go to last page';
    //     }
    //     if (type === 'next') {
    //       return 'Go to next page';
    //     }
    //     if (type === 'previous') {
    //       return 'Go to previous page';
    //     }
    //     return undefined;
    //   },
    // },

  }
};

const uk = {
  props: {
    MuiBreadcrumbs: {
      expandText: 'Показати шлях сторінок'
    },
    MuiTablePagination: {
      backIconButtonText: 'Попередня сторінка',
      labelRowsPerPage: 'Рядків на сторінці:',
      labelDisplayedRows: function labelDisplayedRows(_ref29) {
        const { from, to, count } = _ref29;
        return ''.concat(from, '-').concat(to, ' \u0437 ').concat(count !== -1 ? count : '\u043F\u043E\u043D\u0430\u0434 '.concat(to));
      },
      nextIconButtonText: 'Наступна сторінка'
    },
    MuiRating: {
      getLabelText: function getLabelText(value) {
        let pluralForm = 'Зірок';
        const lastDigit = value % 10;

        if (lastDigit > 1 && lastDigit < 5) {
          pluralForm = 'Зірки';
        } else if (lastDigit === 1) {
          pluralForm = 'Зірка';
        }

        return ''.concat(value, ' ').concat(pluralForm);
      },
      emptyLabelText: 'Рейтинг відсутній'
    },
    MuiAutocomplete: {
      clearText: 'Очистити',
      closeText: 'Згорнути',
      loadingText: 'Завантаження…',
      noOptionsText: 'Немає варіантів',
      openText: 'Розгорнути'
    },
    MuiAlert: {
      closeText: 'Згорнути'
    },
    MuiPagination: {
      'aria-label': 'Навігація сторінками',
      getItemAriaLabel: function getItemAriaLabel(type, page, selected) {
        if (type === 'page') {
          return ''.concat(selected ? '' : 'Перейти на ', '\u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0443 ').concat(page);
        }

        if (type === 'first') {
          return 'Перейти на першу сторінку';
        }

        if (type === 'last') {
          return 'Перейти на останню сторінку';
        }

        if (type === 'next') {
          return 'Перейти на наступну сторінку';
        }

        if (type === 'previous') {
          return 'Перейти на попередню сторінку';
        }

        return undefined;
      }
    }
  }
};

Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.ru = ru;
exports.uk = uk;
exports.en = en;
