import * as authService from '../services/auth.service';
import store from 'src/configure-store';
import { actionCreators as layoutActionCreators} from 'src/reducers/layout';
import translate from 'src/helpers/translate';

export const handleResponse = (response) => {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else if(response.status === 401 || response.status === "401") {
            authService.authorize();
        }else if(response.status >= 500) {
            store.dispatch(layoutActionCreators.showErrorAlert(translate('GlobalResources_ApplicationErrorMessage')))
            response.text().then(text => reject(text))
        }else{
            reject(response.status);
        }
    });
}

export const handleError = (error) =>  {
    return Promise.reject(error && error.message);
}
