import React, { useEffect, useState } from 'react';
import { actionCreators } from 'src/reducers/student/teachers';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Container,
    Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import TeacherCard from './TeacherCard';
import AddNewTeacherCard from './AddNewTeacherCard';
import AddClientDialog from 'src/views/common/ClientSearch/AddClientDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const TeachersListView = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [addNewTeacherDialogIsOpen, setAddNewTeacherDialogIsOpen] = useState(false);
    const teachers = useSelector(state => state.student.teachers.filter(t => t.relationStatus != 4));

    useEffect(() => {
        dispatch(actionCreators.requestTeachers());
    }, [dispatch])

    return (
        <Page
            className={classes.root}
            title="Teachers"
        >
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item  xs={12} md={3}>
                        <AddNewTeacherCard openDialog={() => setAddNewTeacherDialogIsOpen(true)}/>
                    </Grid>
                    {teachers && teachers.map(teacher =>
                        <Grid item key={`teacherCard_${teacher.id}`} xs={12} md={3}>
                            <TeacherCard teacher={teacher} />
                        </Grid>
                    )}
                </Grid>
            </Container>
            <AddClientDialog 
                open={addNewTeacherDialogIsOpen}
                setOpen={setAddNewTeacherDialogIsOpen}
                myClients={teachers}
                sendFriendRequest={(teacher) => dispatch(actionCreators.sendFriendRequest(teacher))}
            />
        </Page>
    );
};

export default TeachersListView;
