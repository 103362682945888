import React from 'react';
import { connect } from 'react-redux';
import GroupDialog from './GroupDialog';
import { actionCreators } from 'src/reducers/teacher';
import translate from 'src/helpers/translate';

const Update = ({group, changeGroupName, ...props}) => {

    const onChangeGroupName = (name) => {
        changeGroupName(group.id, name);
    }

    return (
        <GroupDialog 
            title={translate('UpdateGroupName')} 
            groupNameToChange={group.name} 
            onSubmit={onChangeGroupName}
            {...props}
        />
    );
}

const MapStateToProps = (state, ...ownProps) => {
    return {
        ownProps
    };
}

const MapDispatchToProps = (dispatch) => {
    return {
        changeGroupName: (id, name) => dispatch(actionCreators.changeGroupName(id, name))
    };
}

export default connect(MapStateToProps, MapDispatchToProps)(Update);