import React, { useEffect, useState } from "react";
import { Grid, FormControl, RadioGroup, FormControlLabel, FormHelperText, Button, Radio, colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as apiCaller from 'src/helpers/apiCaller';
import { useDispatch, useSelector } from 'react-redux';
import translate from 'src/helpers/translate';
import { actionCreators } from 'src/reducers/common/clientProblems';
import { actionCreators as layoutActionCreators } from 'src/reducers/layout';
import ProblemLatexAnswer from './ProblemLatexAnswer';
import ProblemLatexText from './ProblemLatexText';
import { ComputeEngine } from '@cortex-js/compute-engine';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0)
    }
}));

export default function ProblemAnswer(props) {
    const classes = useStyles();
    const { clientRating, setGeneratedProblem, problem, generatedProblem, solvingSteps } = props;
    const [userAnswer, setUserAnswer] = useState('');
    const [error, setError] = useState(false);
    const user = useSelector(state => state.userContext.user);
    const dispatch = useDispatch();


    useEffect(() => {
        setUserAnswer('');
        setError(false);
    }, [])


    const handleRadioChange = (event) => {
        if (!generatedProblem.answeredDate) {
            setUserAnswer(event.target.value);
            var newGeneratedProblem = {
                ...generatedProblem,
                answers: generatedProblem.answers.map(a => a.index + 1 == event.target.value ? { ...a, isChosen: true } : { ...a, isChosen: false })
            };
            console.log(newGeneratedProblem);
            setGeneratedProblem(newGeneratedProblem);
            setError(false);
        }
    };

    const handleAnswerChange = (answer) => {
        if (!generatedProblem.answeredDate) {
            setUserAnswer(answer);
            setError(false);
        }
    };

    const applyChangeInGeneratedProblem = (decision) => {
        var newGeneratedProblem = {
            ...generatedProblem,
            answeredDate: new Date().getTime(),
            correctAnswer: decision.rightAnswer,
            answeredCorrectly: decision.answerIsRight,
            answers: generatedProblem.answers.map(a => a.index + 1 == decision.rightAnswer ? { ...a, isCorrect: true } : a)
        };
        console.log(newGeneratedProblem);
        setGeneratedProblem(newGeneratedProblem);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!userAnswer || userAnswer === ' ' || userAnswer === '') {
            setError(true);
        } else {
            setError(false);
            if(generatedProblem.answerIsLatex){
                handleLatexAnswerSubmit();
            }else{
                handleSimpleAnswerSubmit();
            }   
        }
    };

    const handleLatexAnswerSubmit = () => {
        apiCaller.get(`/problemAnswer/getAnswer?id=${generatedProblem.id}&chosenAnswer=${userAnswer}`, true).then(
            (correctAnswer) => {
                try {
                    const computeEngine = new ComputeEngine();
                    const parsedUserAnswer = computeEngine.parse(userAnswer);
                    const parsedCorrectAnswer = computeEngine.parse(correctAnswer);
                    let answerIsCorrect = false;
                    if(parsedCorrectAnswer && parsedUserAnswer){
                        answerIsCorrect = parsedCorrectAnswer.isEqual(parsedUserAnswer);
                    }else{
                        answerIsCorrect = userAnswer === correctAnswer;
                    }
                    sendConfirmAsnwer(answerIsCorrect);
                } catch (error) {
                    console.error('Error evaluating expression:', error);
                }
            }
        );
    }

    const handleSimpleAnswerSubmit = () => {
        sendConfirmAsnwer(null);
    }

    const sendConfirmAsnwer = (answerIsCorrect) => {
        apiCaller.get(`/problemAnswer/confirmAnswer?id=${generatedProblem.id}&chosenAnswer=${userAnswer}&answerIsCorrect=${answerIsCorrect}`, true).then(
            (decision) => {
                if (!generatedProblem.answeredDate && solvingSteps.length < 1) {
                    dispatch(actionCreators.changeRating(problem.id, user.id, decision.problemRaiting));
                    if (decision.answerIsRight) {
                        dispatch(layoutActionCreators.showSuccessAlert(translate('CorrectAnswerSnackBar'), 'top', 'center'));
                    } else {
                        const alertMessage = clientRating > 0 ? 'WrongAnswerRatingDecreasedSnackBar' : 'WrongAnswerSnackBar';
                        dispatch(layoutActionCreators.showWarningAlert(translate(alertMessage), 'top', 'center'));
                    }
                }
                applyChangeInGeneratedProblem(decision);
            }
        );
    }


    return (
        <form onSubmit={handleSubmit}>
            <FormControl component="fieldset" error={error} className={classes.formControl}>
                {problem.type === 0 && !generatedProblem.answerIsLatex && (
                    <RadioGroup aria-label="userAnswer" name="userAnswer" value={userAnswer} onChange={handleRadioChange}>
                        {generatedProblem.answers.map(answer => (
                            <FormControlLabel
                                key={'problemAnswer' + answer.index}
                                value={(answer.index + 1).toString()}
                                control={<Radio color="secondary" />}
                                label={
                                    <Grid container>
                                        <Grid item sm={12}>
                                            <label dangerouslySetInnerHTML={{ __html: answer.value }} />
                                            {generatedProblem.answeredDate && (
                                                <label style={{ color: answer.isCorrect ? colors.green[500] : colors.red[500] }}>
                                                    {answer.isCorrect && ` (${translate('Correct')})`}
                                                    {answer.isChosen && !answer.isCorrect && ` (${translate('Wrong')})`}
                                                </label>
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                            />
                        ))}
                    </RadioGroup>
                )}
                {(problem.type !== 0 || generatedProblem.answerIsLatex) && (
                    <ProblemLatexAnswer value={userAnswer} onValueChange={(_) => handleAnswerChange(_)} />
                )}
                {generatedProblem.answeredDate && generatedProblem.answeredCorrectly === false && !generatedProblem.answerIsLatex && (
                    <FormHelperText>
                        {translate('Wrong')}. {translate('CorrectAnswerIs')}: {generatedProblem.correctAnswer}
                    </FormHelperText>
                )}
                {generatedProblem.answeredDate && generatedProblem.answeredCorrectly === false && generatedProblem.answerIsLatex && (
                    <div>
                        <FormHelperText>{translate('Wrong') + '. '}{translate('CorrectAnswerIs')}</FormHelperText>
                        <ProblemLatexText text={generatedProblem.correctAnswer} />
                    </div>
                )}
                {error && (
                    <div>
                        {translate(problem.type === 0 ? 'PleaseSelectAnswer' : 'PleaseProvideAnswer')}
                    </div>
                )}
                <Grid container>
                    <Grid item sm={12}>
                        {(!generatedProblem.answeredDate) && (
                            <Button type="submit" variant="contained" color="primary" className={classes.button} style={{ flex: generatedProblem.solvingStepsExist ? 0.9 : 1 }}>
                                {translate('CheckAnswer')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </FormControl>
        </form>
    )
}