import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/reducers/teacher';
import {
  Box,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import HomeworkResultsGrid from './HomeworkResultsGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const HomeworkResultView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const user = useSelector(state => state.userContext.user);
  const homework = useSelector(state => state.teacher.homeworks.find(h => h.id == id));
  const gridWidth = (homework && homework.problems.length > 10) ? '100%' : 'lg';

  useEffect(() => {
    if (user && user.role && !homework) {
      dispatch(actionCreators.requestHomeworks());
    }
  }, [user, homework, dispatch])

  return (
    <Page
      className={classes.root}
      title="Homework"
    >
      <Container maxWidth={gridWidth}>
        <Box>
          <HomeworkResultsGrid />
        </Box>
      </Container>
    </Page>
  );
};

export default HomeworkResultView;
