import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView'
import SubjectsView from 'src/views/common/Subjects';
import HelpView from 'src/views/common/Help/HelpView';
import TopicsView from 'src/views/common/Topics';
import ProblemsView from 'src/views/common/Problems';
import ProblemSolvingView from 'src/views/common/Problem/Solving';
import ProblemStatsView from 'src/views/common/Problem/Stats';
import TrainingsView from 'src/views/common/trainings/index';

const commonRoutes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'subjects', element: <SubjectsView /> },
      { path: 'topics/:id', element: <TopicsView /> },
      { path: 'problem/stats/:id', element: <ProblemStatsView /> },
      { path: 'problems/:id', element: <ProblemsView /> },
      { path: 'problem/:id', element: <ProblemSolvingView /> },
      { path: 'account', element: <AccountView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'trainings', element: <TrainingsView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'help', element: <HelpView /> },
      { path: '*', element: <NotFoundView /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/subjects" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default commonRoutes;
