import * as apiCaller from 'src/helpers/apiCaller';
function resetAuthorizationData() {
  localStorage.setItem('authorizationData', '');
  localStorage.setItem('authorizationDataIdToken', '');

  localStorage.setItem('IsAuthorized', 'false');
}

function setAuthorizationData(token, id_token) {
  // if (localStorage.getItem('authorizationData') !== '') {
  //   localStorage.setItem('authorizationData', '');
  // }

  localStorage.setItem('authorizationData', token);
  localStorage.setItem('authorizationDataIdToken', id_token);
  localStorage.setItem('IsAuthorized', 'true');
}

export function authorize(){
  resetAuthorizationData();
  let authorizationUrl = `${process.env.REACT_APP_IDENTITY_URI}/connect/authorize`;
  let client_id = 'mathlife-auth-client-id';
  let redirect_uri = window.location.origin + '/';
  let response_type = 'id_token token';
  let scope = 'openid profile apis';
  let nonce = 'N' + Math.random() + '' + Date.now();
  let state = Date.now() + '' + Math.random();

  let url =
    authorizationUrl + '?' +
    'response_type=' + encodeURI(response_type) + '&' +
    'client_id=' + encodeURI(client_id) + '&' +
    'redirect_uri=' + encodeURI(redirect_uri) + '&' +
    'scope=' + encodeURI(scope) + '&' +
    'nonce=' + encodeURI(nonce) + '&' +
    'state=' + encodeURI(state);

  window.location.href = url;
}

export function authorizedCallback() {
  resetAuthorizationData();

  let hash = window.location.hash.substring(1);

  let result = hash.split('&').reduce(function (result, item) {
    let parts = item.split('=');
    result[parts[0]] = parts[1];
    return result;
  }, {});

  console.log(result.id_token);

  let token = '';
  let id_token = '';
  let authResponseIsValid = false;

  if (!result.error) {

      token = result.access_token;
      id_token = result.id_token;

      let dataIdToken = getDataFromToken(id_token);

        localStorage.setItem('authNonce', '');
        localStorage.setItem('authStateControl', '');

        authResponseIsValid = true;

        console.log('AuthorizedCallback state and nonce validated, returning access token');
      // validate nonce
      // if (dataIdToken.nonce !== localStorage.getItem('authNonce')) {
      //   console.log('AuthorizedCallback incorrect nonce');
      // } else {
      // }
    // if (result.state !== localStorage.getItem('authStateControl')) {
    //   console.log('AuthorizedCallback incorrect state');
    // } else {
    //
    // }
  }

  if (authResponseIsValid) {
    setAuthorizationData(token, id_token);
  }
}

export function logoff() {
  let authorizationUrl = `${process.env.REACT_APP_IDENTITY_URI}/connect/endsession`;
  let id_token_hint = localStorage.getItem('authorizationDataIdToken');
  let post_logout_redirect_uri = window.location.origin + '/';

  let url =
    authorizationUrl + '?' +
    'id_token_hint=' + encodeURI(id_token_hint) + '&' +
    'post_logout_redirect_uri=' + encodeURI(post_logout_redirect_uri);

  resetAuthorizationData();
  
  window.location.href = url;
  // apiCaller.callCustomApiWithAuth(`${process.env.REACT_APP_IDENTITY_URI}/account/logout`, 'POST').then(() => {
  // })

}


  function getDataFromToken(token) {
    let data = {};

    if (typeof token !== 'undefined') {
      let encoded = token.split('.')[1];

      data = JSON.parse(urlBase64Decode(encoded));
    }

    return data;
  }

  function urlBase64Decode(str) {
    let output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }

    return window.atob(output);
  }
