import React from 'react';
import {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import translate from 'src/helpers/translate';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const helpLinks = [
    {
        link: 'https://www.youtube.com/watch?v=HCn-1HBVhts',
        name: 'HelpInRegistration'
    },
    {
        link: 'https://www.youtube.com/watch?v=PQrEA87wnLM',
        name: 'WhatIsAProblem'
    },
    {
        link: 'https://www.youtube.com/watch?v=SmmrtRTZ0wQ&amp;t=10s',
        name: 'WhiteBoards'
    },
    {
        link: 'https://www.youtube.com/watch?v=IlsVhVmfg2M',
        name: 'Trainings'
    },
]

export default function HelpView() {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title="Help"
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Card className={classes.root} raised>
                        <CardContent>
                            <List component="nav">
                                {helpLinks.map(helpLink => (
                                    <ListItem>
                                        <Button href={helpLink.link} key={helpLink.name}>
                                            <ListItemText primary={translate(helpLink.name)} />
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
};
