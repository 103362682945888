import React, { useEffect, useState } from 'react';
import * as apiCaller from 'src/helpers/apiCaller';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';
import { Box, Card, CardContent, Tooltip, Button, CardActions, ButtonGroup, Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
import ProblemBreadCrumbs from '../ProblemBreadCrumbs';
import Skeleton from '@mui/material/Skeleton';
import { Rating } from '@mui/material';
import translate from 'src/helpers/translate';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ProblemBody from './ProblemBody';
import MistakeFoundDialog from './MistakeFoundDialog';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '600px',
    width: '100%',
    paddingBottom: theme.spacing(3),
    marginTop: 10
  }
}));

const Problem = ({ problem, previousProblem, nextProblem }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const user = useSelector(state => state.userContext.user);

  const [generatedProblem, setGeneratedProblem] = useState(null);

  const [mistakeFoundDialogIsOpen, setMistakeFoundDialogIsOpen] = useState(false);

  const [whiteBoardOpened, setWhiteBoardOpened] = useState(false);

  const clientProblem = useSelector(state => state.common.clientProblems
    .find(cp => cp.problemId == problem.id && cp.clientId === user.id));

  useEffect(() => {
    setGeneratedProblem(null);
    setWhiteBoardOpened(false);
    if (problem != undefined && problem.id) {
      apiCaller.get(`/problem/forFront?id=${problem.id}`, true).then(
        (generatedProblem) => {
          setGeneratedProblem(generatedProblem);
        }
      );
    }
  }, [problem])

  const generateNewProblem = () => {
    setGeneratedProblem(null);
    setWhiteBoardOpened(false);
    apiCaller.get(`/problem/generateForFront?id=${problem.id}`, true).then(
      (generatedProblem) => {
        setGeneratedProblem(generatedProblem);
        dispatch(clientProblemsActionCreators.requestClientProblems());
      }
    );
  }

  const handleFoundMistakeClicked = () => {
    setMistakeFoundDialogIsOpen(true);
  }

  return (
    <Container maxWidth={"lg"}>
      <Box mt={whiteBoardOpened ? 5 : 8}>
        {problem && (
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <ProblemBreadCrumbs problem={problem} />
            </Grid>
            <Grid item xs={12}>
              <React.Fragment>
                <Card>
                  <CardContent>
                    <Box component="fieldset" mb={3} borderColor="transparent">
                      <Grid container>
                        <Grid item sm={11}>
                          <Tooltip title={`${translate('Rating')} ${clientProblem?.rating ?? 0}`} arrow>
                            <Rating
                              name="read-only"
                              value={clientProblem?.rating ?? 0}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item sm={1}>
                          <Tooltip title={translate('ShowStatistics')} arrow >
                            <Button component={Link} to={`/app/problem/stats/${problem.id}`}>
                              <EqualizerIcon color="primary" />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                    {generatedProblem && (
                      <ProblemBody
                        problem={problem}
                        generatedProblem={generatedProblem}
                        setGeneratedProblem={(_)=>setGeneratedProblem(_)}
                        clientProblem={clientProblem}
                        onWhiteBoardToggle={(open) => setWhiteBoardOpened(open)}
                        whiteBoardOpened={whiteBoardOpened}
                      />
                    )}
                    {!generatedProblem && (
                      <div>
                        <Skeleton variant="text" width={300} height={40} />
                        <Skeleton variant="rectangular" width={300} height={200} />
                      </div>
                    )}
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <ButtonGroup variant="contained" color="secondary" aria-label="contained primary button group">
                      {previousProblem && (
                        <Tooltip title={translate('PreviousProblem')} arrow >
                          <Button component={Link} to={`/app/problem/${previousProblem.id}`} >
                            <ArrowLeftIcon />
                          </Button>
                        </Tooltip>
                      )}
                      {!generatedProblem?.answeredDate && (
                        <Tooltip title={translate(clientProblem?.generatedWithoutAnswering < 2 ? 'GenerateNewTooltip' : 'GenerateNewTooltipWarning')} arrow >
                          <Button onClick={() => generateNewProblem()}>
                            {translate('GenerateNew')}
                          </Button>
                        </Tooltip>
                      )}
                      {generatedProblem?.answeredDate && (
                        <Button onClick={() => generateNewProblem()} >
                          {translate('GenerateNew')}
                        </Button>
                      )}
                      {nextProblem && (
                        <Tooltip title={translate('NextProblem')} arrow >
                          <Button component={Link} to={`/app/problem/${nextProblem.id}`}>
                            <ArrowRightIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                    <Button
                      color="primary"
                      style={{ marginLeft: 'auto' }}
                      onClick={handleFoundMistakeClicked}
                    >
                      {translate("ProblemResources_FoundAmistake")}
                    </Button>
                  </CardActions>
                </Card>
                {mistakeFoundDialogIsOpen && generatedProblem && (
                  <MistakeFoundDialog
                    generatedProblemId={generatedProblem.id}
                    problemId={problem.id}
                    open={mistakeFoundDialogIsOpen}
                    onClose={() => setMistakeFoundDialogIsOpen(false)} />
                )}
              </React.Fragment>
            </Grid>
          </Grid>
        )}
      </Box>
      {whiteBoardOpened && (
        <Box mt={5} className={classes.root} id="problemContainer"></Box>
      )}
    </Container>
  );
};

export default Problem;
