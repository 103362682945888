import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { actionCreators } from '../../../reducers/user';
import SubmitButton from 'src/utils/SubmitButton';
import translate from 'src/helpers/translate';

const useStyles = makeStyles(() => ({
  root: {}
}));

export default function ProfileDetails( {className, ...rest}){
  const dispatch = useDispatch();
  const user = useSelector(state => state.userContext.user);
  const formIsSubmitting  = useSelector(state => state.userContext.isUpdating);
  const userNameIsBusy = useSelector(state => state.userContext.userNameIsBusy);

  const classes = useStyles();
  const [formIsValid, setFormIsValid] = useState(true);

  const [values, setValues] = useState({
    firstname: '',
    surname: '',
    userName: '',
    city: ''
  });

  const [errors, setErrors] = useState({
    firstname: '',
    surname: '',
    userName: '',
    city: ''
  });

  React.useEffect(() => {
    setValues({
      firstname: user?.name ?? '',
      surname: user?.surname ?? '',
      userName: user?.userName ?? '',
      city: user?.city ?? ''
    });
  }, [user])

  React.useEffect(() => {
    setErrors(errors => {
      return {
        ...errors,
        userName: userNameIsBusy ? translate('UserWithSuchLoginAlreadyRegistered') : ''
      }
    });
  }, [userNameIsBusy])

  const handleChange = (event) => {
    let error = '';

    if (event.target.required && event.target.value === '') {
      error = translate('GlobalResources_RequiredFieldValidation');
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    setErrors({
      ...errors,
      [event.target.name]: error
    });
  };

  const submitForm = () => {
    const user = {
      name: values.firstname,
      surname: values.surname,
      userName: values.userName,
      city: values.city,
    }
    dispatch(actionCreators.updateUser(user));
  };

  return (
    <form
      autoComplete="off"
      // noValidate
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader
          // subheader="The information can be edited"
          title={translate("ProfileResources_Title")}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                error={errors.firstname !== ''}
                helperText={errors.firstname}
                label={translate("ProfileResources_Name")}
                name="firstname"
                onChange={handleChange}
                required
                value={values.firstname}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                error={errors.surname !== ''}
                helperText={errors.surname}
                label={translate("ProfileResources_Surname")}
                name="surname"
                onChange={handleChange}
                required
                value={values.surname}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                error={errors.userName !== ''}
                helperText={errors.userName}
                label={translate("ProfileResources_Login")}
                name="userName"
                onChange={handleChange}
                required
                value={values.userName}
                variant="standard"
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={translate("ProfileResources_City")}
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="standard"
              />
            </Grid> */}
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={translate("ProfileResources_City")}
                name="city"
                onChange={handleChange}
                value={values.city}
                variant="standard"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <SubmitButton
            isSubmitting={formIsSubmitting}
            canBeSubmitted={!formIsSubmitting && formIsValid}
            onSubmit={submitForm}
          />
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};
