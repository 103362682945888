import React, {useEffect} from 'react';
import { actionCreators } from 'src/reducers/common/boards';
import { actionCreators as teachersActionCreators} from 'src/reducers/teacher';
import { useDispatch, useSelector } from 'react-redux';
import CommonBoardView from 'src/views/common/boards/CommonBoardView';

const BoardView = () => {
    const dispatch = useDispatch();

    const workState = useSelector(state => state.teacher.workState);
    const groups = useSelector(state => state.teacher.groups);
    const currentGroup = groups.find(g => g.isCurrent);
    const groupStudents = useSelector(state => state.teacher.studentGroups.filter(gs => currentGroup && gs.groupId == currentGroup.id));
    const currentStudent = useSelector(state => state.teacher.students.find(g => g.isCurrent));

    const students = useSelector(state => state.teacher.students);

    const canShareWithCurrentGroup = (board) => board && board.clients && workState.isGroup && currentGroup
        && groupStudents && groupStudents.length > 0 && !groupStudents.every(gs => board.clients.map(c => c.id).includes(gs.studentId));

    const canShareWithCurrentStudent = (board) => board && board.clients && workState.isStudent && currentStudent
        && !board.clients.map(c => c.id).includes(currentStudent.id);

    const shouldShowSwitch = (board) =>  canShareWithCurrentGroup(board) || canShareWithCurrentStudent(board);

    const currentWorkEntity = workState.isGroup ? currentGroup?.name : currentStudent?.wholeName;

    const shareWithCurrentEntity = (board) => {
        if (canShareWithCurrentGroup(board)) {
            dispatch(actionCreators.shareWithGroup(board.id, currentGroup.id));
        }
        if (canShareWithCurrentStudent(board)) {
            dispatch(actionCreators.shareWithStudent(board.id, currentStudent.id));
        }
    }

    useEffect(()=>{
        if(students && students.length < 1){
            dispatch(teachersActionCreators.requestStudents());
        }

        if(groups && groups.length < 1){
            dispatch(teachersActionCreators.requestGroups());
        }
    },[dispatch, students, groups])

    return (
        <CommonBoardView 
            clients={students}
            shouldShowSwitch={(board) => shouldShowSwitch(board)}
            shareWithCurrentEntity={(board) => shareWithCurrentEntity(board)}
            currentWorkEntity={currentWorkEntity}
            />
    );
};

export default BoardView;
