import React, { useEffect } from 'react';
/* global JXG */

export default function Graph652(props) {

    let {a, b, c} = props.data;
    
    props.board.create('functiongraph',[function(x){return a*JXG.Math.pow(x + b, 1/2) + c}],{strokeColor:'black',highlightStrokeColor:'black'});
    
    return <></>
}