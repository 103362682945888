import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ProblemSolvingStatsGrid from '../../../common/Problem/Stats/ProblemSolvingStatsGrid';
import translate from 'src/helpers/translate';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row({ student, clientProblem, generatedProblems }) {
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    let data = [];
    if (generatedProblems && generatedProblems.length > 0) {
        data = generatedProblems.filter(p =>
            p.answeredCorrectly !== null);
    }

    const allTime = data.filter(d => d.solvingTime < 60 * 60 * 24).reduce(function (a, b) {
        return a + b.solvingTime;
    }, 0);
    const avarageTime = data.length > 0 ? allTime / data.length : 0;

    const avarageTimeSpan = new Date(avarageTime * 1000).toISOString().substr(11, 8);

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {student.wholeName}
                </TableCell>
                <TableCell align="right">{clientProblem?.rating ?? 0}</TableCell>
                <TableCell align="right">{generatedProblems.filter(gp => gp.answeredCorrectly).length}</TableCell>
                <TableCell align="right">{generatedProblems.filter(gp => !gp.answeredCorrectly && gp.answeredDate).length}</TableCell>
                <TableCell align="right">{generatedProblems.filter(gp => gp.answeredDate == null).length}</TableCell>
                <TableCell align="right">{avarageTimeSpan}</TableCell>
            </TableRow>
            <TableRow align="right">
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                {translate('History')}
                            </Typography>
                            <ProblemSolvingStatsGrid generatedProblems={data} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ProblemSolvingStatsForGroupGrid({ generatedProblems }) {
    const { id } = useParams();

    const studentIds = Array.from(new Set(generatedProblems.map(gp => gp.clientId)));

    const students = useSelector(state => state.teacher.students.filter(s => studentIds.some(id => id == s.id)));

    const clientProblems = useSelector(state => state.common.clientProblems.filter(cp => cp.problemId == id && students.some(s => s.id == cp.clientId)));

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{translate('Name')}</TableCell>
                        <TableCell align="right">{translate('Rating')}</TableCell>
                        <TableCell align="right">{translate('Correct')}</TableCell>
                        <TableCell align="right">{translate('Incorrect')}</TableCell>
                        <TableCell align="right">{translate('Unsolved')}</TableCell>
                        <TableCell align="right">{translate('AvgSolvingTime')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {students && students.map((student) => (
                        <Row
                            key={student.id}
                            student={student}
                            clientProblem={clientProblems.find(cp => cp.clientId == student.id)}
                            generatedProblems={generatedProblems.filter(gp => gp.clientId == student.id)}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}