import React, { useEffect } from 'react';
import { actionCreators } from 'src/reducers/common/boards';
import { actionCreators as teachersActionCreators} from 'src/reducers/student/teachers';
import { useDispatch, useSelector } from 'react-redux';
import CommonBoardView from 'src/views/common/boards/CommonBoardView';

const BoardView = () => {
    const dispatch = useDispatch();

    const teachers = useSelector(state => state.student.teachers);

    const currentTeacher = teachers[0];

    const canShareWithCurrentTeacher = (board) => board && board.clients && currentTeacher
        && !board.clients.map(c => c.id).includes(currentTeacher.id);

    const shareWithCurrentEntity = (board) => {
        dispatch(actionCreators.shareWithTeacher(board.id, currentTeacher.id));
    }

    useEffect(()=>{
        if(teachers && teachers.length < 1){
            dispatch(teachersActionCreators.requestTeachers());
        }
    },[dispatch, teachers?.length])

    return (
        <CommonBoardView 
            clients={teachers}
            shouldShowSwitch={(board) => canShareWithCurrentTeacher(board)}
            shareWithCurrentEntity={(board) => shareWithCurrentEntity(board)}
            currentWorkEntity={currentTeacher?.wholeName}
            />
    );
};

export default BoardView;
