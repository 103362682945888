import React from 'react';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StudentProblemStatsView from 'src/views/student/problem/Stats';
import TeacherProblemStatsView from 'src/views/teacher/problem/Stats';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        marginTop: -20
    }
}));

const ProblemStatsView = () => {
    const classes = useStyles();

    const { id } = useParams();

    const problem = useSelector(state => state.common.problems.find(p => p.id == id));

    const user = useSelector(state => state.userContext.user);

    return (
        <Page
            className={classes.root}
            title="Statistics"
        >
            {user.isStudent && (
                <StudentProblemStatsView problem={problem} />
            )}
            {!user.isStudent && (
                <TeacherProblemStatsView problem={problem} />
            )}
        </Page>
    );
};

export default ProblemStatsView;
