import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import ProblemLatexText from './ProblemLatexText';

export default function ProblemText({ generatedProblem }) {

    const [problemImageLoaded, setProblemImageLoaded] = useState(false);

    return (
        <Grid item md={generatedProblem.latexText ? 11 : generatedProblem.graphicUrl ? 6 : 11}>
            <React.Fragment>
                <Typography dangerouslySetInnerHTML={{ __html: generatedProblem.problemText }} />
                {generatedProblem.imageUrl && !generatedProblem.latexText && (
                    <div>
                        <img src={generatedProblem.imageUrl} style={{ marginTop: 10 }} onLoad={() => setProblemImageLoaded(true)} />
                        {!problemImageLoaded && <Skeleton variant="text" width={100} />}
                    </div>
                )}
                {generatedProblem.latexText && (
                    <ProblemLatexText text={generatedProblem.latexText}/>
                )}
                {generatedProblem.problemText1 && (
                    <Typography dangerouslySetInnerHTML={{ __html: generatedProblem.problemText1 }} />
                )}
            </React.Fragment>
        </Grid>
    )
}