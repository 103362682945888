import locales from '../localization/translations/locales';
import store from 'src/configure-store';

if (!String.format) {
    String.format = function(format) {
      var args = Array.prototype.slice.call(arguments, 1);
      return format.replace(/{(\d+)}/g, function(match, number) { 
        return typeof args[number] != 'undefined'
          ? args[number] 
          : match
        ;
      });
    };
}

export default function translate(key, ...params) {
    const state = store.getState();
    let resource = locales[state.culture.locale][key];
    if(params && params.length > 0){
        resource = String.format(resource, params);
    }
    return resource;
}