import React from 'react';
import { Grid, Switch, FormControlLabel } from '@mui/material';

export default function JournalTopBar({shouldShowSwitch, showForCurrentEntity, setShowForCurrentEntity, currentWorkEntity}) {

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={7} sm={8} md={9} />
                <Grid item xs={5} sm={4} md={3} >
                    {shouldShowSwitch && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showForCurrentEntity}
                                    onChange={() => setShowForCurrentEntity(!showForCurrentEntity)}
                                    color="primary"
                                />
                            }
                            label={currentWorkEntity}
                        />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}