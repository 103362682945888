import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { actionCreators } from 'src/reducers/teacher';
import { Done } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { green, red } from '@mui/material/colors';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Avatar, Card, CardContent, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import IconButton from '@mui/material/IconButton';
import TestsTopBar from './TestsTopBar';
import Delete from '@mui/icons-material/Delete';
import { colors } from '@mui/material';
import ConfirmationDialog from 'src/components/common/ConfirmationDialog';
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from 'src/helpers/cookies';

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -35
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    },
    createNewButton: {
        marginRight: 5
    },
    filterByGroupButton: {
        marginBottom: 10,
    },
    cancelButton: {
        color: red[500],
        borderColor: red[500]
    },
    newRequestButton: {
        backgroundColor: green[200]
    },
    addNewButton: {
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    }, button: {
        marginLeft: '-10px',
        fontSize: 10
    },
    deleteButton: {
        marginLeft: '-10px',
        fontSize: 10,
        color: colors.red[400]
    },
    grid: {
        cursor: 'pointer'
    }
}));


export default function TestsGrid({ tests }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const students = useSelector(state => state.teacher.students);
    const workState = useSelector(state => state.teacher.workState);
    const currentGroup = useSelector(state => state.teacher.groups.find(g => g.isCurrent));
    const currentStudent = useSelector(state => state.teacher.students.find(g => g.isCurrent));
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');
    const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
    const [testIdToDelete, setTestIdToDelete] = useState(0);
    const testsAreUpdating = useSelector(state => state.teacher.testsAreUpdating);
    let navigate = useNavigate();

    const problems = useSelector(state => state.common.problems);

    const topics = useSelector(state => state.common.topics);

    const shouldShowSwitch = workState.isGroup && currentGroup
        || workState.isStudent && currentStudent;

    const currentWorkEntity = workState.isGroup ? currentGroup?.name : currentStudent?.wholeName;

    let shouldShowForCurrentEntity = getCookie('showForCurrentEntity');

    shouldShowForCurrentEntity = shouldShowForCurrentEntity == undefined ? true : shouldShowForCurrentEntity == 'true';

    const [showForCurrentEntity, setShowForCurrentEntity] = useState(shouldShowForCurrentEntity);

    if (showForCurrentEntity) {
        if (workState.isGroup && currentGroup) {
            tests = tests.filter(h => h.groups.includes(currentGroup.id));
        } else if (workState.isStudent && currentStudent) {
            tests = tests.filter(h => h.students.includes(currentStudent.id));
        }
    }

    const testsToRender = tests.map(h => {
        let problemsForTopic = h.problems.map(hp => problems.find(p => p.id == hp));
        console.log(problemsForTopic);
        return {
            ...h,
            students: students.filter(s => h.students.some(hs => hs === s.id)),
            joinedTopics: topics.filter(t => problemsForTopic.some(p => p.endTopic == t.id)).map(t => translate(`TopicResources_${t.id}`)).filter(onlyUnique).join(', ')
        };
    }).sort((firstEl, secondEl) => secondEl.id - firstEl.id)

    const handleSend = (params, event) => {
        dispatch(actionCreators.sendTest(params.id))
        event.stopPropagation();
    }

    const handleDelete = (params, event) => {
        event.stopPropagation();
        setTestIdToDelete(params.id);
        setConfirmationDialogMessage(translate('Delete').toString().toLowerCase() + ' ' + params.row.description)
        setConfirmationDialogIsOpen(true);
    }

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'isSent',
            flex: 1,
            disableSelectionOnClick: true,
            headerName: translate('Status'),
            renderCell: (params) =>
                <React.Fragment>
                    <Tooltip title={translate('Delete')} arrow>
                        <IconButton
                            onClick={(event) => handleDelete(params, event)}
                            className={classes.deleteButton}
                            color="secondary"
                            aria-label="upload picture"
                            component="span"
                            size="large">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    {params.value &&
                        (
                            (<Tooltip title={translate('Sent')} arrow>
                                <Done />
                            </Tooltip>)
                        )
                    }
                    {!params.value &&
                        (<Tooltip title={translate(params.row.canBeSent ? 'Send' : 'HomeworkNeedsToBeCompletedBeforeSent')} arrow>
                            <span>
                                <IconButton
                                    onClick={(event) => handleSend(params, event)}
                                    className={classes.button}
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    disabled={!params.row.canBeSent}
                                    size="large">
                                    <SendIcon />
                                </IconButton>
                            </span>
                        </Tooltip>)
                    }
                </React.Fragment>
        },
        {
            field: 'description',
            flex: 2,
            headerName: translate('Description'),
            sortable: false
        },
        {
            field: 'createdOnString',
            flex: 1,
            headerName: translate('CreatedOn'),
            valueGetter: (params) => params.row.id,
            renderCell: (params) => <React.Fragment>{params.row.createdOnString}</React.Fragment>
        }
    ];

    if (tests.some(h => h.students !== null)) {
        columns.push({
            field: 'students',
            flex: 2,
            headerName: translate('Students'),
            renderCell: (params) => (
                <Tooltip title={params.value.map(s => s.wholeName).join(', ')} arrow>
                    <AvatarGroup max={5}>
                        {params.value.slice(0, 5).map(student => (
                            <Avatar
                                key={student?.id}
                                src={`data:image/jpg;base64, ${student?.avatarImage}`}
                                className={classes.small}
                            />
                        ))}
                    </AvatarGroup>
                </Tooltip>
            )
        })
    }

    if (tests.some(h => h.problems !== null)) {
        columns.push({
            field: 'joinedTopics',
            flex: 3,
            headerName: translate('Topics'),
            renderCell: (params) => (
                <Tooltip title={params.value} arrow>
                    <span>
                        {params.value.length < 40 ? params.value : params.value.substring(0, 40) + '...'}
                    </span>
                </Tooltip>
            )
        })
    }



    if (tests.some(h => h.endDate !== null)) {
        columns.push({
            field: 'endDateString',
            flex: 1,
            headerName: translate('DueDate'),
        })
    }

    const handleRowClick = (params) => {
        navigate(`/app/test/${params.id}`, { replace: true });
    };

    const onShowForCurrentEntityChange = (show) => {
        setShowForCurrentEntity(show);
        setCookie('showForCurrentEntity', show, 180);
    }


    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <TestsTopBar
                        shouldShowSwitch={shouldShowSwitch}
                        currentWorkEntity={currentWorkEntity}
                        showForCurrentEntity={showForCurrentEntity}
                        setShowForCurrentEntity={(show) => onShowForCurrentEntityChange(show)}
                    />
                    {testsToRender && testsToRender.length > 0 && (
                        <DataGrid
                        loading={testsAreUpdating}
                        rows={testsToRender}
                        density="compact"
                        columns={columns}
                        pageSize={15}
                        autoHeight={true}
                        disableColumnMenu
                        className={classes.grid}
                        onRowClick={handleRowClick}
                        hideFooter={testsToRender.length <= 15}
                        hideFooterSelectedRowCount />
                    )}
                </CardContent>
            </Card>
            <ConfirmationDialog
                open={confirmationDialogIsOpen}
                onCancel={() => setConfirmationDialogIsOpen(false)}
                onSubmit={() => dispatch(actionCreators.deleteTest(testIdToDelete))}
                message={confirmationDialogMessage}
            />
        </React.Fragment>

    );
}