import * as apiCaller from 'src/helpers/apiCaller';
const problemReceivedType = "GENERATED_PROBLEM_RECEIVED";

function requestProblem(id) {
    return async dispatch => {
        await apiCaller.get(`/problem/getSimple?id=${id}`, true).then(
            (problem) => {
                dispatch(success(problem));
            }
        );
        function success(problem) {
            return { type: problemReceivedType, problem };
        }
    };
}


export const actionCreators = {
    requestProblem
}

const generatedProblems = (state = [], action) => {
    if (action.type === problemReceivedType) {
        return [...state, action.problem];
    }

    return state;
};

export default generatedProblems;