import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';
import { actionCreators as trainingsActionCreators } from 'src/reducers/common/trainings';
import { actionCreators as eduVideosActionCreators } from 'src/reducers/common/eduVideos';
import { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Button, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import LevelResultTimelineItem from './LevelResultTimelineItem';
import Paper from '@mui/material/Paper';
import TeacherRatingTimelineDot from 'src/views/teacher/problems/RatingTimelineDot';
import StudentRatingTimelineDot from 'src/views/student/problems/RatingTimelineDot';
import TrainingsAndVideos from 'src/views/common/TrainingsAndVideos/TrainingsAndVideos';
import translate from 'src/helpers/translate';
import TopicBreadcrumbs from './TopicBreadcrumbs'
import SimpleProblemBody from 'src/views/common/Problem/InTopic/SimpleProblemBody';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 0.5,
        marginTop: -40
    },
    timeline: {
        marginTop: 20
    },
    cardMedia: {
        maxHeight: 80
    },
    paper: {
        padding: '6px 16px',
        width: 450
    },
    problemImage: {
        width: 420
    },
    secondaryTail: {
        backgroundColor: theme.palette.primary.main,
    },
    problemActionButton: {
        color: theme.palette.primary.main
    }
}));

export default function ProblemsGrid(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    let { id } = useParams();

    const problems = useSelector(state => state.common.problems
        .filter(p => p.endTopic === id.toString()).sort((a, b) => a.fullNumber - b.fullNumber));

    const user = useSelector(state => state.userContext.user);

    useEffect(() => {
        if (user && user.role) {
            dispatch(clientProblemsActionCreators.requestClientProblems());
            dispatch(trainingsActionCreators.requestTrainings());
            dispatch(eduVideosActionCreators.requestEduVideos());
        }
    }, [user, dispatch])

    const timeline = [];
    [1, 2, 3].forEach(level => {
        const problemsForLevel = problems.filter(p => p.level === level);

        problemsForLevel.map(problem => timeline.push(
            <ProblemTimeLineItem problem={problem} key={problem.id} />
        ));
        const levelIsLast = !problems.some(p => p.level === level + 1);

        if (problemsForLevel.length > 0) {
            timeline.push(<LevelResultTimelineItem key={`result${level}`} problemsForLevel={problemsForLevel} end={levelIsLast} />);
        }
    })

    return (
        <div className={classes.root} >
            <TopicBreadcrumbs
                topicId={id}
                currentPageUrl={'/app/topics/'}
                returnToRootTopic={true}
                chooseSubjectUrl={'/app/subjects'}
            />
            <TrainingsAndVideos topicId={id} />
            <Timeline align="left" className={classes.timeline}>
                {timeline}
            </Timeline>
        </div>
    );
}

function ProblemTimeLineItem({ problem }) {
    const classes = useStyles();
    const userRole = useSelector(state => state.userContext.user.role);
    return (
        <TimelineItem>
            <TimelineOppositeContent>
                {problem.numberInLevel === 1 && (
                    <Typography variant="body2" color="textSecondary" sx={{ display: { xs: 'none', md: 'block' } }}>
                        {translate(`Level${problem.level}`)}
                    </Typography>
                )}
            </TimelineOppositeContent>
            <TimelineSeparator>
                {userRole === 'Student' ? (<StudentRatingTimelineDot problem={problem} />) : (<TeacherRatingTimelineDot problem={problem} />)}
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={5} className={classes.paper}>
                    <SimpleProblemBody problemId={problem.id} />
                    <Button component={Link} to={`/app/problem/${problem.id}`} size="small" color="primary" >{translate('Solve')}</Button>
                    <Button component={Link} to={`/app/problem/stats/${problem.id}`} size="small" color="primary" >{translate('Statistics')}</Button>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
}