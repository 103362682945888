import React from 'react';

export default function Graph295(props) {

    let {a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w ,x } = props.data;
    
    var A = props.board.create('point', [a, b], { name: '', fixed: true, size:2 });
    var B = props.board.create('point', [c, d], { name: '', fixed: true, size:0 });
    var C = props.board.create('point', [e, f], { name: '', fixed: true, size:2 });
    var D = props.board.create('point', [g, h], { name: '', fixed: true, size:0 });

    var vecAB = props.board.create('arrow', [A, B], { name: 'vecAB', strokeColor: 'black' });
    var vecCD = props.board.create('arrow', [C, D], { name: 'vecCD', strokeColor: 'black' });

    return <></>
}