import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

function MathlifeAlert(props) {
  return <Alert elevation={6} variant="filled" onClose={props.onClose} severity={props.severity}>{props.message}</Alert>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbar(props){
  const classes = useStyles();
  const alert = useSelector(state => state.layout.alert);
  const [open, setOpen] = React.useState(false);
  const vertical = alert?.vertical;
  const horizontal = alert?.horizontal;

  useEffect(() => {
    if (!!alert) {
      setOpen(true);
    }
  }, [alert])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {!!alert && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} elevation={6} variant="filled" severity={alert.severity} >{alert.message}</Alert>
        </Snackbar>
      )}
    </div>
  );
}