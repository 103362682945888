const showAlertType = "SHOW_ALERT";

function showAlert(message, severity, vertical, horizontal) {
  return async dispatch => {
    dispatch({ type: showAlertType, message, severity, vertical, horizontal });
  };
}

export function showSuccessAlert(message, vertical, horizontal) {
  return async dispatch => {
    dispatch(showAlert(message, "success", vertical, horizontal));
  };
}

export function showErrorAlert(message, vertical, horizontal) {
  return async dispatch => {
    dispatch(showAlert(message, "error", vertical, horizontal));
  };
}

export function showWarningAlert(message, vertical, horizontal) {
  return async dispatch => {
    dispatch(showAlert(message, "warning", vertical, horizontal));
  };
}

export function showInfoAlert(message, vertical, horizontal) {
  return async dispatch => {
    dispatch(showAlert(message, "info", vertical, horizontal));
  };
}

export const actionCreators = {
  showSuccessAlert,
  showErrorAlert,
  showWarningAlert,
  showInfoAlert
};

export const actionTypes = {
  showAlertType
};

const initialState = {
  alert: null
}

export const layout = (state = initialState, action) => {
  if (action.type === showAlertType) {
    return {
      ...state,
      alert: {
        message: action.message,
        severity: action.severity,
        vertical: action.vertical ?? 'bottom',
        horizontal: action.horizontal ?? 'center',
      }
    }
  }

  return state;
};
