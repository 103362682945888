const uk = {
    LoginResources_ExternalLoginConfirmation_Text2: 'Будь ласка, введіть ваш email нижче щоб завершити реєстрацію',
    LoginResources_ExternalLoginConfirmation_Text1: 'Ви успішно ідентифіковані з',
    LoginResources_ExternalLoginConfirmation_UnseccessfulLogin: 'Неуспiшний вхiд с сервера',
    LoginResources_SubmitButton: 'Увійти',
    LoginResources_LoginError: 'Пароль або логін введений неправильно',
    LoginResources_ResetPassword_Header: 'Змiнити Ваш пароль',
    LoginResources_ForgotPassword: 'Забули пароль ?',
    LoginResources_Header: 'Використати локальний аккаунт для входу',
    LoginResources_ForgotPassword_emailButton: 'Вiдправити код',
    LoginResources_ResetPasswordTopic: 'Сброс пароля',
    LoginResources_ForgotPassword_SuccessEmailSent: 'Будь ласка перевірте свою електронну скриньку для відновлення пароля',
    LoginResources_Password_Label: 'Пароль',
    LoginResources_Login_Label: 'Логін',
    LoginResources_ResetPassword_SubmitButton: 'Змiнити',
    LoginResources_RegisterButton: 'Немає облікового запису ? Зареєструйте нового користувача',
    LoginResources_ResetPassword_SuccessResseting: 'Ваш пароль був успішно змінений. Будь ласка',
    LoginResources_ExternalLoginHeader: 'Увійти через один клiк використувавши :',
    CreateWorkResources_NoStudents: 'У вас ще немає студентів',
    CreateWorkResources_AddNewGroupStudent: 'Додати',
    CreateWorkResources_ChooseSpecTasks: 'Вибрати конкретні завдання',
    CreateWorkResources_TimeWholeTest: 'Вибрати час для всієї роботи',
    CreateWorkResources_AddChosenTasks: 'Додати вибрані завдання',
    CreateWorkResources_ChooseTasksByAmount: 'Вибрати за кількістю завдань в кожному рівні',
    CreateWorkResources_ChooseTasksAllTasksInTopic: 'Скласти з усіх завдань з даної теми',
    CreateWorkResources_TimeInLevel: 'Вибрати кількість хвилин для одного завдання в рівні',
    CreateWorkResources_ChooseStudents: 'Виберіть учнiв',
    CreateWorkResources_TestWithoutTimeButton: 'Без часу',
    CreateWorkResources_ChooseTaskValidation: 'Виберiть хоча б одне завдання',
    CreateWorkResources_ChoseeTasks: 'Виберіть завдання',
    CreateWorkResources_ChosenScore: 'Вибрані бали',
    CreateWorkResources_SuccessfullyAddedWork: 'успiшно створений',
    CreateWorkResources_ChosenTasks: 'Вибрані завдання',
    CreateWorkResources_ChooseTimeValidation: 'Виберіть час',
    CreateWorkResources_NoGroups: 'У вас ще немає груп',
    CreateWorkResources_RetakeAmount: 'Кількість перездач',
    CreateWorkResources_NeededRaiting: 'Потрібний рейтинг',
    CreateWorkResources_EndDate: 'Дата очікуваного виконання',
    CreateWorkResources_ChooseScore: 'Виберіть бали',
    CreateWorkResources_ChooseScoreValidation: 'Виберіть кількість балів',
    CreateWorkResources_SuccessfullyAddedHomeWork: 'успiшно створена',
    CreateWorkResources_ChooseStudentsValidation: 'Виберіть принаймні одного студентa',
    CreateWorkResources_UnlimitedTime: 'Без часу',
    CreateWorkResources_CreateWorkButton: 'Створити роботу',
    CreateWorkResources_ChosenStudents: 'Вибрані учнi',
    CreateWorkResources_AnotherTopic: 'Обрати іншу тему',
    CreateWorkResources_ChosenGroups: 'Вибрані групи',
    CreateWorkResources_RemoveAll: 'Скасувати усi завдання',
    CreateWorkResources_YourStudents: 'Вашi учнi',
    CreateWorkResources_ExamName: 'Назва іспиту',
    CreateWorkResources_ChooseTime: 'Виберіть час',
    CreateWorkResources_TasksInTopic: 'Завдань в темі',
    CreateWorkResources_Minutes: 'хвилин',
    CreateWorkResources_CalculateButton: 'Порахувати',
    CreateWorkResources_YourGroups: 'Вашi групи',
    CreateWorkResources_ChosenTime: 'Вибраний час',
    CreateWorkResources_HomeWorkName: 'Назва домашнього завдання',
    CreateWorkResources_TestName: 'Назва тесту',
    CreateWorkResources_ChooseTasksConfirm: 'Підтвердити',
    CreateWorkResources_AddStudentsButton: 'Додати обраних учнiв',
    CreateWorkResources_ChooseScoreExplanation: 'Виберіть бал для правильно вирішеного завдання в кожному рівні',
    ChooseStudentOrGroupToWorkWith: 'Виберiть учня або групу',
    ChooseScoreForLevel: 'Виберіть бал для правильно вирішеного завдання у {0}',
    GeneratedProblem: 'Згенероване завдання',
    Actions: 'Дії',
    TestEdition: 'Редагувати тест',
    TestStudentRetakeWasAdded: 'Перездачу успішно додано для учня',
    Create: 'Створити',
    NeededRating: 'Потрібний рейтинг',
    StudentRatingHasBeenChanged: 'Рейтинг учня з цієї задачі був змінений',
    HomeworNeededRatingHasBeenChanged: 'Потрібний рейтинг для домашнього завдання було успішно змінено',
    Customers: 'Користувачi',
    Groups: 'Групи',
    Teachers: 'Вчителі',
    MyWorkResources_MaxScore: 'Максимальний бал',
    Students: 'Учні',
    MyBoards: 'Мої дошки',
    BoardWasAdded: 'Дошка {0} була успішно додана',
    PleaseProvideNameForBoard: 'Назва для дошки',
    CreateNewBoard: 'Створити нову дошку',
    StudentsBoards: 'Дошки учнів',
    WhiteBoards: 'Онлайн дошки',
    Help: 'Допомога',
    Mark: 'Оцінка',
    RetakesLeft: 'Перездач залишилось',
    MaxScore: 'Макс. оцінка',
    TestTopics: 'Тест на тем(у/и)',
    TestProblemsCount: 'Kількість задач',
    TestSolvingTime: 'Загальний час на рішення',
    SolvingHistory: 'Історія рішення',
    TestMaxScore: 'Максимальний бал',
    Time: 'Час',
    Language: 'Мова',
    GroupName: 'Назва',
    NoStudentsFound: 'Не знайдено',
    HelpInRegistration: 'Допомога в реєстрації',
    WhatIsAProblem: 'Что такое задача',
    GroupIdentifier: 'Iдентифiкатор',
    UserWithSuchLoginAlreadyRegistered: 'Користувач з таким логiн вже зареєстрований',
    CreateNewGroupTitle: 'Створити нову групу',
    UpdateGroupName: 'Змiнити групу',
    ProvideEmailOrLogin: 'Введiть логiн aбо електронну пошту',
    YouAlreadyHaveAGroupWithSuchName: 'У Вас вже є група з такою назвою',
    CreateNewGroupInfo: 'Будь ласка, введіть назву для групи',
    Cancel: 'Скасувати',
    Accept: 'Прийняти',
    CancelRequest: 'Вiдмiнити',
    Requested: 'Запитаний',
    Added: 'Доданий',
    NewRequest: 'Новий запит',
    Level1: 'Рiвень А',
    Level2: 'Рiвень Б',
    Level3: 'Рiвень В',
    Solve: 'розв\'язати',
    Rating: 'Рейтинг',
    AvarageRating: 'Среднiй рейтинг',
    StatisticsForProblem: 'Статистика по задачі',
    Statistics: 'Статистика',
    SolvingTimeDescription: 'Час рішення (с)',
    SolvingTime: 'Час рішення',
    Solved: 'Вирішено',
    Date: 'Дата',
    CurrentRating: 'Поточний рейтинг',
    DeleteGroup: 'усунути групу {0}',
    CheckAnswer: 'Перевірити відповідь',
    NextProblem: 'Наступна',
    PreviousProblem: 'Попередня',
    PleaseSelectAnswer: 'Будь ласка, виберіть відповідь',
    PleaseProvideAnswer: 'Будь ласка, введіть відповідь',
    RequestWasCanceled: 'Запит був відхилений',
    Wrong: 'Неправильно',
    Correct: 'Правильно',
    Incorrect: 'Неправильно',
    Unsolved: 'Без відповідi',
    AvgSolvingTime: 'Cер. час вирішення',
    CorrectAnswerIs: 'Правильна відповідь',
    GenerateNew: 'перегенерувати',
    OpenWhiteBoard: 'Відкрити чернетку',
    CloseWhiteBoard: 'Закрити чернетку',
    GenerateNewTooltip: 'Ваш рейтинг буде знижений після 3-х кратного генерування завдання без відповіді',
    GenerateNewTooltipWarning: 'Це ваше 3-е генерування цього завдання без відповіді. Ваш рейтинг буде знижений!',
    RatingWillNotBeChangedWarning: 'Рейтинг не буде змінений так як Ви вже відповіли на це завдання',
    SolvingStepsTooltipWarning: 'Ви не зможете підвищити рейтинг цього завдання, якщо подивитеся підказку',
    SolvingStepsTooltip: 'Подивитися підказку',
    CorrectAnswerSnackBar: 'Відповідь правильна. Рейтинг був підвищений на 1',
    WrongAnswerSnackBar: 'Відповідь неправильна',
    ShowTheory: 'Теорія по даній задачі',
    EduVideos: 'Навчальні відео',
    ForAdmin: 'Для адмiна',
    ShowProblemExample: 'Приклад рішення подібної задачі',
    WrongAnswerRatingDecreasedSnackBar: 'Відповідь неправильна. Рейтинг був знижений на 1',
    RequestWasAccepted: 'Запитаннє було прийнято',
    History: 'Історія',
    SearchForProblem: 'Шукати задачу по id',
    WrongProblemId: 'Не існує задачі з id: {0}',
    StudentWasRemoved: 'Учень усунутий',
    RemoveFromStudents: 'Усунути',
    RemoveStudent: 'усунути {0} зі своїх учнів',
    ChangeLanguage: 'Змінити мову',
    SharedResources_Ukrainian: 'Українська',
    SharedResources_Language: 'Мова:',
    SharedResources_Submit: 'Підтвердити',
    SharedResources_Password: 'Пароль',
    SharedResources_English: 'English',
    SharedResources_Russian: 'Русский',
    ShowStatistics: 'Подивитися статистику',
    Sent: 'Відправлено',
    Description: 'Назва',
    Journal: 'Журнал',
    Topics: 'Завдання',
    DueDate: 'Термін',
    Progress: 'Прогрес',
    CreatedOn: 'Cтворено',
    Send: 'Відправити',
    Rating: 'Рейтинг',
    Status: 'Статус',
    Delete: 'Усунути',
    Yes: 'Так',
    Add: 'Додати',
    BackToBoards: 'Повернутись до всіх дошок',
    EditionAllowed: 'Дозволено редагувати',
    ShareWith: 'Поділитися з',
    BoardWasSharedWithStudent: 'Ви успішно поділилися дошкою з учнем',
    BoardWasSharedWithGroup: 'Ви успішно поділилися дошкою з групою',
    EditionOfTheBoardAllowed: 'Учню було дозволено редагувати дошку',
    EditionOfTheBoardProhibitted: 'Учню було заборонено редагувати дошку',
    DeleteBoard: 'видалити дошку?',
    BoardWasDeleted: 'Дошка була успішно видалена',
    TrainingWasAdded: 'Тренажер був успiшно доданий',
    TrainingWasDeleted: 'Тренажер був успiшно доданий',
    VideoWasAdded: 'Вiдео було успiшно додано',
    VideoWasRemoved: 'Вiдео було успiшно додано',
    Link: ' Посилання',
    ChangeName: 'Змінити назву',
    ChooseStudentsToAdd: 'Виберіть учнів',
    ChooseGroupsToAdd: 'Виберіть групи',
    ChooseProblemForWork: 'Виберіть задачі для роботи',
    ChangeNameTitle: 'Будь ласка, введіть нову назву',
    NameWasSuccessfullyChanged: 'Назва була успішно змінена',
    StudentsWereSuccessfullyUpdated: 'Учні були успішно оновлені',
    GroupsWereSuccessfullyUpdated: 'Групи були успішно оновлені',
    ProblemsWereSuccessfullyUpdated: 'Задачі були успішно оновлені',
    HomeworkWasSuccessfullySent: 'Домашнє завдання було успішно надіслано учням',
    TestWasSuccessfullySent: 'Тест був успішно надісланий учням',
    HomeworkWasDeleted: 'Домашнє завдання видалено',
    StudentProblemInTestTooltip: 'Подивитися сгенерувану задачу',
    TestWasDeleted: 'Тест видалено',
    HomeworkNeedsToBeCompletedBeforeSent: 'Домашнє завдання повинно мати завдання та учнів для відправки',
    PleaseProvideNameForHomework: 'Будь ласка, введіть назву для нового домашнього завдання',
    CreateNewHomework: 'Створити нову домашню роботу',
    PleaseProvideNameForTest: 'Будь ласка, введіть назву для нового теста',
    CreateNewTest: 'Створити новий тест',
    DuplicateWork: 'Здублювати роботу',
    TestResultFor: 'Результати тесту для',
    AddRetake: 'Добавити пересдачу',
    EditTest: 'Редагувати тест',
    MinutesRemainingAlert: 'Залишилось {0} хвилина',
    StudentRatingInHomeworkTooltip: 'Рейтинг учня. Натисніть, щоб побачити історію рішення',
    HomeworkWasSuccessfullyAdded: 'Домашнє завдання {0} додано',
    TestWasSuccessfullyAdded: 'Тест {0} доданий',
    EmailTemplatesResources_NewTestIsSentTemplate: 'Вам був задан новий тест : <table><tr><td>Кількість завдань</td><td>{ProblemsCount}</td></tr><tr><td>Виконати до</td><td>{DueDate}</td></tr></table><p><a href=\'{TestLink}\'>Перейти до тесту</a></p>',
    EmailTemplatesResources_NewHomeworkIsSentTemplate: 'Вам було задано нове домашнє завдання : <table><tr><td>Кількість завдань</td><td>{ProblemsCount}</td></tr><tr><td>Необхідно виконати до</td><td>{DueDate}</td></tr></table><p><a href=\'{HomeworkLink}\'>Перейти до домашнього завдання</a></p>',
    EmailTemplatesResources_NewTestIsSentTemplate_Subject: 'Новий тест',
    GlobalResources_Slider_Header_Homework: 'Домашнi роботи',
    GlobalResources_NewMessageEmailTopic: 'Нове повідомлення для',
    GlobalResources_AreYouSureYouWantToRemove: 'Ви впевнені, що бажаєте усунути',
    GlobalResources_Slider_Header_Teacher: 'Вчительська',
    GlobalResources_MovedToCloudUrl1: 'Ми переїхали ! Наша нова адреса',
    GlobalResources_MovedToCloudUrl2: '. Очікуємо на Вас там :)',
    GlobalResources_NotFoundDescription: 'Сторінка, яку ви шукаєте, не існує. Можливо, шлях до сторінці був визнаний недійсним',
    GlobalResources_OnlineDescLabel: 'Онлайн дошка',
    GlobalResources_RemoveFromStudents: 'з Ваших учнів',
    GlobalResources_RemoveFromGroup: 'Усунути з цієї групи?',
    GlobalResources_ConfirmYourAccount: 'Підтвердіть свій аккаунт',
    GlobalResources_Layout_Header_Home: 'Головна',
    GlobalResources_Layout_Header_Lang: 'ua',
    GlobalResources_Slider_Header_Test: 'Тестування',
    GlobalResources_Slider_Header_Exam: 'Iспит',
    GlobalResources_RemoveFromHomeTest: 'з цього тесту?',
    GlobalResources_RemoveFromHomeWork: 'з цього домашнього завдання?',
    GlobalResources_EmailConfirmText2: 'Будь ласка, підтвердіть свій аккаунт, натиснувши',
    GlobalResources_EmailConfirmText3: 'тут',
    GlobalResources_EmailConfirmText0: 'Добрий день,',
    GlobalResources_EmailConfirmText1: 'Дякуємо Вам за реєстрацію в MathLife.',
    GlobalResources_Layout_Header_Hello: 'Привіт',
    GlobalResources_Layout_Header_Login: 'Увійти',
    GlobalResources_MessageSendToEmal: 'Повідомлення буде відправлено на',
    GlobalResources_RequiredFieldValidation: 'Поле не може бути порожнім',
    GlobalResources_ApplicationErrorMessage: 'Помилка на сервері. Будь ласка, повторіть пізніше',
    GlobalResources_Layout_Header_Language_en: 'Англыйська',
    GlobalResources_Layout_Header_Language_uk: 'Украынська',
    GlobalResources_Layout_Header_Language_ru: 'Россыйська',
    GlobalResources_AreYouSureYouWantTo: 'Ви впевнені, що бажаєте ',
    GlobalResources_Tests: 'Тести',
    GlobalResources_Today: 'Сьогоднi',
    GlobalResources_ProblemMistakeTopicEmail: 'Знайдена помилка в завданні',
    GlobalResources_RemoveFromTeachers: 'з Ваших викладачів?',
    GlobalResources_Slider_Header_Education: 'Навчання',
    GlobalResources_Slider_MoreDetails_Button: 'Дiзнатись бiльше',
    GlobalResources_NotFoundLabel: 'Сторінку не знайдено',
    GlobalResources_Layout_Header_Service: 'Сервисы',
    GlobalResources_Layout_Header_Registr: 'Регистрация',
    GlobalResources_DbConnectionFailed: 'Что-то пошло не так. Пожалуйста, попробуйте повторить действие через несколько минут.',
    GlobalResources_Layout_Header_LogOff: 'Выйти',
    MyWorkResources_RightAnswer: 'Правильна відповідь',
    MyWorkResources_NewHomeWorkSubject: 'Нове домашнє завдання',
    MyWorkResources_RetakeTest: 'Перескласти тест',
    MyWorkResources_EnoughHomeworkRating: 'Всі завдання вирішені як мінімум до рейтингу $NeededRating.',
    MyWorkResources_TaskIds: 'Завдання',
    MyWorkResources_WithoutTime: 'Необмежено',
    MyWorkResources_Teacher: 'Вчитель',
    MyWorkResources_AnswerReceived: 'Відповідь прийнято',
    MyWorkResources_ContinueTest: 'Продовжити тест',
    MyWorkResources_TakesLeft: 'Залишилося здач',
    MyWorkResources_ShowSolvedTest: 'Подивитися тест',
    MyWorkResources_NotEnoughHomeworkRating: 'Не всі завдання вирішені як мінімум до рейтингу $NeededRating.',
    MyWorkResources_StartDate: 'Дата створення',
    MyWorkResources_Students: 'Учнiв',
    MyWorkResources_A: 'А',
    MyWorkResources_C: 'В',
    MyWorkResources_B: 'Б',
    MyWorkResources_Sum: 'Сума',
    MyWorkResources_Level: 'Рівень',
    MyWorkResources_Tasks: 'Задач',
    MyWorkResources_Score: 'Набрано балів',
    MyWorkResources_SolvedProblems: 'Вирішених завдань',
    MyWorkResources_BeginTest: 'Розпочати тест',
    MyWorkResources_HomeWorkLabel: 'Домашня робота',
    MyWorkResources_CurrentTests: 'Поточні тести',
    MyWorkResources_RightAnswerWithoutRounding: 'Правильна відповідь без округлення',
    MyWorkResources_FinishTest: 'Завершити тест',
    MyWorkResources_Raiting: 'Рейтинг',
    MyWorkResources_RaitingToAdd: 'до рейтингу за це завдання',
    MyWorkResources_SolvedTests: 'Вирішені тести',
    MyWorkResources_Task: 'Задачa',
    MyWorkResources_SeeResults: 'Подивитися результати',
    MyWorkResources_Points: 'Баллы',
    NotificationResources_ClientsConnectedForStudent: 'Вчитель {ClientName} був доданий до Ваших вчителів',
    NotificationResources_ClientsConnectedForTeacher: 'Учень {ClientName} був доданий до Ваших учнів',
    ProblemResources_TheoryLink: 'Посилання на теорiю',
    ProblemResources_RightAnswer: 'Правильна відповідь',
    ProblemResources_NextTask: 'Наступна',
    ProblemResources_OpenDraft: 'Відкрити чернетку',
    ProblemResources_PrevTask: 'Попередня',
    ProblemResources_FoundAmistake: 'Знайшли помилку?',
    Mistakes: 'Помилки в задачах',
    CloseMistake: 'Закрити помилку',
    MistakeHasBeenClosed: 'Помилка була успішно закрита, а відповідь була надіслана клієнту',
    NoMistakesInProblemsRaisedYet: 'Поки що жодної помилки не було надіслано',
    NoHomeworksAssignedYet: 'Вам не було задано домашніх завдань',
    ProblemResources_MistakeKind1: 'Вірна відповідь невірна',
    ProblemResources_MistakeKind2: 'Немає вірної відповіді',
    ProblemResources_MistakeKind4: 'Інша помилка',
    ChooseGroupOrStudent: 'Виберіть групу або учня',
    ProblemResources_RatingAfterAnswer: 'Рейтинг задачі після відповіді',
    ProblemResources_ExampleLink: 'Показати приклад',
    ProblemResources_CloseDraft: 'Закрити чернетку',
    ProblemResources_CorrectAnswer: 'Відповідь вірна!',
    ProblemResources_MistakeSendingExplanation: 'Натискаючи кнопку нижче, Ви відправляєте запит нашій команді на розгляд помилки. Ми обіцяємо розглянути ваш запит протягом 24 годин і дати Вам відповідь. Дякуємо, що робите нас краще.',
    ProblemResources_RatingAfterGeneration: 'Рейтинг задачі після генерації',
    ProblemResources_TestAssign: 'Тест заданий',
    ProblemResources_DecisionTextForMistake: 'Текст рішення',
    ProblemResources_ConfirmButton: 'Підтвердити',
    ProblemResources_KindOfMistake: 'Виберіть тип помилки',
    ProblemResources_NextLevel: 'Наступний рівень',
    ProblemResources_WrongAnswer: 'Відповідь невірна!',
    ProblemResources_SolvingOfTheProblem: 'Pішення задачі',
    ProblemResources_PrevLevel: 'Попередній рівень',
    ProblemResources_CloseMistakeButton: 'Закрити і відправити рішення',
    ProblemResources_InitialRating: 'Початковий рейтінг задачі',
    ProblemResources_RightAnswerWithoutRounding: 'Правильна відповідь без округлення',
    ProblemResources_KindOfMistakeWord: 'Вид помилки',
    ProblemResources_TaskExample: 'Приклад вирішення такого завдання',
    ProblemResources_MistakeDetailsWord: 'Деталі помилки',
    ProblemResources_HomeworkAssign: 'Домашня робота задана',
    ProblemResources_DetailsOfMistake: 'Кілька слів про помилку',
    ProblemResources_Trainings: 'Тренажери',
    ProblemResources_CloseMistakeEmailTopic: 'Помилка у завданні розглянута',
    ProblemResources_CheckingAnswer: 'Перевіряємо Вашу відповідь',
    ProfileResources_DataWasSaved: 'Дані збережені',
    RequestSent: 'Запит успішно відправлений',
    ProfileResources_Title: 'Профіль',
    MistakeHasBeenSent: 'Помилка у завданнi була успішно відправлена',
    StudentsInGroupWereChanged: 'Учні в групі були успішно змінені',
    ProfileResources_Surname: 'Призвище',
    ProfileResources_Email: 'Email',
    ProfileResources_ChangeAvatarLink: 'Змінити фото профілю',
    ProfileResources_School: 'Школа',
    ProfileResources_Close: 'Закрити',
    ProfileResources_PictureIsTooBig: 'Зображення дуже велике. Будь ласка виберіть зображення не більше 4 МБ',
    ProfileResources_Login: 'Логін',
    ProfileResources_DtBirthday: 'Дата народження',
    ProfileResources_Phone: 'Телефон',
    ProfileResources_AvatarError: 'Помилка при завантаженні фала. Будь ласка спробуйте не використовувати мобільні пристрої або спробуйте завантажити інший файл',
    ProfileResources_ChooseFile: 'Вибрати файл',
    ProfileResources_SaveChanges: 'Зберегти',
    ProfileResources_City: 'Місто',
    ProfileResources_ProfileInfoError: 'Помилка при збереженні Ваших даних. Переконайтеся в правильності даних, що вводяться',
    ProfileResources_EmailParents: 'Email батьків',
    ProfileResources_LastName: 'По батькові',
    ProfileResources_InvalidMessage: 'Поле заповнене з помилками',
    RegistrationResources_RegistrConfirmation_Link: 'натисніть тут , щоб Увійти',
    RegistrationResources_Student: 'Учень',
    RegistrationResources_Error_Login_Exists: 'Такий логін вже зареєстрований. Спробуйте інший',
    RegistrationResources_ConfirmPasswordLabel: 'Підтвердження пароля',
    RegistrationResources_SubmitButton: 'Реєстрація',
    RegistrationResources_ErrorRegistration: 'Виникла помилка, спробуйте ще раз',
    RegistrationResources_LoginLabel: 'Логін',
    RegistrationResources_Header: 'Реєстрація',
    RegistrationResources_Teacher: 'Вчитель',
    RegistrationResources_SuccesRegistration1: 'Дякуємо Вам за реєстрацію. На вказану Вами поштову скриньку було відправлено лист з підтвердженням реєстрації.\n        Гарного дня!',
    RegistrationResources_Error_ConfirmPassword_Match: 'Паролі не співпадають',
    RegistrationResources_PasswordLabel: 'Пароль',
    RegistrationResources_Error_Password_Regex: 'Пароль повинен містити як мінімум одну велику літеру , маленьку букву і цифру',
    RegistrationResources_Error_Login_Min: 'Поле не повинно бути менше 2 знаків',
    RegistrationResources_Error_Empty: 'Поле не може бути порожнім',
    RegistrationResources_Iam: 'Я',
    RegistrationResources_Reset: 'Змінити пароль',
    RegistrationResources_Error_Password_Min: 'Поле не повинно бути менше 8 знаків',
    RegistrationResources_Error_Email_Exists: 'Такий емейл вже зареєстрований .',
    RegistrationResources_RegistrConfirmation: 'Дякуємо за реєстрацію. Будь ласка',
    RegistrationResources_Error_Email_LogInAs: 'Ввійти як',
    RegistrationResources_Error_Email_Invalid: 'Введіть правильний email',
    Settings: 'Налаштування',
    TestResources_RegenerateTest: 'Перегенерувати  тести',
    TestResources_TakeNumber: 'Здача',
    TestResources_RetakeTest: 'Перездати тест',
    TestResources_RightAnswerText: 'Правильна відповідь:',
    TestResources_Closed: 'Закрито',
    TestResources_RegenerateStudentTest: 'Перегенерувати завдання',
    TestResources_IsSent: 'Надіслано учням',
    TestResources_TestInQueueText: 'В черзi',
    TestResources_AnswerRecieved: 'Відповідь прийнято',
    TestResources_GenerateTest: 'Згенерувати тести',
    TestResources_ProblemNotGenerated: 'Задача не згенерована',
    TestResources_BackToTest: 'Назад до тесту',
    TestResources_AnswerNotReceived: 'Відповідь не отримана. Будь ласка, спробуйте ще раз. Ваш вчитель буде проінформований про це.',
    TestResources_ConfirmRetake: 'Ви впевнені, що хочете перездати тест?',
    TestResources_ConfirmBegin: 'Ви впевнені, що хочете почати тест?',
    TestResources_Filter_ByName: 'По імені',
    TestResources_Filters_ToSend: 'До відправки',
    TestResources_RefreshTestAfterGeneration: 'Обновити сторінку',
    TestResources_Creating: 'Тест створюється',
    TestResources_GenerateStudentTest: 'Згенерувати завдання',
    TestResources_ConfirmFinish: 'Ви впевнені, що хочете завершити тест?',
    TestResources_Filter_Score: 'Кращий результат',
    TestResources_Filter_BestTake: 'Краща здача',
    TestResources_New: 'Нові',
    TestResources_DueDate: 'Виконати до',
    TestResources_Filters_StartingWithLast: 'Від останніх',
    TestResources_GenerateTestTooltip: 'Генерування завдань може зайняти декілька хвилин',
    TestResources_CreatedOn: 'Дата створення',
    TestResources_BackToTests: 'Назад до тестiв',
    TestResources_BeginTest: 'Почати тест',
    TestResources_SendToStudents: 'Вiдправити учням',
    TestResources_Filter_LastTake: 'Остання здача',
    TestResources_Generating: 'Генерується',
    TestAnswerWasAcceptedSnackBar: 'Відповідь на задачу отримана',
    TestAnswerWasNotAcceptedSnackBar: 'Помилка при отримані відповіді',
    ConfirmAsnwer: 'Підтвердити відповідь',
    ChooseSubject: 'Вибрати предмет',
    GoToTopic: 'Перейти до теми',
    TopicResources_0: 'Всі теми',
    TopicResources_5: 'Теорія ймовірностей',
    TopicResources_4: 'Комбінаторика',
    TopicResources_7: 'Різні задачі підвищеної складності',
    TopicResources_6: 'Экономика и финансы',
    TopicResources_1: 'Алгебра',
    TopicResources_3: 'Геометрія',
    TopicResources_2: 'Тригонометрія',
    TopicResources_63: 'Складні відсотки',
    TopicResources_62: 'Прості відсотки',
    TopicResources_61: 'Поняття відсотка. Найпростіші задачі на відсотки',
    TopicResources_67: 'Попит і пропозиція. Еластичність.',
    TopicResources_66: 'Податки',
    TopicResources_65: 'Грошові потоки',
    TopicResources_64: 'Інфляція',
    TopicResources_68: 'Різні задачи  економічного змісту',
    TopicResources_14: 'Послідовності й границі',
    TopicResources_15: 'Початок аналізу',
    TopicResources_16: 'Задачі з параметрами',
    TopicResources_11: 'Тотожні перетворення алгебраїчних виразів',
    TopicResources_12: 'Алгебраїчні рівняння та нерівності',
    TopicResources_13: 'Функції',
    TopicResources_24: 'Аркуси',
    TopicResources_23: 'Тригонометричні нерівності',
    TopicResources_22: 'Тригонометричні рівняння',
    TopicResources_21: 'Тригонометричні перетворення',
    TopicResources_32: 'Стереометрія',
    TopicResources_31: 'Планіметрія',
    TopicResources_141: 'Довільні послідовності',
    TopicResources_142: 'Прогресії',
    TopicResources_143: 'Границі',
    TopicResources_151: 'Похідна',
    TopicResources_153: 'Інтеграл',
    TopicResources_152: 'Екстремуми, найбільше і найменше значення функції',
    TopicResources_126: 'Нерівності',
    TopicResources_127: 'Рівняння і нерівності мішаного типу. Обчислення площ.',
    TopicResources_124: 'Системи рівнянь',
    TopicResources_125: 'Текстові задачі',
    TopicResources_122: 'Ірраціональні рівняння',
    TopicResources_123: 'Рівняння з модулями',
    TopicResources_121: 'Раціональні рівняння',
    TopicResources_137: 'Функції з модулем',
    TopicResources_136: 'Складена функція',
    TopicResources_135: 'Обернена функція',
    TopicResources_134: 'Періодичність функції',
    TopicResources_133: 'Парність та непарність функції',
    TopicResources_132: 'Елементарні функції',
    TopicResources_131: 'Область визначення і множина значень функції',
    TopicResources_139: 'Логарифмічна і показникова функції',
    TopicResources_138: 'Перетворення графіків функцій',
    TopicResources_324: 'Кут між двома площинами',
    TopicResources_325: 'Відстань від точки до прямої',
    TopicResources_326: 'Відстань від точки до площини',
    TopicResources_327: 'Відстань між двома прямими',
    TopicResources_321: 'Рівняння площини',
    TopicResources_322: 'Кут між прямими',
    TopicResources_323: 'Кут між прямой и площиной',
    TopicResources_329: 'Векторна алгебра і координатний метод у просторі',
    TopicResources_315: 'Векторна алгебра і координатний метод на площині',
    TopicResources_313: 'Багатокутник',
    TopicResources_312: 'Чотирикутник',
    TopicResources_311: 'Трикутник',
    TopicResources_1422: 'Геометрична прогресія',
    TopicResources_1423: 'Мішані задачі на прогресію',
    TopicResources_1421: 'Арифметична прогресія',
    TopicResources_1261: 'Лінійні та квадратні нерівності',
    TopicResources_1262: 'Раціональні нерівності з простими коренями',
    TopicResources_1263: 'Раціональні нерівності з кратними коренями',
    TopicResources_1264: 'Дрібно-раціональні нерівності',
    TopicResources_1265: 'Системи раціональних нерівностей',
    TopicResources_1266: 'Ірраціональні нерівності',
    TopicResources_1267: 'Нерівності з модулями',
    TopicResources_1253: 'Суміші',
    TopicResources_1252: 'Робота',
    TopicResources_1251: 'Рух',
    TopicResources_1256: 'Різні задачи',
    TopicResources_1255: 'Відсотки',
    TopicResources_1254: 'Числа',
    TopicResources_1242: 'Системи Віетівського типу',
    TopicResources_1243: 'Системи ірраціональних рівнянь',
    TopicResources_1241: 'Системи лінійних рівнянь',
    TopicResources_1244: 'Системи нелінійних рівнянь',
    TopicResources_1214: 'Дрібно-раціональні рівняння',
    TopicResources_1213: 'Цілі раціональні рівняння. Метод заміни',
    TopicResources_1212: 'Цілі раціональні рівняння. Розклад на множники',
    TopicResources_1211: 'Лінійні та квадратні рівняння',
    TopicResources_1392: 'Логарифмічні та показникові рівняння',
    TopicResources_1393: 'Логарифмічні та показникові нерівності',
    TopicResources_1391: 'Властивості логарифмічної та показникової функцій',
    Finish: 'Закінчити',
    TestWillBeClosedIn5Seconds: 'Тест буде автоматично закінчено через 5 секунд',
    ScoreForProblem: 'Бали за задачу',
    OutOf: 'з',
    NewHomeworkReceived: 'Вам була задана нова домашня робота',
    NewTestReceived: 'Вам був заданий новий тест',
    RemoveTeacher: 'Усунути з викладачів',
    AddNewTeacher: 'Додати нового викладача',
    TeacherBoards: 'Дошки моїх викладачів',
    ChooseStudentsForGroup: 'Виберіть учнів для групи'
};
export default uk;