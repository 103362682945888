/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import translate from '../../../helpers/translate';
import { Link } from 'react-router-dom';

export default function TopicBreadcrumbs({ topicId, currentPageUrl, returnToRootTopic, chooseSubjectUrl }) {
    const tId = topicId.toString();
    const topicLength = tId.length;
    let topics = []

    for (let i = 1; i < topicLength; i++) {
        let innerTopic = tId.substring(0, i);
        topics.push(innerTopic);
    }

    return (
        <Breadcrumbs maxItems={4} aria-label="breadcrumb">
            <Link
                key={'topic_' + 0}
                style={{ color: "#1B73E8" }}
                to={chooseSubjectUrl}
            >
                {translate('ChooseSubject')}
            </Link>
            {topics.map(t => (
                <Link
                    key={'topic_' + t}
                    style={{ color: "#1B73E8" }}
                    to={returnToRootTopic ? `${currentPageUrl}${tId.substring(0, 1)}` : `${currentPageUrl}${t}`}
                >
                    {translate('TopicResources_' + t)}
                </Link>
            ))}
            <Typography color="textPrimary">{translate('TopicResources_' + tId)}</Typography>
        </Breadcrumbs>
    );
}