import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import translate from 'src/helpers/translate';
import { Button, CardActions, CardHeader, List, Tooltip, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { topicService } from 'src/services/topic.service.js';
import { TopicListItem as StudentTopicListItem } from '../../student/topics/TopicListItem';
import { TopicListItem as TeacherTopicListItem } from '../../teacher/topics/TopicListItem';
import TrainingsAndVideos from 'src/views/common/TrainingsAndVideos/TrainingsAndVideos';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        marginTop: -20
    },
    media: {
        height: 140,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    progressBox: {
        paddingLeft: theme.spacing(2),
        marginTop: -15,
    },
    trainingsButton: {
        marginLeft: theme.spacing(1),
    }
}));

export default function TopicCard({ topic }) {
    const topics = useSelector(state => state.common.topics);
    const innerTopics = topicService.getInnerTopics(topic.id, topics);
    const user = useSelector(state => state.userContext.user);
    const userRole = user.role;
    const classes = useStyles();
    
    return (
        <Card className={classes.root} >
            <CardHeader
                title={translate(`TopicResources_${topic.id}`)}
            />
            {innerTopics && innerTopics.length > 0 && (
                <CardContent className={classes.cardContent}>
                    <List>
                        {innerTopics.map(innerTopic => userRole === 'Student'
                            ? <StudentTopicListItem key={innerTopic.id} topic={innerTopic} topics={topics} />
                            : <TeacherTopicListItem key={innerTopic.id} topic={innerTopic} topics={topics} />
                        )}
                    </List>
                </CardContent>
            )}
            <CardActions>
                {(!innerTopics || innerTopics.length === 0) && (
                    <Button size="small" color="primary" className={classes.trainingsButton} component={Link} to={`/app/problems/${topic.id}`}>
                        {translate('MyWorkResources_TaskIds')}
                    </Button>
                )}
                <TrainingsAndVideos 
                    topicId={topic.id}
                />
            </CardActions>
        </Card>
    );
}
