import React from 'react';
import {
  Box,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import TeacherHomeworksGrid from 'src/views/teacher/homeworks/list/HomeworksGrid';
import StudentHomeworksGrid from 'src/views/student/homeworks/HomeworksGrid';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const HomeworksListView = () => {
  const classes = useStyles();
  const user = useSelector(state => state.userContext.user);
  const problems = useSelector(state => state.common.problems);
  const topics = useSelector(state => state.common.topics);

  return (
    <Page
      className={classes.root}
      title="Homeworks"
    >
      <Container maxWidth="lg">
        <Box mt={3}>
          {user && user.roles && problems.length > 0 && topics.length > 0 && (
            <React.Fragment>
              {user.roles.includes('Student') && (
                <StudentHomeworksGrid />
              )}
              {!user.roles.includes('Student') && (
                <TeacherHomeworksGrid />
              )}
            </React.Fragment>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default HomeworksListView;
