import React, { useEffect } from 'react';
/* global JXG */

export default function Graph56(props) {

    let {a, b, c} = props.data;
    
    props.board.create('functiongraph',[function(x){return a*JXG.Math.pow(b*x + c, 1/2)}],{strokeColor:'black',highlightStrokeColor:'black'});
    
    return <></>
}