import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { actionCreators } from '../../../reducers/culture';
import Transalted from 'src/localization/translations';
import translate from 'src/helpers/translate';

const useStyles = makeStyles(({
  root: {}
}));

const Language = ({ className, locale, changeLanguage }) => {
  const classes = useStyles();
  const [lang, setLang] = useState(locale);
  const langs = { 
    ru: "Русский",
    uk: "Українська",
    en: "English"
    // he: "עִברִית"
  };

  return (
    <form
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader
          subheader={translate("ChangeLanguage")}
          title={translate("Language")}
        />
        <Divider />
        <CardContent>
          <Autocomplete
            options={Object.values(langs)}
            style={{ width: 300 }}
            value={langs[lang]}
            disableClearable
            onChange={(event, newValue) => {
              const key = Object.keys(langs).find(key => langs[key] === newValue);
              setLang(key);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" fullWidth />
            )}
          />
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => changeLanguage(lang)}
          >
            <Transalted translate="ProfileResources_SaveChanges"/>
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Language.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = function(state, ownProps) {
  return {
    className: ownProps.className,
    locale: state.culture.locale
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (lang) => dispatch(actionCreators.changeCulture(lang))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Language);
