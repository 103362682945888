import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from 'src/utils/SubmitButton';
import translate from 'src/helpers/translate';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionSummary, Checkbox, FormControlLabel, Typography, Chip, Tooltip, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';
import { actionCreators } from 'src/reducers/common/clientProblems';
import SimpleProblemBody from 'src/views/common/Problem/InTopic/SimpleProblemBody';


const useStyles = makeStyles((theme) => ({
    innerTopicAccordion: {
        marginLeft: 20,
        marginRight: 10,
        marginBottom: 10,
    }
}));

export default function ChooseProblemsForWorkDialog(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { open, onCancel, alreadyAdded, onSubmit, studentsInWork } = props;

    const user = useSelector(state => state.userContext.user);

    const topics = useSelector(state => state.common.topics);

    const problems = useSelector(state => state.common.problems);

    const clientProblems = useSelector(state =>
        state.common.clientProblems.filter(cp => problems.map(p => p.id).includes(cp.problemId)
            && studentsInWork && studentsInWork.includes(cp.clientId)));

    const [selected, setSelected] = useState([]);

    const handleClose = () => {
        onCancel();
    };

    const submit = () => {
        onSubmit(selected);
        onCancel();
    };

    useEffect(() => {
        setSelected(alreadyAdded);
    }, [alreadyAdded]);

    useEffect(() => {
        dispatch(actionCreators.requestClientProblems());
    }, []);

    const handleProblemChange = (problem) => {
        if (!selected.includes(problem)) {
            setSelected([...selected, problem]);
        } else {
            setSelected([...selected.filter(p => p != problem)]);
        }
    };

    const calculateAvarageRating = (problemIds) => {
        var clientProblemsForProblem = clientProblems.filter(cp => problemIds.includes(cp.problemId));
        if (clientProblemsForProblem.length > 0) {
            const sumOfRatings = clientProblemsForProblem.reduce((result, current) => {
                return result + current.rating;
            }, 0);
            return Math.round(sumOfRatings / (studentsInWork.length * problemIds.length) * 100) / 100
        }
        return 0;
    }


    function buildTopicToRender(topic) {
        const expanded = alreadyAdded && alreadyAdded.some(p => p.allTopics.includes(topic.id.toString()));

        const selectable = !topics.some(t => t.id.toString().length > topic.id.toString().length
            && t.id.toString().substring(0, topic.id.toString().length) === topic.id.toString());

        const problemsInTopic = selectable && problems.filter(p => p.endTopic == topic.id.toString())

        const topicIsChecked = problemsInTopic && problemsInTopic.every(p => selected.includes(p));


        const handleTopicChange = (event) => {
            if (event.target.checked) {
                setSelected([...selected, ...problemsInTopic]);
            } else {
                setSelected([...selected.filter(p => !problemsInTopic.includes(p))]);
            }
        };

        const showStudentsRating = studentsInWork && studentsInWork.length > 0;

        let studentsAvarageRating =
            showStudentsRating && problemsInTopic &&
            problemsInTopic.length > 0 && calculateAvarageRating(problemsInTopic.map(p => p.id));

        return (
            <Accordion
                key={topic.id}
                defaultExpanded={expanded}
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    {selectable && (
                        <React.Fragment>
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox checked={topicIsChecked} onChange={handleTopicChange} />}
                                label={translate(`TopicResources_${topic.id}`)}
                            />
                            {showStudentsRating && studentsAvarageRating > 0 && (
                                <Tooltip title={translate("AvarageRating")}>
                                    <Chip color="primary" label={studentsAvarageRating} />
                                </Tooltip>
                            )}
                        </React.Fragment>
                    )}
                    {!selectable && (
                        <Typography variant="h5">
                            {translate(`TopicResources_${topic.id}`)}
                        </Typography>
                    )}
                </AccordionSummary>
                <div className={classes.innerTopicAccordion}>
                    {buildTopicsToRender(topic)}
                </div>
                {selectable && (
                    <React.Fragment>
                        {buildProblemsForLevel(problemsInTopic, 1)}
                        {buildProblemsForLevel(problemsInTopic, 2)}
                        {buildProblemsForLevel(problemsInTopic, 3)}
                    </React.Fragment>
                )}
            </Accordion>
        )
    }

    function buildProblemsForLevel(problems, level) {
        const problemsInLevel = problems.filter(p => p.level == level);
        return (
            <React.Fragment>
                {problemsInLevel.length > 0 && (
                    <React.Fragment>
                        <Typography style={{ marginLeft: 15 }}>
                            {translate(`Level${level}`)}
                        </Typography>
                        <Divider />
                    </React.Fragment>
                )}
                {problemsInLevel.map(problem => buildProblem(problem))}
            </React.Fragment>
        )
    }



    function buildProblem(problem) {
        const showStudentsRating = studentsInWork && studentsInWork.length > 0;

        let studentsAvarageRating = showStudentsRating && calculateAvarageRating([problem.id]);

        return (
            <React.Fragment key={`CheckboxForProblem_${problem.id}`}>
                <Checkbox checked={selected.includes(problem)} onChange={() => handleProblemChange(problem)} />
                {user && user.roles.includes("Admin") && (<span> ({problem.id})</span>)}
                {showStudentsRating && studentsAvarageRating !== 0 && (
                    <Tooltip sx={{marginLeft:1}} title={translate(studentsInWork.length === 1 ? "Rating" : "AvarageRating")}>
                        <Chip color="primary" label={studentsAvarageRating} />
                    </Tooltip>
                )}
                <SimpleProblemBody sx={{ marginBottom: -10, cursor: 'pointer' }} onClick={(event) => handleProblemChange(problem, event)} problemId={problem.id} />
            </React.Fragment>
        )
    }

    function buildTopicsToRender(outerTopic) {

        const outerTopicString = outerTopic?.id?.toString() ?? "";
        const outerTopicLegth = outerTopicString === "" ? 1 : outerTopicString.length;

        let topicsToRender = [];

        const currentDepthTopics = outerTopic != undefined
            ? topics.filter(t => t.id.toString().substring(0, outerTopicLegth) === outerTopicString
                && t.id.toString().length === outerTopicLegth + 1)
            : topics.filter(t => t.id.toString().length === 1);

        currentDepthTopics.forEach(topic => {
            const topicToRender = buildTopicToRender(topic)
            topicsToRender.push(topicToRender);
        });

        return topicsToRender;
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth={true}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{translate("ChooseProblemForWork")}</DialogTitle>
                <DialogContent>
                    {buildTopicsToRender()}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary">
                        {translate('Cancel')}
                    </Button>
                    <SubmitButton
                        isSubmitting={false}
                        canBeSubmitted={true}
                        onSubmit={submit}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}