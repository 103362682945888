import React, { useEffect } from 'react';
/* global JXG */

export default function Graph651(props) {


    let {a, b} = props.data;
        
    props.board.create('functiongraph',[function(x){return JXG.Math.pow(a*x + b, 1/2)}],{strokeColor:'black',highlightStrokeColor:'black'});

    return <></>
}