import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as apiCaller from 'src/helpers/apiCaller';
import { Grid, Typography, FormControl, RadioGroup, FormControlLabel, FormHelperText, Button, TextField, Radio, colors, Tooltip, IconButton, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import translate from 'src/helpers/translate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import ProblemText from 'src/views/common/Problem/Solving/ProblemText';

const useStyles = makeStyles((theme) => ({
    helperButton: {
        flex: 0.1,
        marginBottom: -5
    },
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0)
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '600px',
        width: '100%',
        paddingBottom: theme.spacing(3),
        marginTop: 10
    }
}));

export default function GeneratedProblem(props) {
    const classes = useStyles();
    const { id, problemId, getGeneratedProblemAction } = props;
    const [graphLoaded, setGrapghLoaded] = useState(false);
    const [generatedProblem, setGeneratedProblem] = useState(null);
    const [helperText, setHelperText] = useState('');
    const [answerImagesLoaded, setAnswerImagesLoaded] = useState({ 0: false, 1: false, 2: false, 3: false });
    const problem = useSelector(state => state.common.problems.find(p => p.id === problemId));

    const [whiteBoardOpened, setWhiteBoardOpened] = useState(false);
    const [answersInfo, setAnswersInfo] = useState(
        {
            '1': { value: '', color: '' },
            '2': { value: '', color: '' },
            '3': { value: '', color: '' },
            '4': { value: '', color: '' }
        });

    useEffect(() => {
        getGeneratedProblemAction().then(
            (data) => {
                setGeneratedProblem(data);
                if (!data.answeredCorrectly) {
                    if (problem.type === 0) {
                        setAnswersInfo(
                            {
                                ...answersInfo,
                                // [data.userAnswer]: { value: ` (${translate('Wrong')})`, color: colors.red[500] },
                                [data.correctAnswerChoiseFormat]: { value: ` (${translate('CorrectAnswerIs')})`, color: colors.green[500] }
                            });
                    } else {
                        setHelperText((data.userAnswer != null ? translate('Wrong') : '') + ` (${translate('CorrectAnswerIs')} ${data.correctAnswer})`);
                    }
                } else {
                    if (problem.type === 0) {
                        setAnswersInfo({ ...answersInfo, [data.userAnswer]: { value: ` (${translate('Correct')})`, color: colors.green[500] } });
                    } else {
                        setHelperText(translate('Correct'));
                    }
                }
            }
        )
    }, [id, problem])

    const openWhiteBoard = () => {
        apiCaller.get(`/whiteBoard/getForGeneratedProblem?id=${generatedProblem.id}`, true).then(
            (data) => {
                setWhiteBoardOpened(true);
                const wt = new window.api.WhiteboardTeam('#problemContainer', {
                    clientId: data.clientId,
                    boardCode: data.boardGuid,
                    board: {
                        tool: 'pen',
                        thickness: 1,
                        color: '#000000',
                        bg: 'Small grid'
                    }
                });
            }
        );
    }

    const closeWhiteBoard = () => {
        setWhiteBoardOpened(false);
    }

    return (
        <React.Fragment>
            {generatedProblem && problem && (
                <React.Fragment>
                    <Grid container className={classes.formControl}>
                        {!generatedProblem.userAnswer && (
                            <Tooltip title={translate("Unsolved")}>
                                <BlockIcon />
                            </Tooltip>
                        )}
                        {generatedProblem.userAnswer && !generatedProblem.answeredCorrectly && (
                            <CancelIcon color="error" />
                        )}
                        {generatedProblem.userAnswer && generatedProblem.answeredCorrectly && (
                            <CheckCircleIcon color="secondary" />
                        )}
                        <ProblemText generatedProblem={generatedProblem} />
                        {generatedProblem.graphicUrl && (
                            <Grid item md={generatedProblem.latexText ? 12 : 6}>
                                {!graphLoaded && (<Skeleton variant="rectangular" width={200} height={200} />)}
                                <img src={generatedProblem.graphicUrl} onLoad={() => setGrapghLoaded(true)} />
                            </Grid>
                        )}
                        <Grid item md={6}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                {problem.type === 0 && (
                                    <RadioGroup aria-label="userAnswer" name="userAnswer" value={generatedProblem.userAnswer}>
                                        {!generatedProblem.answersAsImages && generatedProblem.answers.map(answer => (
                                            <FormControlLabel
                                                key={'problemAnswer' + generatedProblem.answers.indexOf(answer)}
                                                value={(generatedProblem.answers.indexOf(answer) + 1).toString()}
                                                control={<Radio color="secondary" checked={generatedProblem.userAnswer == generatedProblem.answers.indexOf(answer) + 1} />}
                                                label={
                                                    <Grid container>
                                                        <Grid item sm={12}>
                                                            <label dangerouslySetInnerHTML={{ __html: answer }} />
                                                            <label style={{ color: answersInfo[(generatedProblem.answers.indexOf(answer) + 1).toString()].color }}>
                                                                {answersInfo[(generatedProblem.answers.indexOf(answer) + 1).toString()].value}
                                                            </label>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        ))}
                                        {generatedProblem.answersAsImages && generatedProblem.answers.map(answer => (
                                            <FormControlLabel
                                                key={'problemAnswer' + generatedProblem.answers.indexOf(answer)}
                                                value={(generatedProblem.answers.indexOf(answer) + 1).toString()}
                                                control={<Radio color="secondary" checked={generatedProblem.userAnswer == generatedProblem.answers.indexOf(answer) + 1} />}
                                                label={
                                                    <Grid container>
                                                        <Grid item sm={12}>
                                                            <img src={answer} onLoad={() => setAnswerImagesLoaded({ ...answerImagesLoaded, [generatedProblem.answers.indexOf(answer)]: true })} />
                                                            <label style={{ color: answersInfo[(generatedProblem.answers.indexOf(answer) + 1).toString()].color }}>
                                                                {answersInfo[(generatedProblem.answers.indexOf(answer) + 1).toString()].value}
                                                            </label>
                                                            {!answerImagesLoaded[generatedProblem.answers.indexOf(answer)] && <Skeleton variant="text" />}
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                )}
                                {problem.type !== 0 && (
                                    <TextField value={generatedProblem.userAnswer || ''}></TextField>
                                )}
                                <FormHelperText>{helperText}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid>
                        {whiteBoardOpened && (
                            <React.Fragment >
                                <Tooltip style={{ marginTop: -30 }} title={translate('CloseWhiteBoard')} arrow>
                                    <IconButton
                                        onClick={() => closeWhiteBoard()}
                                        component="span"
                                        className={classes.helperButton}
                                        size="large">
                                        <DesktopAccessDisabledIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                <Box mt={5} className={classes.root} id="problemContainer"></Box>
                            </React.Fragment>
                        )}
                        {!whiteBoardOpened && (
                            <Tooltip style={{ marginTop: -30 }} title={translate('OpenWhiteBoard')} arrow>
                                <IconButton
                                    onClick={() => openWhiteBoard()}
                                    component="span"
                                    className={classes.helperButton}
                                    size="large">
                                    <DesktopWindowsIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        )}

                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}