import React, { useEffect } from 'react';

export default function Graph579(props) {

    let {a, b, c, d, e, f, g, h, i, j} = props.data;
    const board = props.board;

    var p1 = board.create('point',[a,b], {name: "A", size:2, fixed: true});
    var p2 = board.create('point',[c,d], {name: "B", size:2, fixed: true});
    var p3 = board.create('point',[e,f], {name: "C", size:2, fixed: true});
    var p4 = board.create('point',[g,h], {name: "D", size:2, fixed: true});
    var p5 = board.create('point',[i,j], {name: "E", size:2, fixed: true});

    var lineParams = {
        strokeColor:'blue',
        highlightStrokeColor:'blue',
        straightFirst:false, 
        straightLast:false, 
        strokeWidth:2, 
        fixed:true
    }
    board.create('line',[p1,p2], lineParams);
    board.create('line',[p2,p3], lineParams);
    board.create('line',[p1,p3], lineParams);
    board.create('line',[p4,p5], lineParams);

    return <></>
}