import React, { useEffect } from 'react';

export default function Graph58(props) {

    let {a, b, d, t, z} = props.data;
    const board = props.board;

    var p1 = board.create('point',[a,d], {size:2, fixed: true});
    var p2 = board.create('point',[t,z], {size:2, fixed: true});
    var p3 = board.create('point',[b,d], {size:2, fixed: true});

    board.create('line',[p1,p2], {straightFirst:false, straightLast:false, strokeWidth:2, fixed:true});
    board.create('line',[p2,p3], {straightFirst:false, straightLast:false, strokeWidth:2, fixed:true});

    return <></>
}