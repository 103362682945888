import * as apiCaller from 'src/helpers/apiCaller';
import translate from 'src/helpers/translate';
import { actionCreators as layoutActionCreators } from '../layout';
const testsReceivedType = "TESTS_RECEIVED";
const testsRequestedType = "TESTS_REQUESTED"
const testsStateChangedType = "TEST_STATE_CHANGED";
const testSolvingFinishedType = "TEST_SOLVING_FINISHED";
const remainingSolvingTimeDecreasedType = "REMAINING_SOLVING_TIME_DECREASED"
const startedSolvingProblemType = "STARTED_SOLVING_PROBLEM"
const showResultType = "SHOW_RESULT_TYPE";
const closeType = "CLOSE_RESULT_TYPE";

function requestTests() {
    return async dispatch => {
        dispatch({ type: testsRequestedType });

        await apiCaller.get('/tests').then(
            (tests) => {
                dispatch(success(tests));
            }
        );

        function success(tests) {
            return { type: testsReceivedType, tests };
        }
    };
}

function begin(testId) {
    return async dispatch => {
        const body = {
            testId
        };
        await apiCaller.post('/tests/begin', body).then(
            (test) => { dispatch(success(test)); }
        );

        function success(test) {
            return { type: testsStateChangedType, test };
        }
    };
}

function finish(testId) {
    return async dispatch => {
        const body = {
            testId
        };
        await apiCaller.post('/tests/finish', body).then(
            (test) => { dispatch(success(test)); }
        );

        function success(test) {
            return { type: testSolvingFinishedType, test };
        }
    };
}

function showResult(testId) {
    return dispatch => {
        dispatch({ type: showResultType, testId });
    };
}

function close(testId) {
    return dispatch => {
        dispatch({ type: closeType, testId });
    };
}

function retake(testId) {
    return async dispatch => {
        const body = {
            testId
        };
        await apiCaller.post('/tests/retake', body).then(
            (test) => { dispatch(success(test)); }
        );

        function success(test) {
            return { type: testsStateChangedType, test };
        }
    };
}

function decreaseRemainingSolvingTime(testId) {
    return dispatch => {
        dispatch({ type: remainingSolvingTimeDecreasedType, testId });
    };
}

function startSolvingProblem(testId, problemId) {
    return async dispatch => {
        const body = {
            testId,
            problemId
        };
        await apiCaller.post('/tests/StartSolvingProblem', body).then(
            () => { dispatch(success()); }
        );

        function success() {
            return { type: startedSolvingProblemType, testId, problemId };
        }
    };
}

export const actionCreators = {
    requestTests,
    begin,
    retake,
    finish,
    decreaseRemainingSolvingTime,
    startSolvingProblem,
    showResult,
    close
}

const initialState = {
    list: [],
    areUpdating: false
}

const tests = (state = initialState, action) => {
    if (action.type === testsRequestedType) {
        return {
            ...state,
            areUpdating: true
        }
    }

    if (action.type === testsReceivedType) {
        return {
            list: action.tests,
            areUpdating: false
        }
    }

    if (action.type === testsStateChangedType) {
        return {
            list: state.list.map(t => {
                if (t.id === action.test.id) {
                    return action.test;
                }
                return t;
            }),
            areUpdating: false
        }
    }

    if (action.type === testSolvingFinishedType) {
        return {
            list: state.list.map(t => {
                if (t.id === action.test.id) {
                    return {...action.test, showResult: true};
                }
                return t;
            }),
            areUpdating: false
        }
    }

    if (action.type === startedSolvingProblemType) {
        return {
            list: state.list.map(t => {
                if (t.id === action.testId) {
                    return {...t, currentlySolvingProblemId: action.problemId};
                }
                return t;
            }),
            areUpdating: false
        }
    }

    if (action.type === showResultType) {
        return {
            list: state.list.map(t => {
                if (t.id === action.testId) {
                    return {...t, showResult: true};
                }
                return t;
            }),
            areUpdating: false
        }
    }

    if (action.type === closeType) {
        return {
            list: state.list.map(t => {
                if (t.id === action.testId) {
                    return {...t, showResult: false};
                }
                return t;
            }),
            areUpdating: false
        }
    }

    if (remainingSolvingTimeDecreasedType) {
        return {
            list: state.list.map(t => {
                if (t.id === action.testId) {
                    return { ...t, remainingSolvingTime: t.remainingSolvingTime - 1 };
                }
                return t;
            }),
            areUpdating: false
        }
    }

    return state;
};

export default tests