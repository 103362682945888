import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Avatar } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset'
      },
    },
    topActionBar: {
      marginTop: -55,
      marginBottom: 10
    },
    createNewButton: {
      marginRight: 5
    }
  });

export default function Row(props) {
    const { group, translate, setEditDialogOpen, setGroupToEdit, onGroupDelete, onEditStudentsInGroupDialogOpen } = props;
    const [open, setOpen] = useState(false);
  
  
    const classes = useRowStyles();
  
    const onGroupEdit = () => {
      setGroupToEdit(group);
      setEditDialogOpen(true);
    }
  
    const onStudentsInGroupEdit = () => {
      setGroupToEdit(group);
      onEditStudentsInGroupDialogOpen(true);
    }
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {group.name}
          </TableCell>
          <TableCell>{group.students.length}</TableCell>
          <TableCell>{group.identifier}</TableCell>
          <TableCell>
            <IconButton size="small" onClick={onStudentsInGroupEdit}>
              <GroupAddIcon />
            </IconButton>
            <IconButton size="small" onClick={onGroupEdit}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={() => onGroupDelete(group)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  {translate('Students')}
                </Typography>
                <Table size="small" aria-label="studentsInGroup">
                  <TableBody>
                    {group.students.map((student) => (
                      <TableRow key={group.id + student.id}>
                        <TableCell component="th" scope="row">
                          <Avatar src={`data:image/jpg;base64, ${student.avatar}`} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {student.name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  Row.propTypes = {
    row: PropTypes.shape({
      students: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired
        }),
      ),
      name: PropTypes.string.isRequired,
      studentsAmount: PropTypes.number.isRequired,
      identifier: PropTypes.string.isRequired
    }),
  };