import React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TeacherLevelResultTimelineDot from '../../teacher/problems/LevelResultTimelineDot';
import StudentLevelResultTimelineDot from '../../student/problems/LevelResultTimelineDot';
import { Paper } from '@mui/material';
import { useSelector } from 'react-redux';


export default function LevelResultTimelineItem({end, problemsForLevel}) {
    const user = useSelector(state => state.userContext.user);

    return (
        <TimelineItem>
            <TimelineOppositeContent>
            </TimelineOppositeContent>
            <TimelineSeparator>
                {user.role === 'Student' ? (<StudentLevelResultTimelineDot problemsForLevel={problemsForLevel} end={end}/>) 
                    : (<TeacherLevelResultTimelineDot problemsForLevel={problemsForLevel} end={end}/>)}
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={5} style={{width: 450}}>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
}