const workStateReceivedType = "WORK_STATE_RECEIVED";
const workStateChangedType = "WORK_STATE_CHANGED";

export const initialState = {
  isStudent: false,
  isGroup: false
}

export const workState = (state = initialState, action) => {
  if (action.type === workStateReceivedType) {
    return {
      isStudent: action.workState.isStudent,
      isGroup: action.workState.isGroup
    };
  }

  if (action.type === workStateChangedType) {
    return {
      isStudent: action.isStudent,
      isGroup: !action.isStudent
    };
  }

  return state;
};
