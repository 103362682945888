import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Container,
    Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import ProblemGraphStats from '../../../common/Problem/Stats/ProblemGraphStats';
import ProblemSolvingTimeStats from '../../../common/Problem/Stats/ProblemSolvingTimeStats';
import ProblemSolvingStatsGrid from '../../../common/Problem/Stats/ProblemSolvingStatsGrid';
import ProblemSolvingTimeStatsForGroup from './ProblemSolvingTimeStatsForGroup';
import * as apiCaller from 'src/helpers/apiCaller';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';
import studentsInGroupService from 'src/stateServices/studentsInGroupService';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        marginTop: -20
    }
}));

const ProblemStatsView = (props) => {
    let { problemId, specificStudent } = props;
    const dispatch = useDispatch();

    const { id } = useParams();

    if (!problemId) {
        problemId = id;
    }

    const problem = useSelector(state => state.common.problems.find(p => p.id == problemId));

    const workState = useSelector(state => state.teacher.workState);

    let currentStudent = useSelector(state => state.teacher.students.find(s => s.isCurrent));

    if (specificStudent !== undefined) {
        currentStudent = specificStudent;
    }

    const currentGroup = useSelector(state => state.teacher.groups.find(s => s.isCurrent));

    const [generatedProblems, setGeneratedProblems] = useState([]);

    const showForStudent = (workState.isStudent || specificStudent) && currentStudent !== null && currentStudent !== undefined;

    let rating = 0;

    const studentProblem = useSelector(state => state.common.clientProblems
        .find(cp => cp.problemId == problem.id && currentStudent && cp.clientId === currentStudent.id));
    rating = studentProblem?.rating ?? 0;

    const showForGroup = (workState.isGroup && !specificStudent) && currentGroup !== null && currentGroup !== undefined;

    if (showForGroup) {
        rating = studentsInGroupService.getAvarageRatingOfProblemInGroup(currentGroup.id);
    }

    useEffect(() => {
        setGeneratedProblems([]);
        const url = showForStudent
            ? `/generatedProblem/GetForStudentProblem?id=${problemId}&studentId=${currentStudent?.id ?? ''}`
            : showForGroup
                ? `/generatedProblem/GetForGroupProblem?id=${problemId}&groupId=${currentGroup.id}`
                : `/generatedProblem/getForProblem?id=${problemId}`;

        apiCaller.get(url).then(
            (problems) => {
                setGeneratedProblems(problems);
            }
        );
    }, [problemId, showForStudent, showForGroup, currentStudent, currentGroup])

    useEffect(() => {
        dispatch(clientProblemsActionCreators.requestClientProblems());
    }, [dispatch])

    return (
        <Container maxWidth="lg">
            <Box mt={3}>
                {problem && !showForGroup && (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            xl={4}
                            xs={12}
                        >
                            <ProblemGraphStats
                                problem={problem}
                                generatedProblems={generatedProblems}
                                rating={rating}
                                student={currentStudent} />
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            xl={4}
                            xs={12}
                        >
                            <ProblemSolvingTimeStats problem={problem} generatedProblems={generatedProblems} >
                                <ProblemSolvingStatsGrid />
                            </ProblemSolvingTimeStats>
                        </Grid>
                    </Grid>
                )}
                {problem && showForGroup && (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            lg={10}
                            md={10}
                            xl={10}
                            xs={12}
                        >
                            <ProblemSolvingTimeStatsForGroup generatedProblems={generatedProblems} />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Container>
    );
};

export default ProblemStatsView;
