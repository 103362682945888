import React from 'react';
import { connect } from 'react-redux';
import GroupDialog from './GroupDialog';
import { actionCreators } from 'src/reducers/teacher';
import translate from 'src/helpers/translate';

const Create = ({...props}) => {
    return (
        <GroupDialog title={translate('CreateNewGroupTitle')} {...props}/>
    );
}

const MapStateToProps = (state, ...ownProps) => {
    return {
        ownProps
    };
}

const MapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (name) => dispatch(actionCreators.createNewGroup(name))
    };
}

export default connect(MapStateToProps, MapDispatchToProps)(Create);