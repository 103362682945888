import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from 'src/utils/SubmitButton';
import translate from '../../helpers/translate';
import { Grid, TextField } from '@mui/material';

export default function ChangePropertyDialog(props) {
    const { title, onSubmit, onCancel, open, initialValue } = props;

    const [value, setValue] = useState('');

    const onValueChange = (event) => {
        setValue(event.target.value);
    }

    useEffect(() => { setValue(initialValue)}, [initialValue]);

    const submit = () => {
        onSubmit(value);
        onCancel();
    }

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Grid item sm={8} xs={12}>
                    <TextField
                        autoFocus
                        size="small"
                        id="name"
                        type="string"
                        value={value}
                        onChange={onValueChange}
                        fullWidth
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    color="primary">
                    {translate('Cancel')}
                </Button>
                <SubmitButton
                    isSubmitting={false}
                    canBeSubmitted={value !== ''}
                    text={translate('Save')}
                    onSubmit={submit}
                />
            </DialogActions>
        </Dialog>
    )
}