import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import translate from 'src/helpers/translate';
import { Tooltip, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import TrainingsDialog from 'src/components/common/TrainingsDialog';
import EduVideosDialog from 'src/components/common/EduVideosDialog';

const useStyles = makeStyles((theme) => ({
}));

export default function TrainingsAndVideos({ topicId, problemId }) {
    const user = useSelector(state => state.userContext.user);
    const isAdmin = user && user.roles && user.roles.includes('Admin');
    const classes = useStyles();
    const [trainingDialogIsOpen, setTrainingDialogIsOpen] = useState(false);
    const [eduVideosDialogIsOpen, setEduVideosDialogIsOpen] = useState(false);

    const trainingsForTopic = useSelector(state => state.common.trainings.filter(tr => topicId && tr.topicId.toString().startsWith(topicId)));
    const eduVideosForTopic = useSelector(state => state.common.eduVideos.filter(ev => topicId && ev.topicId.toString().startsWith(topicId)));

    const trainingsForProblem = useSelector(state => state.common.trainings.filter(tr => problemId && tr.problemId == problemId));
    const eduVideosForProblem = useSelector(state => state.common.eduVideos.filter(ev => problemId && ev.problemId == problemId));

    const trainings = problemId ? trainingsForProblem : trainingsForTopic;
    const eduVideos = problemId ? eduVideosForProblem : eduVideosForTopic; 

    return (
        <React.Fragment>
            {(isAdmin || (trainings && trainings.length > 0)) && (
                <React.Fragment>
                    <Tooltip title={translate('ProblemResources_Trainings')} arrow>
                        <IconButton
                            component="span"
                            className={classes.trainingsButton}
                            onClick={() => setTrainingDialogIsOpen(true)}
                            size="large">
                            <FitnessCenterIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <TrainingsDialog
                        trainings={trainings}
                        topicId={topicId}
                        problemId={problemId}
                        canEdit={isAdmin}
                        open={trainingDialogIsOpen}
                        onClose={() => setTrainingDialogIsOpen(false)}
                    />
                </React.Fragment>
            )}
            {(isAdmin || (eduVideos && eduVideos.length > 0)) && (
                <React.Fragment>
                    <Tooltip title={translate('EduVideos')} arrow>
                        <IconButton
                            component="span"
                            className={classes.trainingsButton}
                            onClick={() => setEduVideosDialogIsOpen(true)}
                            size="large">
                            <OndemandVideoIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <EduVideosDialog
                        eduVideos={eduVideos}
                        canEdit={isAdmin}
                        topicId={topicId}
                        problemId={problemId}
                        open={eduVideosDialogIsOpen}
                        onClose={() => setEduVideosDialogIsOpen(false)}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
