import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
    Avatar,
    Card,
    Container,
    IconButton,
    Paper,
    Tooltip,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { green, red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import translate from 'src/helpers/translate';
import { DataGrid } from '@mui/x-data-grid';
import { actionCreators } from 'src/reducers/common/clientProblems';
import TopicBreadcrumbs from 'src/views/common/Problems/TopicBreadcrumbs';
import JournalTopBar from './JournalTopBar';
import { getCookie, setCookie } from 'src/helpers/cookies';
import SimpleProblemBody from 'src/views/common/Problem/InTopic/SimpleProblemBody';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    board: {
        height: '600px',
        width: '100%'
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    addNewButton: {
        marginLeft: 30,
        // marginTop: -5,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    deleteButton: {
        marginTop: -7,
        marginLeft: 10,
        fontSize: 10,
        color: red[400]
    },
    problemTooltip: {
        maxWidth: 'none'
    }, some: {
        flex: 0.5,
        marginTop: -5
    },
}));


export default function JournalView() {
    const navigate = useNavigate();
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.userContext.user);

    useEffect(() => {
        if (user && user.role) {
            dispatch(actionCreators.requestClientProblems());
        }
    }, [user, dispatch])

    const topics = useSelector(state => state.common.topics);
    const currentGroup = useSelector(state => state.teacher.groups.find(g => g.isCurrent));
    const currentStudent = useSelector(state => state.teacher.students.find(g => g.isCurrent));
    const workState = useSelector(state => state.teacher.workState);
    const studentInCurrentGroup = useSelector(state => state.teacher.studentGroups.filter(sg => sg.groupId == currentGroup?.id));
    const students = useSelector(state => state.teacher.students);
    const problems = useSelector(state => state.common.problems);

    const shouldShowSwitch = workState.isGroup && currentGroup
        || workState.isStudent && currentStudent;

    const currentWorkEntity = workState.isGroup ? currentGroup?.name : currentStudent?.wholeName;

    let shouldShowForCurrentEntity = getCookie('showForCurrentEntity');

    shouldShowForCurrentEntity = shouldShowForCurrentEntity == undefined ? true : shouldShowForCurrentEntity == 'true';

    const [showForCurrentEntity, setShowForCurrentEntity] = useState(shouldShowForCurrentEntity);

    let studentsToShow = students;
    if (currentGroup && workState.isGroup && showForCurrentEntity) {
        studentsToShow = students.filter(s => studentInCurrentGroup.map(sig => sig.studentId).includes(s.id));
    } else if (currentStudent && workState.isStudent && showForCurrentEntity) {
        studentsToShow = students.filter(s => currentStudent.id == s.id);
    }
    const clientProblems = useSelector(state => state.common.clientProblems.filter(cp => studentsToShow.map(s => s.id).includes(cp.clientId)));
    let innerTopics = topics.filter(t => t.id.toString().length == 1);

    if (topics && id != 0) {
        innerTopics = topics.filter(t => t.id.toString().startsWith(id.toString()) && t.id.toString().length == id.toString().length + 1);
    } else if (topics && id == 0) {
        innerTopics = topics.filter(t => t.id.toString().length == 1);
    }

    const studentsForGrid = studentsToShow.map(s => {
        let ratings = innerTopics.map(t => {
            const problemsInTopic = problems.filter(p => p.allTopics.includes(t.id.toString()));
            const studentProblems = clientProblems.filter(cp => cp.clientId == s.id && problemsInTopic.map(pit => pit.id).includes(cp.problemId));
            const ratingsSum = studentProblems.reduce((sum, item) => {
                return sum + item.rating;
            }, 0);
            const rating = ratingsSum / problemsInTopic.length;
            const roundedRating = Math.round(rating * 10) / 10;
            return { topicId: t.id, rating: roundedRating }
        });
        if (innerTopics.length < 1) {
            const problemsInTopic = problems.filter(p => p.endTopic == id).sort((p1, p2) => p1.id - p2.id);
            ratings = problemsInTopic.map(problem => {
                const studentRating = clientProblems.find(cp => cp.clientId == s.id && cp.problemId == problem.id)?.rating ?? 0;
                return { problemId: problem.id, rating: studentRating };
            })
        }
        return {
            ...s,
            ratings
        };
    });

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'wholeName',
            flex: 2,
            headerName: translate('Students'),
            renderCell: (params) => (
                <React.Fragment>
                    <Avatar
                        key={params.row.id}
                        src={`data:image/jpg;base64, ${params.row.avatarImage}`}
                        className={classes.small}
                    />
                    {params.value}
                </React.Fragment>
            )
        },
    ];

    if (innerTopics && innerTopics.length > 0) {
        innerTopics.forEach(topic => {
            columns.push(
                {
                    field: `ratingForTopic_${topic.id}`,
                    flex: 1,
                    headerName: translate(`TopicResources_${topic.id}`),
                    sort: true,
                    renderCell: (params) => (
                        <Typography>
                            {params.row.ratings.find(r => r.topicId == topic.id).rating}
                        </Typography>
                    ),
                    valueGetter: (params) => params.row.ratings.find(r => r.topicId == topic.id).rating,
                    renderHeader: (params) => (
                        <React.Fragment>
                            <Tooltip title={translate(`GoToTopic`)}>
                                <IconButton
                                    size="small"
                                    onClick={(event) => { navigate(`/app/journal/${topic.id}`, { replace: true }); event.stopPropagation(); }}>
                                    <ArrowRightIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={translate(`TopicResources_${topic.id}`)}>
                                <span>
                                    {translate(`TopicResources_${topic.id}`)}
                                </span>
                            </Tooltip>
                        </React.Fragment>
                    )
                }
            )
        });
    } else {
        const problemsInTopic = problems.filter(p => p.endTopic == id).sort((p1, p2) => p1.id - p2.id);
        problemsInTopic.forEach(problem => {
            columns.push(
                {
                    field: `ratingForProblem_${problem.id}`,
                    flex: 1,
                    headerName: problem.id,
                    sort: true,
                    renderCell: (params) => (
                        <Typography>
                            {params.row.ratings.find(r => r.problemId == problem.id).rating}
                        </Typography>
                    ),
                    valueGetter: (params) => params.row.ratings.find(r => r.problemId == problem.id).rating,
                    renderHeader: (params) => (
                        <Tooltip
                            classes={{ tooltip: classes.problemTooltip }}
                            title={
                                <Paper>
                                    <SimpleProblemBody problemId={problem.id} />
                                </Paper>
                            }
                        >
                            <strong>
                                {problem.id}
                            </strong>
                        </Tooltip>
                    )
                }
            )
        })
    }

    const onShowForCurrentEntityChange = (show) => {
        setShowForCurrentEntity(show);
        setCookie('showForCurrentEntity', show, 180);
    }

    return (
        <Page title="Journal">
            <Container maxWidth="xl">
                <JournalTopBar
                    shouldShowSwitch={shouldShowSwitch}
                    currentWorkEntity={currentWorkEntity}
                    showForCurrentEntity={showForCurrentEntity}
                    setShowForCurrentEntity={(show) => onShowForCurrentEntityChange(show)}
                />
                {id != 0 && (
                    <TopicBreadcrumbs
                        topicId={id}
                        currentPageUrl={'/app/journal/'}
                        returnToRootTopic={true}
                        chooseSubjectUrl={'/app/journal/0'}
                    />
                )}
                <Card>
                    <DataGrid
                        loading={false}
                        rows={studentsForGrid}
                        density="compact"
                        columns={columns}
                        pageSize={15}
                        autoHeight={true}
                        disableColumnMenu
                        className={classes.grid}
                        hideFooter={studentsForGrid.length <= 15}
                        hideFooterSelectedRowCount
                    />
                </Card>
            </Container>
        </Page>
    );
}