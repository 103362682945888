import React from 'react';
/* global JXG */

export default function Graph344(props) {

    let {a, b, c, d, n} = props.data;
    
    props.board.create('functiongraph',[function(x){return a*JXG.Math.pow(x - d, n) + b*(x-d) + c}],{strokeColor:'blue',highlightStrokeColor:'blue'});
    props.board.create('functiongraph',[function(x){return b*(x-d)+c}],{strokeColor:'green',highlightStrokeColor:'green'});
    props.board.create('point',[d,c], {size:2, fixed: true, name:'A'});
    
    return <></>
}