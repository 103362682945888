import React, { useEffect } from 'react';
import { actionCreators } from 'src/reducers/student/homeworks';
import { actionCreators as trainingsActionCreators } from 'src/reducers/common/trainings';
import eduVideos, { actionCreators as eduVideosActionCreators } from 'src/reducers/common/eduVideos';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import HomeworkSolving from './HomeworkSolving';
import { useDispatch, useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop:-10
    }
}));

export default function HomeworkSolvingView() {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const homework = useSelector(state => state.student.homeworks.list.find(h => h.id == id));
    const problems = useSelector(state => state.common.problems);
    const problemsInHomework = problems && problems.filter(p => homework.problems.includes(p.id)).sort((a, b) => a.id - b.id);

    useEffect(() => {
        dispatch(actionCreators.requestHomeworks());
        dispatch(clientProblemsActionCreators.requestClientProblems());
    }, [dispatch])

    useEffect(() => {
        dispatch(trainingsActionCreators.requestTrainings());
    }, [dispatch])

    useEffect(() => {
        dispatch(eduVideosActionCreators.requestEduVideos());
    }, [dispatch])

    return (
        <React.Fragment >
            {homework && problemsInHomework.length > 0 && (
                <HomeworkSolving className={classes.root} homework={homework} problems={problemsInHomework}/>
            )}
        </React.Fragment>
    );
}