import { combineReducers } from "redux"
import problems from './problems';
import trainings from './trainings';
import eduVideos from './eduVideos';
import topics from './topics';
import boards from './boards';
import clientProblems from './clientProblems';
import generatedProblems from './generatedProblems';

export default combineReducers(
    {
        problems,
        clientProblems, 
        topics,
        eduVideos,
        trainings,
        boards,
        generatedProblems
    }
);