import * as apiCaller from 'src/helpers/apiCaller';
import translate from 'src/helpers/translate';
import { actionCreators as layoutActionCreators } from '../layout';
const homeworksReceivedType = "HOMEWORKS_RECEIVED";
const homeworksRequestedType = "HOMEWORKS_REQUESTED"

function requestHomeworks() {
    return async dispatch => {
        dispatch({type: homeworksRequestedType});

        await apiCaller.get('/homeworks').then(
            (homeworks) => {
                dispatch(success(homeworks));
            }
        );
        
        function success(homeworks) {
            return { type: homeworksReceivedType, homeworks };
        }
    };
}

export const actionCreators = {
    requestHomeworks
}

const initialState = {
    list: [],
    areUpdating: false
}

const homeworks = (state = initialState, action) => {
    if (action.type === homeworksRequestedType) {
        return {
            ...state,
            areUpdating: true
        } 
    }
    
    if (action.type === homeworksReceivedType) {
        return {
            list: action.homeworks,
            areUpdating: false
        } 
    }

    return state;
};

export default homeworks