import React from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
}));

const GroupsSelectedList = ({ groups, selected, onSelect }) => {
    const classes = useStyles();

    const handleListItemClick = (event, index) => {
        onSelect(index);
    };

    return (
        <div className={classes.root}>
            <List component="nav" dense={true}>
                {groups && groups.sort(g => g.isCurrent ? -1 : 1).map(group => (
                    <ListItem
                        button
                        key={group.id}
                        selected={selected === group.id}
                        onClick={(event) => handleListItemClick(event, group.id)}
                    >
                        <ListItemText primary={group.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default GroupsSelectedList;