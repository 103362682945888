import React from 'react';
/* global JXG */

export default function Graph346(props) {

    let {a, b, c} = props.data;

    props.board.create('functiongraph',[function(x){return ((x-a)*(x-a)+0.2)*((x+b)*(x+b)+0.2)*(x-c)}],{strokeColor:'blue',highlightStrokeColor:'blue'});
    
    return <></>
}