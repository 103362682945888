import translate from "src/helpers/translate";
import { userService } from "../services/user.service";
import { actionCreators as layoutActionCreators } from './layout';
import { actionCreators as cultureActionCreators } from './culture';
const requestUserType = "REQUEST_USER";
const udpateUserType = "UDPATE_USER";
const userNameIsBusyType = "USER_NAME_IS_BUSY";
const userUpdatedType = "USER_IS_UPDATED";
const userWasNotUpdatedType = "USER_WAS_NOT_UPDATED";

export function requestUser() {
  return async dispatch => {
    userService.getUser().then(
      user => {
        dispatch(success(user));
        dispatch(cultureActionCreators.changeCulture(user.language));
      }
    );

    function success(user) {
      return { type: requestUserType, user };
    }
  };
}

export function updateUser(user) {
  return async dispatch => {

    dispatch(updateUserRequested());

    userService.updateUser(user).then(
      () => {
        dispatch({type: userUpdatedType, user});
        dispatch(layoutActionCreators.showSuccessAlert(translate('ProfileResources_DataWasSaved')));
      }
    ).catch(error => {
      if(error === 400){
        dispatch({type: userNameIsBusyType});
        dispatch(layoutActionCreators.showWarningAlert(translate('UserWithSuchLoginAlreadyRegistered')));
      }else{
        dispatch({type: userWasNotUpdatedType});
      }
    });

    function updateUserRequested() {
      return { type: udpateUserType };
    }
  };
}

export function changeAvatar(avatar) {
  return async dispatch => {

    dispatch(updateUserRequested());

    userService.changeAvatar(avatar).then(
      () => dispatch(actionCreators.requestUser())
    );

    function updateUserRequested() {
      return { type: udpateUserType };
    }
  };
}


export const actionCreators = {
  requestUser,
  updateUser,
  changeAvatar
};

const initialState = {
  user: {},
  userNameIsBusy: false,
  isUpdating: false
}

export const userContext = (state = initialState, action) => {
  if (action.type === requestUserType) {
    return {
      isUpdating: false,
      user: action.user,
      userNameIsBusy: false
    };
  }

  if (action.type === userWasNotUpdatedType) {
    return {
      ...state,
      isUpdating: false,
      userNameIsBusy: false
    };
  }
  
  if (action.type === userUpdatedType) {
    return {
      isUpdating: false,
      user: {...state.user, userName: action.user.userName, name: action.user.name, surname: action.user.surname, city: action.user.city },
      userNameIsBusy: false
    };
  }
  if (action.type === udpateUserType) {
    return {
      ...state,
      isUpdating: true,
      userNameIsBusy: false
    };
  }

  if (action.type === userNameIsBusyType) {
    return {
      ...state,
      isUpdating: false,
      userNameIsBusy: true
    };
  }

  return state;
};
