import React from 'react';
import { useSelector } from 'react-redux';
import CommonTopicListItem from '../../common/Topics/CommonTopicListItem';

export function TopicListItem({ topic, isNested, topics }) {

    const problemsInTopic = useSelector(state => state.common.problems.filter(p => p.allTopics.includes(topic.id.toString())));

    let clientProblems = useSelector(state => state.common.clientProblems.filter(cp => problemsInTopic.map(p => p.id).includes(cp.problemId)));

    return (
        <CommonTopicListItem 
            topic={topic} 
            isNested={isNested} 
            topics={topics} 
            problemsInTopic={problemsInTopic} 
            clientProblems={clientProblems}
        />
    );
}

