import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Card,
    CardHeader,
    Container,
    IconButton,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { green, red } from '@mui/material/colors';
import { ArrowBack, Delete, ExpandMore } from '@mui/icons-material';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { actionCreators } from 'src/reducers/common/boards';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import translate from 'src/helpers/translate';
import ConfirmationDialog from 'src/components/common/ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    board: {
        height: '600px',
        width: '100%'
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    addNewButton: {
        marginLeft: 30,
        marginTop: -5,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    deleteButton: {
        marginTop: -7,
        marginLeft: 10,
        fontSize: 10,
        color: red[400]
    },
}));

const CommonBoardView = ({
    clients,
    shouldShowSwitch,
    shareWithCurrentEntity,
    currentWorkEntity
}) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const board = useSelector(state => state.common.boards.find(b => b.id == id));

    const user = useSelector(state => state.userContext.user);

    const boardClients = clients.filter(s => board && board.clients.map(c => c.id).includes(s.id));

    const [boardHasBeenRendered, setBoardHasBeenRendered] = useState(false);

    const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);

    const allowEdit = (clientId) => {
        dispatch(actionCreators.allowEdit(id, clientId));
    }

    const prohibitEdit = (clientId) => {
        dispatch(actionCreators.prohibitEdit(id, clientId));
    }

    const deleteBoard = () => {
        setConfirmationDialogIsOpen(true);
    }

    useEffect(() => {
        dispatch(actionCreators.requestBoards());
    }, [dispatch])

    useEffect(() => {
        if (board && !boardHasBeenRendered) {
            const wt = new window.api.WhiteboardTeam('#problemContainer', {
                clientId: '2979b43d0021b7015034adbb9b60da43',
                boardCode: board.code,
                board: {
                    tool: 'pen',
                    thickness: 1,
                    color: '#000000',
                    bg: 'Small grid'
                }
            });
            setBoardHasBeenRendered(true);
        }
    }, [board]);

    const clientsInBoard = board && board.clients && boardClients && boardClients.length > 0
        && boardClients.map(boardClient => {
            let client = board.clients.find(s => s.id == boardClient.id);
            return {
                id: boardClient.id,
                wholeName: boardClient.wholeName,
                avatarImage: boardClient.avatarImage,
                readOnly: client.readOnly
            }
        })

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'wholeName',
            flex: 2,
            headerName: translate(user.isTeacher ? 'Students' : 'Teachers'),
            renderCell: (params) => (
                <React.Fragment>
                    <Avatar
                        key={params.id}
                        src={`data:image/jpg;base64, ${params.row.avatarImage}`}
                        className={classes.small}
                    />
                    {params.value}
                </React.Fragment>
            )
        }
    ];

    if (board.createdByCurrentUser) {
        columns.push(
            {
                field: 'readOnly',
                flex: 1,
                headerName: translate('EditionAllowed'),
                renderCell: (params) => <React.Fragment>
                    <Switch
                        checked={!params.value}
                        onChange={() => params.value ? allowEdit(params.id) : prohibitEdit(params.id)}
                        color="primary"
                    />
                </React.Fragment>
            }
        )
    }

    return (
        <Page
            className={classes.root}
            title="Board"
        >
            <Container maxWidth="lg">
                {board && (
                    <Card>
                        <CardHeader
                            title={board.description}
                            subheader={board.createdOnString}
                            avatar={
                                <Tooltip title={translate('BackToBoards')} >
                                    <Link to={"/app/boards"}>
                                        <IconButton size="medium">
                                            <ArrowBack fontSize="inherit" />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            }
                        />
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ marginLeft: 10, marginTop: 5 }}>{board?.name}</Typography>
                                <Typography>
                                    {board.createdByCurrentUser && shouldShowSwitch(board) && (
                                        <Button
                                            className={classes.addNewButton}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => shareWithCurrentEntity(board)}
                                        >
                                            {translate('ShareWith') + ' ' + currentWorkEntity}
                                        </Button>
                                    )}
                                    {board.createdByCurrentUser && (
                                        <Tooltip title={translate('Delete')} arrow>
                                            <IconButton
                                                onClick={deleteBoard}
                                                className={classes.deleteButton}
                                                color="secondary"
                                                aria-label="upload picture"
                                                component="span"
                                                size="large">
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <React.Fragment>
                                    {clientsInBoard && clientsInBoard.length > 0 && <DataGrid
                                        loading={!clientsInBoard}
                                        rows={clientsInBoard}
                                        density="compact"
                                        columns={columns}
                                        pageSize={15}
                                        autoHeight={true}
                                        disableColumnMenu
                                        onRowClick={() => { }}
                                        hideFooter={clientsInBoard.length <= 15}
                                        hideFooterSelectedRowCount />
                                    }
                                </React.Fragment>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                )}
                <Box mt={5} className={classes.board} id="problemContainer"></Box>
            </Container>
            <ConfirmationDialog
                open={confirmationDialogIsOpen}
                onCancel={() => setConfirmationDialogIsOpen(false)}
                onSubmit={() => dispatch(actionCreators.deleteBoard(id, board.code, navigate))}
                message={translate('DeleteBoard')}
            />
        </Page>
    );
};

export default CommonBoardView;
