
import { actionCreators as layoutActionCreators } from 'src/reducers/layout';
import {actionCreators as teacherActionCreators } from 'src/reducers/teacher/index';
import translate from './translate';

const common_events = [
    'GENERIC_EVENT',
    'NEW_ITEM'
]

const teacher_events = [
    'PROBLEMS_GENERATED',
    'STUDENT_BEGAN_TEST',
    'STUDENT_SOLVED_PROBLEM',
    'STUDENT_FINISHED_TEST',
    'STUDENT_SOLVED_PROBLEM_IN_TEST'
]

const student_events = [
    'WHITE_BOARD_READONLY_CHANGED'
]

export function subscribeToCommonEvents (channel){
    return async dispatch => {
        channel.bind('GENERIC_EVENT', function (data) {
            dispatch(layoutActionCreators.showSuccessAlert(JSON.stringify(data.Text)));
        });
        channel.bind('NEW_ITEM', function (data) {
            dispatch(layoutActionCreators.showSuccessAlert(translate(data.Text)));
        });
    }
}

export function subscribeToTeacherEvents (channel){
    return async dispatch => {
        teacher_events.map(event => {
            if(event === 'STUDENT_BEGAN_TEST'){
                channel.bind(event, function (data) {
                    dispatch(teacherActionCreators.changeProblemStatusToSolving(data.TestId, data.ProblemId, data.StudentId));
                    dispatch(teacherActionCreators.changeStudentTestStatusToSolving(data.StudentId));
                });
            }
            if(event === 'STUDENT_SOLVED_PROBLEM_IN_TEST'){
                channel.bind(event, function (data) {
                    dispatch(teacherActionCreators.changeProblemStatusToSolved(data.TestId, data.ProblemId, data.StudentId, data.AnswerIsRight));
                });
            }
            if(event === 'PROBLEMS_GENERATED' || event === 'STUDENT_FINISHED_TEST'){
                channel.bind(event, function (data) {
                    dispatch(teacherActionCreators.requestTestProblems(data.TestId));
                    dispatch(teacherActionCreators.requestTestStudents(data.TestId));
                });
            }
        })
    }
}

const pusherSubscriber = {
    subscribeToCommonEvents, 
    subscribeToTeacherEvents
}

export default pusherSubscriber;