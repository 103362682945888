const en = {
    LoginResources_ExternalLoginConfirmation_Text2: 'Please enter your email below and click the Register button to finish logging in.',
    LoginResources_ExternalLoginConfirmation_UnseccessfulLogin: 'Unsuccessful login with service.',
    LoginResources_SubmitButton: 'Log in',
    LoginResources_LoginError: 'Password or login is wrong',
    LoginResources_ResetPassword_Header: 'Reset your password.',
    LoginResources_ForgotPassword: 'Forgot your password?',
    LoginResources_Header: 'Use a local account to log in',
    LoginResources_ForgotPassword_emailButton: 'Send code to reset password',
    LoginResources_ResetPasswordTopic: 'Reset password',
    LoginResources_ForgotPassword_SuccessEmailSent: 'Please check your email to reset your password.',
    LoginResources_Password_Label: 'Password',
    LoginResources_Login_Label: 'Login',
    ChooseStudentOrGroupToWorkWith: 'Choose student or group to work with',
    LoginResources_ResetPassword_SubmitButton: 'Reset',
    LoginResources_RegisterButton: 'Not registered yet? Register new user',
    LoginResources_ResetPassword_SuccessResseting: 'Your password has been reset. Please',
    LoginResources_RememberMe_Label: 'Remember me?',
    LoginResources_ExternalLoginHeader: 'Use another service to log in.',
    CreateWorkResources_NoStudents: 'You have no students yet',
    CreateWorkResources_AddNewGroupStudent: 'Add one',
    CreateWorkResources_ChooseSpecTasks: 'Select specific problems',
    CreateWorkResources_TimeWholeTest: 'Choose amount of minutes for the whole work',
    CreateWorkResources_AddChosenTasks: 'Add selected problem',
    CreateWorkResources_ChooseTasksByAmount: 'Select on the number of problems in each level',
    CreateWorkResources_ChooseTasksAllTasksInTopic: 'Create work of all the problems in the topic',
    CreateWorkResources_TimeInLevel: 'Choose amount of minutes for each level',
    CreateWorkResources_ChooseStudents: 'Choose students',
    CreateWorkResources_TestWithoutTimeButton: 'Create a test without time',
    CreateWorkResources_ChooseTaskValidation: 'Choose at least one problem',
    CreateWorkResources_ChoseeTasks: 'Choose problems',
    CreateWorkResources_ChosenScore: 'Chosen score',
    CreateWorkResources_SuccessfullyAddedWork: 'successfully added',
    CreateWorkResources_ChosenTasks: 'Chosen problems',
    CreateWorkResources_ChooseTimeValidation: 'Choose time',
    CreateWorkResources_NoGroups: 'You have no groups yet',
    CreateWorkResources_RetakeAmount: 'Amount of takes',
    CreateWorkResources_NeededRaiting: 'Needed raiting',
    CreateWorkResources_EndDate: 'Date of expected performance',
    CreateWorkResources_ChooseScore: 'Choose score',
    CreateWorkResources_ChooseScoreValidation: 'Please select a score',
    CreateWorkResources_CreateNewClasswork: 'Create new classwork',
    CreateWorkResources_SuccessfullyAddedHomeWork: 'successfully added',
    CreateWorkResources_ChooseStudentsValidation: 'Choose at least one student',
    CreateWorkResources_UnlimitedTime: 'Without time',
    CreateWorkResources_CreateWorkButton: 'Create prepared work',
    CreateWorkResources_ChosenStudents: 'Chosen students',
    CreateWorkResources_NeededRaitingToolTip: 'Every problem should be solved to this raiting to complete a homework',
    CreateWorkResources_AnotherTopic: 'Select another topic',
    CreateWorkResources_ChosenGroups: 'Chosen groups',
    CreateWorkResources_RemoveAll: 'Remove all',
    CreateWorkResources_YourStudents: 'Your students',
    CreateWorkResources_ExamName: 'Exam name',
    CreateWorkResources_ChooseTime: 'Chose time',
    CreateWorkResources_TasksInTopic: 'Problems in topic',
    CreateWorkResources_Minutes: 'minutes',
    CreateWorkResources_CalculateButton: 'Calculate',
    CreateWorkResources_MyClasswork: 'My classworks',
    CreateWorkResources_YourGroups: 'Your groups',
    CreateWorkResources_ChosenTime: 'Chosen time',
    CreateWorkResources_HomeWorkName: 'Home work name',
    CreateWorkResources_TestName: 'Test name',
    CreateWorkResources_ChooseTasksConfirm: 'Сonfirm',
    CreateWorkResources_AddStudentsButton: 'Add checked students',
    CreateWorkResources_ChooseScoreExplanation: 'Choose a score for a right solved problem in each level',
    ChooseScoreForLevel: 'Choose a score for a right solved problem in {0}',
    TestEdition: 'Edit test',
    MyBoards: 'My boards',
    StudentsBoards: 'My students boards',
    PleaseProvideNameForBoard: 'Please provide a description for a new board',
    CreateNewBoard: 'Create a new board',
    BoardWasAdded: 'Board {0} was successfully added',
    Create: 'Create',
    Customers: 'Customers',
    Groups: 'Groups',
    Teachers: 'Teachers',
    Students: 'Students',
    Journal: 'Journal',
    WhiteBoards: 'White boards',
    Help: 'Help',
    Language: 'Language',
    GroupName: 'Name',
    CreateNewGroupTitle: 'Create a new group',
    UpdateGroupName: 'Change group name',
    CreateNewGroupInfo: 'Please provide a name for a group',
    Cancel: 'Cancel',
    Accept: 'Accept',
    CancelRequest: 'Reject',
    NewRequest: 'New reqeust',
    Requested: 'Requested',
    Added: 'Added',
    Level1: 'Level A',
    Level2: 'Level B',
    Level3: 'Level C',
    Solve: 'Solve',
    Rating: 'Rating',
    AvarageRating: 'Avarage rating',
    NeededRating: 'Needed rating',
    StudentRatingHasBeenChanged: 'Student rating for the problem was changed',
    HomeworNeededRatingHasBeenChanged: 'Homework needed rating was successfully changed',
    Actions: 'Actions',
    Status: 'Status',
    Delete: 'Delete',
    CurrentRating: 'Current rating',
    NextProblem: 'Next',
    PreviousProblem: 'Previous',
    Unsolved: 'Unsolved',
    StatisticsForProblem: 'Statistics for a problem',
    SolvingTimeDescription: 'Solving time (s)',
    SolvingTime: 'Solving time',
    AvarageShort: 'avg',
    Solved: 'Solved',
    Date: 'Date',
    HelpInRegistration: 'Help in registration',
    WorkWithTests: 'Work with tests',
    WhatIsAProblem: 'What is a problem',
    CheckAnswer: 'Check answer',
    OpenWhiteBoard: 'Open white board',
    CloseWhiteBoard: 'Close white board',
    PleaseSelectAnswer: 'Please select an answer',
    PleaseProvideAnswer: 'Please provide an answer',
    RequestWasCanceled: 'Request was canceled',
    RequestWasAccepted: 'Reqeust was accepted',
    StudentWasRemoved: 'Student was removed',
    RemoveFromStudents: 'Remove',
    RemoveStudent: 'remove {0} from your students',
    DeleteGroup: 'remove group {0} ',
    YouAlreadyHaveAGroupWithSuchName: 'You already have a group with such name',
    NoStudentsFound: 'No students found',
    GroupIdentifier: 'Identifier',
    ChangeLanguage: 'Change language',
    UserWithSuchLoginAlreadyRegistered: 'User with such login already registered',
    ProvideEmailOrLogin: 'Provide email or login',
    RequestSent: 'Request was successfully sent',
    StudentsInGroupWereChanged: 'Students in group were successfuly changed',
    EditionAllowed: 'Edition allowed',
    ShareWith: 'Share with',
    BoardWasSharedWithStudent: 'The board was shared with a student',
    BoardWasSharedWithGroup: 'The board was shared with a group',
    EditionOfTheBoardAllowed: 'Edition of the board allowed',
    EditionOfTheBoardProhibitted: 'Edition of the board prohibitted',
    DeleteBoard: 'delete the board?',
    BoardWasDeleted: 'The board was deleted',
    Wrong: 'Wrong',
    Correct: 'Correct',
    Incorrect: 'Incorrect',
    NotAnswered: 'Not answered',
    AvgSolvingTime: 'Avg solving time',
    GenerateNew: 'Generate new',
    CorrectAnswerIs: 'Correct answer is',
    GenerateNewTooltip: 'Your rating will be decreased after generating the problem 3 times without answering',
    GenerateNewTooltipWarning: 'It\'s your 3rd generation of this problem without answering. Your rating will be decreased!',
    RatingWillNotBeChangedWarning: 'Rating will not be changed as you already answered this problem',
    SolvingStepsTooltipWarning: 'You will not be able to increase the rating for this problem once you check the hint up',
    CorrectAnswerSnackBar: 'Answer is correct. Rating was increased by 1',
    WrongAnswerSnackBar: 'Answer is incorrect',
    WrongAnswerRatingDecreasedSnackBar: 'Answer is incorrect. Rating was decreased by 1',
    SolvingStepsTooltip: 'Show a hint',
    ShowTheory: 'Theory for this problem',
    ShowProblemExample: 'Similar problem solving',
    SharedResources_Ukrainian: 'Українська',
    SharedResources_Language: 'Language:',
    SharedResources_Submit: 'Submit',
    SharedResources_Password: 'Password',
    SharedResources_English: 'English',
    SharedResources_Russian: 'Русский',
    History: 'History',
    SearchForProblem: 'Search problem by id',
    WrongProblemId: 'There is no problem with id: {0}',
    EduVideos: 'Educational videos',
    ForAdmin: 'For admin',
    ShowStatistics: 'Show statistics',
    Statistics: 'Statistics',
    Sent: 'Sent',
    Description: 'Description',
    Topics: 'Topics',
    DueDate: 'Due date',
    Progress: 'Progress',
    Send: 'Send',
    Add: 'Add',
    Yes: 'Yes',
    Link: 'Link',
    EditTest: 'Edit test',
    CreatedOn: 'Created on',
    BackToBoards: 'Back to boards',
    VideoWasAdded: 'Video was added successfully',
    VideoWasRemoved: 'Video was deleted successfully',
    TrainingWasAdded: 'Training was added successfully',
    TrainingWasDeleted: 'Training was deleted successfully',
    TestStudentRetakeWasAdded: 'Retake for the student was added',
    TestResultFor: 'Test result for',
    ChangeName: 'Change name',
    ChangeNameTitle: 'Please provide a new name',
    NameWasSuccessfullyChanged: 'The name was successfully changed',
    HomeworkWasSuccessfullySent: 'Homework was successfully sent to students',
    TestWasSuccessfullySent: 'Test was successfully sent to students',
    HomeworkWasDeleted: 'Homework was deleted',
    TestWasDeleted: 'Test was deleted',
    ChooseStudentsToAdd: 'Choose students to add',
    ChooseGroupsToAdd: 'Choose groups to add',
    ChooseProblemForWork: 'Choose problems for work',
    StudentsWereSuccessfullyUpdated: 'Students were successfully updated',
    ProblemsWereSuccessfullyUpdated: 'Problems were successfully updated',
    GroupsWereSuccessfullyUpdated: 'Groups were successfully updated',
    StudentProblemInTestTooltip: 'Show generated problem',
    GeneratedProblem: 'Generated problem',
    HomeworkNeedsToBeCompletedBeforeSent: 'Homework must have students and problems to be sent',
    PleaseProvideNameForHomework: 'Please provide a name for a new homework',
    CreateNewHomework: 'Create a new homework',
    PleaseProvideNameForTest: 'Please provide a name for a new test',
    CreateNewTest: 'Create a test',
    DuplicateWork: 'Duplicate this work',
    HomeworkWasSuccessfullyAdded: 'Homework {0} was created',
    TestWasSuccessfullyAdded: 'Test {0} was created',
    StudentRatingInHomeworkTooltip:'Student\'s rating. Click to see history of solving',
    EmailTemplatesResources_NewHomeworkIsSentTemplate_Subject: 'New homework assigned',
    EmailTemplatesResources_NewTestIsSentTemplate: 'A new test has been assigned : <table><tr><td>Amount of problems</td><td>{ProblemsCount}</td></tr><tr><td>To be done before</td><td>{DueDate}</td></tr></table><p><a href=\'{TestLink}\'>Go to test</a></p>',
    EmailTemplatesResources_NewHomeworkIsSentTemplate: 'A new homework has been assigned : <table><tr><td>Amount of problems</td><td>{ProblemsCount}</td></tr><tr><td>Should be done before</td><td>{DueDate}</td></tr></table><p><a href=\'{HomeworkLink}\'>Go to homework</a></p>',
    EmailTemplatesResources_NewTestIsSentTemplate_Subject: 'New test assigned',
    GlobalResources_Slider_Header_Homework: 'Homeworks',
    GlobalResources_Layout_Header_Service: 'Services',
    GlobalResources_NewMessageEmailTopic: 'A new message for ',
    GlobalResources_AreYouSureYouWantToRemove: 'Are you sure you want to remove ',
    GlobalResources_NotFoundDescription: 'Page you are looking for does not exist. Perhaps the path to the page was invalid',
    GlobalResources_OnlineDescLabel: 'Online desc',
    GlobalResources_RemoveFromStudents: 'from your students ? ',
    GlobalResources_RemoveFromGroup: 'Remove from this group?',
    GlobalResources_ConfirmYourAccount: 'Confirm your account',
    GlobalResources_Layout_Header_Home: 'Home',
    GlobalResources_Layout_Header_Lang: 'en',
    GlobalResources_Slider_Header_Test: 'Testing',
    GlobalResources_Slider_Header_Exam: 'Exam',
    GlobalResources_Layout_Header_Registr: 'Registration',
    GlobalResources_RemoveFromHomeTest: 'from this test ?',
    GlobalResources_RemoveFromHomeWork: 'from this homework ?',
    GlobalResources_EmailConfirmText2: 'Please confirm your account by clicking',
    GlobalResources_EmailConfirmText3: 'here',
    GlobalResources_EmailConfirmText0: 'Hello, ',
    GlobalResources_EmailConfirmText1: 'Thank You for registration in MathLife.',
    GlobalResources_Layout_Header_Hello: 'Hello',
    GlobalResources_Layout_Header_Login: 'Login',
    GlobalResources_MessageSendToEmal: 'Message will be sent to ',
    GlobalResources_RequiredFieldValidation: 'The field can not be empty',
    GlobalResources_ApplicationErrorMessage: 'Server error. Please try again later',
    GlobalResources_AreYouSureYouWantTo: 'Are you sure you want to ',
    GlobalResources_Tests: 'Tests',
    GlobalResources_Today: 'Today',
    GlobalResources_DbConnectionFailed: 'Something went wrong. Please, try again in a minute.',
    GlobalResources_Layout_Header_LogOff: 'Log Off',
    GlobalResources_ProblemMistakeTopicEmail: 'Mistake in problem found',
    GlobalResources_RemoveFromTeachers: 'from your teachers?',
    GlobalResources_Slider_Header_Education: 'Education',
    GlobalResources_Slider_MoreDetails_Button: 'More',
    GlobalResources_NotFoundLabel: 'Page not found',
    MyWorkResources_RightAnswer: 'Right answer',
    MyWorkResources_NewHomeWorkSubject: 'New homework',
    MyWorkResources_RetakeTest: 'Retake the test',
    MyWorkResources_EnoughHomeworkRating: 'All problems are solved at least up to the rating of $NeededRating.',
    MyWorkResources_TaskIds: 'Problems',
    MyWorkResources_WithoutTime: 'Unlimited',
    MyWorkResources_Teacher: 'Teacher',
    MyWorkResources_AnswerReceived: 'Answer received',
    MyWorkResources_ContinueTest: 'Continue the test',
    MyWorkResources_TakesLeft: 'Takes left',
    MyWorkResources_Points: 'Points',
    MyWorkResources_ShowSolvedTest: 'Show test',
    MyWorkResources_NotEnoughHomeworkRating: 'Not all problems are solved at least up to the rating of $NeededRating.',
    MyWorkResources_StartDate: 'Creation date',
    MyWorkResources_Students: 'Students',
    MyWorkResources_A: 'А',
    MyWorkResources_C: 'С',
    MyWorkResources_B: 'В',
    MyWorkResources_Sum: 'Sum',
    MyWorkResources_Level: 'Level',
    MyWorkResources_Tasks: 'Problems',
    MyWorkResources_Score: 'Score',
    Mark: 'Score',
    RetakesLeft: 'Retakes left',
    TestTopics: 'Test topic(s)',
    TestProblemsCount: 'Amount of problems',
    TestSolvingTime: 'Overall time for solving',
    TestMaxScore: 'Max availalble score',
    MaxScore: 'Max score',
    Time: 'Time',
    MinutesRemainingAlert: '{0} minute left',
    MyWorkResources_MaxScore: 'Max score is',
    MyWorkResources_SolvedProblems: 'Solved problems',
    MyWorkResources_BeginTest: 'Begin the test',
    MyWorkResources_HomeWorkLabel: 'Home work',
    MyWorkResources_CurrentTests: 'Current tests',
    MyWorkResources_RightAnswerWithoutRounding: 'Right answer without rounding',
    MyWorkResources_FinishTest: 'Finish test',
    MyWorkResources_Raiting: 'Rating',
    MyWorkResources_RaitingToAdd: 'to raiting for this problem',
    MyWorkResources_SolvedTests: 'Solved tests',
    MyWorkResources_Task: 'Problem',
    MyWorkResources_SeeResults: 'View results',
    NotificationResources_ClientsConnectedForStudent: 'Teacher {ClientName} was added to your teachers',
    NotificationResources_ClientsConnectedForTeacher: 'Student {ClientName} was added to your students',
    ProblemResources_TheoryLink: 'Theory link',
    ProblemResources_RightAnswer: 'Correct answer',
    ProblemResources_NextTask: 'Next',
    ProblemResources_OpenDraft: 'Open draft',
    ProblemResources_PrevTask: 'Previous',
    ProblemResources_FoundAmistake: 'Found a mistake ?',
    ProblemResources_MistakeKind1: 'Right answer is wrong',
    ProblemResources_MistakeKind3: 'After providing the answer, an error page appears',
    ProblemResources_MistakeKind2: 'There is no right answer',
    ProblemResources_MistakeKind4: 'Other mistake',
    Mistakes: 'Mistakes',
    CloseMistake: 'Close mistake',
    MistakeHasBeenClosed: 'Mistake has been successfully closed',
    NoMistakesInProblemsRaisedYet: 'No mistakes in problems raised yet',
    NoHomeworksAssignedYet:'No homeworks were assigned to you yet',
    ProblemResources_RatingAfterAnswer: 'Task Rating After Answer',
    ProblemResources_ExampleLink: 'Show Example',
    ProblemResources_CloseDraft: 'Close draft',
    ProblemResources_CorrectAnswer: 'Correct Answer',
    ProblemResources_MistakeSendingExplanation: 'Clicking button below You are sending a request to our support team for looking to the mistake you found. We promise to consider your request and give you a response in 24 hours. Thank You for making us better.',
    ProblemResources_RatingAfterGeneration: 'Task Rating After Generation',
    ProblemResources_TestAssign: 'Test is assign',
    ProblemResources_DecisionTextForMistake: 'Decision message',
    ProblemResources_ConfirmButton: 'Confirm',
    ProblemResources_KindOfMistake: 'What kind of mistake is this',
    ProblemResources_NextLevel: 'Next level',
    ProblemResources_WrongAnswer: 'Wrong Answer',
    ProblemResources_SolvingOfTheProblem: 'Solving of the problem',
    ProblemResources_PrevLevel: 'Previous level',
    ProblemResources_CloseMistakeButton: 'Close and send decision',
    ProblemResources_InitialRating: 'Initial Task Rating',
    ProblemResources_RightAnswerWithoutRounding: 'Correct answer without rounding',
    ProblemResources_KindOfMistakeWord: 'Kind of mistake',
    ProblemResources_TaskExample: 'Problem example',
    ProblemResources_MistakeDetailsWord: 'Mistake details',
    ProblemResources_HomeworkAssign: 'Homework is assign',
    ProblemResources_DetailsOfMistake: 'Some details about the mistake',
    ProblemResources_Trainings: 'Trainings',
    ProblemResources_CloseMistakeEmailTopic: 'Error in the problem considered',
    ProblemResources_CheckingAnswer: 'Checking your answer...',
    ChooseGroupOrStudent: 'Choose group or student',
    MistakeHasBeenSent: 'Mistake has been sent',
    ProfileResources_Title: 'Profile',
    ProfileResources_Surname: 'Surname',
    ProfileResources_Email: 'Email',
    ProfileResources_ChangeAvatarLink: 'Change profile picture',
    ProfileResources_School: 'School',
    ProfileResources_Close: 'Close',
    ProfileResources_PictureIsTooBig: 'Your picture is too big. Please choose a picture less than 4 MB',
    ProfileResources_Login: 'Login',
    ProfileResources_DtBirthday: 'Date of birth',
    ProfileResources_Phone: 'Phone',
    ProfileResources_AvatarError: 'Error while updating your avatar. Please try to upload anoher picture or try on other device',
    ProfileResources_ChooseFile: 'Choose file',
    ProfileResources_SaveChanges: 'Save',
    ProfileResources_City: 'City',
    ProfileResources_Name: 'Name',
    ProfileResources_PictureNotMoreThan: 'Less than 4 MB',
    ProfileResources_ProfileInfoError: 'Error while updating your information. Please make sure you are entering a valid data',
    ProfileResources_EmailParents: 'Parents email',
    ProfileResources_LastName: 'Last name',
    ProfileResources_InvalidMessage: 'Invalid field',
    ProfileResources_DataWasSaved: 'Profile data is saved',
    RegistrationResources_RegistrConfirmation_Link: 'сlick here to Log in',
    RegistrationResources_Student: 'Student',
    RegistrationResources_ConfirmPasswordLabel: 'Confirmation password',
    RegistrationResources_SubmitButton: 'Sign Up',
    RegistrationResources_ErrorRegistration: 'An error occured, please try again',
    RegistrationResources_LoginLabel: 'Login',
    RegistrationResources_Header: 'Registration',
    RegistrationResources_Teacher: 'Teacher',
    RegistrationResources_SuccesRegistration1: 'Thank you for registration. We sent You an email with a confirmation link. \n        Have a good day!',
    RegistrationResources_Error_Password_Regex: 'Password must include at least 1 Uppercase Alphabet, 1 Lowercase Alphabet and 1 Number',
    RegistrationResources_Error_Login_Min: 'Field must be at least 2 symbols',
    RegistrationResources_Error_Empty: 'Field can not be empty',
    RegistrationResources_Iam: 'I am ',
    RegistrationResources_Reset: 'Reset',
    RegistrationResources_Error_Password_Min: 'Field must be at least 8 symbols',
    RegistrationResources_Error_Email_Exists: 'Such email already exists.',
    RegistrationResources_RegistrConfirmation: 'Thank you for confirming your email. Please',
    RegistrationResources_Error_Email_LogInAs: 'Log in as',
    RegistrationResources_Error_Email_Invalid: 'Enter valid email',
    Settings: 'Settings',
    AddRetake: 'Add retake',
    TestResources_RegenerateTest: 'Regenerate tests',
    TestResources_TakeNumber: 'Take',
    TestResources_RetakeTest: 'Retake test',
    TestResources_RightAnswerText: 'Right answer: ',
    TestResources_Closed: 'Closed',
    SolvingHistory: 'Solving history',
    TestResources_RegenerateStudentTest: 'Regenerate problems',
    TestResources_IsSent: 'Sent to students',
    TestResources_TestInQueueText: 'In a queue for generation',
    TestResources_AnswerRecieved: 'Answer recieved',
    TestResources_GenerateTest: 'Generate tests',
    TestResources_ProblemNotGenerated: 'Problem not generated',
    TestResources_BackToTest: 'Back to test',
    TestResources_Solved: 'Solved',
    TestResources_AnswerNotReceived: 'Answered was not received. Please try again. Your teacher will be informed about this.',
    TestResources_ConfirmRetake: 'Are you sure you want to retake the test?',
    TestResources_ConfirmBegin: 'Are you sure you want to begin the test?',
    TestResources_Filter_ByName: 'By name',
    TestResources_Filters_ToSend: 'To send',
    TestResources_RefreshTestAfterGeneration: 'Refresh the page',
    TestResources_Creating: 'Test is being created',
    TestResources_GenerateStudentTest: 'Generate problems',
    TestResources_ConfirmFinish: 'Are you sure you want to finish the test?',
    TestResources_Filter_Score: 'Best score',
    TestResources_Filter_BestTake: 'Best take',
    TestResources_New: 'New',
    TestResources_DueDate: 'Due date',
    TestResources_ContinueSolving: 'Continue solving',
    TestResources_Filters_StartingWithLast: 'From last ones',
    TestResources_GenerateTestTooltip: 'Generating problems can take several minutes',
    TestResources_CreatedOn: 'Creation date',
    TestResources_TestIsSolving: 'Solving in process',
    TestResources_BackToTests: 'Back to tests',
    TestResources_BeginTest: 'Begin test',
    TestResources_SendToStudents: 'Send to students',
    TestResources_Filter_LastTake: 'Last take',
    TestResources_Generating: 'Generating',
    TestAnswerWasAcceptedSnackBar: 'Answer for this problem was accepted',
    TestAnswerWasNotAcceptedSnackBar: 'Confirming answer failed',
    ConfirmAsnwer: 'Confirm answer',
    ChooseSubject: 'Choose subject',
    GoToTopic: 'Go to the topic',
    TopicResources_0: 'All topics',
    TopicResources_5: 'Probability theory',
    TopicResources_4: 'Combinatorics',
    TopicResources_7: 'Different high complexity tasks',
    TopicResources_6: 'Economy and Finance ',
    TopicResources_1: 'Algebra',
    TopicResources_3: 'Geometry',
    TopicResources_2: 'Trigonometry',
    TopicResources_63: 'Compound interest',
    TopicResources_62: 'Simple interest',
    TopicResources_61: 'Concept of percent. The simplest tasks on interest',
    TopicResources_67: 'Supply and demand. Elasticity.',
    TopicResources_66: 'Taxes',
    TopicResources_65: 'Сash flows',
    TopicResources_64: 'Inflation',
    TopicResources_68: 'Various problems of economic content',
    TopicResources_14: 'Sequences and limits',
    TopicResources_16: 'Problems with parameters',
    TopicResources_11: 'Identical transformations of algebraic expressions',
    TopicResources_12: 'Algebraic equations and inequalities',
    TopicResources_13: 'Functions',
    TopicResources_24: 'Arcus',
    TopicResources_23: 'Trigonometric inequalities',
    TopicResources_22: 'Trigonometric equations',
    TopicResources_21: 'Trigonometric transformations',
    TopicResources_32: 'Stereometry',
    TopicResources_31: 'Planimetry',
    TopicResources_141: 'Arbitrary sequences',
    TopicResources_142: 'Progressions',
    TopicResources_143: 'Limits',
    TopicResources_151: 'Derivative',
    TopicResources_153: 'Integral',
    TopicResources_152: 'Extremes, the largest and smallest functions values',
    TopicResources_126: 'Inequalities',
    TopicResources_127: 'Mixed type equations and inequalities. Calculation of the area.',
    TopicResources_124: 'System equations',
    TopicResources_125: 'Text Tasks',
    TopicResources_122: 'Irrational equations',
    TopicResources_123: 'Equations with modules',
    TopicResources_121: 'Rational equation',
    TopicResources_137: 'Functions with modulus',
    TopicResources_136: 'Composite function',
    TopicResources_135: 'Inverse function',
    TopicResources_134: 'Function periodicity',
    TopicResources_133: 'Parity and odd functions',
    TopicResources_132: 'Elementary functions',
    TopicResources_131: 'Domain and range of the function',
    TopicResources_139: 'Logarithmic and exponential functions',
    TopicResources_324: 'The angle between two planes',
    TopicResources_325: 'The distance from point to line',
    TopicResources_326: 'The distance from point to plane',
    TopicResources_327: 'The distance between two lines',
    TopicResources_321: 'Equation of the plane',
    TopicResources_322: 'The angle between the lines',
    TopicResources_323: 'The angle between the line and the plane',
    TopicResources_328: 'The calculation of surface area and volume of geometric solids',
    TopicResources_329: 'Vector algebra and coordinate method in space',
    TopicResources_315: 'Vector algebra and coordinate method in the plane',
    TopicResources_314: 'Circle, sector, segment. Angles associated with the circle',
    TopicResources_313: 'Polygon',
    TopicResources_312: 'Quadrilateral',
    TopicResources_311: 'Triangle',
    TopicResources_1422: 'Geometric progression',
    TopicResources_1423: 'Mixed problems for the progression',
    TopicResources_1421: 'Arithmetical progression',
    TopicResources_1261: 'Linear and quadratic inequalities',
    TopicResources_1262: 'Rational inequalities with simple roots',
    TopicResources_1263: 'Rational inequalities with multiple roots',
    TopicResources_1264: 'Fractional-rational inequalities',
    TopicResources_1265: 'Rational inequality systems',
    TopicResources_1266: 'Irrational inequalities',
    TopicResources_1267: 'Inequalities with modules',
    TopicResources_1253: 'Mixtures',
    TopicResources_1252: 'Work',
    TopicResources_1251: 'Movement',
    TopicResources_1256: 'Different problems',
    TopicResources_1255: 'Interest',
    TopicResources_1254: 'Numbers',
    TopicResources_1242: 'Viet type systems',
    TopicResources_1243: 'Irrational system of equations',
    TopicResources_1241: 'Linear system equations',
    TopicResources_1244: 'System of nonlinear equations',
    TopicResources_1214: 'Rational-fractional equations',
    TopicResources_1213: 'Entire  rational equation. Substitution method',
    TopicResources_1212: 'Entire rational equation. Multiplier expansion',
    TopicResources_1211: 'Linear and quadratic equations',
    TopicResources_1392: 'Logarithmic and exponential equations',
    TopicResources_1393: 'Logarithmic and exponential inequalities',
    TopicResources_1391: 'Logarithmic and exponential function properties',
    Finish: 'Finish',
    TestWillBeClosedIn5Seconds: 'Test will be automatically closed in 5 seconds',
    ScoreForProblem: 'Score for the problem',
    OutOf: 'out of',
    NewHomeworkReceived: 'New homework was assigned to you',
    NewTestReceived: 'New test was assigned to you',
    RemoveTeacher: 'Remove teacher',
    AddNewTeacher: 'Add new teacher',
    TeacherBoards: 'My teachers boards',
    ChooseStudentsForGroup: 'Choose students for the group'
};
export default en;