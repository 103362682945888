import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import locales from './locales';

const Translated = ({ translate, locale }) => {
  const translations = locales[locale]
  const translation = translations[translate];
  return <span>{translation}</span>;
};

Translated.propTypes = {
  translate: PropTypes.string,
  locale: PropTypes.string
};

const mapStateToProps = function(state, ownProps) {
  return {
    translate: ownProps.translate,
    locale: state.culture.locale
  }
}

export default connect(mapStateToProps)(Translated);
