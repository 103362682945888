import React from 'react';
import { Box, Card, CardContent, Tooltip, Button, CardActions, Grid, CardHeader, Divider, colors, useTheme, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import { Rating } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { actionCreators } from 'src/reducers/common/clientProblems';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  }
}));

const ProblemGraphStats = ({ problem, generatedProblems, rating, student }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const correctWord = translate('Correct');
  const wrongWord = translate('Wrong');
  const unsolvedWord = translate('Unsolved');

  const user = useSelector(state => state.userContext.user);

  const allRatings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  let correctProblems = [];
  let incorrectProblems = [];
  let unsolvedProblems = [];

  if (generatedProblems && generatedProblems.length > 0) {
    correctProblems = generatedProblems.filter(p => p.answeredCorrectly);
    unsolvedProblems = generatedProblems.filter(p => p.userAnswer === null);
    incorrectProblems = generatedProblems.filter(p => !p.answeredCorrectly && p.userAnswer !== null);
  }

  const data = {
    datasets: [
      {
        data: [correctProblems.length, incorrectProblems.length, unsolvedProblems.length],
        backgroundColor: [
          colors.green[600],
          colors.red[600],
          colors.indigo[500]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: [correctWord, wrongWord, unsolvedWord]
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const states = [
    {
      title: correctWord,
      value: correctProblems.length,
      icon: DoneOutlineIcon,
      color: colors.green[600]
    },
    {
      title: wrongWord,
      value: incorrectProblems.length,
      icon: CancelIcon,
      color: colors.red[600]
    },
    {
      title: unsolvedWord,
      value: unsolvedProblems.length,
      icon: HelpOutlineIcon,
      color: colors.indigo[500]
    }
  ];

  const handleStudentRatingChange = (event) => 
  {
    dispatch(actionCreators.changeStudentRating(problem.id, student.id, event.target.value));
  }

  return (
    <Card>
      <CardHeader title={translate('StatisticsForProblem')} />
      <Divider />
      <CardContent>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Grid container>
            <Grid item sm={10}>
              <Tooltip style={{ marginTop: 12, marginRight: 10 }} title={`${translate('CurrentRating')} ${rating}`} arrow>
                <Rating
                  name="read-only"
                  value={rating}
                />
              </Tooltip>
              {user && user.role == 'Teacher' && (
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-outlined-label">{translate('Rating')}</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={rating}
                    onChange={handleStudentRatingChange}
                    label={translate('Rating')}
                  >
                    {allRatings.map(t => (
                      <MenuItem key={t} value={t}>{t}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item sm={2}>
              <Tooltip title={translate('Solve')} arrow >
                <Button component={Link} to={`/app/problem/${problem.id}`}>
                  <CreateIcon color="primary" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Box
          height={300}
          position="relative"
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          {states.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              <Icon style={{ color }} />
              <Typography
                style={{ color }}
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>

      <CardActions className={classes.cardActions}>
      </CardActions>
    </Card>
  );
};

export default ProblemGraphStats;
