import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProblemActions from './ProblemActions';
import ProblemText from './ProblemText';
import ProblemAnswer from './ProblemAnswer';
import Graph from './Graph';
import ProblemLatexText from './ProblemLatexText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0)
  }
}));

const ProblemBody = ({ problem, generatedProblem, setGeneratedProblem, clientProblem, whiteBoardOpened, onWhiteBoardToggle }) => {
  const classes = useStyles();
  const [solvingSteps, setSolvingSteps] = useState([]);

  useEffect(() => {
    setSolvingSteps([]);
  }, [generatedProblem?.id])

  return (
    <React.Fragment>
      <ProblemActions
        problem={problem}
        generatedProblem={generatedProblem}
        solvingSteps={solvingSteps}
        setSolvingSteps={(steps) => setSolvingSteps(steps)}
        onWhiteBoardToggle={onWhiteBoardToggle}
        whiteBoardOpened={whiteBoardOpened}
      />
      <Grid container className={classes.formControl}>
        <ProblemText generatedProblem={generatedProblem}/>
        {generatedProblem.graphData && (
          <Grid item md={12}>
            <Graph problemId={problem.id} data={generatedProblem.graphData}/>
          </Grid>
        )}
        <Grid item md={6}>
            <ProblemAnswer 
              clientRating={clientProblem?.rating ?? 0}
              setGeneratedProblem={(_) => setGeneratedProblem(_)} 
              problem={problem}
              generatedProblem={generatedProblem}
              solvingSteps={solvingSteps}
              />
        </Grid>
        <Grid item xs={12} >
          {solvingSteps && solvingSteps.map(ss =>
            <div key={ss.id}>
              {ss.stepType === 1 && <Typography dangerouslySetInnerHTML={{ __html: ss.description }} />}
              {ss.stepType === 3 && <ProblemLatexText text={ss.description} />}
            </div>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProblemBody;
