import React, { useState } from 'react';
import {
  Backdrop,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }, backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const NotFoundView = ({ path }) => {
  const classes = useStyles();

  const [backdropOpen, setBackdropOpen] = useState(true);

  const handleCloseBackdrop = () => {
    setBackdropOpen(false);
  };

  return (
    <Page
      className={classes.root}
      title="404"
    >
      <Backdrop className={classes.backdrop} open={backdropOpen} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            {path}
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle2"
          >
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/undraw_page_not_found_su7k.svg"
            />
          </Box>
        </Container>
      </Box> */}
    </Page> 
  );
};

export default NotFoundView;
