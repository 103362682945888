import store from 'src/configure-store';

const studentsInGroupService = {
    getStudentsInCurrentGroup,
    getAvarageRatingOfProblemInGroup,
    getAvarageRatingOfProblemCurrentGroup
};

function getStudentsInCurrentGroup(){
    const state = store.getState();

    const currentGroup = state.teacher.groups.find(g => g.isCurrent);

    const studentsInGroup = state.teacher.studentGroups.filter(sg => sg.groupId === currentGroup.id).map(sg => sg.studentId);
    
    return studentsInGroup;
}

function getAvarageRatingOfProblemInGroup(groupId, problemId)
{
    const state = store.getState();
    const studentsInGroup = state.teacher.studentGroups.filter(sg => sg.groupId === groupId).map(sg => sg.studentId);

    const studentProblems = state.common.clientProblems.filter(cp => cp.problemId === problemId && studentsInGroup.includes(cp.clientId));

    let avarageRating = studentProblems.reduce(function(a, b){
        return a + b.rating;
    }, 0);
    
    avarageRating = studentsInGroup && studentsInGroup.length > 0 ? Math.round(avarageRating / studentsInGroup.length) : 0;

    return avarageRating;
}

function getAvarageRatingOfProblemCurrentGroup(problemId)
{
    const state = store.getState();
    const studentsInGroup = getStudentsInCurrentGroup();

    const studentProblems = state.common.clientProblems
            .filter(cp => cp.problemId === problemId && studentsInGroup.includes(cp.clientId));

    let avarageRating = studentProblems.reduce(function(a, b){
        return a + b.rating;
    }, 0);
    
    avarageRating = studentsInGroup && studentsInGroup.length > 0 ? Math.round(avarageRating / studentsInGroup.length) : 0;

    return avarageRating;
}

export default studentsInGroupService;