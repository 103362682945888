import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import translate from 'src/helpers/translate';
import { Link } from 'react-router-dom';
import { topicService } from 'src/services/topic.service';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    button: {
        justifyContent: 'flex-start',
        width: '100%'
      },
});

export default function SubjectCard(props) {
    const {
        subject
    } = props;

    const classes = useStyles();

    const topics = useSelector(state => topicService.getInnerTopics(subject.id, state.common.topics));

    const url = topics && topics.length > 0 ? `/app/topics/${subject.id}` : `/app/problems/${subject.id}`;

    return (
            <Card className={classes.root} raised>
                <CardActionArea component={Link} to={url} >
                    <CardMedia
                        className={classes.media}
                        image={`/static/subjects/${subject.id}.jpg`}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {translate(`TopicResources_${subject.id}`)}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
    );
}
