import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import { actionCreators as trainingsActionCreators } from 'src/reducers/common/trainings';
import { actionCreators as eduVideosActionCreators } from 'src/reducers/common/eduVideos';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';
import { useParams } from 'react-router-dom';
import Problem from './Problem';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '600px',
        width: '100%',
        paddingBottom: theme.spacing(3),
        marginTop: -20
    }
}));

const ProblemSolvingView = () => {
    const classes = useStyles();

    const { id } = useParams();

    const dispatch = useDispatch();

    const user = useSelector(state => state.userContext.user);

    const problem = useSelector(state => state.common.problems.find(p => p.id == id));

    const problemsInSameTopic = useSelector(state => state.common.problems.filter(p => p.endTopic === problem.endTopic));

    let previousProblem = null;
    previousProblem = problemsInSameTopic.find(p => p.level === problem.level && p.numberInLevel === problem.numberInLevel - 1);
    if (!previousProblem) {
        const problemsFromPreviousLevel = problemsInSameTopic.filter(p => p.level === problem.level - 1);
        if (problemsFromPreviousLevel && problemsFromPreviousLevel.length > 0) {
            const problemNumberFromPreviousLevel =
                Math.max.apply(Math, problemsFromPreviousLevel.map(p => p.fullNumber));
            previousProblem = problemsFromPreviousLevel.find(p => p.fullNumber === problemNumberFromPreviousLevel);
        }
    }

    let nextProblem = null;
    nextProblem = problemsInSameTopic.find(p => p.level === problem.level && p.numberInLevel === problem.numberInLevel + 1);
    if (!nextProblem) {
        const problemsFromNextLevel = problemsInSameTopic.filter(p => p.level === problem.level + 1);
        if (problemsFromNextLevel && problemsFromNextLevel.length > 0) {
            const problemNumberFromNextLevel =
                Math.min.apply(Math, problemsFromNextLevel.map(p => p.fullNumber));
            nextProblem = problemsFromNextLevel.find(p => p.fullNumber === problemNumberFromNextLevel);
        }
    }

    useEffect(() => {
        if (user && user.role) {
            dispatch(clientProblemsActionCreators.requestClientProblems());
            dispatch(trainingsActionCreators.requestTrainings());
            dispatch(eduVideosActionCreators.requestEduVideos());
        }
    }, [user, dispatch])

    return (
        <Page
            className={classes.root}
            title="Solving"
        >
            {problem && (
                <Problem
                    problem={problem}
                    previousProblem={previousProblem}
                    nextProblem={nextProblem}
                />
            )}
        </Page>
    );
};

export default ProblemSolvingView;
