import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { actionCreators as layoutActionCreators} from 'src/reducers/layout'
import DialogTitle from '@mui/material/DialogTitle';
import translate from 'src/helpers/translate';
import * as apiCaller from 'src/helpers/apiCaller';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '600px',
        minWidth: '600px',
        paddingBottom: theme.spacing(3),
        marginTop: -20
    },
    textField:{
        width: '100%'
    }
}));

const MistakeFoundDialog = ({ generatedProblemId, problemId, onClose, open }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const mistakeTypes = [
        translate("ProblemResources_MistakeKind1"),
        translate("ProblemResources_MistakeKind2"),
        translate("ProblemResources_MistakeKind3"),
        translate("ProblemResources_MistakeKind4")
    ]

    const [mistakeKind, setMistakeType] = useState(mistakeTypes[0]);
    
    const [details, setDetails] = useState('');

    const handleMistakeTypeChange = (event) => {
        setMistakeType(event.target.value);
    }

    const handleMistakeDetailsChange = (event) => {
        setDetails(event.target.value);
    }

    const handleSend = () => {
        const body = {
            generatedProblemId,
            problemId,
            mistakeKind,
            details
        }
        apiCaller.post('/mistakesInProblem/errorInProblem', body, true).then(
            () => {
                dispatch(layoutActionCreators.showInfoAlert(translate("MistakeHasBeenSent")))
                onClose();
            }
        )
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle id="customized-dialog-title">
                {translate("ProblemResources_KindOfMistake")}
            </DialogTitle>
            <DialogContent dividers>
                        <Typography gutterBottom>
                            {translate("ProblemResources_KindOfMistake")}
                        </Typography>
                        <Select
                            value={mistakeKind}
                            onChange={handleMistakeTypeChange}
                            label={translate("ProblemResources_KindOfMistakeWord")}
                        >
                            {mistakeTypes.map(mt => (
                                <MenuItem key={mt} value={mt}>{mt}</MenuItem>
                            ))}
                        </Select>
                        <br />
                        <br />
                        <br />
                        <TextField id="standard-secondary"
                            className={classes.textField}
                            label={translate("ProblemResources_DetailsOfMistake")}
                            value={details}
                            onChange={handleMistakeDetailsChange}
                            multiline
                            minRows={1}
                            variant="standard"
                            color="secondary" />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleSend} color="primary">
                    {translate("Send")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MistakeFoundDialog;
