import React from 'react';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import { Link } from 'react-router-dom';
import { Chip, Collapse, List, ListItem, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { topicService } from 'src/services/topic.service.js';
import { TopicListItem as StudentTopicListItem } from '../../student/topics/TopicListItem';
import { TopicListItem as TeacherTopicListItem } from '../../teacher/topics/TopicListItem';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        marginTop: -20
    },
    media: {
        height: 140,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    progressBox: {
        paddingLeft: theme.spacing(2),
        marginTop: -15,
    }
}));

export default function CommonTopicListItem({ topic, isNested, topics, clientProblems, problemsInTopic, clientsAmount }) {
    const classes = useStyles();

    const userRole = useSelector(state => state.userContext.user.role);

    const innerTopics = topicService.getInnerTopics(topic.id, topics);

    let maxRating = problemsInTopic.length * 3;

    if (clientsAmount) {
        maxRating = maxRating * clientsAmount;
    }

    const rating = clientProblems.reduce(function (a, b) {
        return a + (b.rating > 3 ? 3 : b.rating);
    }, 0);

    const ratingInPercents = Math.round(rating / maxRating * 100);

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            {(!innerTopics || innerTopics.length === 0) && (
                <Link to={`/app/problems/${topic.id}`}>
                    <ListItem button className={isNested && classes.nested} alignItems='center'>
                        <ListItemText style={{color: "#1B73E8"}}>
                            {translate(`TopicResources_${topic.id}`)}
                        </ListItemText>
                        {ratingInPercents > 0 && (
                            <Chip label={ratingInPercents + '%'} size="small" color="primary" />
                            )}
                    </ListItem>
                </Link>
            )}
            {innerTopics && innerTopics.length > 0 && (
                <div>
                    <ListItem button className={isNested && classes.nested} onClick={handleClick} >
                        <ListItemText primary={translate(`TopicResources_${topic.id}`)} />
                        {ratingInPercents > 0 && (
                            <Chip label={ratingInPercents + '%'} size="small" color="primary" />
                        )}
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {innerTopics.map(innerTopic => userRole === 'Student'
                                ? <StudentTopicListItem key={innerTopic.id} topic={innerTopic} topics={topics} isNested />
                                : <TeacherTopicListItem key={innerTopic.id} topic={innerTopic} topics={topics} isNested />
                            )}
                        </List>
                    </Collapse>
                </div>
            )}
        </React.Fragment>
    );
}

