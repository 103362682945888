import React  from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import translate from '../../helpers/translate';
import { Grid } from '@mui/material';

export default function InformationalDialog(props){
    const { title, open, onClose, children, fullWidth, maxWidth } = props;

    return (
        <Dialog open={open} onClose={onClose} maxWidth={maxWidth ?? "md"} fullWidth={fullWidth ?? false} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                        <Grid container spacing={3}>
                            {children}
                        </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="primary">
                        {translate('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
    )
}