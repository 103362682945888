import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import translate from 'src/helpers/translate';
import SubmitButton from 'src/utils/SubmitButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Search from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { Avatar, Grid, IconButton, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as apiCaller from 'src/helpers/apiCaller';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    textField:{
        marginTop:'10px'
    }
}));

const AddClientDialog = ({
    open,
    setOpen,
    myClients,
    sendFriendRequest
}) => {
    const [foundClient, setFoundClient] = useState(null);
    const [clientToFind, setClientToFind] = useState('');
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [searchWordIsEmail, setSearchWordIsEmail] = useState(false);

    const classes = useStyles();
    const textFieldName = searchWordIsEmail ? translate('ProfileResources_Email') : translate('ProfileResources_Login');


    const handleClose = () => {
        setOpen(false);
    };

    const submit = () => {
        sendFriendRequest(foundClient);
        setOpen(false);
    };

    const handleClientToFindChange = (event) => {
        setClientToFind(event.target.value);
        setSearchPerformed(false);
        if(foundClient){
            setFoundClient(null);
        }
        if(event.target.value.includes('@')){
            setSearchWordIsEmail(true);
        }else{
            setSearchWordIsEmail(false);
        }
    }

    const searchForClient = () => {
        if(clientToFind){
            setSearchPerformed(true);
            apiCaller.get(`/studentTeacher/search?login=${clientToFind}`, true).then(client => {
                if(client && !myClients.map(s => s.id).includes(client.id)){
                    setFoundClient(client);
                }
            })
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate('ProvideEmailOrLogin')}</DialogTitle>
                <DialogContent>
                    <div style={{  }}>
                        <Grid container spacing={3}>
                            <Grid item sm={8} xs={12}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    size="small"
                                    id="name"
                                    label={textFieldName}
                                    type="string"
                                    value={clientToFind}
                                    onChange={handleClientToFindChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={4} xs={12} >
                                <IconButton
                                    onClick={() => searchForClient()}
                                    variant="standard"
                                    color="secondary"
                                    style={{marginTop: 15}}
                                    size="large">
                                        <Search />
                                </IconButton>    
                            </Grid>
                        </Grid>
                        {searchPerformed && foundClient && (
                            <List className={classes.root}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar src={`data:image/jpg;base64, ${foundClient.avatarImage}`} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={foundClient.wholeName}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {foundClient.login}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        )}
                        {searchPerformed && !foundClient && (
                            <Typography>{translate('NoClientsFound')}</Typography>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary">
                        {translate('Cancel')}
                    </Button>
                    <SubmitButton
                        isSubmitting={false}
                        canBeSubmitted={!!foundClient}
                        text={translate('CreateWorkResources_AddNewGroupStudent')}
                        onSubmit={submit}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddClientDialog;