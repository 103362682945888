import React from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
}));

const StudentsSelectedList = ({ students, selected, onSelect }) => {
    const classes = useStyles();

    const handleListItemClick = (event, index) => {
        onSelect(index);
    };

    return (
        <div className={classes.root}>
            <List component="nav" dense={true}>
                {students && students.sort(s => s.isCurrent ? -1 : 1).map(student => (
                    <ListItem
                        button
                        key={student.id}
                        selected={selected === student.id}
                        onClick={(event) => handleListItemClick(event, student.id)}
                    >
                        <ListItemIcon>
                            <Avatar
                                src={`data:image/jpg;base64, ${student.avatarImage}`}
                            />
                        </ListItemIcon>
                        <ListItemText primary={student.wholeName} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default StudentsSelectedList;