import React, { useEffect } from 'react';

export default function Graph86(props) {
    
    let {a, b, c} = props.data;
    
    console.log(props.board);
    props.board.create('functiongraph',[function(x){return a*x*x + b*x + c;}],{strokeColor:'black',highlightStrokeColor:'black'});

    return <></>
}