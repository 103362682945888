import { handleResponse, handleError } from './requestHandlers';
import * as authService from 'src/services/auth.service';
import store from 'src/configure-store';


export async function get(url, noArea) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem("authorizationData") },
    };

    return await callApi(url, requestOptions, noArea);
}

export async function post(url, body, noArea) {
    const requestOptions = {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem("authorizationData")  },
        body: JSON.stringify(body)
    };

    return await callApi(url, requestOptions, noArea);
}

export async function postAsFormData(url, formData, noArea) {    
    const requestOptions = {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
        credentials: 'include'
    };

    return await callApi(url, requestOptions, noArea);
}

export async function callWithNoAreaApi(url, requestOptions){
    let authData = localStorage.getItem('IsAuthorized');
    if(authData !== 'true' && authData !== true){
      authService.authorize();
    }
    const response = await fetch(url, requestOptions)
                            .then(handleResponse, handleError);

    return response;
}

export async function callCustomApiWithAuth(url, method){
    const requestOptions = {
        method: method,
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem("authorizationData"), 'Content-Type': 'application/json', 'Accept': 'application/json'  },
    };
    const response = await fetch(url, requestOptions)
                            .then(handleResponse, handleError);

    return response;
}

export async function callApi(url, requestOptions, noArea){
    const state = store.getState();
    const user = state.userContext.user;
    if(user && !noArea){
        url = '/' + user.role + url;
    }

    url = `${process.env.REACT_APP_WEB_API}${url}`;

    return callWithNoAreaApi(url, requestOptions);
}
