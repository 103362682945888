import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import NavBar from './NavBar';
import TopBar from './TopBar';
import CustomizedSnackbar from './CustomizedSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isNavOpen, seNavOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isNavOpen) {
      seNavOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <div className={classes.root}>
      <TopBar onToggleNavBar={() => seNavOpen(!isNavOpen)} />
      <NavBar
        closeNav={() => seNavOpen(false)}
        isNavOpen={isNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
            <CustomizedSnackbar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
