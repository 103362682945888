const ru = {
  LoginResources_ExternalLoginConfirmation_Text2: 'Пожалуйста введите ваш email ниже чтобы завершить регистрацию',
  LoginResources_ExternalLoginConfirmation_Text1: 'Вы успешно аутентифицированы с ',
  LoginResources_ExternalLoginConfirmation_UnseccessfulLogin: 'Неуспешный вход с Сервера',
  LoginResources_SubmitButton: 'Войти',
  LoginResources_LoginError: 'Пароль или логин введен неправильно',
  LoginResources_ResetPassword_Header: 'Изменить Ваш пароль',
  LoginResources_ForgotPassword: 'Забыли свой пароль ?',
  LoginResources_Header: 'Использовать локальный аккаунт для входа',
  LoginResources_ForgotPassword_emailButton: 'Отправить код',
  LoginResources_ResetPasswordTopic: 'Сброс пароля',
  LoginResources_ForgotPassword_SuccessEmailSent: 'Пожалуйста проверьте свой электронный ящик для восстановления пароля',
  LoginResources_Password_Label: 'Пароль',
  LoginResources_Login_Label: 'Логин',
  LoginResources_ResetPassword_SubmitButton: 'Изменить',
  LoginResources_RegisterButton: 'Нет аккаунта ? Зарегистрируйте нового пользователя',
  LoginResources_ResetPassword_SuccessResseting: 'Ваш пароль был успешно изменен. Пожалуйста',
  LoginResources_RememberMe_Label: 'Запомнить меня',
  LoginResources_ExternalLoginHeader: 'Войти через один клик используя :',
  CreateWorkResources_NoStudents: 'У вас еще нет студентов',
  CreateWorkResources_AddNewGroupStudent: 'Добавить',
  CreateWorkResources_ChooseSpecTasks: 'Выбрать конкретные задачи',
  CreateWorkResources_TimeWholeTest: 'Выбрать время для всей работы',
  CreateWorkResources_AddChosenTasks: 'Добавить выбранные задачи',
  CreateWorkResources_ChooseTasksByAmount: 'Выбрать по количеству задач в каждом уровне',
  CreateWorkResources_ChooseTasksAllTasksInTopic: 'Составить по всем задачам из данной темы',
  CreateWorkResources_TimeInLevel: 'Выбрать количество минут для одной задачи в уровне',
  CreateWorkResources_ChooseStudents: 'Выберите учеников',
  CreateWorkResources_TestWithoutTimeButton: 'Без времени',
  CreateWorkResources_ChooseTaskValidation: 'Выберите хотя бы одну задачу',
  CreateWorkResources_ChoseeTasks: 'Выберите задачи',
  CreateWorkResources_ChosenScore: 'Выбранные баллы',
  CreateWorkResources_SuccessfullyAddedWork: 'успешно добавлен',
  CreateWorkResources_ChosenTasks: 'Выбранные задачи',
  CreateWorkResources_ChooseTimeValidation: 'Выберите время',
  CreateWorkResources_NoGroups: 'У вас еще нет групп',
  CreateWorkResources_RetakeAmount: 'Количество сдач',
  CreateWorkResources_NeededRaiting: 'Нужный рейтинг',
  CreateWorkResources_EndDate: 'Дата ожидаемого выполнения',
  CreateWorkResources_ChooseScore: 'Выберите баллы',
  CreateWorkResources_ChooseScoreValidation: 'Выберите количество баллов',
  CreateWorkResources_SuccessfullyAddedHomeWork: 'успешно добавлено',
  CreateWorkResources_ChooseStudentsValidation: 'Выберите хотя бы одного ученика',
  CreateWorkResources_UnlimitedTime: 'Без времени',
  CreateWorkResources_CreateWorkButton: 'Создать работу',
  CreateWorkResources_ChosenStudents: 'Выбранные ученики',
  CreateWorkResources_NeededRaitingToolTip: 'Рейтинг, до которого должна быть решена каждая задача, чтобы закончить домашнее задание',
  CreateWorkResources_AnotherTopic: 'Выбрать другую тему',
  CreateWorkResources_ChosenGroups: 'Выбранные группы',
  CreateWorkResources_RemoveAll: 'Убрать все задачи',
  CreateWorkResources_YourStudents: 'Ваши ученики',
  CreateWorkResources_ExamName: 'Название экзамена',
  CreateWorkResources_ChooseTime: 'Выберите время ',
  CreateWorkResources_TasksInTopic: 'Задач в теме',
  CreateWorkResources_Minutes: 'минут',
  CreateWorkResources_CalculateButton: 'Посчитать',
  CreateWorkResources_YourGroups: 'Ваши группы',
  CreateWorkResources_ChosenTime: 'Выбранное время',
  CreateWorkResources_HomeWorkName: 'Название домашнего задания',
  CreateWorkResources_TestName: 'Название теста',
  CreateWorkResources_ChooseTasksConfirm: 'Подтвердить',
  CreateWorkResources_AddStudentsButton: 'Добавить выбранных учеников',
  CreateWorkResources_ChooseScoreExplanation: 'Выберите балл для правильно решенной задачи в каждом уровне',
  ChooseStudentOrGroupToWorkWith: 'Выберите ученика или группу',
  GeneratedProblem: 'Сгенерированная задача',
  Actions: 'Действия',
  ChooseScoreForLevel: 'Выберите балл для правильно решенной задачи в {0}',
  TestEdition: 'Редактировать тест',
  MyBoards: 'Мои доски',
  BoardWasAdded: 'Доска {0} была успешно добавлена',
  StudentsBoards: 'Доски учеников',
  PleaseProvideNameForBoard: 'Название для доски',
  CreateNewBoard: 'Создать новую доску',
  Create: 'Создать',
  Customers: 'Пользователи',
  Groups: 'Группы',
  Teachers: 'Преподаватели',
  MyWorkResources_MaxScore: 'Максимальный балл',
  TestStudentRetakeWasAdded: 'Пересдача успешно добавлена для ученика',
  Students: 'Ученики',
  WhiteBoards: 'Онлайн доски',
  Help: 'Помощь',
  Journal: 'Журнал',
  UpdateGroupName: 'Изменить группу',
  Language: 'Язык',
  GroupName: 'Название',
  GroupIdentifier: 'Идентификатор',
  CreateNewGroupTitle: 'Создать новую группу',
  YouAlreadyHaveAGroupWithSuchName: 'У Вас уже есть группа с таким названием',
  CreateNewGroupInfo: 'Пожалуйста введите название для группы',
  Cancel: 'Отмена',
  Accept: 'Принять',
  CancelRequest: 'Отменить',
  Requested: 'Запрошен',
  Added: 'Добавлен',
  RemoveFromStudents: 'Убрать',
  RemoveStudent: 'убрать {0} со своих учеников',
  DeleteGroup: 'удалить группу {0}',
  NewRequest: 'Новый запрос',
  Level1: 'Уровень А',
  Level2: 'Уровень Б',
  Level3: 'Уровень В',
  Solve: 'Решать',
  Rating: 'Рейтинг',
  AvarageRating: 'Средний рейтинг',
  CurrentRating: 'Текущий рейтинг',
  CheckAnswer: 'Проверить ответ',
  NextProblem: 'Следующая',
  PreviousProblem: 'Предыдущая',
  PleaseSelectAnswer: 'Пожалуйста, выберите ответ',
  PleaseProvideAnswer: 'Пожалуйста, введите ответ',
  RequestWasCanceled: 'Запрос был отклонен',
  RequestWasAccepted: 'Запрос был принят',
  StudentWasRemoved: 'Ученик убран',
  ChangeLanguage: 'Сменить язык',
  NoStudentsFound: 'Не найдено',
  UserWithSuchLoginAlreadyRegistered: 'Пользователь с таким логином уже зарегистрирован',
  ProvideEmailOrLogin: 'Введите логин или имейл',
  SharedResources_Ukrainian: 'Українська',
  SharedResources_Submit: 'Подтвердить',
  SharedResources_IAccept: 'Я принимаю',
  SharedResources_Password: 'Пароль',
  SharedResources_Groupidentifier: 'Идентификатор группы (не обязательно)',
  SharedResources_English: 'English',
  SharedResources_Confirmpassword: 'Подтвердить пароль',
  SharedResources_Russian: 'Русский',
  SharedResources_Rememberme: 'Запомнить меня?',
  History: 'История',
  SearchForProblem: 'Искать задачу по id',
  WrongProblemId: 'Не существует задачи с id: {0}',
  Wrong: 'Неправильно',
  Correct: 'Правильно',
  Incorrect: 'Неправильно',
  SolvingHistory: 'История решения',
  TestResultFor: 'Результаты теста для',
  AvgSolvingTime: 'Сред. время решения',
  Unsolved: 'Без ответа',
  CorrectAnswerIs: 'Правильный ответ',
  StatisticsForProblem: 'Статистика по задаче',
  SolvingTimeDescription: 'Время решения (с)',
  SolvingTime: 'Время решения',
  Solved: 'Решено',
  Date: 'Дата',
  Link: 'Ссылка',
  EditionAllowed: 'Разрешено править',
  ShareWith: 'Поделиться с',
  BoardWasSharedWithStudent: 'Вы успешно поделились доской с учеником',
  BoardWasSharedWithGroup: 'Вы успешно поделились доской с группой',
  EditionOfTheBoardAllowed: 'Ученику было разрешено править доску',
  EditionOfTheBoardProhibitted: 'Ученику было запрещено править доску',
  DeleteBoard: 'удалить доску?',
  BoardWasDeleted: 'Доска была успешно удалена',
  TrainingWasAdded: 'Тренажер был успешно добавлен',
  TrainingWasDeleted: 'Тренажер был успешно удален',
  VideoWasAdded: 'Видео было успешно добавлено',
  VideoWasRemoved: 'Видео было успешно удалено',
  HelpInRegistration: 'Помощь в регистрации',
  WhatIsAProblem: 'Что такое задача',
  AvarageShort: 'сред',
  RatingWillNotBeChangedWarning: 'Рейтинг не будет изменен так как Вы уже ответили на эту задачу',
  GenerateNew: 'Перегенерировать',
  OpenWhiteBoard: 'Открыть черновик',
  CloseWhiteBoard: 'Закрыть черновик',
  GenerateNewTooltip: 'Ваш рейтинг будет снижен после 3-х кратного генерирования задачи без ответа',
  GenerateNewTooltipWarning: 'Это ваше 3-е генерирование этой задачи без ответа. Ваш рейтинг будет снижен!',
  EmailTemplatesResources_NewHomeworkIsSentTemplate_Subject: 'Новое домашнее задание',
  SolvingStepsTooltipWarning: 'Вы не сможете повысить рейтинг этой задачи, если посмотрите подсказку',
  SolvingStepsTooltip: 'Посмотреть подсказку',
  CorrectAnswerSnackBar: 'Ответ правильный. Рейтинг был повышен на 1',
  WrongAnswerSnackBar: 'Ответ неправильный',
  ShowTheory: 'Теория по данной задаче',
  ShowProblemExample: 'Пример решения подобной задачи',
  EduVideos: 'Обучающие видео',
  ForAdmin: 'Для админа',
  ShowStatistics: 'Посмотреть статистику',
  Statistics: 'Статистика',
  NeededRating: 'Нужный рейтинг',
  Sent: 'Отправлено',
  Description: 'Название',
  Topics: 'Задачи',
  DueDate: 'Срок',
  StudentRatingHasBeenChanged: 'Рейтинг ученика по данной задаче был именен',
  HomeworNeededRatingHasBeenChanged: 'Нужный рейтинг для домашнего задания был успешно изменен',
  Progress: 'Прогресс',
  CreatedOn: 'Создано',
  Send: 'Отправить',
  Rating: 'Рейтинг',
  Status: 'Статус',
  Delete: 'Удалить',
  Yes: 'Да',
  BackToBoards: 'Вернуться ко всем доскам',
  ChangeName: 'Изменить название',
  ChangeNameTitle: 'Пожалуйста, введите новое название',
  ChooseStudentsToAdd: 'Выберите учеников',
  ChooseGroupsToAdd: 'Виберите группы',
  ChooseProblemForWork: 'Выберите задачи для работы',
  StudentsWereSuccessfullyUpdated: 'Ученики были успешно обновлены',
  ProblemsWereSuccessfullyUpdated: 'Задачи были успешно обновлены',
  GroupsWereSuccessfullyUpdated: 'Группы были успешно обновлены',
  NameWasSuccessfullyChanged: 'Название было успешно изменено',
  StudentProblemInTestTooltip: 'Посмотреть сгенерированную задачу',
  HomeworkWasSuccessfullySent: 'Домашнее задание было успешно отправлено ученикам',
  TestWasSuccessfullySent: 'Тест был успешно отправлен ученикам',
  HomeworkWasDeleted: 'Домашнее задание удалено',
  TestWasDeleted: 'Тест успешно удален',
  DuplicateWork: 'Сдублировать работу',
  HomeworkNeedsToBeCompletedBeforeSent: 'Домашнее задание должно иметь задачи и учеников для отправки',
  PleaseProvideNameForHomework: 'Пожалуйста введите имя для нового домашнего задания',
  HomeworkWasSuccessfullyAdded: 'Домашнее задание {0} добавлено',
  TestWasSuccessfullyAdded: 'Тест {0} добавлен',
  CreateNewHomework: 'Создать новую домашнюю работу',
  PleaseProvideNameForTest: 'Пожалуйста введите имя для нового теста',
  CreateNewTest: 'Создать новый тест',
  StudentRatingInHomeworkTooltip: 'Рейтинг ученика. Нажмите, чтобы увидеть историю решения',
  WrongAnswerRatingDecreasedSnackBar: 'Ответ неправильный. Рейтинг был снижен на 1',
  EmailTemplatesResources_NewTestIsSentTemplate_Subject: 'Новый тест',
  GlobalResources_Slider_Header_Homework: 'Домашние задания',
  GlobalResources_Layout_Header_Service: 'Сервисы',
  GlobalResources_NewMessageEmailTopic: 'Новое сообщение для ',
  GlobalResources_AreYouSureYouWantToRemove: 'Вы уверены, что хотите убрать ',
  GlobalResources_Slider_Header_Teacher: 'Учительская',
  GlobalResources_NotFoundDescription: 'Страница которую вы ищите не существует. Возможно путь к странице был указан неверно',
  GlobalResources_OnlineDescLabel: 'Онлайн доска',
  GlobalResources_RemoveFromStudents: 'из Ваших учеников ?',
  GlobalResources_RemoveFromGroup: 'Убрать из этой группы?',
  GlobalResources_ConfirmYourAccount: 'Подтвердите свой аккаунт',
  GlobalResources_Layout_Header_Home: 'Главная',
  GlobalResources_Layout_Header_Lang: 'ru',
  GlobalResources_Slider_Header_Test: 'Тестирование',
  GlobalResources_Slider_Header_Exam: 'Экзамен',
  GlobalResources_Layout_Header_Registr: 'Регистрация',
  GlobalResources_RemoveFromHomeTest: 'из этого теста ?',
  ChooseGroupOrStudent: 'Выберите группу или ученика',
  GlobalResources_RemoveFromHomeWork: 'из этого домашнего задания ?',
  GlobalResources_EmailConfirmText2: 'Подтвердите Вашу регистрацию кликнув ',
  GlobalResources_EmailConfirmText3: 'тут',
  GlobalResources_EmailConfirmText0: 'Здравствуйте, ',
  GlobalResources_EmailConfirmText1: 'Благодарим за регистрацию в MathLife.',
  GlobalResources_Layout_Header_Hello: 'Привет',
  GlobalResources_Layout_Header_Login: 'Войти',
  GlobalResources_MessageSendToEmal: 'Сообщение будет отправлено на ',
  GlobalResources_RequiredFieldValidation: 'Поле не может быть пустым',
  GlobalResources_ApplicationErrorMessage: 'Ошибка на сервере. Пожалуйста повторите действие позже. Наша команда уже разбирается с этой проблемой. Спасибо',
  GlobalResources_AreYouSureYouWantTo: 'Вы уверены, что хотите ',
  GlobalResources_Tests: 'Тесты',
  GlobalResources_Today: 'Сегодня',
  GlobalResources_DbConnectionFailed: 'Что-то пошло не так. Пожалуйста, попробуйте повторить действие через несколько минут.',
  GlobalResources_Layout_Header_LogOff: 'Выйти',
  GlobalResources_ProblemMistakeTopicEmail: 'Найдена ошибка в задаче',
  GlobalResources_RemoveFromTeachers: 'из Ваших преподавателей?',
  GlobalResources_Slider_Header_Education: 'Обучение',
  GlobalResources_Slider_MoreDetails_Button: 'Подробнее',
  GlobalResources_NotFoundLabel: 'Страница не найдена',
  MyWorkResources_RightAnswer: 'Правильный ответ',
  MyWorkResources_NewHomeWorkSubject: 'Новое домашнее задание',
  MyWorkResources_RetakeTest: 'Пересдать тест ',
  MyWorkResources_EnoughHomeworkRating: 'Все задачи решены как минимум до рейтинга $NeededRating.',
  MyWorkResources_TaskIds: 'Задачи',
  MyWorkResources_WithoutTime: 'Неограничено',
  MyWorkResources_Teacher: 'Преподаватель',
  MyWorkResources_AnswerReceived: 'Ответ принят',
  MyWorkResources_ContinueTest: 'Продолжить тест',
  MyWorkResources_TakesLeft: 'Осталось сдач',
  MyWorkResources_Points: 'Баллы',
  MyWorkResources_ShowSolvedTest: 'Посмотреть тест',
  MyWorkResources_NotEnoughHomeworkRating: 'Не все задачи решены как минимум до рейтинга $NeededRating.',
  MyWorkResources_StartDate: 'Дата создания',
  MyWorkResources_Students: 'Учеников',
  MyWorkResources_A: 'А',
  MyWorkResources_C: 'В',
  AddRetake: 'Добавить пересдачу',
  TestTopics: 'Тест на тем(у/ы)',
  TestProblemsCount: 'Количество задач',
  TestSolvingTime: 'Время на решение тест',
  TestMaxScore: 'Максимальний балл',
  EditTest: 'Изменить тест',
  Add: 'Добавить',
  Mark: 'Оценка',
  RetakesLeft: 'Пересдач осталось',
  MaxScore: 'Макс. оценка',
  Time: 'Время',
  MinutesRemainingAlert: 'Осталась {0} минута',
  MyWorkResources_B: 'Б',
  MyWorkResources_Sum: 'Сумма',
  MyWorkResources_Level: 'Уровень',
  MyWorkResources_Tasks: 'Задач',
  MyWorkResources_Score: 'Набрано баллов',
  MyWorkResources_SolvedProblems: 'Решенных задач',
  MyWorkResources_BeginTest: 'Начать тест',
  MyWorkResources_HomeWorkLabel: 'Домашняя работа',
  MyWorkResources_CurrentTests: 'Текущие тесты',
  MyWorkResources_RightAnswerWithoutRounding: 'Правильный ответ без округления',
  MyWorkResources_FinishTest: 'Закончить тест',
  MyWorkResources_Raiting: 'Рейтинг',
  MyWorkResources_RaitingToAdd: 'к рейтингу за эту задачу',
  MyWorkResources_SolvedTests: 'Решенные тесты',
  MyWorkResources_Task: 'Задачa',
  MyWorkResources_SeeResults: 'Посмотреть результаты',
  NotificationResources_ClientsConnectedForStudent: 'Учитель {ClientName} был добавлен к Вашим учителям',
  NotificationResources_ClientsConnectedForTeacher: 'Ученик {ClientName} был добавлен к Вашим ученикам',
  ProblemResources_TheoryLink: 'Ссылка на теорию',
  ProblemResources_RightAnswer: 'Правильный ответ',
  ProblemResources_NextTask: 'Следующая',
  ProblemResources_OpenDraft: 'Открыть черновик',
  ProblemResources_PrevTask: 'Предыдушая',
  ProblemResources_FoundAmistake: 'Нашли ошибку ?',
  Mistakes: 'Ошибки в задачах',
  CloseMistake: 'Закрыть ошибку',
  MistakeHasBeenClosed: 'Ошибка была успешно закрыта, а ответ был отправлен клиенту',
  NoMistakesInProblemsRaisedYet: 'Пока что ни одной ошибки не было выслано',
  NoHomeworksAssignedYet:'Вам не было задано домашних заданий',
  ProblemResources_MistakeKind1: 'Правильный ответ неправильный',
  ProblemResources_MistakeKind3: 'Появилось окно ошибки после подтверждения ответа',
  ProblemResources_MistakeKind2: 'Нет правильного ответа',
  ProblemResources_MistakeKind4: 'Другая ошибка',
  ProblemResources_RatingAfterAnswer: 'Рейтинг после ответа',
  ProblemResources_ExampleLink: 'Показать пример',
  ProblemResources_CloseDraft: 'Закрыть черновик',
  ProblemResources_CorrectAnswer: 'Ответ верный !',
  ProblemResources_MistakeSendingExplanation: 'Нажимая кнопку ниже, Вы отправляете запрос нашей команде на рассмотрение ошибки. Мы обещаем рассмотреть ваш запрос в течении 24 часов и дать Вам ответ. Спасибо, что делаете нас лучше.',
  ProblemResources_RatingAfterGeneration: 'Рейтинг после генерации задачи',
  ProblemResources_TestAssign: 'Тест задан',
  ProblemResources_DecisionTextForMistake: 'Текст решения',
  ProblemResources_ConfirmButton: 'Подтвердить',
  ProblemResources_KindOfMistake: 'Выберите тип ошибки',
  ProblemResources_NextLevel: 'Следующий уровень',
  ProblemResources_WrongAnswer: 'Ответ неверный !',
  ProblemResources_SolvingOfTheProblem: 'Решение задачи',
  ProblemResources_PrevLevel: 'Предыдуший уровень',
  ProblemResources_CloseMistakeButton: 'Закрыть и отправить решение',
  ProblemResources_InitialRating: 'Начальный рейтинг задачи',
  ProblemResources_RightAnswerWithoutRounding: 'Правильный ответ без округления',
  ProblemResources_KindOfMistakeWord: 'Вид ошибки',
  ProblemResources_TaskExample: 'Пример решения подобной задачи',
  ProblemResources_MistakeDetailsWord: 'Детали ошибки',
  ProblemResources_HomeworkAssign: 'Домашняя работа задана',
  ProblemResources_DetailsOfMistake: 'Несколько слов об ошибке',
  ProblemResources_Trainings: 'Тренажеры',
  ProblemResources_CloseMistakeEmailTopic: 'Ошибка в задаче рассмотрена',
  ProblemResources_CheckingAnswer: 'Проверяем Ваш ответ...',
  ProfileResources_DataWasSaved: 'Данные сохранены',
  RequestSent: 'Запрос успешно отправлен',
  MistakeHasBeenSent: 'Ошибка в задаче была успешно отправлена',
  StudentsInGroupWereChanged: 'Ученики в группе были успешно изменены',
  ProfileResources_Title: 'Профиль',
  ProfileResources_Surname: 'Фамилия',
  ProfileResources_ChangeAvatarLink: 'Сменить фото профиля',
  ProfileResources_ProfileInfoError: 'Ошибка при сохранении Ваших данных. Убедитесь в правильности вводимых данных',
  ProfileResources_PictureNotMoreThan: 'Не больше 4МБ',
  ProfileResources_School: 'Школа',
  ProfileResources_SaveChanges: 'Сохранить',
  ProfileResources_InvalidMessage: 'Поле заполнено с ошибками',
  ProfileResources_Email: 'Имейл',
  ProfileResources_Close: 'Закрыть',
  ProfileResources_Login: 'Логин',
  ProfileResources_Phone: 'Телефон',
  ProfileResources_LastName: 'Отчество',
  ProfileResources_EmailParents: 'Email родителей',
  ProfileResources_PictureIsTooBig: 'Картинка слишком большая. Пожалуйста выберите картинку не больше 4МБ',
  ProfileResources_AvatarError: 'Ошибка при загрузке фала. Пожалуйста попробуйте не использовать мобильные устройства или попробуйте загрузить другой файл',
  ProfileResources_ChooseFile: 'Выбрать файл',
  ProfileResources_DtBirthday: 'Дата рождения',
  ProfileResources_City: 'Город',
  ProfileResources_Name: 'Имя',
  RegistrationResources_RegistrConfirmation_Link: 'нажмите на ссылку чтобы войти',
  RegistrationResources_Student: 'Ученик',
  RegistrationResources_Error_Login_Exists: 'Такой логин уже зарегистрирован. Попробуйте другой',
  RegistrationResources_ConfirmPasswordLabel: 'Подтверждение пароля',
  RegistrationResources_SubmitButton: 'Регистрация',
  RegistrationResources_ErrorRegistration: 'Произошла ошибка, попробуйте еще раз',
  RegistrationResources_LoginLabel: 'Логин',
  RegistrationResources_Header: 'Регистрация',
  RegistrationResources_Teacher: 'Учитель',
  RegistrationResources_Error_ConfirmPassword_Match: 'Пароли не совпадают',
  RegistrationResources_PasswordLabel: 'Пароль',
  RegistrationResources_Error_Password_Regex: 'Пароль должен содержать как минимум одну заглавную букву, маленькую букву и цифру',
  RegistrationResources_Error_Login_Min: 'Поле не должно быть меньше 2 знаков',
  RegistrationResources_Error_Empty: 'Поле не может быть пустым',
  RegistrationResources_Iam: 'Я',
  RegistrationResources_Reset: 'Изменить пароль',
  RegistrationResources_Error_Password_Min: 'Поле не должно быть меньше 8 знаков',
  RegistrationResources_Error_Email_Exists: 'Такой имейл уже зарегистрирован.',
  RegistrationResources_RegistrConfirmation: 'Спасибо за регистрацию. Пожалуйста',
  RegistrationResources_Error_Email_LogInAs: 'Войти как',
  RegistrationResources_Error_Email_Invalid: 'Введите правильный email',
  Settings: 'Настройки',
  TestResources_RegenerateTest: 'Перегенерировать  тесты',
  TestResources_TakeNumber: 'Сдача',
  TestResources_RetakeTest: 'Пересдать тест',
  TestResources_RightAnswerText: 'Правильный ответ :',
  TestResources_Closed: 'Закрыт',
  TestResources_RegenerateStudentTest: 'Перегенерировать  задачи',
  TestResources_IsSent: 'Отправлено ученикам',
  TestResources_TestInQueueText: 'В очереди на генерирование',
  TestResources_AnswerRecieved: 'Ответ принят',
  TestResources_GenerateTest: 'Сгенерировать тесты',
  TestResources_ProblemNotGenerated: 'Задача не сгенерирована',
  TestResources_BackToTest: 'Назад к тесту',
  TestResources_Solved: 'Решенные',
  TestResources_AnswerNotReceived: 'Ответ не получен. Пожалуйста, попробуйте еще раз. Ваш учитель будет проинформирован об этом.',
  TestResources_ConfirmRetake: 'Вы уверены, что хотите пересдать тест?',
  TestResources_ConfirmBegin: 'Вы уверены, что хотите начать тест?',
  TestResources_Filter_ByName: 'По имени',
  TestResources_Filters_ToSend: 'К отправке',
  TestResources_RefreshTestAfterGeneration: 'Обновить страницу',
  TestResources_Creating: 'Тест создается',
  TestResources_GenerateStudentTest: 'Сгенерировать задачи',
  TestResources_ConfirmFinish: 'Вы уверены, что хотите завершить тест?',
  TestResources_Filter_Score: 'Лучший результат',
  TestResources_Filter_BestTake: 'Лучшая сдача',
  TestResources_New: 'Новые',
  TestResources_DueDate: 'Выполнить до',
  TestResources_ContinueSolving: 'Продолжить решать',
  TestResources_Filters_StartingWithLast: 'От последних',
  TestResources_GenerateTestTooltip: 'Генерирование задач может занят несколько минут',
  TestResources_CreatedOn: 'Дата создания',
  TestResources_TestIsSolving: 'Решается',
  TestResources_BackToTests: 'Назад к тестам',
  TestResources_BeginTest: 'Начать тест',
  TestResources_SendToStudents: 'Отправить ученикам',
  TestResources_Filter_LastTake: 'Последняя сдача',
  TestResources_Generating: 'Тест генерируется',
  TestAnswerWasAcceptedSnackBar: 'Ответ на задачу получен',
  TestAnswerWasNotAcceptedSnackBar: 'Ошибка при потверждении ответа',
  ConfirmAsnwer: 'Подтвердить ответ',
  ChooseSubject: 'Выбрать предмет',
  GoToTopic: 'Перейти к теме',
  TopicResources_0: 'Все теми',
  TopicResources_5: 'Теория вероятностей',
  TopicResources_4: 'Комбинаторика',
  TopicResources_7: 'Разные задачи повышенной сложности',
  TopicResources_6: 'Экономика и финансы',
  TopicResources_1: 'Алгебра',
  TopicResources_3: 'Геометрия',
  TopicResources_2: 'Тригонометрия',
  TopicResources_63: 'Сложные проценты',
  TopicResources_62: 'Простые проценты',
  TopicResources_61: 'Понятие процента. Простейшие задачи на проценты',
  TopicResources_67: 'Спрос и предложения. Эластичность',
  TopicResources_66: 'Налоги',
  TopicResources_65: 'Денежные потоки',
  TopicResources_64: 'Инфляция',
  TopicResources_68: 'Разные задачи экономического содержания',
  TopicResources_14: 'Последовательности и пределы',
  TopicResources_15: 'Начала анализа',
  TopicResources_16: 'Задачи с параметрами',
  TopicResources_11: 'Тождественные преобразования алгебраических выражений',
  TopicResources_12: 'Алгебраические уравнения и неравенства',
  TopicResources_13: 'Функции',
  TopicResources_24: 'Аркусы',
  TopicResources_23: 'Тригонометрические неравенства',
  TopicResources_22: 'Тригонометрические уравнения',
  TopicResources_21: 'Тригонометрические преобразования',
  TopicResources_32: 'Стереометрия',
  TopicResources_31: 'Планиметрия',
  TopicResources_141: 'Произвольные последовательности',
  TopicResources_142: 'Прогрессии',
  TopicResources_143: 'Пределы',
  TopicResources_151: 'Производная',
  TopicResources_153: 'Интеграл',
  TopicResources_152: 'Экстремумы, наибольшее и наименьшее значения функции',
  TopicResources_126: 'Неравенства',
  TopicResources_127: 'Уравнения и неравенства смешанного типа. Вычисление площадей.',
  TopicResources_124: 'Системы уравнений',
  TopicResources_125: 'Текстовые задачи',
  TopicResources_122: 'Иррациональные уравнения',
  TopicResources_123: 'Уравнения с модулями',
  TopicResources_121: 'Рациональные уравнения',
  TopicResources_137: 'Функции с модулем',
  TopicResources_136: 'Сложная функция',
  TopicResources_135: 'Обратная функция',
  TopicResources_134: 'Периодичность функции',
  TopicResources_133: 'Четность и нечетность функции',
  TopicResources_132: 'Элементарные функции',
  TopicResources_131: 'Область определения и множество значений функции',
  TopicResources_139: 'Логарифмическая и показательная функции',
  TopicResources_138: 'Преобразования графиков функций',
  TopicResources_324: 'Угол между двумя плоскостями',
  TopicResources_325: 'Расстояние от точки до прямой',
  TopicResources_326: 'Расстояние от точки до плоскости',
  TopicResources_327: 'Расстояние между двумя прямыми',
  TopicResources_321: 'Уравнение плоскости',
  TopicResources_322: 'Угол между прямыми',
  TopicResources_323: 'Угол между прямой и плоскостью',
  TopicResources_328: 'Вычисление площадей поверхностей и объемов геометрических тел',
  TopicResources_329: 'Векторная алгебра и координатный метод в пространстве',
  TopicResources_315: 'Векторная алгебра и координатный метод на плоскости',
  TopicResources_314: 'Окружность, сектор, сегмент. Углы, связанные с окружностью',
  TopicResources_313: 'Многоугольник',
  TopicResources_312: 'Четырехугольник',
  TopicResources_311: 'Треугольник',
  TopicResources_1422: 'Геометрическая прогрессия',
  TopicResources_1423: 'Смешанные задачи на прогрессию',
  TopicResources_1421: 'Арифметическая прогрессия',
  TopicResources_1261: 'Линейные и квадратные неравенства',
  TopicResources_1262: 'Рациональные неравенства с простыми корнями',
  TopicResources_1263: 'Рациональные неравенства с кратными корнями',
  TopicResources_1264: 'Дробно-рациональные неравенства',
  TopicResources_1265: 'Системы рациональных неравенств',
  TopicResources_1266: 'Иррациональные неравенства',
  TopicResources_1267: 'Неравенства с модулями',
  TopicResources_1253: 'Смеси',
  TopicResources_1252: 'Работа',
  TopicResources_1251: 'Движение',
  TopicResources_1256: 'Разные задачи',
  TopicResources_1255: 'Проценты',
  TopicResources_1254: 'Числа',
  TopicResources_1242: 'Системы Виетовского типа',
  TopicResources_1243: 'Системы иррациональных уравнений',
  TopicResources_1241: 'Системы линейных уравнений',
  TopicResources_1244: 'Системы нелинейных уравнений',
  TopicResources_1214: 'Дробно-рациональные уравнения',
  TopicResources_1213: 'Целые рациональные уравнения. Метод замены',
  TopicResources_1212: 'Целые рациональные уравнения. Разложение на множители',
  TopicResources_1211: 'Линейные и квадратные уравнения',
  TopicResources_1392: 'Логарифмические и показательные уравнения',
  TopicResources_1393: 'Логарифмические и показательные неравенства',
  TopicResources_1391: 'Свойства логарифмической и показательной функций',
  WhiteBoardResources_Unnamedboard: 'Без названия',
  Finish: 'Закончить',
  TestWillBeClosedIn5Seconds: 'Тест будет автоматически закончен через 5 секунд',
  ScoreForProblem: 'Баллы за задачу',
  OutOf: 'из',
  NewHomeworkReceived: 'Вам было задано новое домашнее задание',
  NewTestReceived: 'Вам был задан новый тест',
  RemoveTeacher: 'Убрать из преподавателей',
  AddNewTeacher: 'Добавить нового преподавателя',
  TeacherBoards: 'Доски моих преподавателей',
  ChooseStudentsForGroup: 'Выберите учеников для группы'
};
export default ru;