import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from 'src/utils/SubmitButton';
import translate from 'src/helpers/translate';
import { DataGrid } from '@mui/x-data-grid';


export default function ChooseEntitiesForWorkDialog(props) {
    
    const { open, onCancel, alreadyAdded, all, onSubmit, title, nameOfEntities } = props;
    const [ selected, setSelected ] = useState([]);
    
    useEffect(() => {
        if(alreadyAdded){
            setSelected(alreadyAdded.map(s => s.id));
        }
    },[alreadyAdded])
    
    const handleClose = () => {
        onCancel();
    };
    
    const submit = () => {
        if(selected.length !== alreadyAdded.length
            || selected.some(s => !alreadyAdded.map(s => s.id).includes(s))){
                onSubmit(selected);
            }
        onCancel();
    };
    
    const handleSelect = (event) => {
        setSelected(event.selectionModel ?? event);
    };
    
    const columns = [
        { field: 'id', hide: true },
        { 
            field: 'wholeName',
            headerName: nameOfEntities,  
            flex: 1,
            valueGetter: (params) => {
                return params.row['wholeName'] ?? params.row['name']
            } 
        }
    ];

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <div style={{ height: 400, minWidth: 400 }}>
                        <DataGrid 
                            rows={all} 
                            selectionModel={selected}
                            density="compact"
                            onSelectionModelChange={handleSelect}
                            columns={columns} 
                            pageSize={10} 
                            checkboxSelection 
                            disableColumnMenu 
                            hideFooterSelectedRowCount />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        disabled={false}
                        color="primary">
                        {translate('Cancel')}
                    </Button>
                    <SubmitButton
                        isSubmitting={false} 
                        canBeSubmitted={true}
                        onSubmit={submit}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}