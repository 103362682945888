import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';


const useStyles = makeStyles((theme) => ({
    secondaryTail: {
        backgroundColor: theme.palette.primary.main,
    },
}));


export default function RatingTimelineDot({ rating }) {
    const classes = useStyles();
    return (
        <React.Fragment>
            {rating < 3 && (
                <React.Fragment>
                    <TimelineDot style={{ width: 35, display: "flex", justifyContent: "center" }}>
                        <Typography>{rating}</Typography>
                    </TimelineDot>
                    <TimelineConnector />
                </React.Fragment>
            )}
            {rating > 2 && (
                <React.Fragment>
                    <TimelineDot color="primary" style={{ width: 35, display: "flex", justifyContent: "center" }}>
                        <Typography>{rating}</Typography>
                    </TimelineDot>
                    <TimelineConnector className={classes.secondaryTail}/>
                </React.Fragment>
            )}

        </React.Fragment>
    );
}