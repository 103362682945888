import React from 'react';
/* global JXG */

export default function Graph84(props) {

    let {a, b, c} = props.data;

    props.board.create('functiongraph',[function(x){return JXG.Math.pow(a*x*x + b*x + c,1/2)}],{strokeColor:'blue',highlightStrokeColor:'blue'});
    
    return <></>
}