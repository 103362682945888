import * as apiCaller from 'src/helpers/apiCaller';
import { actionCreators as layoutActionCreators } from '../layout';
import translate from 'src/helpers/translate';
const eduVideosReceivedType = "EDU_VIDEOS_RECEIVED";
const eduVideoWasDeletedType = "VIDEO_DELETED";
const eduVideoWasAddedType = "VIDEO_ADDED";

function requestEduVideos() {
    return async dispatch => {
        await apiCaller.get('/eduVideo/get', true).then(
            (eduVideos) => {
                dispatch(success(eduVideos));
            }
        );
        function success(eduVideos) {
            return { type: eduVideosReceivedType, eduVideos };
        }
    };
}

function deleteEduVideo(id) {
    return async dispatch => {
        await apiCaller.post('/eduVideo/remove',{id}, true).then(
            () => {
                dispatch(success(id));
                dispatch(layoutActionCreators.showSuccessAlert(translate("VideoWasRemoved")));
            }
        );
        function success(id) {
            return { type: eduVideoWasDeletedType, id };
        }
    };
}

function addEduVideo(name, link, topicId, problemId) {
    return async dispatch => {
        var body = {
            name,
            url: link, 
            topicId, 
            problemId
        }
        await apiCaller.post('/eduVideo/create', body, true).then(
            (eduVideo) => {
                dispatch(success(eduVideo));
                dispatch(layoutActionCreators.showSuccessAlert(translate("VideoWasAdded")));
            }
        );
        function success(eduVideo) {
            return { type: eduVideoWasAddedType, eduVideo };
        }
    };
}


export const actionCreators = {
    requestEduVideos,
    addEduVideo,
    deleteEduVideo
}

const eduVideos = (state = [], action) => {
    if (action.type === eduVideosReceivedType) {
        return action.eduVideos;
    }

    if (action.type === eduVideoWasDeletedType){
        return [...state.filter(video => video.id !== action.id)];
    }

    if(action.type === eduVideoWasAddedType){
        return [action.eduVideo, ...state];
    }

    return state;
};

export default eduVideos