import React from 'react';
import { useSelector } from 'react-redux';
import RatingTimelineDotCommon from '../../common/Problems/RatingTimelineDot'; 

export default function RatingTimelineDot({ problem }) {

    const clientProblem = useSelector(state => state.common.clientProblems.find(cp => cp.problemId === problem.id));

    const rating = clientProblem ? clientProblem.rating : 0;

    return (<RatingTimelineDotCommon rating={rating} />);
}