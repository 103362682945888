import React from 'react';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import {
    CheckSquare
} from 'react-feather';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    secondaryTail: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function LevelResultTimelineDot({ problemsForLevel, isLastOne, clientProblems }) {
    const classes = useStyles();
    let isDone = false;
    if(clientProblems && clientProblems.length > 0 && problemsForLevel.length <= clientProblems.length){
        isDone = !clientProblems.some(cp => cp.rating < 3);
    }
    return (
        <React.Fragment>
            {!isDone && (
                <React.Fragment>
                    <TimelineDot style={{ width: 35, display: "flex", justifyContent: "center" }}>
                        <CheckSquare />
                    </TimelineDot>
                    {!isLastOne && <TimelineConnector />}
                </React.Fragment>
            )}
            {isDone && (
                <React.Fragment>
                    <TimelineDot color="primary" style={{ width: 35, display: "flex", justifyContent: "center" }}>
                        <CheckSquare />
                    </TimelineDot>
                    {!isLastOne && <TimelineConnector className={classes.secondaryTail}/>}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}