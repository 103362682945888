import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, CardHeader } from '@mui/material';
import translate from 'src/helpers/translate';
import { actionCreators } from 'src/reducers/student/teachers';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from 'src/components/common/ConfirmationDialog';

export default function TeacherCard({ teacher }) {
    const dispatch = useDispatch();
    const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = React.useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] = React.useState('');

    const removeFromStudentsWithConfirm = () => {
        setConfirmationDialogMessage(translate('Delete').toLowerCase() + ' ' + teacher.wholeName + translate('GlobalResources_RemoveFromTeachers'));
        setConfirmationDialogIsOpen(true);
    }

    return (
        <Card sx={{ height: 120, maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar
                        src={`data:image/jpg;base64, ${teacher.avatarImage}`}
                    />
                }
                title={teacher.wholeName}
                subheader={teacher.lastStatusChangeDate}
            />
            <CardActions>
                {teacher.relationStatus == 1 && (
                    <React.Fragment>
                        <Button onClick={removeFromStudentsWithConfirm} color="error" size="small">{translate('RemoveTeacher')}</Button>
                    </React.Fragment>
                )}
                {teacher.relationStatus == 2 && (
                    <React.Fragment>
                        <Button onClick={() => dispatch(actionCreators.acceptFriendRequest(teacher))} color="secondary" size="small">{translate('Accept')}</Button>
                        <Button onClick={() => dispatch(actionCreators.rejectFriendRequest(teacher))} color="error" size="small">{translate('CancelRequest')}</Button>
                    </React.Fragment>
                )}
                {teacher.relationStatus == 3 && (
                    <React.Fragment>
                        <Button onClick={() => dispatch(actionCreators.rejectFriendRequest(teacher))} color="error" size="small">{translate('CancelRequest')}</Button>
                    </React.Fragment>
                )}
            </CardActions>
            <ConfirmationDialog 
                open={confirmationDialogIsOpen}
                onCancel={() => setConfirmationDialogIsOpen(false)} 
                onSubmit={() => dispatch(actionCreators.removeConnection(teacher))}
                message={confirmationDialogMessage}
                />
        </Card>
    );
}
