import * as React from 'react';
import { useSelector } from 'react-redux';
import translate from 'src/helpers/translate';
import { DataGrid } from '@mui/x-data-grid';

export default function TestSolvingHistory({ test, data }) {
    const takes = [...new Set(data.map(item => item.take))].sort((a, b) => b - a);
    const problems = useSelector(state => state.common.problems);

    const calculateScore = (prev, current) => {
        if (!current.generatedProblem?.answeredCorrectly) {
            return prev;
        }
        let problem = problems.find(p => p.id === current.problemId);
        if (problem.level == 1) {
            return prev + test.scoreA;
        }
        if (problem.level == 2) {
            return prev + test.scoreB;
        }
        if (problem.level == 3) {
            return prev + test.scoreC;
        }
    }

    const takesWithScore = takes.map(t => {
        return {
            id: t, take: t, mark: data.filter(tp => tp.take == t).reduce((prev, current) => calculateScore(prev, current), 0)
        }
    })

    console.log(takesWithScore);

    const columns = [
        { field: 'id', hide: true },
        { 
            field: 'take', 
            flex: 1, 
            headerName: translate('TestResources_TakeNumber') 
        },
        {
            field: 'mark',
            flex: 1,
            headerName: translate('Mark')
        }
    ];

    return (
        <React.Fragment>
            {takesWithScore.length > 0 && (
                <DataGrid
                    rows={takesWithScore}
                    density="compact"
                    columns={columns}
                    pageSize={10}
                    autoHeight={true}
                    disableColumnMenu
                    hideFooter={takesWithScore.length <= 10}
                    hideFooterSelectedRowCount />
            )}
        </React.Fragment>
    );
}
