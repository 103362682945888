import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from 'src/utils/SubmitButton';
import translate from 'src/helpers/translate';
import { Grid, TextField } from '@mui/material';
import { actionCreators } from 'src/reducers/teacher';

export default function ChangeTestDialog(props) {
    const { onCancel, open, test } = props;

    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [takes, setTakes] = useState(0);
    const [time, setTime] = useState(0);
    const [scoreA, setScoreA] = useState(0);
    const [scoreB, setScoreB] = useState(0);
    const [scoreC, setScoreC] = useState(0);
    const canBeSubmitted = name != '' && time > 0 && scoreA > 0 && scoreB > 0 && scoreC > 0 && takes >= 0;

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onTakesChange = (event) => {
        setTakes(event.target.value);
    }

    const onTimeChange = (event) => {
        setTime(event.target.value);
    }

    const onScoreAChange = (event) => {
        setScoreA(event.target.value);
    }

    const onScoreBChange = (event) => {
        setScoreB(event.target.value);
    }

    const onScoreCChange = (event) => {
        setScoreC(event.target.value);
    }

    const submit = () => {
        const data = {
            id: test.id,
            description: name,
            time,
            takes,
            scoreA,
            scoreB,
            scoreC
        };
        dispatch(actionCreators.updateTest(data));
        onCancel();
    }

    useEffect(() => {
        setName(test.description);
        setTakes(test.takes);
        setTime(test.testTime);
        setScoreA(test.scoreA);
        setScoreB(test.scoreB);
        setScoreC(test.scoreC);
    }, [test])

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{translate("TestEdition")}</DialogTitle>
            <DialogContent>
                <Grid item sm={12} xs={12}>
                    <TextField
                        autoFocus
                        size="small"
                        id="name"
                        type="string"
                        helperText={translate("Description")}
                        value={name}
                        error={!name}
                        onChange={onNameChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        size="small"
                        id="time"
                        type="number"
                        helperText={translate("CreateWorkResources_TimeWholeTest")}
                        value={time}
                        error={!time || time <= 0}
                        inputProps={{min:1}}
                        onChange={onTimeChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        size="small"
                        id="takes"
                        type="number"
                        helperText={translate("CreateWorkResources_RetakeAmount")}
                        value={takes}
                        error={takes < 1}
                        inputProps={{min:1}}
                        onChange={onTakesChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        size="small"
                        helperText={translate("ChooseScoreForLevel", translate("Level1"))}
                        id="scoreA"
                        type="number"
                        value={scoreA}
                        error={!scoreA || scoreA < 1}
                        inputProps={{min:1}}
                        onChange={onScoreAChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        size="small"
                        id="scoreB"
                        type="number"
                        inputProps={{min:1}}
                        error={!scoreB || scoreB < 1}
                        helperText={translate("ChooseScoreForLevel", translate("Level2"))}
                        value={scoreB}
                        onChange={onScoreBChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        size="small"
                        id="scoreC"
                        type="number"
                        inputProps={{min:1}}
                        error={!scoreC || scoreC < 1}
                        helperText={translate("ChooseScoreForLevel", translate("Level3"))}
                        value={scoreC}
                        onChange={onScoreCChange}
                        fullWidth
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    color="primary">
                    {translate('Cancel')}
                </Button>
                <SubmitButton
                    isSubmitting={false}
                    canBeSubmitted={canBeSubmitted}
                    text={translate('Save')}
                    onSubmit={submit}
                />
            </DialogActions>
        </Dialog>
    )
}