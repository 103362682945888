import React from 'react';

export default function Graph456(props) {

    let {a, b, c, d, e, dynamicFunction} = props.data;
    
    props.board.create('functiongraph',[function(x){return a}],{strokeColor:'green',highlightStrokeColor:'green'});
    props.board.create('functiongraph',[function(x){return eval(dynamicFunction)}],{strokeColor:'blue',highlightStrokeColor:'blue'});
    var p1 = props.board.create('point',[b,c], {size:2, fixed: true, name:'', visible:false});
    var p2 = props.board.create('point',[d,e], {size:2, fixed: true, name:'', visible:false});

    var lineParams = {
        strokeColor:'green',
        highlightStrokeColor:'green',
        straightFirst:false, 
        straightLast:false, 
        strokeWidth:2, 
        fixed:true
    }

    props.board.create('line',[p1,p2], lineParams);
    
    return <></>
}