import React from 'react';
import {  useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import TestSolvingTimer from './TestSolvingTimer';

export default function TestSolvingRemainingTimeBar({ testId }) {
    const testTime = useSelector(state => state.student.tests.list.find(t => t.id == testId).time);
    const remainingSolvingTime = useSelector(state => state.student.tests.list.find(t => t.id == testId).remainingSolvingTime);
    const showResult = useSelector(state => state.student.tests.list.find(t => t.id == testId).showResult);
    const isSolving = useSelector(state => state.student.tests.list.find(t => t.id == testId).isSolving);

    return (
        <Box>
            {isSolving && !showResult &&
                    <TestSolvingTimer testId={testId} testTimeSeconds={testTime * 60} remainingSolvingTimeSeconds={remainingSolvingTime} />
                }
        </Box>
    );
}