import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/reducers/teacher';
import {
  Box,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import TestsGrid from './TestsGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TestsListView = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const tests = useSelector(state => state.teacher.tests);

  useEffect(() => {
    dispatch(actionCreators.requestTests());
  }, [dispatch])

  return (
    <Page
      className={classes.root}
      title="Tests"
    >
      <Container maxWidth="lg">
        <Box mt={3}>
          <TestsGrid tests={tests}/>
        </Box>
      </Container>
    </Page>
  );
};

export default TestsListView;
