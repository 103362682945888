import React, { useEffect, useRef } from 'react'
import { MathfieldElement } from 'mathlive';
// import './MathLiveComponent.css';


const ProblemLatexText = ({ text }) => {  
  const mathFieldRef = useRef(null);

  useEffect(() => {
    // Create a MathfieldElement instance
    const mathField = new MathfieldElement();

    // Set the LaTeX value
    mathField.value = text;
    mathField.readOnly = true; // Make the field read-only
    mathField.style.setProperty('border', 'none');

    // Append the MathfieldElement to the ref container
    if (mathFieldRef.current) {
      mathFieldRef.current.appendChild(mathField);
    }
  }, []);

    return <div ref={mathFieldRef}></div>;
  };
  
  export default ProblemLatexText;