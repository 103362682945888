import React, { useEffect, useState } from 'react';
import * as apiCaller from 'src/helpers/apiCaller';
import { Box, Card, CardContent, Tooltip, Button, CardActions, ButtonGroup, Grid, Container, Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import translate from 'src/helpers/translate';
import ProblemBodyInTest from './ProblemBodyInTest';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    cardActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '600px',
        width: '100%',
        paddingBottom: theme.spacing(3),
        marginTop: 10
    }
}));

const ProblemInTest = ({ testProblem, showResult }) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [whiteBoardOpened, setWhiteBoardOpened] = useState(false);
    const problem = useSelector(state => state.common.problems.find(p => p.id == testProblem.problemId));
    const test = useSelector(state => state.student.tests.list.find(p => p.id == testProblem.testId));
    const scoreForProblem = problem.level == 1 ? test.scoreA : problem.level == 2 ? test.scoreB : problem.level == 3 ? test.scoreC : 1;
    return (
        <Container  maxWidth={whiteBoardOpened ? "lg" : "md"}>
            <Box sx={{marginTop:-2}} mt={whiteBoardOpened ? 5 : 8}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <React.Fragment>
                            <Box sx={{marginBottom:'-2px'}} component="fieldset" mb={3} borderColor="transparent">
                                <Grid container>
                                    <Grid item sm={5}>
                                        <Typography>{translate(`Level${problem.level}`) + `; ${translate('ScoreForProblem')}: ${scoreForProblem}`}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Card>
                                <CardContent>
                                    {testProblem && <ProblemBodyInTest 
                                        testProblem={testProblem} 
                                        showResult={showResult} 
                                        whiteBoardOpened={whiteBoardOpened}
                                        onWhiteBoardToggle={(open) => setWhiteBoardOpened(open)}/>}
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Box>
            {whiteBoardOpened && (
                <Box mt={5} className={classes.root} id="problemContainer"></Box>
            )}
            
        </Container>
    );
};

export default ProblemInTest;
