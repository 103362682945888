import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { actionCreators } from 'src/reducers/teacher';
import { Add as AddIcon, Done, DoneAll, Mood } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Avatar, Card, CardContent, Grid, Button, Switch, FormControlLabel, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddClientDialog from 'src/views/common/ClientSearch/AddClientDialog';
import ConfirmationDialog from 'src/components/common/ConfirmationDialog';
import translate from 'src/helpers/translate';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -35
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    },
    createNewButton: {
        marginRight: 5
    },
    filterByGroupButton: {
        marginBottom: 10,
    },
    cancelButton:{
        color: red[500],
        borderColor: red[500]
    },
    newRequestButton:{
        backgroundColor: green[200]
    },
    addNewButton: {
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    }
}));

export default function StudentsGrid({ props }) {
    const dispatch = useDispatch();
    const students = useSelector(state => state.teacher.students);
    const requestedStudents = useSelector(state => state.teacher.requestedStudents);
    const studentsAwaitingConfirmation = useSelector(state => state.teacher.studentsAwaitingConfirmation);
    const studentGroups = useSelector(state => state.teacher.studentGroups);
    const workState = useSelector(state => state.teacher.workState);
    const studentsAreUpdating = useSelector(state => state.teacher.studentsAreUpdating);
    const currentGroup = useSelector(state => state.teacher.groups.find(g => g.isCurrent));

    const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');
    const [studentId, setStudentId] = useState('');

    let studentsToRender = [...studentsAwaitingConfirmation, ...requestedStudents, ...students]
        .map(s => {
            return {
                ...s,
                actions: s.relationStatus
            }
        });

    const classes = useStyles();
    const [showForCurrentGroup, setShowForCurrentGroup] = useState(false);
    const [addStudentDialogIsOpen, setAddStudentDialogIsOpen] = useState(false);

    if (showForCurrentGroup && currentGroup && workState.isGroup) {
        let studentInGroupIds = studentGroups.filter(sg => sg.groupId === currentGroup.id).map(sg => sg.studentId);
        studentsToRender = studentsToRender.filter(s => studentInGroupIds.includes(s.id));
    }

    const cancelRequestFromStudent = (id) => {
        dispatch(actionCreators.cancelRequestFromStudent(id));
    }

    const cancelRequestToStudent = (id) => {
        dispatch(actionCreators.cancelRequestToStudent(id));
    }

    const removeFromStudentsWithConfirm = (params) => {
        setStudentId(params.id);
        setConfirmationDialogMessage(translate('RemoveStudent', params.row.wholeName));
        setConfirmationDialogIsOpen(true);
    }

    const acceptRequestFromStudent = (id) => {
        dispatch(actionCreators.acceptRequestFromStudent(id));
    }

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'avatarImage',
            width: 50,
            align: "center",
            renderHeader: (params) => (
                <span></span>
            ),
            renderCell: (params) => (
                <Avatar
                    src={`data:image/jpg;base64, ${params.value}`}
                    className={classes.small}
                />
            )
        },
        {
            field: 'wholeName',
            flex: 2,
            headerName: translate('Students'),
        }, {
            field: 'relationStatus',
            flex: 1,
            align: "center",
            renderHeader: (params) => (
                <span>{translate('Status')}</span>
            ),
            renderCell: (params) => (
                <div>
                    {params.value === 1 && (<Chip  color="secondary" size="small" icon={<DoneAll />} label={translate("Added")} />)}
                    {params.value === 2 && (<Chip  color="primary" size="small" icon={<Done />} label={translate("Requested")} />)}
                    {params.value === 3 && (<Chip  className={classes.newRequestButton} size="small" icon={<Mood />} label={translate("NewRequest")} />)}
                </div>
            )
        }, {
            field: 'actions',
            flex: 1,
            align: "center",
            renderHeader: (params) => (
                <span>{translate('Actions')}</span>
            ),
            renderCell: (params) => (
                <div>
                    {params.value === 1 && (
                        <Chip 
                            className={classes.cancelButton} 
                            variant="standard"
                            size="small" 
                            onDelete={() => removeFromStudentsWithConfirm(params)} 
                            label={translate("RemoveFromStudents")} />
                        )}
                    {params.value === 2 && (
                        <Chip 
                            variant="standard" 
                            className={classes.cancelButton}
                            size="small" 
                            onDelete={() => cancelRequestToStudent(params.id)}
                            label={translate("CancelRequest")} 
                        />)}
                    {params.value === 3 && (
                        <Chip
                            size="small"
                            label={translate("Accept")}
                            clickable
                            onClick={() => acceptRequestFromStudent(params.id)}
                            color="secondary"
                            icon={<DoneAll />}
                        />)}
                    {params.value === 3 && ( <label> </label> )}
                    {params.value === 3 && (
                        <Chip className={classes.cancelButton}
                            size="small"
                            variant="standard" 
                            onDelete={() => cancelRequestFromStudent(params.id)}
                            label={translate("CancelRequest")}
                        />)}
                </div>
            )
        },
    ];




    return (
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={3} sm={3} md={2}>
                            <Button
                                className={classes.addNewButton}
                                variant="contained"
                                color="primary"
                                onClick={() => setAddStudentDialogIsOpen(true)}>
                                <AddIcon className={classes.createNewButton} />
                                {translate('CreateWorkResources_AddNewGroupStudent')}
                            </Button>
                        </Grid>
                        <Grid item xs={4} sm={5} md={7} />
                        <Grid item xs={5} sm={4} md={3} >
                            {currentGroup && workState.isGroup && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showForCurrentGroup}
                                            onChange={() => setShowForCurrentGroup(!showForCurrentGroup)}
                                            color="primary"
                                        />
                                    }
                                    label={currentGroup.name}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <DataGrid
                        loading={studentsAreUpdating}
                        rows={studentsToRender}
                        density="compact"
                        columns={columns}
                        pageSize={15}
                        autoHeight={true}
                        disableColumnMenu
                        hideFooterSelectedRowCount />
                </CardContent>
            </Card>
            {addStudentDialogIsOpen && 
                <AddClientDialog 
                open={addStudentDialogIsOpen} 
                setOpen={setAddStudentDialogIsOpen} 
                myClients={[students, requestedStudents, studentsAwaitingConfirmation]}
                sendFriendRequest={(clientId) => dispatch(actionCreators.sendFriendRequestToStudent(clientId))}
                />
            }
            <ConfirmationDialog 
                open={confirmationDialogIsOpen}
                onCancel={() => setConfirmationDialogIsOpen(false)} 
                onSubmit={() => dispatch(actionCreators.removeFromStudents(studentId))}
                message={confirmationDialogMessage}
                />
        </div>
    );
}