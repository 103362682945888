import React, { useState } from 'react';
import { Card, CardContent, Typography, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import TestInfoDialog from './TestInfoDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -35
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    }, backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    grid: {
        cursor: 'pointer'
    },
    testHasNotTakenYet:{
        backgroundColor: theme.palette.success.light,
    }
}));

export default function TestsGrid({tests}) {
    
    const classes = useStyles();
    const [testInfoDialogOpen, setTestInfoDialogOpen] = useState(false);
    const [selectedTestId, setSelectedTestId] = useState(0);

    const handleClickOpen = () => {
        setTestInfoDialogOpen(true);
    };
    const handleClose = () => {
        setTestInfoDialogOpen(false);
    };

    const [backdropOpen, setBackdropOpen] = useState(tests.areUpdating);

    const handleCloseBackdrop = () => {
        setBackdropOpen(false);
    };

    const handleRowClick = (params) => {
        setSelectedTestId(params.row.id);
        setTestInfoDialogOpen(true);
    };
    
    const columns = [
        { field: 'id', hide: true },
        {
            field: 'name',
            flex: 2,
            headerName: translate('Description'),
            sortable: false
        },
        {
            field: 'joinedTopics',
            flex: 3,
            headerName: translate('Topics'),
            renderCell: (params) => (
                <Tooltip title={params.value} className={classes.toolTip} arrow>
                    <span>
                        {params.value}
                    </span>
                </Tooltip>
            )
        },
        {
            field: 'createdDateString',
            flex: 1,
            headerName: translate('CreatedOn'),
            valueGetter: (params) => params.row.id,
            renderCell: (params) => <React.Fragment>{params.row.createdDateString}</React.Fragment>
        },
        {
            field: 'time',
            flex: 1,
            headerName: translate('Time')
        },
        {
            field: 'retakesLeft',
            flex: 1,
            headerName: translate('RetakesLeft')
        },
        {
            field: 'mark',
            flex: 1,
            headerName: translate('Mark')
        },
        {
            field: 'maxScore',
            flex: 1,
            headerName: translate('MaxScore')
        }
    ];

    const getRowClassName = (params) => {
        if(params.row.canBegin){
            return classes.testHasNotTakenYet;
        }
    }

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Backdrop className={classes.backdrop} open={backdropOpen} onClick={handleCloseBackdrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {!tests.areUpdating && tests.list.length < 1 && (
                        <Typography>
                            {translate("NoTestsAssignedYet")}
                        </Typography>
                    )}
                    {tests.list.length > 0 && (
                        <DataGrid
                            loading={tests.areUpdating}
                            rows={tests.list}
                            density="compact"
                            columns={columns}
                            pageSize={15}
                            getRowClassName={getRowClassName}
                            autoHeight={true}
                            disableColumnMenu
                            className={classes.grid}
                            onRowClick={handleRowClick}
                            hideFooter={tests.list.length <= 15}
                            hideFooterSelectedRowCount />
                    )}
                </CardContent>
            </Card>
            {
                tests.list.find(t => t.id === selectedTestId) && (
                    <TestInfoDialog
                    isOpened={testInfoDialogOpen}
                    open={handleClickOpen}
                    close={handleClose}
                    test={tests.list.find(t => t.id == selectedTestId)}
                   />
                )
            }
        </React.Fragment>
    );
}