import * as apiCaller from 'src/helpers/apiCaller';
import { actionCreators as layoutActionCreators } from '../layout';
import translate from 'src/helpers/translate';
const trainingsReceivedType = "TRAININGS_RECEIVED";
const trainingWasDeletedType = "TRAINING_DELETED";
const trainingWasAddedType = "TRAINING_ADDED";


function requestTrainings() {
    return async dispatch => {
        await apiCaller.get('/training/get', true).then(
            (trainings) => {
                dispatch(success(trainings));
            }
        );
        function success(trainings) {
            return { type: trainingsReceivedType, trainings };
        }
    };
}

function deleteTraining(id) {
    return async dispatch => {
        await apiCaller.post('/training/remove', {id}, true).then(
            () => {
                dispatch(success(id));
                dispatch(layoutActionCreators.showSuccessAlert(translate("TrainingWasDeleted")));
            }
        );
        function success(id) {
            return { type: trainingWasDeletedType, id };
        }
    };
}


function addTraining(description, link, topicId, problemId) {
    return async dispatch => {
        var body = {
            name: description,
            url: link, 
            topicId, 
            problemId
        }
        await apiCaller.post('/training/create', body, true).then(
            (training) => {
                dispatch(success(training));
                dispatch(layoutActionCreators.showSuccessAlert(translate("TrainingWasAdded")));
            }
        );
        function success(training) {
            return { type: trainingWasAddedType, training };
        }
    };
}


export const actionCreators = {
    requestTrainings,
    deleteTraining,
    addTraining
}

const trainings = (state = [], action) => {
    if (action.type === trainingsReceivedType) {
        return action.trainings;
    }

    if (action.type === trainingWasDeletedType){
        return [...state.filter(training => training.id !== action.id)];
    }

    if(action.type === trainingWasAddedType){
        return [action.training, ...state];
    }

    return state;
};

export default trainings