
import React, { useEffect, useState } from 'react';
import * as apiCaller from 'src/helpers/apiCaller';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import Page from 'src/components/Page';
import { actionCreators } from 'src/reducers/admin/mistakes';
import { useDispatch, useSelector } from 'react-redux';
import GeneratedProblem from 'src/views/common/GeneratedProblem/GeneratedProblem';
import CloseMistake from './CloseMistake';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));


export default function MistakesView() {
    const classes = useStyles();

    const mistakes = useSelector(state => state.admin.mistakes);
    const user = useSelector(state => state.userContext.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user && user.role) {
            dispatch(actionCreators.requestMistakes());
        }
    }, [user, dispatch]);

    return (
        <Page
            className={classes.root}
            title="Mistakes"
        >
            <Container maxWidth="lg">
                <Box mt={3}>
                    {mistakes.length < 1 && (
                        <Card>
                            <CardHeader title={translate('NoMistakesInProblemsRaisedYet')} />
                        </Card>
                    )}
                    {mistakes.map(mistake => (
                        <React.Fragment key={`mistakeFor_${mistake.generatedProblemId}`}>
                            <Card style={{ marginBottom: 15 }}>
                                <CardHeader>
                                </CardHeader>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Typography>
                                                {mistake.mistakeKind}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>
                                                {mistake.clientWholeName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {mistake.details}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <GeneratedProblem 
                                        id={mistake.generatedProblemId} 
                                        problemId={mistake.problemId} 
                                        getGeneratedProblemAction={() => apiCaller.get(`/problem/getGeneratedProblem?id=${mistake.generatedProblemId}`)}
                                    />
                                </CardContent>
                                <CardActions>
                                    <CloseMistake mistake={mistake} />
                                </CardActions>
                            </Card>
                        </React.Fragment>
                    ))}
                </Box>
            </Container>
        </Page>
    );
}