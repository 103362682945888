import React from 'react';

export default function Graph650(props) {

    let {a, b, c, yMin, yMax} = props.data;

    props.board.create('functiongraph',[function(x){return a}],{strokeColor:'green',highlightStrokeColor:'green'});
    var p1 = props.board.create('point',[c,yMin], {size:2, fixed: true, name:'', visible:false});
    var p2 = props.board.create('point',[c,yMax], {size:2, fixed: true, name:'', visible:false});
    
    var lineParams = {
        strokeColor:'green',
        highlightStrokeColor:'green',
        straightFirst:false, 
        straightLast:false, 
        strokeWidth:2, 
        fixed:true
    }
    
    props.board.create('line',[p1,p2], lineParams);

    props.board.create('functiongraph',[function(x){return a + b/(x - c)}],{strokeColor:'blue',highlightStrokeColor:'blue'});
    
    return <></>
}