import * as apiCaller from "../helpers/apiCaller";

export const userService = {
  getUser,
  updateUser,
  loginCheck,
  changeAvatar
};

// function logout() {
//   localStorage.removeItem("mathlife-user");
// }

async function getUser() {
  const url = '/profile/getUser';
  return await apiCaller.get(url, true).then(user => {
    // login successful if there's a jwt token in the response
    if (user) {
      // let serializedUser = JSON.stringify(user);
      // localStorage.setItem("mathlife-user", serializedUser);
      return user;
    }

    return user;
  });
}

async function updateUser(user) {
  const url = '/profile/saveProfileNew';
  const response = await apiCaller.post(url, user, true);
  return response;
}

async function changeAvatar(avatar) {
  const url = '/profile/changeAvatar';

  let formData = new FormData();
     
  formData.append("file", avatar);

  return await apiCaller.postAsFormData(url, formData, true);
}

function loginCheck(username) {
  const url = `api/users/loginCheck`;

  return apiCaller.post(url, { login: username });
}
