import * as apiCaller from "../helpers/apiCaller";
import { getCookie, setCookie } from "../helpers/cookies";
import store from 'src/configure-store';
const requestCultureType = "CHANGE_CULTURE";

export function changeCulture(culture) {
  return async dispatch => {

    var state = store.getState();

    if(state.culture.locale === culture) return;

    if(!culture){
      let cultureFromCookie = getCookie(".AspNetCore.Culture");
      if(cultureFromCookie){
        culture = cultureFromCookie.slice(-2);
      }
    }

    apiCaller.post(`/home/setLanguageFront?culture=${culture}`, {}, true);

    setCookie(".AspNetCore.Culture", culture, 180);

    dispatch({ type: requestCultureType, culture });
    
    if(culture === "he"){
      document.dir = 'rtl';
    }else{
      document.dir = 'ltr';
    }
  };
}

export const actionCreators = {
  changeCulture
};


let cultureCookie = getCookie(".AspNetCore.Culture");

if (cultureCookie) {
  cultureCookie = cultureCookie.slice(-2);
  if(cultureCookie === "he"){
    document.dir = 'rtl';
  }
} else {
  cultureCookie = "uk"
}

const defaultState = {
  locale: cultureCookie,
  dir: cultureCookie === "he" ? "rtl" : "ltr"
}

export const culture = (state = defaultState , action) => {
  if (action.type === requestCultureType) {
    return {
      locale: action.culture,
      dir: action.culture === "he" ? "rtl" : "ltr" 
    };
  }

  return state;
};
