import React from 'react';
import {
  Box,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import StudentsGrid from './StudentsGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const StudentsListView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Students"
    >
      <Container maxWidth="lg">
        <Box mt={3}>
          <StudentsGrid />
        </Box>
      </Container>
    </Page>
  );
};

export default StudentsListView;
