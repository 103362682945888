import * as apiCaller from 'src/helpers/apiCaller';
import translate from 'src/helpers/translate';
import { actionCreators as layoutActionCreators } from '../layout';
const teachersRequestedType = "TEACHERS_REQUESTED"

function requestTeachers() {
    return async dispatch => {

        await apiCaller.get('/teachers/get').then(
            (teachers) => {
                dispatch(success(teachers));
            }
        );

        function success(teachers) {
            return { type: teachersRequestedType, teachers };
        }
    };
}

function sendFriendRequest(teacher) {
    return async dispatch => {
        await apiCaller.get(`/studentTeacher/sendRequest?id=${teacher.id}`, true).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate("RequestSent")));
                dispatch(requestTeachers());
            }
        );
    };
}

function acceptFriendRequest(teacher) {
    return async dispatch => {
        await apiCaller.get(`/studentTeacher/acceptFriendRequest?id=${teacher.id}`, true).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate("RequestWasAccepted")));
                dispatch(requestTeachers());
            }
        );
    };
}

function rejectFriendRequest(teacher) {
    return async dispatch => {
        await apiCaller.get(`/studentTeacher/rejectFriendRequest?id=${teacher.id}`, true).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate("RequestWasCanceled")));
                dispatch(requestTeachers());
            }
        );
    };
}

function removeConnection(teacher) {
    return async dispatch => {
        await apiCaller.get(`/studentTeacher/removeConnection?id=${teacher.id}`, true).then(
            () => {
                dispatch(requestTeachers());
            }
        );
    };
}

export const actionCreators = {
    requestTeachers,
    sendFriendRequest,
    acceptFriendRequest,
    rejectFriendRequest,
    removeConnection
}

const initState = [];

const teachers = (state = initState, action) => {
    if (action.type === teachersRequestedType) {
        return action.teachers;
    }

    return state;
};

export default teachers;