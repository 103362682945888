import React from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';
import GroupsListView from 'src/views/teacher/groups/groupsListView';
import StudentsListView from 'src/views/teacher/students/studentsListView';
import BoardsListView from 'src/views/teacher/boards/index';
import HomeworksListView from 'src/views/common/homeworks/index';
import TestsListView from 'src/views/teacher/tests/list/index';
import HomeworkResultView from 'src/views/teacher/homeworks/result/index';
import TestResultView from 'src/views/teacher/tests/result/index';
import JournalView from 'src/views/teacher/journal/JournalView';
import BoardView from 'src/views/teacher/board/index';

const teacherRoutes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'homeworks', element: <HomeworksListView /> },
      { path: 'tests', element: <TestsListView /> },
      { path: 'test/:id', element: <TestResultView /> },
      { path: 'homework/:id', element: <HomeworkResultView /> },
      { path: 'groups', element: <GroupsListView /> },
      { path: 'students', element: <StudentsListView /> },
      { path: 'boards', element: <BoardsListView /> },
      { path: 'board/:id', element: <BoardView /> },
      { path: 'journal/:id', element: <JournalView /> }
    ]
  }
];

export default teacherRoutes;
