import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { culture } from './reducers/culture';
import { userContext } from './reducers/user';
import { teacher } from './reducers/teacher';
import { layout } from './reducers/layout';
import common from './reducers/common';
import admin from './reducers/admin';
import student from './reducers/student';

// const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory();

const initialState = window.initialReduxState;

const store = configureStore(history, initialState);

export default store;

function configureStore(history, initialState) {
  const reducers = {
      layout,
      userContext,
      culture,
      teacher,
      student,
      common,
      admin
  }
  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === "development";
  if (
    isDevelopment &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );
}
