import React from 'react';
import { useSelector } from 'react-redux';
import RatingTimelineDotCommon from '../../common/Problems/RatingTimelineDot'; 
import studentsInGroupService from 'src/stateServices/studentsInGroupService';

export default function RatingTimelineDot({ problem }) {

    const workState = useSelector(state => state.teacher.workState);

    if(workState.isStudent){
        return (<RatingTimelineDotForStudent problem={problem}/>);
    }else if(workState.isGroup){
        return (<RatingTimelineDotForGroup problem={problem}/>);
    }else{
        return (<RatingTimelineDotCommon rating={0} />);
    }
    
}

function RatingTimelineDotForGroup({ problem }) {
    const avarageRating = studentsInGroupService.getAvarageRatingOfProblemCurrentGroup(problem.id);
        
    return (
        <RatingTimelineDotCommon rating={avarageRating} />
    );
}


function RatingTimelineDotForStudent({ problem }) {
    const currentStudent = useSelector(state => state.teacher.students.find(g => g.isCurrent));
    
    const clientProblem = 
        useSelector(state => state.common.clientProblems
            .find(cp => cp.problemId === problem.id && cp.clientId === currentStudent?.id));
    
    const rating = clientProblem ? clientProblem.rating : 0;

    return (
        <RatingTimelineDotCommon rating={rating} />
    );
}