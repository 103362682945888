import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import TopicCard from './TopicCard';
import Grid from '@mui/material/Grid';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';
import { actionCreators as trainingsActionCreators } from 'src/reducers/common/trainings';
import { actionCreators as eduVideosActionCreators } from 'src/reducers/common/eduVideos';
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { topicService } from 'src/services/topic.service';
import translate from 'src/helpers/translate';
import { Typography } from '@mui/material';
import TrainingsAndVideos from '../TrainingsAndVideos/TrainingsAndVideos';
import TopicBreadcrumbs from '../Problems/TopicBreadcrumbs';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -50
    }
}));

export default function TopicsGrid(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    let { id } = useParams();

    const topics = useSelector(state => topicService.getInnerTopics(id, state.common.topics));

    const user = useSelector(state => state.userContext.user);

    useEffect(() => {
        if (user && user.role) {
            dispatch(clientProblemsActionCreators.requestClientProblems());
            dispatch(trainingsActionCreators.requestTrainings());
            dispatch(eduVideosActionCreators.requestEduVideos());
        }
    }, [user, dispatch])

    return (
        <React.Fragment>
            <Grid container spacing={1} style={{ marginLeft: -20, marginTop: -40, marginBottom: 50 }}>
                <Grid item xs={8} md={4}>
                    <TopicBreadcrumbs
                        topicId={id}
                        currentPageUrl={'/app/topics/'}
                        returnToRootTopic={true}
                        chooseSubjectUrl={'/app/subjects'}
                    />
                </Grid>
                <Grid item xs={4} md={2} style={{ marginTop: -10 }}>
                    <TrainingsAndVideos topicId={id} />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.root}>
                {topics.map(topic => (
                    <Grid item sm={12} key={topic.id}>
                        <TopicCard topic={topic} />
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
}