import React from 'react';

export default function Graph746(props) {

    let {dynamicFunction} = props.data;
    
    let pointsArray = parsePoints(dynamicFunction);

    // Create a curve through the points using spline interpolation
    props.board.create('chart', [pointsArray.map(a => a[0]), pointsArray.map(a => a[1])], {chartStyle:'spline'});
    
    return <></>
}

function parsePoints(input) {
    // Remove leading and trailing whitespace
    input = input.trim();

    // Extract point substrings
    let pointsArray = input.match(/\(([^)]+)\)/g);

    // Map the pointsArray to an array of arrays
    let points = pointsArray.map(point => {
        // Remove parentheses and split the coordinates by semicolon
        let coords = point.slice(1, -1).split(';');
        
        // Return an array with x and y as numbers
        return [parseFloat(coords[0]), parseFloat(coords[1])];
    });

    return points;
}