import React, { useEffect, useState } from 'react';
import * as apiCaller from 'src/helpers/apiCaller';
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpIcon from '@mui/icons-material/Help';
import translate from 'src/helpers/translate';
import InformationalDialog from 'src/components/common/InformationalDialog';
import TrainingsAndVideos from 'src/views/common/TrainingsAndVideos/TrainingsAndVideos';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  helperButton: {
    flex: 0.1,
    // marginBottom: -5
  },
  problemActionButtons: {
    marginTop: -20,
    marginLeft: -5
  }
}));

const ProblemActions = ({ problem, generatedProblem, solvingSteps, setSolvingSteps, whiteBoardOpened, onWhiteBoardToggle }) => {
  const classes = useStyles();
  const [showProblemExample, setShowProblemExample] = useState(false);
  const [showTheory, setShowTheory] = useState(false);
  const [adminDialogIsOpen, setAdminDialogIsOpen] = useState(false);
  
  const user = useSelector(state => state.userContext.user);

  if (generatedProblem.problemExample && !generatedProblem.problemExampleIsPng) {
    const rightPath = "src=\"" + process.env.REACT_APP_WEB_API + "/Task";
    generatedProblem.problemExample = generatedProblem.problemExample.replaceAll("src=\"/Task", rightPath);
  }

  useEffect(() => {
    setShowProblemExample(false);
    setShowTheory(false);
  }, [generatedProblem.id])

  const showSolvingSteps = () => {
    apiCaller.get(`/problem/showSteps?id=${generatedProblem.id}`, true).then(
      (steps) => {
        setSolvingSteps(steps);
      }
    );
  }

  const openWhiteBoard = () => {
    apiCaller.get(`/whiteBoard/getForGeneratedProblem?id=${generatedProblem.id}`, true).then(
      (data) => {
        onWhiteBoardToggle(true);
        const wt = new window.api.WhiteboardTeam('#problemContainer', {
            clientId: data.clientId,
            boardCode: data.boardGuid,
            board: {
              tool: 'pen',
              thickness: 1,
              color: '#000000',
              bg : 'Small grid'
            }
        });
      }
    );
  }

  const closeWhiteBoard = () => {
    onWhiteBoardToggle(false);
  }

  const onTheoryClick = () => {
    if (generatedProblem.theoryIsUrl) {
      window.open(generatedProblem.theoryUrl, '_blank').focus();
    } else {
      setShowTheory(true);
    }
  }

  return (
    <React.Fragment>
      <Grid item sm={12} className={classes.problemActionButtons}>
        {generatedProblem.theoryUrl && (
          <React.Fragment>
            <Tooltip title={translate('ShowTheory')} arrow>
              <IconButton
                component="span"
                className={classes.helperButton}
                onClick={() => onTheoryClick()}
                size="large">
                <DescriptionIcon color="primary" />
              </IconButton>
            </Tooltip>
            {!generatedProblem.theoryIsUrl && (
              <InformationalDialog
                open={showTheory}
                onClose={() => setShowTheory(false)}
              >
                {!generatedProblem.theoryIsUrl && !generatedProblem.theoryIsDocFile && (
                  <img src={`${process.env.REACT_APP_PROBLEM_API}/${generatedProblem.theoryUrl}`} />
                )}
              </InformationalDialog>
            )}
          </React.Fragment>
        )}
        {generatedProblem.problemExample && generatedProblem.problemExample != "" && (
          <React.Fragment>
            <Tooltip title={translate('ShowProblemExample')} arrow>
              <IconButton
                component="span"
                className={classes.helperButton}
                onClick={() => setShowProblemExample(true)}
                size="large">
                <HelpIcon color="primary" />
              </IconButton>
            </Tooltip>
            <InformationalDialog
              open={showProblemExample}
              onClose={() => setShowProblemExample(false)}
            >
              {generatedProblem.problemExampleIsPng && (
                <img style={{marginTop:25, marginLeft:15}} src={`${process.env.REACT_APP_PROBLEM_API}${generatedProblem.problemExample.includes('/') ? '/' : '\\'}${generatedProblem.problemExample}`} />
              )}
              {!generatedProblem.problemExampleIsPng && (
                <Typography style={{marginTop:25, marginLeft:15}}dangerouslySetInnerHTML={{ __html: generatedProblem.problemExample }} />
              )}
            </InformationalDialog>
          </React.Fragment>
        )}
        {generatedProblem.solvingStepsExist && solvingSteps && solvingSteps.length < 1 && (
          <Tooltip title={translate(generatedProblem.answeredDate ? 'SolvingStepsTooltip' : 'SolvingStepsTooltipWarning')} arrow>
            <IconButton
              onClick={() => showSolvingSteps()}
              component="span"
              className={classes.helperButton}
              size="large">
              <EmojiObjectsIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {whiteBoardOpened && (
          <Tooltip title={translate('CloseWhiteBoard')} arrow>
            <IconButton
              onClick={() => closeWhiteBoard()}
              component="span"
              className={classes.helperButton}
              size="large">
              <DesktopAccessDisabledIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {!whiteBoardOpened && (
          <Tooltip title={translate('OpenWhiteBoard')} arrow>
            <IconButton
              onClick={() => openWhiteBoard()}
              component="span"
              className={classes.helperButton}
              size="large">
              <DesktopWindowsIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {user && user.roles && user.roles.includes('Admin') && (
          <React.Fragment>
            <Tooltip title={translate('ForAdmin')} arrow>
              <IconButton
                component="span"
                className={classes.helperButton}
                onClick={() => setAdminDialogIsOpen(true)}
                size="large">
                <FontDownloadIcon color="primary" />
              </IconButton>
            </Tooltip>
            <InformationalDialog
              title={translate('ForAdmin')}
              open={adminDialogIsOpen}
              onClose={() => setAdminDialogIsOpen(false)}
            >
              <Grid container spacing={2} sx={{marginLeft:2, marginTop:4}}>
                <Grid item sm={6}>
                  <Typography>Правильный ответ</Typography>
                </Grid>
                <Grid item sm={6}>
                  {generatedProblem.correctAnswer}
                </Grid>
                <Grid item sm={6}>
                  <Typography>Правильный ответ для выбора</Typography>
                </Grid>
                <Grid item sm={6}>
                  {generatedProblem.correctAnswerChoiseFormat}
                </Grid>
                <Grid item sm={6}>
                  <Typography>Файл</Typography>
                </Grid>
                <Grid item sm={6}>
                  {generatedProblem.file}
                </Grid>
                <Grid item sm={6}>
                  <Typography>Подсказка</Typography>
                </Grid>
                <Grid item sm={6}>
                  {generatedProblem.adminHint}
                </Grid>
              </Grid>
            </InformationalDialog>
          </React.Fragment>
        )}
        <React.Fragment>
          <TrainingsAndVideos problemId={problem.id}/>
        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
};

export default ProblemActions;
