import React, { useEffect, useState, useRef } from 'react';
/* global JXG */
import Graph56 from './graphs/Graph56';
import Graph58 from './graphs/Graph58';
import Graph617 from './graphs/Graph617';
import Graph651 from './graphs/Graph651';
import Graph652 from './graphs/Graph652';
import Graph85 from './graphs/Graph85';
import Graph86 from './graphs/Graph86';
import Graph87 from './graphs/Graph87';
import Graph308 from './graphs/Graph308';
import Graph309 from './graphs/Graph309';
import Graph344 from './graphs/Graph344';
import Graph345 from './graphs/Graph345';
import Graph57 from './graphs/Graph57';
import Graph580 from './graphs/Graph580';
import Graph579 from './graphs/Graph579';
import Graph595 from './graphs/Graph595';
import Graph488 from './graphs/Graph488';
import Graph489 from './graphs/Graph489';
import Graph456 from './graphs/Graph456';
import Graph746 from './graphs/Graph746';
import Graph650 from './graphs/Graph650';
import Graph82 from './graphs/Graph82';
import Graph83 from './graphs/Graph83';
import Graph84 from './graphs/Graph84';
import Graph346 from './graphs/Graph346';
import Graph295 from './graphs/Graph295';


export default function Graph(props) {
  const boardRef = useRef(null);
  const boardInstance = useRef(null);
  const components = {
    56: Graph56,
    57: Graph57,
    58: Graph58,
    82: Graph82,
    83: Graph83,
    84: Graph84,
    85: Graph85,
    86: Graph86,
    87: Graph87,
    295: Graph295,
    308: Graph308,
    309: Graph309,
    344: Graph344,
    345: Graph345,
    346: Graph346,
    456: Graph456,
    488: Graph488,
    489: Graph489,
    579: Graph579,
    580: Graph580,
    595: Graph595,
    617: Graph617,
    651: Graph651,
    652: Graph652,
    746: Graph746,
    650: Graph650
  };

  const SpecificGraph = components[props.problemId];
  let { xMin, xMax, yMin, yMax, width, height } = props.data;
  if (xMin == 0) {
    xMin = -1;
  }
  if (yMin == 0) {
    yMin = -1;
  }
  if (xMax == 0) {
    yMin = 1;
  }
  if (yMin == 0) {
    yMax = 1;
  }

  let widthToApply = width ?? 500;
  let heightToApply = height ?? 500;

  useEffect(() => {
    if (boardRef.current) {
    if (!boardInstance.current) {
      boardInstance.current = JXG.JSXGraph.initBoard(boardRef.current, {
        boundingbox: [xMin, yMax, xMax, yMin],
        axis: true,
        showNavigation: true,
        showCopyright: false,
        defaultAxes: {
          x: {
            name: 'X',
            withLabel: true,
            label: {
              position: 'rt',
              offset: [-5, -15]
            }
          },
          y: {
            withLabel: true,
            name: 'Y',
            label: {
              position: 'rt',
              offset: [-20, -5]
            }
          }
        }
      });
    }
  }
  }, [props.data])

  return <div ref={boardRef} id="jsxgraph-board" style={{ height: heightToApply, width: widthToApply }}>
    {boardInstance.current != null && SpecificGraph && <SpecificGraph data={props.data} board={boardInstance.current} />}
  </div>
}