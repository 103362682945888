import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProblemGraphStats from '../../../common/Problem/Stats/ProblemGraphStats';
import ProblemSolvingTimeStats from '../../../common/Problem/Stats/ProblemSolvingTimeStats';
import ProblemSolvingStatsGrid from '../../../common/Problem/Stats/ProblemSolvingStatsGrid';
import * as apiCaller from 'src/helpers/apiCaller';
import { actionCreators as clientProblemsActionCreators } from 'src/reducers/common/clientProblems';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        marginTop: -20
    }
}));

const ProblemStatsView = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { id } = useParams();

    const problem = useSelector(state => state.common.problems.find(p => p.id == id));

    const user = useSelector(state => state.userContext.user);

    const clientProblem = useSelector(state => state.common.clientProblems
        .find(cp => cp.problemId == problem.id && cp.clientId === user.id));

    const rating = clientProblem?.rating ?? 0;

    const [generatedProblems, setGeneratedProblems] = useState([]);

    useEffect(() => {
        setGeneratedProblems(null);
        apiCaller.get(`/generatedProblem/getForProblem?id=${id}`).then(
            (problems) => {
                setGeneratedProblems(problems);
            }
        );
    }, [id])

    useEffect(() => {
        if (user && user.role) {
            dispatch(clientProblemsActionCreators.requestClientProblems());
        }
    }, [user, dispatch])

    return (
        <Container maxWidth="lg">
            <Box mt={3}>
                {problem && (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            xl={4}
                            xs={12}
                        >
                            {generatedProblems && <ProblemGraphStats problem={problem} rating={rating} generatedProblems={generatedProblems} />}
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            xl={4}
                            xs={12}
                        >
                            {generatedProblems && 
                            <ProblemSolvingTimeStats generatedProblems={generatedProblems}>
                                <ProblemSolvingStatsGrid />
                            </ProblemSolvingTimeStats>}
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Container>
    );
};

export default ProblemStatsView;
