import React from 'react';
import { useSelector } from 'react-redux';
import CommonTopicListItem from '../../common/Topics/CommonTopicListItem';

export function TopicListItem({ topic, isNested, topics }) {

    const problemsInTopic = useSelector(state => state.common.problems.filter(p => p.allTopics.includes(topic.id.toString())));
    let clientProblems = useSelector(state => 
        state.common.clientProblems.filter(cp => problemsInTopic.map(p => p.id).includes(cp.problemId)));
    const workState = useSelector(state => state.teacher.workState);

    const students = useSelector(state => state.teacher.students);

    return (
        <React.Fragment>
            {workState.isStudent && (

                <TopicListItemForStudentWorkState
                    topic={topic}
                    isNested={isNested}
                    topics={topics}
                    problemsInTopic={problemsInTopic}
                    clientProblems={clientProblems}
                />
            )}
            {workState.isGroup && (
                <TopicListItemForGroupWorkState
                    topic={topic}
                    isNested={isNested}
                    topics={topics}
                    problemsInTopic={problemsInTopic}
                    clientProblems={clientProblems}
                />
            )}
            {!workState.isStudent && !workState.isGroup &&(
                <CommonTopicListItem
                    topic={topic}
                    isNested={isNested}
                    topics={topics}
                    problemsInTopic={problemsInTopic}
                    clientProblems={clientProblems}
                    clientsAmount={students.length}
                />
            )}
        </React.Fragment>
    );
}


function TopicListItemForStudentWorkState({ topic, isNested, topics, problemsInTopic, clientProblems }) {


    const currentStudent = useSelector(state => state.teacher.students.find(s => s.isCurrent));
    clientProblems = clientProblems.filter(cp => cp.clientId === currentStudent?.id);

    return (
        <CommonTopicListItem
            topic={topic}
            isNested={isNested}
            topics={topics}
            problemsInTopic={problemsInTopic}
            clientProblems={clientProblems}
        />
    );
}

function TopicListItemForGroupWorkState({ topic, isNested, topics, problemsInTopic, clientProblems }) {

    const currentGroup = useSelector(state => state.teacher.groups.find(g => g.isCurrent));

    const studentsInGroup = useSelector(state => state.teacher.studentGroups.filter(sg => sg.groupId === currentGroup?.id)).map(sg => sg.studentId);

    clientProblems = clientProblems.filter(cp => studentsInGroup.includes(cp.clientId));

    return (
        <CommonTopicListItem
            topic={topic}
            isNested={isNested}
            topics={topics}
            problemsInTopic={problemsInTopic}
            clientProblems={clientProblems}
            clientsAmount={studentsInGroup.length}
        />
    );
}
