import React from 'react';

export default function Graph83(props) {

    let {a, b, c, d, e, yMin, yMax} = props.data;

    var p1 = props.board.create('point',[d,yMin], {size:2, fixed: true, name:'', visible:false});
    var p2 = props.board.create('point',[d,yMax], {size:2, fixed: true, name:'', visible:false});
    var p3 = props.board.create('point',[e,yMin], {size:2, fixed: true, name:'', visible:false});
    var p4 = props.board.create('point',[e,yMax], {size:2, fixed: true, name:'', visible:false});
    
    var lineParams = {
        strokeColor:'green',
        highlightStrokeColor:'green',
        straightFirst:true, 
        straightLast:true, 
        strokeWidth:1, 
        fixed:true
    }
    
    props.board.create('line',[p1,p2], lineParams);
    props.board.create('line',[p3,p4], lineParams);

    props.board.create('functiongraph',[function(x){return Math.log2(a * x * x + b * x + c)}],{strokeColor:'blue',highlightStrokeColor:'blue'});
    
    return <></>
}