import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { actionCreators } from 'src/reducers/student/tests';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ProblemInTest from './ProblemInTest';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import translate from 'src/helpers/translate';
import TestSolvingTimer from './TestSolvingTimer';
import TestSolvingRemainingTimeBar from './TestSolvingRemainingTimeBar';
import CheckIcon from '@mui/icons-material/Check';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider } from '@mui/material';

function ProblemTab({ problem }) {
    const { userAnswer, answeredCorrectly } = problem.generatedProblem;
    console.log(userAnswer);
    console.log(answeredCorrectly);
    return (
        <React.Fragment>
            {translate('MyWorkResources_Task') + ' ' + problem.problemId}
            {answeredCorrectly == null && userAnswer && <CheckIcon />}
            {answeredCorrectly == true && userAnswer && <TaskAltIcon color="secondary" />}
            {answeredCorrectly == false && userAnswer && <CancelIcon color="error" />}
        </React.Fragment>
    );
}

export default function TestSolvingBody({ testId, testProblems }) {
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const currentlySolvingProblemId = useSelector(state => state.student.tests.list.find(t => t.id == testId).currentlySolvingProblemId);
    const showResult = useSelector(state => state.student.tests.list.find(t => t.id == testId).showResult);
    const isSolving = useSelector(state => state.student.tests.list.find(t => t.id == testId).isSolving);

    console.log('rendering');
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (isSolving) {
            dispatch(actionCreators.startSolvingProblem(testId, newValue));
        }
    };

    useEffect(() => {
        setValue(currentlySolvingProblemId != 0 ? currentlySolvingProblemId : testProblems[0].problemId)
    }, [currentlySolvingProblemId, testProblems[0].problemId])

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <TestSolvingRemainingTimeBar testId={testId} />
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {testProblems.map(problem => (
                            <Tab
                                key={`tabforproblem_${problem.problemId}`}
                                label={<ProblemTab problem={problem} />}
                                value={problem.problemId}
                            />
                        ))}
                    </Tabs>
                </Box>
                {testProblems.map(testProblem => (
                    <TabPanel
                        key={`TabContentForProblemInHomework_${testProblem.problemId}`}
                        value={testProblem.problemId}>
                        <ProblemInTest testProblem={testProblem} showResult={showResult && !isSolving} />
                    </TabPanel>
                ))}

            </TabContext>
        </Box>
    );
}