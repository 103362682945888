import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators as teacherActionCreators } from 'src/reducers/teacher';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Container
} from '@mui/material';
import Page from 'src/components/Page';
import BoardsGrid from 'src/views/common/boards/list/BoardsGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BoardsListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const students = useSelector(state => state.teacher.students);

  useEffect(() => {
    if (!students || students.length < 1) {
      dispatch(teacherActionCreators.requestStudents());
    }
  }, [dispatch, students?.length])
  return (
    <Page
      className={classes.root}
      title="Boards"
    >
      <Container maxWidth="lg">
        <Box mt={3}>
          <BoardsGrid clients={students}/>
        </Box>
      </Container>
    </Page>
  );
};

export default BoardsListView;
