import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/reducers/teacher';
import {
  Box,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import TestResultsGrid from './TestResultsGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TestResultView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const test = useSelector(state => state.teacher.tests.find(h => h.id == id));

  useEffect(() => {
      dispatch(actionCreators.requestTests());
  }, [dispatch])

  return (
    <Page
      className={classes.root}
      title="Test"
    >
      <Container maxWidth="lg">
        <Box>
          {test && <TestResultsGrid test={test}/>}
        </Box>
      </Container>
    </Page>
  );
};

export default TestResultView;
