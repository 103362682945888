import React from 'react';
import ProblemSolvingStatsForGroupGrid from './ProblemSolvingStatsForGroupGrid';
import ProblemSolvingTimeStats from '../../../common/Problem/Stats/ProblemSolvingTimeStats';

const ProblemSolvingTimeStatsForGroup = ({ generatedProblems }) => {
  return (
    <ProblemSolvingTimeStats generatedProblems={generatedProblems} >
      <ProblemSolvingStatsForGroupGrid />
    </ProblemSolvingTimeStats>
  );
};

export default ProblemSolvingTimeStatsForGroup;
