import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/reducers/teacher';
import { Add as AddIcon, Done, DoneAll, Group, Mood, Send } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Grid, Button, Switch, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import ChangeTestDialog from './ChangeTestDialog';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import ConfirmationDialog from 'src/components/common/ConfirmationDialog';
import { colors } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChooseEntitiesForWorkDialog from '../../workRelated/ChooseStudentsForWorkDialog'
import ChooseProblemsForWorkDialog from '../../workRelated/ChooseProblemsForWorkDialog';
import PersonIcon from '@mui/icons-material/Person';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 2,
        marginTop: -25
    },
    deleteButton: {
        color: colors.red[400]
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    },
    createNewButton: {
        marginRight: 5
    },
    filterByGroupButton: {
        marginBottom: 10,
    },
    cancelButton: {
        color: red[500],
        borderColor: red[500]
    },
    newRequestButton: {
        backgroundColor: green[200]
    },
    addNewButton: {
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    }
}));

export default function TestTopBar({ test, students, shouldSelectStudentsOnOpen, shouldSelectProblemsOnOpen, groups, problems, navigate }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [changeTestNameDialogIsOpen, setChangeTestNameDialogIsOpen] = useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');
    const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
    const [changeTestStudentsDialogIsOpen, setChangeTestStudentsDialogIsOpen] = useState(false);
    const [changeTestGroupsDialogIsOpen, setChangeTestGroupsDialogIsOpen] = useState(false);
    const [changeTestProblemsDialogIsOpen, setChangeTestProblemsDialogIsOpen] = useState(false);
    const allStudents = useSelector(state => state.teacher.students);
    const allGroups = useSelector(state => state.teacher.groups);
    const testIsUpdating = useSelector(state => state.teacher.testIsUpdating);
    const testProblems = useSelector(state => state.teacher.testProblems.filter(tp => tp.testId == test.id))
    const generatedProblems = testProblems.filter(tp => tp.generatedProblemId !== 0);
    const generatedProblemExistsForEachStudent = students.every(s => generatedProblems.filter(gp => gp.studentId == s.id).length >= problems.length);
    const generatedProblemExistsForEachProblem = problems.every(p => generatedProblems.filter(gp => gp.problemId == p.id).length >= students.length);
    const generatedProblemExistsForEachProblemForEachStudent = generatedProblemExistsForEachStudent && generatedProblemExistsForEachProblem;
    const canBeGenerated = !generatedProblemExistsForEachProblemForEachStudent
        && !test.isSent && test.canBeSent && !testIsUpdating;


    useEffect(() => {
        setChangeTestStudentsDialogIsOpen(shouldSelectStudentsOnOpen);
    }, [shouldSelectStudentsOnOpen])

    useEffect(() => {
        setChangeTestProblemsDialogIsOpen(shouldSelectProblemsOnOpen);
    }, [shouldSelectProblemsOnOpen])

    const handleDelete = () => {
        setConfirmationDialogMessage(translate('Delete').toLowerCase() + ' ' + test.description)
        setConfirmationDialogIsOpen(true);
    }

    const handleSend = () => {
        dispatch(actionCreators.sendTest(test.id))
    }

    const handleNameChange = () => {
        setChangeTestNameDialogIsOpen(true);
    }

    const handleStudentsChange = () => {
        setChangeTestStudentsDialogIsOpen(true);
    }

    const handleGroupsChange = () => {
        setChangeTestGroupsDialogIsOpen(true);
    }

    const handleProblemsChange = () => {
        setChangeTestProblemsDialogIsOpen(true);
    }

    const handleDuplicate = () => {
        dispatch(actionCreators.duplicateTest(test.id, navigate))
    }

    const handleGenerateProblems = () => {
        dispatch(actionCreators.generateProblemsForTest(test.id))
    }

    const onProblemsChangeSubmit = (selectedProblems) => {
        dispatch(actionCreators.changeProblemsInTest(test.id, selectedProblems.map(p => p.fullNumber))).then(() => {
            if (selectedProblems.length > 0 && test.students.length == 0) {
                setChangeTestStudentsDialogIsOpen(true);
            }
        })
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={8}>
                    <Tooltip title={translate('EditTest')} onClick={handleNameChange} arrow>
                        <IconButton
                            // variant="contained"
                            // color="primary"
                            size="medium"
                            component="span"

                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translate('Delete')} arrow>
                        <IconButton onClick={handleDelete} size="medium" className={classes.deleteButton} aria-label="delete test" component="span">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    {!test.isSent && (
                        <React.Fragment>
                            <Tooltip title={translate('Students')} arrow>
                                <IconButton onClick={handleStudentsChange} size="medium" color="secondary" aria-label="change students" component="span">
                                    <PersonIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={translate('Groups')} arrow>
                                <IconButton onClick={handleGroupsChange} size="medium" color="secondary" aria-label="change groups" component="span">
                                    <Group />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={translate('Topics')} arrow>
                                <IconButton onClick={handleProblemsChange} size="medium" color="secondary" aria-label="change topics" component="span">
                                    <AssignmentIcon />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )}
                    <Tooltip title={translate('DuplicateWork')} arrow>
                        <IconButton onClick={handleDuplicate} size="medium" color="secondary" aria-label="duplicate work" component="span">
                            <FilterNoneIcon />
                        </IconButton>
                    </Tooltip>
                    {
                        canBeGenerated && (
                            <Tooltip title={translate('TestResources_GenerateStudentTest')} arrow>
                                <IconButton onClick={handleGenerateProblems} size="medium" color="secondary" aria-label="generate problems" component="span">
                                    <PlaylistPlayIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                    {!test.isSent && (
                        <Tooltip title={translate(test.canBeSent ? 'Send' : 'TestNeedsToBeCompletedBeforeSent')} arrow>
                            <span>
                                <IconButton
                                    size="medium"
                                    className={classes.button}
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={handleSend}
                                    disabled={!test.canBeSent}
                                >
                                    <Send />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
            <ChangeTestDialog
                open={changeTestNameDialogIsOpen}
                onCancel={() => setChangeTestNameDialogIsOpen(false)}
                test={test}
            />
            <ChooseEntitiesForWorkDialog
                open={changeTestStudentsDialogIsOpen}
                onCancel={() => setChangeTestStudentsDialogIsOpen(false)}
                alreadyAdded={students}
                title={translate('ChooseStudentsToAdd')}
                nameOfEntities={translate('Students')}
                all={allStudents}
                onSubmit={(students) => dispatch(actionCreators.changeStudentsInTest(test.id, students))}
            />
            <ChooseEntitiesForWorkDialog
                open={changeTestGroupsDialogIsOpen}
                onCancel={() => setChangeTestGroupsDialogIsOpen(false)}
                alreadyAdded={groups}
                title={translate('ChooseGroupsToAdd')}
                nameOfEntities={translate('Groups')}
                all={allGroups}
                onSubmit={(groups) => dispatch(actionCreators.changeGroupsInTest(test.id, groups))}
            />
            {changeTestProblemsDialogIsOpen && (
                <ChooseProblemsForWorkDialog
                    open={changeTestProblemsDialogIsOpen}
                    onCancel={() => setChangeTestProblemsDialogIsOpen(false)}
                    alreadyAdded={problems}
                    studentsInWork={students.map(s => s.id)}
                    onSubmit={(selectedProblems) => onProblemsChangeSubmit(selectedProblems)}
                />
            )}
            <ConfirmationDialog
                open={confirmationDialogIsOpen}
                onCancel={() => setConfirmationDialogIsOpen(false)}
                onSubmit={() => {
                    dispatch(actionCreators.deleteTest(test.id));
                    navigate('/app/tests', { replace: true });
                }}
                message={confirmationDialogMessage}
            />
        </React.Fragment>
    );
}