/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import translate from 'src/helpers/translate';
import { Typography } from '@mui/material';


export default function ProblemBreadCrumbs({ problem }) {
    const tId = problem.endTopic.toString();

    const topicLength = tId.length;

    let topics = []

    for (let i = 1; i < topicLength; i++) {
        let innerTopic = tId.substring(0, i);
        topics.push(innerTopic);
    }

    return (
        <Breadcrumbs maxItems={4} aria-label="breadcrumb">
            {topics.map(t => (
                <Link key={'ProblemBreadCrumbs_' + t.id} style={{ color: "#1B73E8" }} to={`/app/topics/${tId.substring(0, 1)}`}>
                    {translate('TopicResources_' + t)}
                </Link>
            ))}
            <Link style={{ color: "#1B73E8" }} to={`/app/problems/${tId}`}>
                {translate('TopicResources_' + tId)}
            </Link>
            <Typography>
                {translate(`Level${problem.level}`) + ` ${problem.numberInLevel}`}
            </Typography>
        </Breadcrumbs>
    );
}