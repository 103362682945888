import React from 'react';
import * as apiCaller from 'src/helpers/apiCaller';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import translate from 'src/helpers/translate';
import { Grid } from '@mui/material';
import GeneratedProblem from '../GeneratedProblem/GeneratedProblem';

export default function GeneratedProblemDialog(props) {
    const { onCancel, open, problemId, generatedProblemId } = props;

    return (
        <Dialog maxWidth={"lg"} fullWidth={true} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" onClose={onCancel}>{translate("GeneratedProblem")}</DialogTitle>
            <DialogContent dividers>
                <Grid item sm={12} xs={12}>
                    <GeneratedProblem 
                        id={generatedProblemId} 
                        problemId={problemId} 
                        getGeneratedProblemAction={() => apiCaller.get(`/problem/getGeneratedProblem?id=${generatedProblemId}`, true)}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    color="primary">
                    {translate('ProfileResources_Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}