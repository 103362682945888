import * as apiCaller from 'src/helpers/apiCaller';
const problemsReceivedType = "PROBLEMS_RECEIVED";

function requestProblems() {
    return async dispatch => {
        await apiCaller.get('/problem', true).then(
            (problems) => {
                dispatch(success(problems));
            }
        );
        function success(problems) {
            return { type: problemsReceivedType, problems };
        }
    };
}


export const actionCreators = {
    requestProblems
}

const problems = (state = [], action) => {
    if (action.type === problemsReceivedType) {
        return action.problems;
    }

    return state;
};

export default problems