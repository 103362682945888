import * as apiCaller from 'src/helpers/apiCaller';
import translate from 'src/helpers/translate';
import { actionCreators as layoutActionCreators } from '../layout';
const mistakesReceivedType = "MISTAKES_RECEIVED";
const mistakeIsCLosedType = "MISTAKE_CLOSED";

function requestMistakes() {
    return async dispatch => {
        await apiCaller.get('/mistakesInProblem', true).then(
            (mistakes) => {
                dispatch(success(mistakes));
            }
        );
        function success(mistakes) {
            return { type: mistakesReceivedType, mistakes };
        }
    };
}

function closeMistake(mistakeId, solution) {
    const body = {
        mistakeId,
        decisionText: solution
    };
    return async dispatch => {
        await apiCaller.post('/mistakesInProblem/closeMistake', body, true).then(
            () => {
                dispatch(layoutActionCreators.showSuccessAlert(translate('MistakeHasBeenClosed')));
                dispatch(success());
            }
        );
        function success() {
            return { type: mistakeIsCLosedType, mistakeId };
        }
    };
}

export const actionCreators = {
    requestMistakes,
    closeMistake
}

const mistakes = (state = [], action) => {
    if (action.type === mistakesReceivedType) {
        return action.mistakes ?? [];
    }
    
    if (action.type === mistakeIsCLosedType) {
        return [...state.filter(m => m.id != action.mistakeId)];
    }

    return state;
};

export default mistakes