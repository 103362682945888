import React from 'react';

export default function Graph345(props) {

    let {dynamicFunction} = props.data;
    
    props.board.create('functiongraph',
    [
        function(x)
            {
                return eval(dynamicFunction); 
            }],{strokeColor:'green',highlightStrokeColor:'green'});
    
    return <></>
}