import React, { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Typography,
  IconButton,
  Button,
  Toolbar,
  colors,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import ChooseWorkEntityDialog from 'src/views/teacher/workEntity';
import translate from 'src/helpers/translate';
import { useNavigate } from "react-router-dom";
import { actionCreators as layoutActionCreators } from 'src/reducers/layout';
import * as authService from 'src/services/auth.service';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  avatar: {
    width: 60,
    height: 60
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const TopBar = ({
  className,
  currentWorkEntityName,
  onToggleNavBar,
  user,
  ...rest
}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [problemId, setProblemId] = useState();
  const [workEntityDialogIsOpen, setWorkEntityDialogIsOpen] = useState(false);
  const problems = useSelector(state => state.common.problems.map(p => p.id));
  const dispatch = useDispatch();

  const logout = () => {
    authService.logoff();
    // window.location.href = `${process.env.REACT_APP_WEB_API}/Home/Logout?returnUrl=%2F`;
  };

  const handleProblemSearchKey = (event) => {
    if (event.charCode === 13) {
      if(problems.includes(parseInt(problemId, 10))){
        navigate(`/app/problem/${problemId}`, { replace: true });
      }else{
        dispatch(layoutActionCreators.showWarningAlert(translate('WrongProblemId', problemId)));
      }
    }
  }

  const handleProblemIdChange = (event) => {
    let problemId = event.target.value;
    setProblemId(problemId);
  };

  return (
    <AppBar
      color="primary"
      className={clsx(classes.root, className)}
      elevation={10}
    >
      <Toolbar variant="dense">
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={onToggleNavBar}
          size="large">
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" color="inherit">
          Math Life
        </Typography>
        <Box flexGrow={1} />
        {user && user.roles && user.roles.includes('Teacher') && (
          <Button variant="contained" color="primary" onClick={() => setWorkEntityDialogIsOpen(true)}>
            {currentWorkEntityName}
            <ArrowDropDownIcon />
          </Button>
        )}
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon onClick={() => navigate(`/app/problem/${problemId}`, { replace: true })}/>
          </div>
          <InputBase
            placeholder={translate('SearchForProblem')}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={problemId}
            onKeyPress={handleProblemSearchKey}
            onChange={handleProblemIdChange}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
        <Box flexGrow={30} />
        <IconButton color="inherit" onClick={() => logout()} size="large">
          <InputIcon />
        </IconButton>
      </Toolbar>
      {user && user.roles && user.roles.includes('Teacher') && (
        <ChooseWorkEntityDialog
          isOpen={workEntityDialogIsOpen}
          toogleDialogOpen={setWorkEntityDialogIsOpen}
        />
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};


const getCurrentEntityName = (state) => {
  const { workState, groups, students } = state.teacher;
  let result = translate('ChooseGroupOrStudent');
  if (workState.isStudent) {
    const currentStudent = students.find(s => s.isCurrent);
    if (currentStudent) {
      result = currentStudent.wholeName;
    }
  } else if (workState.isGroup) {
    const currentGroup = groups.find(s => s.isCurrent);
    if (currentGroup) {
      result = currentGroup.name;
    }
  }
  return result;
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    user: state.userContext.user,
    currentWorkEntityName: getCurrentEntityName(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // changeAvatar: (avatar) => dispatch(actionCreators.changeAvatar(avatar))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
