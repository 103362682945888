import React from 'react';
import {connect} from 'react-redux';
import {
  Container,
  Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = ({user}) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Account"
    >
      {user && 
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <ProfileDetails />
          </Grid>
        </Grid>
      </Container>}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userContext.user
  }
}

export default connect(mapStateToProps)(Account);
