import * as apiCaller from "../helpers/apiCaller";

export const teacherService = {
  getStudents,
  getGroups,
  getStudentGroups,
  getCurrentWorkState,
  setCurrentStudent,
  setCurrentGroup,
  getHomeworks,
  setCurrentWorkState,
  createNewGroup,
  changeGroupName,
  deleteGroup,
  updateStudentsInGroup,
  sendFriendRequestToStudent,
  cancelRequestFromStudent,
  cancelRequestToStudent,
  removeFromStudents,
  acceptRequestFromStudent,
  deleteHomework,
  sendHomework,
  addNewHomework,
  changeHomeworkName,
  changeStudentsInHomework,
  changeGroupsInHomework,
  getHomework,
  changeProblemsInHomework,
  duplicateHomework,
  getTests,
  deleteTest,
  sendTest,
  addNewTest,
  requestTestProblems,
  changeProblemsInTest,
  changeGroupsInTest,
  changeStudentsInTest,
  changeTestName,
  getTest,
  duplicateTest,
  generateProblemsForTest,
  updateTest,
  requestTestStudents,
  addTestRetakeToStudent,
  changeNeededRating,
  changeStudentRating
};

async function cancelRequestFromStudent(studentId) {
  return await apiCaller.get(`/studentTeacher/rejectFriendRequest?id=${studentId}`, true);
}

async function cancelRequestToStudent(studentId) {
  return await apiCaller.get(`/studentTeacher/rejectFriendRequest?id=${studentId}`, true);
}

async function removeFromStudents(studentId) {
  return await apiCaller.get(`/studentTeacher/removeConnection?id=${studentId}`, true);
}

async function acceptRequestFromStudent(studentId) {
  return await apiCaller.get(`/studentTeacher/acceptFriendRequest?id=${studentId}`, true);
}

async function sendFriendRequestToStudent(studentId) {
  return await apiCaller.get(`/studentTeacher/sendRequest?id=${studentId}`, true);
}

async function setCurrentStudent(studentId) {
  return await apiCaller.get(`/workState/setCurrentStudent?studentId=${studentId}`);
}

async function createNewGroup(name) {
  return await apiCaller.post(`/groups/create`, name);
}

async function deleteGroup(id) {
  return await apiCaller.get(`/groups/delete?groupId=${id}`);
}

async function sendHomework(id) {
  return await apiCaller.get(`/homework/sendForFront?id=${id}`);
}

async function changeNeededRating(id, rating) {
  return await apiCaller.get(`/homework/changeNeededRating?id=${id}&rating=${rating}`);
}

async function changeStudentRating(problemId, studentId, rating) {
  return await apiCaller.get(`/clientProblems/changeStudentRating?problemId=${problemId}&studentId=${studentId}&rating=${rating}`);
}

async function sendTest(id) {
  return await apiCaller.get(`/test/sendForFront?id=${id}`);
}

async function deleteHomework(id) {
  return await apiCaller.get(`/homework/deleteForFront?id=${id}`);
}

async function deleteTest(id) {
  return await apiCaller.get(`/test/deleteForFront?id=${id}`);
}

async function changeHomeworkName(id, newName) {
  return await apiCaller.get(`/homework/changeName?id=${id}&value=${newName}`);
}

async function changeStudentsInHomework(id, students) {
  const body = {
    id,
    students
  };
  return await apiCaller.post(`/homework/changeStudents`, body);
}

async function changeGroupsInHomework(id, groups) {
  const body = {
    id,
    groups
  };
  return await apiCaller.post(`/homework/changeGroups`, body);
}

async function changeProblemsInHomework(id, problems) {
  const body = {
    id,
    problems
  };
  return await apiCaller.post(`/homework/changeProblems`, body);
}

async function changeTestName(id, newName) {
  return await apiCaller.get(`/test/changeName?id=${id}&value=${newName}`);
}

async function changeStudentsInTest(id, students) {
  const body = {
    id,
    students
  };
  return await apiCaller.post(`/test/changeStudents`, body);
}

async function changeGroupsInTest(id, groups) {
  const body = {
    id,
    groups
  };
  return await apiCaller.post(`/test/changeGroups`, body);
}

async function updateTest(test) {
  return await apiCaller.post(`/test/update`, test);
}

async function changeProblemsInTest(id, problems) {
  const body = {
    id,
    problems
  };
  return await apiCaller.post(`/test/changeProblems`, body);
}


async function addNewHomework(description, addCurrentEntity) {
  return await apiCaller.get(`/homework/create?description=${description}&addCurrentEntity=${addCurrentEntity}`).then(homework => {
    return homework;
  });
}

async function addNewTest(description, addCurrentEntity) {
  return await apiCaller.get(`/test/create?description=${description}&addCurrentEntity=${addCurrentEntity}`).then(test => {
    return test;
  });
}

async function duplicateHomework(id) {
  return await apiCaller.get(`/homework/duplicateNew?id=${id}`).then(homework => {
    return homework;
  });
}

async function duplicateTest(id) {
  return await apiCaller.get(`/test/duplicateNew?id=${id}`);
}

async function updateStudentsInGroup(groupId, students) {
  const body = {
    id: groupId,
    students
  };
  return await apiCaller.post(`/groups/editStudents`, body );
}

async function changeGroupName(id, name) {
  const body = {
    id,
    name
  }
  return await apiCaller.post(`/groups/editName`, body );
}

async function setCurrentGroup(groupId) {
  return await apiCaller.get(`/workState/setCurrentGroup?groupId=${groupId}`);
}

async function setCurrentWorkState(workState) {
  return await apiCaller.get(`/workState/setCurrentWorkState?workState=${workState}`);
}

async function getStudents() {
  return await apiCaller.get('/students/get').then(students => {
    return students;
  });
}

async function getHomeworks() {
  return await apiCaller.get('/homeworks/GetForFront').then(homeworks => {
    return homeworks;
  });
}

async function getTests() {
  return await apiCaller.get('/tests/GetForFront').then(tests => {
    return tests;
  });
}

async function generateProblemsForTest(id) {
  return await apiCaller.get(`/test/generate?id=${id}&isRegeneration=${false}`);
}

async function requestTestProblems(testId) {
  return await apiCaller.get(`/generatedProblem/GetAllForTest?id=${testId}`).then(tests => {
    return tests;
  });
}

async function requestTestStudents(testId) {
  return await apiCaller.get(`/test/getTestStudents?id=${testId}`).then(testStudents => {
    return testStudents;
  });
}

async function addTestRetakeToStudent(testId, studentId) {
  return await apiCaller.get(`/test/allowRetake?testId=${testId}&studentId=${studentId}`);
}



async function getHomework(id) {
  return await apiCaller.get(`/homeworks/GetOneForFront?id=${id}`).then(homework => {
    return homework;
  });
}

async function getTest(id) {
  return await apiCaller.get(`/tests/GetOneForFront?id=${id}`).then(test => {
    return test;
  });
}

async function getGroups() {
  return await apiCaller.get('/groups/get').then(groups => {
    if (groups) {
      return groups;
    }

    return groups;
  });
}

async function getStudentGroups() {
  return await apiCaller.get('/studentGroups').then(studentGroups => {
    if (studentGroups) {
      return studentGroups;
    }

    return studentGroups;
  });
}

async function getCurrentWorkState() {
  return await apiCaller.get('/workState').then(workState => {
    if (workState) {
      return workState;
    }

    return workState;
  });
}
