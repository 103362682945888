import React from 'react';
import {
  Box,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import SubjectsGrid from './SubjectsGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SubjectsView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Subjects"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <SubjectsGrid />
        </Box>
      </Container>
    </Page>
  );
};

export default SubjectsView;
