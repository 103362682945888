const homeworkRatingService = {
    getRating
};

function getRating(homework, clientProblems, students){

    if(!homework || homework.students.length < 1 || homework.problems.length < 1){
        return '';
    }

    homework.students = homework.students.filter(hs => students.map(s => s.id).includes(hs));

    const neededRating = homework.neededRating == 0 ? 3 : homework.neededRating;

    const studentsProblemsInHomework = clientProblems.filter(cp => homework.problems.includes(cp.problemId) && homework.students.includes(cp.clientId));

    const totalHomeworkNeededRating = homework.problems.length * homework.students.length * neededRating;

    const totalHomeworkActualRating = studentsProblemsInHomework.reduce((sum, item) => {
        return sum + (item.rating > neededRating ? neededRating : item.rating);
    }, 0)

    if(homework.id == 350){
        console.log('totalHomeworkNeededRating');
        console.log(totalHomeworkNeededRating);
        console.log('totalHomeworkActualRating');
        console.log(totalHomeworkActualRating);
    }
    const result = totalHomeworkActualRating * 100 / totalHomeworkNeededRating;
    
    return Math.ceil(result) + '%';
}

export default homeworkRatingService;