import React, { useState } from 'react';
import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GeneratedProblemDialog from 'src/views/common/Problem/GeneratedProblemDialog';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  correctIcon:{
    color: colors.green[500],
  },
  notSolvedIcon:{
    color: colors.blue[500],
  },
  grid: {
    cursor: 'pointer'
  },
  wrongIcon:{
    color: colors.red[500]
  },
  solvedCell: {
    marginTop: '12px',
  }
}));

const ProblemSolvingStatsGrid = ({ generatedProblems }) => {
  const classes = useStyles();
  const [problemId, setProblemId] = useState(0);
  const [generatedProblemId, setGeneratedProblemId] = useState(0);
  const [generatedProblemDialogOpen, setGeneratedProblemDialogOpen] = useState(false);

  const result = [...generatedProblems]?.sort((a, b) => b.id - a.id);
  const columns = [
    { field: 'id', hide: true },
    {
        field: 'result',
        flex: 1,
        align: "center",
        renderHeader: (params) => (
            <span>{translate('Solved')}</span>
        ),
        renderCell: (params) => (
          <div className={classes.solvedCell}>
            {params.row.userAnswer === null && (<HelpOutlineIcon className={classes.notSolvedIcon} />)}
            {params.row.userAnswer !== null && params.row.answeredCorrectly && (<CheckCircleIcon className={classes.correctIcon} />)}
            {params.row.userAnswer !== null && !params.row.answeredCorrectly && (<CancelIcon className={classes.wrongIcon} />)}
          </div>
        ),
        valueGetter: (params) => params.row.answeredCorrectly ? 2 : 1
    },
    // {
    //     field: 'solvingTimeString',
    //     flex: 3,
    //     headerName: translate('SolvingTimeDescription'),
    //     renderCell: (params) => (
    //         params.solvingTimeString
    //     )
    // }, 
    {
        field: 'answeredDate',
        flex: 3,
        align: "center",
        renderHeader: (params) => (
            <span>{translate('Date')}</span>
        ),
        renderCell: (params) => (
        <div>
          {params.row.answeredDate}
        </div>),
        valueGetter: (params) => params.row.id
    }
  ];

  const handleStudentClick = (params) => {
    const generatedProblem = generatedProblems.find(s => s.id === params.id);
    console.log(generatedProblem.id);
    setGeneratedProblemId(generatedProblem.id);
    setProblemId(generatedProblem.problemId);
    setGeneratedProblemDialogOpen(true);
  }
  
  return (
    result &&
        <React.Fragment>  
          <DataGrid
          rows={result}
          density="compact"
          columns={columns}
          pageSize={15}
          onRowClick={(params) => handleStudentClick(params)}
          hideFooterPagination={result.length <= 15}
          autoHeight={true}
          className={classes.grid}
          disableColumnMenu
          hideFooterSelectedRowCount />
        <GeneratedProblemDialog
          problemId={problemId}
          generatedProblemId={generatedProblemId}
          open={generatedProblemDialogOpen}
          onCancel={() => setGeneratedProblemDialogOpen(false)}
        />
      </React.Fragment>
  );
};

export default ProblemSolvingStatsGrid;
