import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Avatar, CardHeader } from '@mui/material';
import translate from 'src/helpers/translate';

export default function AddNewTeacherCard({openDialog}) {
    return (
        <Card sx={{ height: 120, maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar src="" />
                }
            />
            <CardActions>
                <Button onClick={openDialog} size="small">{translate('AddNewTeacher')}</Button>
            </CardActions>
        </Card>
    );
}
