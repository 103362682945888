import { actionCreators as layoutActionCreators } from 'src/reducers/layout';
import translate from 'src/helpers/translate';
import { teacherService } from 'src/services/teacher.service';
import * as apiCaller from 'src/helpers/apiCaller';
const clientProblemsReceivedType = "CLIENT_PROBLEMS_RECEIVED";
const changeClientProblemsRatingType = "CLIENT_PROBLEM_RATING_CHANGED";
const studentRatingUpdatedType = "STUDENT_RATING_UPDATED";

export function updateStudentRating(studentId, problemId, rating) {
    return async dispatch => {
      dispatch({type: studentRatingUpdatedType, studentId, problemId, rating})
    };
  }

function requestClientProblems() {
    return async dispatch => {
        await apiCaller.get('/clientProblems').then(
            (clientProblems) => {
                dispatch(success(clientProblems));
            }
        );
        function success(clientProblems) {
            return { type: clientProblemsReceivedType, clientProblems };
        }
    };
}

function changeRating(problemId, clientId, rating) {
    return async dispatch => {
        dispatch(success(problemId, clientId, rating));

        function success(problemId, clientId, rating) {
            return { type: changeClientProblemsRatingType, problemId, clientId, rating };
        }
    };
}

export function changeStudentRating(problemId, studentId, rating) {
    return async dispatch => {
      teacherService.changeStudentRating(problemId, studentId, rating).then(
        () => {
          dispatch({type: studentRatingUpdatedType, problemId, studentId, rating});
          dispatch(layoutActionCreators.showSuccessAlert(translate("StudentRatingHasBeenChanged")));
        }
      );
    };
  }


export const actionCreators = {
    requestClientProblems,
    changeRating,
    updateStudentRating,
    changeStudentRating
}

const clientProblems = (state = [], action) => {
    if (action.type === clientProblemsReceivedType) {
        return action.clientProblems;
    }

    if (action.type === studentRatingUpdatedType) {
        return [...state.map(cp => cp.clientId == action.studentId && cp.problemId == action.problemId ? {...cp, rating : action.rating} : cp)];
    }

    if (action.type === changeClientProblemsRatingType) {
        const clientProblem = state.find(cp => cp.clientId === action.clientId && cp.problemId == action.problemId);
        if(clientProblem){
            return state.map(cp => {
                if (cp === clientProblem) {
                    return { ...cp, rating: action.rating }
                } else {
                    return cp;
                }
            });
        }else{
            return [...state, {clientId : action.clientId, problemId: action.problemId, rating: action.rating}]
        }
    }

    return state;
};

export default clientProblems