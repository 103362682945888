import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { actionCreators as layoutActionCreators } from 'src/reducers/layout';
import { actionCreators as testsActionCreators } from 'src/reducers/student/tests';
import translate from 'src/helpers/translate'

function LinearProgressWithLabel(props) {
    const minutes = parseInt(props.remaining / 60, 10);
    let seconds = props.remaining - minutes * 60;
    if (seconds < 10 && seconds > 0) {
        seconds = `0${seconds}`;
    }else if(seconds <= 0){
        seconds = `00`;
    }

    return (
        <Box sx={{ display: 'flex', marginTop:1, alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress sx={{ height: 10, borderRadius:5}} variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 50 }}>
                <Typography variant="body2" color="text.secondary">{`${minutes < 1 ? 0 : minutes}:${seconds}`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function TestSolvingTimer({ testId, testTimeSeconds, remainingSolvingTimeSeconds }) {
    const currentTimeInPercents = 100 - parseInt(remainingSolvingTimeSeconds / testTimeSeconds * 100);
    const [progress, setProgress] = React.useState(currentTimeInPercents);
    const dispatch = useDispatch();


    React.useEffect(() => {
        if (remainingSolvingTimeSeconds == 60) {
            dispatch(layoutActionCreators.showInfoAlert(translate('MinutesRemainingAlert', 1), 'top', 'center'));
        } else if (remainingSolvingTimeSeconds == 5) {
            dispatch(layoutActionCreators.showWarningAlert(translate('TestWillBeClosedIn5Seconds'), 'top', 'center'));
        }else if(remainingSolvingTimeSeconds == 0){
            dispatch(testsActionCreators.finish(testId));
        }
    }, [remainingSolvingTimeSeconds]);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 100 / testTimeSeconds));
            dispatch(testsActionCreators.decreaseRemainingSolvingTime(testId));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [testTimeSeconds, testId, dispatch]);

    return (
        <Box sx={{ width: '100%', marginLeft: 1 }}>
            <LinearProgressWithLabel value={progress} remaining={remainingSolvingTimeSeconds} />
        </Box>
    );
}
