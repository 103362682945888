import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from 'src/utils/SubmitButton';
import translate from '../../helpers/translate';
import { Grid, Typography } from '@mui/material';

export default function ConfirmationDialog(props) {
    const { title, message, onSubmit, onCancel, open } = props;

    const submit = () => {
        onSubmit();
        onCancel();
    }

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Typography>
                    {translate("GlobalResources_AreYouSureYouWantTo")} <b>{message}</b> ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    color="primary">
                    {translate('Cancel')}
                </Button>
                <SubmitButton
                    isSubmitting={false}
                    canBeSubmitted={true}
                    text={translate('Yes')}
                    onSubmit={submit}
                />
            </DialogActions>
        </Dialog>
    )
}