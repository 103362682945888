import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import translate from 'src/helpers/translate';
import SubmitButton from 'src/utils/SubmitButton';

const GroupDialog = (props) => {
    const { groupNameToChange, open, title, setOpen, existingGroupNames, onSubmit, groupIsBeingCreated } = props;
    const [groupName, setGroupName] = useState(''); 
    const [groupNameIsUnique, setGroupNameIsUnique] = useState(true); 
    const isOpen = open || groupIsBeingCreated;

    useEffect(() => {
        if(groupNameToChange){
            const name = groupNameToChange;
            setGroupName(name);
        }
    }, [groupNameToChange])

    const handleClose = () => {
        setOpen(false);
    };

    const submit = () => {
        onSubmit(groupName);
        setOpen(false);
        setGroupName('');
    };

    const handleGroupNameChange = (event) => {
        let name = event.target.value;
        setGroupName(name);
        setGroupNameIsUnique(!existingGroupNames.includes(name));
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate('CreateNewGroupInfo')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        size="small"
                        id="name"
                        label={translate('GroupName')}
                        type="string"
                        value={groupName}
                        error={!groupNameIsUnique}
                        onChange={handleGroupNameChange}
                        helperText={!groupNameIsUnique && translate('YouAlreadyHaveAGroupWithSuchName')}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleClose}
                        disabled={groupIsBeingCreated} 
                        color="primary">
                        {translate('Cancel')}
                    </Button>
                    <SubmitButton 
                        isSubmitting={groupIsBeingCreated} 
                        canBeSubmitted={groupNameIsUnique && groupName !== ''} 
                        onSubmit={submit}
                        />
                </DialogActions>
            </Dialog>
        </div>
    );
}

const MapStateToProps = (state, ...ownProps) => {
    return {
        ownProps,
        existingGroupNames: state.teacher.groups.map(g => g.name),
        groupIsBeingCreated: state.teacher.groupsAreUpdating
    };
}

export default connect(MapStateToProps)(GroupDialog);