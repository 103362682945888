import { colors } from '@mui/material';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const theme = (dir, locales) => createTheme(adaptV4Theme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#1B73E8'
    },
    secondary: {
      main: colors.green[500]
    },
    default: {
      main: colors.yellow[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  direction: dir,
  typography,
  overrides: {
    // Style sheet name ⚛️
    MuiTimelineOppositeContent: {
      // Name of the rule
      root: {
        // Some CSS
        flex: 0.1,
        
      },
    },
  },
}, locales));

export default theme;
