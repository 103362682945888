import React, { useState } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  // Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ImageUploader from 'react-images-upload';
import translate from 'src/helpers/translate';
import SubmitButton from 'src/utils/SubmitButton';
import { actionCreators } from 'src/reducers/user';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const avatarButton = {
  backgroundColor: "#4CAF4F",
  borderRadius: 4,
  height: 35
}


const Profile = ({ className, changeAvatar, formIsSubmitting, user, ...rest }) => {
  const classes = useStyles();

  const [pictures, setPictures] = useState([]);

  const onDrop = picture => {
    if(picture && picture.length > 0){
      setPictures([...pictures, picture]);
    }else{
      setPictures([]);
    }
  };

  const onSubmit = () => {
    changeAvatar(pictures.slice()[0][0]);
    setPictures([]);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
            {user && user.avatar && (
                <Avatar
                className={classes.avatar}
                src={`data:image/jpg;base64, ${user.avatar.avatarImage}`}
              />
            )}
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.surname} {user.name}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
        <CardActions>
          <ImageUploader
                    {...rest}
                    buttonStyles={avatarButton}
                    buttonText={translate('ProfileResources_ChangeAvatarLink')}
                    label={translate('ProfileResources_PictureNotMoreThan')}
                    fileSizeError={translate('ProfileResources_PictureIsTooBig')}
                    onChange={onDrop}
                    withIcon={false}
                    withPreview={pictures && pictures.length > 0}
                    singleImage={true}
                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                    maxFileSize={5242880}
                    />
          <Divider />
          {<SubmitButton 
              isSubmitting={formIsSubmitting} 
              canBeSubmitted={pictures && pictures.length > 0}
              onSubmit={onSubmit}
              />}
        </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    user: state.userContext.user,
    formIsSubmitting: state.userContext.isUpdating
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeAvatar: (avatar) => dispatch(actionCreators.changeAvatar(avatar))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
