import { teacherService } from '../../services/teacher.service'
import { workState as workStateReducer, initialState as workStateInitialState } from './workState';
import { actionCreators as layoutActionCreators } from '../layout';
import translate from 'src/helpers/translate';
const studentsReceivedType = "STUDENTS_RECEIVED";
const homeworksReceivedType = "HOMEWORKS_RECEIVED";
const homeworkReceivedType = "HOMEWORK_RECEIVED";
const testReceivedType = "TEST_RECEIVED";
const groupsReceivedType = "GROUPS_RECEIVED";
const studentGroupsReceivedType = "STUDENT_GROUPS_RECEIVED";
const workStateReceivedType = "WORK_STATE_RECEIVED";
const workStateChangedType = "WORK_STATE_CHANGED";
const currentStudentChangedType = "CURRENT_STUDENT_CHANGED";
const currentGroupChangedType = "CURRENT_GROUP_CHANGED";
const groupWillBeCreatedType = "GROUP_WILL_BE_CREATED";
const groupCreatedType = "GROUP_CREATED";
const groupUpdatedType = "GROUP_UPDATED";
const groupDeletedType = "GROUP_DELETED";
const homeworkDeletedType = "HOMEWORK_DELETED";
const testDeletedType = "TEST_DELETED";
const studentsInGroupUpdatedType = "STUDENT_IN_GROUP_UPDATED";
const friendRequestSentType = "FRIEND_REQUEST_SENT";
const cancelRequestFromStudentType = "CANCEL_REQUEST_FROM_STUDENT";
const cancelRequestToStudentType = "CANCEL_REQUEST_TO_STUDENT";
const acceptRequestFromStudentType = "ACCEPT_REQUEST_FROM_STUDENT";
const removeFromStudentsType = "REMOVE_FROM_STUDENTS";
const studentsAreUpdatingType = "STUDENTS_ARE_UPDATING";
const homeworksAreUpdatingType = "HOMEWORKS_ARE_UPDATING";
const homeworkIsSentType = "HOMEWORK_IS_SENT";
const testIsSentType = "TEST_IS_SENT";
const homeworkNameIsChangedType = "HOMEWORK_NAME_IS_CHANGED";
const testNameIsChangedType = "TEST_NAME_IS_CHANGED";
const homeworkStudentsAreChangedType = "HOMEWORK_STUDENTS_ARE_CHANGED";
const homeworkGroupsAreChangedType = "HOMEWORK_GROUPS_ARE_CHANGED";
const homeworkProblemsAreChangedType = "HOMEWORK_PROBLEMS_ARE_CHANGED";
const homeworkIsAddedType = "HOMEWORK_IS_ADDED_TYPE";
const testIsAddedType = "TEST_IS_ADDED_TYPE";
const testsAreUpdatingType = "TESTS_ARE_UPDATING";
const testsReceivedType = "TESTS_RECEIVED";
const testProblemsReceivedType = "TEST_PROBLEMS_RECEIVED";
const testStudentsReceivedType = "TEST_STUDENTS_RECEIVED";
const testIsUpdatingType = "TEST_IS_UPDATING";
const testUpdatedType = "TEST_UPDATED";
const studentTestRetakeIsAddedTYpe = "TEST_STUDENT_RETAKES_ADDED";
const homeworkRatingChangedType = "HOMEWORK_RATING_WAS_CHANGED";
const testProblemStatusChangedToSolvingType = "TEST_PROBLEM_IS_BEING_SOLVED";
const testStudentStatusChangedToSolvingType = "STUDENT_TEST_STATUS_CHANGED_TO_SOLVING";
const testProblemStatusChangedToSolvedType = "TEST_PROBLEM_IS_SOLVED";


export function sendFriendRequestToStudent(student) {
  return async dispatch => {
    dispatch({type: studentsAreUpdatingType});
    teacherService.sendFriendRequestToStudent(student.id).then(
      () => {
        dispatch(success());
        dispatch(layoutActionCreators.showSuccessAlert(translate("RequestSent")));
      }
    );

    function success() {
      return { type: friendRequestSentType, student };
    }
  };
}

export function changeWorkState(isStudent) {
  return async dispatch => {
    teacherService.setCurrentWorkState(isStudent ? 2 : 3).then(
      dispatch(success())
    );

    function success() {
      return { type: workStateChangedType, isStudent };
    }
  };
}

export function changeCurrentStudent(studentId) {
  return async dispatch => {
    teacherService.setCurrentStudent(studentId).then(
      dispatch(success())
    );

    function success() {
      return { type: currentStudentChangedType, studentId };
    }
  };
}

export function changeCurrentGroup(groupId) {
  return async dispatch => {
    teacherService.setCurrentGroup(groupId).then(
      dispatch(success())
    );

    function success() {
      return { type: currentGroupChangedType, groupId };
    }
  };
}

export function updateStudentsInGroup(groupId, students) {
  return async dispatch => {
    teacherService.updateStudentsInGroup(groupId, students).then(
      () => {
        dispatch(success());
        dispatch(layoutActionCreators.showSuccessAlert(translate("StudentsInGroupWereChanged")));
      }
    );

    function success() {
      return { type: studentsInGroupUpdatedType, groupId, students };
    }
  };
}

export function createNewGroup(name) {
  return async dispatch => {
    dispatch({ type: groupWillBeCreatedType });

    teacherService.createNewGroup(name).then(
      group => {
        dispatch({type: groupCreatedType, group});
        dispatch({type: currentGroupChangedType, groupId: group.id})
      }
    );
  };
}

export function changeGroupName(id, name) {
  return async dispatch => {
    dispatch({ type: groupWillBeCreatedType });

    teacherService.changeGroupName(id, name).then(
      () => {
        dispatch({type: groupUpdatedType, id, name})
      }
    );
  };
}

export function deleteGroup(id) {
  return async dispatch => {
    teacherService.deleteGroup(id).then(
      () => {
        dispatch({type: groupDeletedType, id})
      }
    );
  };
}

export function deleteHomework(id, navigate) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.deleteHomework(id).then(
      () => {
        dispatch({type: homeworkDeletedType, id})
        navigate('/app/homeworks', { replace: true });
        dispatch(layoutActionCreators.showInfoAlert(translate("HomeworkWasDeleted")));
      }
    );
  };
}

export function deleteTest(id) {
  return async dispatch => {
    dispatch({type: testsAreUpdatingType})
    teacherService.deleteTest(id).then(
      () => {
        dispatch({type: testDeletedType, id})
        dispatch(layoutActionCreators.showInfoAlert(translate("TestWasDeleted")));
      }
    );
  };
}

export function changeHomeworkName(id, newName) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.changeHomeworkName(id, newName).then(
      () => {
        dispatch({type: homeworkNameIsChangedType, id, newName})
        dispatch(layoutActionCreators.showSuccessAlert(translate("NameWasSuccessfullyChanged")));
      }
    );
  };
}

export function changeTestName(id, newName) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.changeTestName(id, newName).then(
      () => {
        dispatch({type: testNameIsChangedType, id, newName})
        dispatch(layoutActionCreators.showSuccessAlert(translate("NameWasSuccessfullyChanged")));
      }
    );
  };
}

export function changeStudentsInHomework(id, students) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.changeStudentsInHomework(id, students).then(
      () => {
        dispatch(requestHomework(id));
        dispatch(layoutActionCreators.showSuccessAlert(translate("StudentsWereSuccessfullyUpdated")));
      }
    );
  };
}

export function changeStudentsInTest(id, students) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.changeStudentsInTest(id, students).then(
      () => {
        dispatch(requestTest(id));
        dispatch(requestTestStudents(id));
        dispatch(layoutActionCreators.showSuccessAlert(translate("StudentsWereSuccessfullyUpdated")));
      }
    );
  };
}


export function changeGroupsInHomework(id, groups) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.changeGroupsInHomework(id, groups).then(
      () => {
        dispatch(requestHomework(id));
        dispatch(requestTestStudents(id));
        dispatch(layoutActionCreators.showSuccessAlert(translate("GroupsWereSuccessfullyUpdated")));
      }
    );
  };
}

export function changeGroupsInTest(id, groups) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.changeGroupsInTest(id, groups).then(
      () => {
        dispatch(requestTest(id));
        dispatch(layoutActionCreators.showSuccessAlert(translate("GroupsWereSuccessfullyUpdated")));
      }
    );
  };
}



export function changeProblemsInHomework(id, problems) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.changeProblemsInHomework(id, problems).then(
      () => {
        dispatch(requestHomework(id));
        dispatch(layoutActionCreators.showSuccessAlert(translate("ProblemsWereSuccessfullyUpdated")));
      }
    );
  };
}

export function changeProblemsInTest(id, problems) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.changeProblemsInTest(id, problems).then(
      () => {
        dispatch(requestTest(id));
        dispatch(layoutActionCreators.showSuccessAlert(translate("ProblemsWereSuccessfullyUpdated")));
      }
    );
  };
}


export function sendHomework(id) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.sendHomework(id).then(
      () => {
        dispatch({type: homeworkIsSentType, id})
        dispatch(layoutActionCreators.showSuccessAlert(translate("HomeworkWasSuccessfullySent")));
      }
    );
  };
}

export function changeNeededRating(id, rating) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.changeNeededRating(id, rating).then(
      () => {
        dispatch({type: homeworkRatingChangedType, id, rating})
        dispatch(layoutActionCreators.showSuccessAlert(translate("HomeworNeededRatingHasBeenChanged")));
      }
    );
  };
}

export function sendTest(id) {
  return async dispatch => {
    dispatch({type: testsAreUpdatingType})
    teacherService.sendTest(id).then(
      () => {
        dispatch({type: testIsSentType, id})
        dispatch(layoutActionCreators.showSuccessAlert(translate("TestWasSuccessfullySent")));
      }
    );
  };
}

export function duplicateTest(id, navigate) {
  return async dispatch => {
    dispatch({type: testsAreUpdatingType})
    teacherService.duplicateTest(id).then(
      () => {
        dispatch(requestTests());
        navigate("/app/tests", {replace: true});
      }
    );
  };
}

export function duplicateHomework(id, navigate) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.duplicateHomework(id).then(
      (homework) => {
        dispatch({type: homeworkIsAddedType, homework});
        navigate("/app/homeworks", {replace: true});
        dispatch(layoutActionCreators.showSuccessAlert(translate("HomeworkWasSuccessfullyAdded", homework.description)));
      }
    );
  };
}

export function addNewHomework(description, showForCurrentEntity) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.addNewHomework(description,showForCurrentEntity).then(
      (homework) => {
        dispatch({type: homeworkIsAddedType, homework});
        dispatch(layoutActionCreators.showSuccessAlert(translate("HomeworkWasSuccessfullyAdded", description)));
      }
    );
  };
}

export function addNewTest(description, showForCurrentEntity) {
  return async dispatch => {
    dispatch({type: testsAreUpdatingType})
    teacherService.addNewTest(description, showForCurrentEntity).then(
      (test) => {
        dispatch({type: testIsAddedType, test});
        dispatch(layoutActionCreators.showSuccessAlert(translate("TestWasSuccessfullyAdded", description)));
      }
    );
  };
}

export function addTestRetakeToStudent(testId, studentId) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.addTestRetakeToStudent(testId, studentId).then(
      () => {
        dispatch({type: studentTestRetakeIsAddedTYpe, testId, studentId});
        dispatch(layoutActionCreators.showSuccessAlert(translate("TestStudentRetakeWasAdded")));
      }
    );
  };
}


export function requestCurrentWorkState() {
  return async dispatch => {
    dispatch(homeworksAreRequested());
    teacherService.getCurrentWorkState().then(
      workState => {
        dispatch(success(workState));
      }
    );

    function success(workState) {
      return { type: workStateReceivedType, workState };
    }

    function homeworksAreRequested() {
      return { type: homeworksAreUpdatingType };
    }
  };
}

export function requestStudents() {
  return async dispatch => {
    teacherService.getStudents().then(
      students => {
        dispatch(success(students));
      }
    );

    function success(students) {
      return { type: studentsReceivedType, students };
    }
  };
}

export function requestHomeworks() {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.getHomeworks().then(
      homeworks => {
        dispatch(success(homeworks));
      }
    );

    function success(homeworks) {
      return { type: homeworksReceivedType, homeworks };
    }
  };
}

export function requestTests() {
  return async dispatch => {
    dispatch({type: testsAreUpdatingType})
    teacherService.getTests().then(
      tests => {
        dispatch(success(tests));
      }
    );

    function success(tests) {
      return { type: testsReceivedType, tests };
    }
  };
}


export function generateProblemsForTest(testId) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.generateProblemsForTest(testId);
  };
}

export function requestTestProblems(testId) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.requestTestProblems(testId).then(
      testProblems => {
        dispatch(success(testProblems));
      }
    );

    function success(testProblems) {
      return { type: testProblemsReceivedType, testId, testProblems };
    }
  };
}

export function changeProblemStatusToSolving(testId, problemId, studentId) {
  return async dispatch => {
    dispatch(success(testId, problemId, studentId));

    function success(testId, problemId, studentId) {
      return { type: testProblemStatusChangedToSolvingType, testId, problemId, studentId };
    }
  };
}

export function changeStudentTestStatusToSolving(studentId) {
  return async dispatch => {
    dispatch(success(studentId));

    function success(studentId) {
      return { type: testStudentStatusChangedToSolvingType, studentId };
    }
  };
}

export function changeProblemStatusToSolved(testId, problemId, studentId, answerIsRight) {
  return async dispatch => {
    dispatch(success(testId, problemId, studentId, answerIsRight));

    function success(testId, problemId, studentId, answerIsRight) {
      return { type: testProblemStatusChangedToSolvedType, testId, problemId, studentId,answerIsRight };
    }
  };
}

export function requestTestStudents(testId) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.requestTestStudents(testId).then(
      testStudents => {
        dispatch(success(testStudents));
      }
    );

    function success(testStudents) {
      return { type: testStudentsReceivedType, testId, testStudents };
    }
  };
}

export function requestHomework(id) {
  return async dispatch => {
    dispatch({type: homeworksAreUpdatingType})
    teacherService.getHomework(id).then(
      homework => {
        dispatch(success(homework));
      }
    );

    function success(homework) {
      return { type: homeworkReceivedType, homework };
    }
  };
}

export function requestTest(id) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.getTest(id).then(
      test => {
        dispatch(success(test));
      }
    );

    function success(test) {
      return { type: testReceivedType, test };
    }
  };
}

export function updateTest(test) {
  return async dispatch => {
    dispatch({type: testIsUpdatingType})
    teacherService.updateTest(test).then(
      () => {
        dispatch(requestTest(test.id));
      }
    );
  };
}


export function requestGroups() {
  return async dispatch => {
    teacherService.getGroups().then(
      groups => {
        dispatch(success(groups));
      }
    );

    function success(groups) {
      return { type: groupsReceivedType, groups };
    }
  };
}

export function requestStudentGroups() {
  return async dispatch => {
    teacherService.getStudentGroups().then(
      studentGroups => {
        dispatch(success(studentGroups));
      }
    );

    function success(studentGroups) {
      return { type: studentGroupsReceivedType, studentGroups };
    }
  };
}

export function cancelRequestFromStudent(id) {
  return async dispatch => {
    dispatch({type: studentsAreUpdatingType});
    teacherService.cancelRequestFromStudent(id).then(
      () => {
        dispatch(success());
        dispatch(layoutActionCreators.showInfoAlert(translate("RequestWasCanceled")));
      }
    );

    function success() {
      return { type: cancelRequestFromStudentType, id };
    }
  };
}

export function cancelRequestToStudent(id) {
  return async dispatch => {
    dispatch({type: studentsAreUpdatingType});
    teacherService.cancelRequestToStudent(id).then(
      () => {
        dispatch(success());
        dispatch(layoutActionCreators.showInfoAlert(translate("RequestWasCanceled")));
      }
    );

    function success() {
      return { type: cancelRequestToStudentType, id };
    }
  };
}

export function removeFromStudents(id) {
  return async dispatch => {
    dispatch({type: studentsAreUpdatingType});
    teacherService.removeFromStudents(id).then(
      () => {
        dispatch(success());
        dispatch(layoutActionCreators.showInfoAlert(translate("StudentWasRemoved")));
      }
    );

    function success() {
      return { type: removeFromStudentsType, id };
    }
  };
}

export function acceptRequestFromStudent(id) {
  return async dispatch => {
    dispatch({type: studentsAreUpdatingType});
    teacherService.acceptRequestFromStudent(id).then(
      () => {
        dispatch(success());
        dispatch(layoutActionCreators.showSuccessAlert(translate("RequestWasAccepted")));
      }
    );

    function success() {
      return { type: acceptRequestFromStudentType, id };
    }
  };
}



export const actionCreators = {
  requestStudents,
  requestGroups,
  requestStudentGroups,
  requestCurrentWorkState,
  requestHomeworks,
  changeCurrentStudent,
  changeWorkState,
  changeCurrentGroup,
  createNewGroup,
  changeGroupName,
  deleteGroup,
  updateStudentsInGroup,
  sendFriendRequestToStudent,
  cancelRequestFromStudent,
  cancelRequestToStudent,
  removeFromStudents,
  acceptRequestFromStudent,
  deleteHomework,
  sendHomework,
  sendTest,
  addNewHomework,
  changeHomeworkName,
  changeStudentsInHomework,
  changeGroupsInHomework,
  requestHomework,
  changeProblemsInHomework,
  duplicateHomework,
  requestTests,
  requestTest,
  deleteTest,
  addNewTest,
  requestTestProblems,
  duplicateTest,
  changeTestName,
  changeStudentsInTest,
  changeGroupsInTest,
  changeProblemsInTest,
  generateProblemsForTest,
  updateTest,
  requestTestStudents,
  addTestRetakeToStudent,
  changeNeededRating,
  changeProblemStatusToSolved,
  changeProblemStatusToSolving,
  changeStudentTestStatusToSolving
};

const initialState = {
  students: [],
  requestedStudents: [],
  homeworks: [],
  tests: [],
  testProblems: [],
  testStudents: [],
  studentsAwaitingConfirmation: [],
  groups: [],
  groupsAreUpdating: false,
  studentsAreUpdating: false,
  homeworksAreUpdating: false,
  testsAreUpdating: false,
  testIsUpdating: false,
  studentGroups: [],
  workState: workStateInitialState
}

export const teacher = (state = initialState, action) => {

  if (action.type === studentsAreUpdatingType) {
    return {
      ...state,
      studentsAreUpdating: true
    };
  }

  if (action.type === testIsUpdatingType) {
    return {
      ...state,
      testIsUpdating: true
    };
  }

  
  if (action.type === homeworksAreUpdatingType) {
    return {
      ...state,
      homeworksAreUpdating: true
    };
  }

  if (action.type === testsAreUpdatingType) {
    return {
      ...state,
      testsAreUpdating: true
    };
  }
  
  if (action.type === studentsReceivedType) {
    return {
      ...state,
      students: action.students.filter(s => s.relationStatus === 1),
      requestedStudents: action.students.filter(s => s.relationStatus === 2),
      studentsAwaitingConfirmation: action.students.filter(s => s.relationStatus === 3),
    };
  }

  if (action.type === homeworksReceivedType) {
    return {
      ...state,
      homeworks: action.homeworks,
      homeworksAreUpdating: false
    };
  }

  if (action.type === testsReceivedType) {
    return {
      ...state,
      tests: action.tests,
      testsAreUpdating: false
    };
  }

  if (action.type === testProblemsReceivedType) {
    // const result ;
    return {
      ...state,
      testProblems: action.testProblems,
      testIsUpdating: false
    };
  }

  if (action.type === testStudentsReceivedType) {
    return {
      ...state,
      testStudents: action.testStudents,
      testIsUpdating: false
    };
  }

  if (action.type === studentTestRetakeIsAddedTYpe) {
    return {
      ...state,
      testStudents: [...state.testStudents.map(ts => {
        if(ts.studentId == action.studentId){
          return {...ts, takes: ts.takes + 1, retakesLeft: ts.retakesLeft + 1}
        }
        return ts;
      })],
      testIsUpdating: false
    };
  }


  if (action.type === homeworkReceivedType) {
    return {
      ...state,
      homeworks: [...state.homeworks.filter(h => h.id !== action.homework.id), action.homework],
      homeworksAreUpdating: false
    };
  }

  if (action.type === testReceivedType) {
    return {
      ...state,
      tests: [...state.tests.filter(h => h.id !== action.test.id), action.test],
      testIsUpdating: false
    };
  }

  if (action.type === groupsReceivedType) {
    return {
      ...state,
      groupsAreUpdating: false,
      groups: action.groups
    };
  }

  if (action.type === groupWillBeCreatedType) {
    return {
      ...state,
      groupsAreUpdating: true
    };
  }

  if (action.type === groupCreatedType) {
    return {
      ...state,
      groups: [ ...state.groups, action.group ],
      groupsAreUpdating: false
    };
  }

  if (action.type === homeworkIsAddedType) {
    return {
      ...state,
      homeworks: [ ...state.homeworks, action.homework ],
      homeworksAreUpdating: false
    };
  }

  if (action.type === testIsAddedType) {
    return {
      ...state,
      tests: [ ...state.tests, action.test ],
      testsAreUpdating: false
    };
  }

  if (action.type === groupUpdatedType) {
    return {
      ...state,
      groups: state.groups.map(group => {
        if(group.id === action.id){
          return {...group, name: action.name}
        }
        return group;
      }),
      groupsAreUpdating: false
    };
  }

  if (action.type === homeworkIsSentType) {
    return {
      ...state,
      homeworksAreUpdating: false,
      homeworks: state.homeworks.map(homework => {
        if(homework.id === action.id){
          return {...homework, isSent: true}
        }
        return homework;
      }),
    };
  }

  if (action.type === testIsSentType) {
    return {
      ...state,
      testsAreUpdating: false,
      tests: state.tests.map(test => {
        if(test.id === action.id){
          return {...test, isSent: true}
        }
        return test;
      }),
    };
  }

  if (action.type === homeworkNameIsChangedType) {
    return {
      ...state,
      homeworksAreUpdating: false,
      homeworks: state.homeworks.map(homework => {
        if(homework.id === action.id){
          return {...homework, description: action.newName}
        }
        return homework;
      }),
    };
  }

  if (action.type === testNameIsChangedType) {
    return {
      ...state,
      testIsUpdating: false,
      tests: state.tests.map(test => {
        if(test.id === action.id){
          return {...test, description: action.newName}
        }
        return test;
      }),
    };
  }


  if (action.type === testProblemStatusChangedToSolvedType) {
    return {
      ...state,
      testProblems: state.testProblems.map(testProblem => {
        if(testProblem.testId === action.testId 
          && testProblem.studentId === action.studentId
          && testProblem.problemId === action.problemId){
          return {...testProblem, answered: true, isSolving: false, answeredCorrectly: action.answerIsRight}
        }
        return testProblem;
      }),
    };
  }

  if (action.type === testProblemStatusChangedToSolvingType) {
    return {
      ...state,
      testProblems: state.testProblems.map(testProblem => {
        if(testProblem.testId === action.testId 
          && testProblem.studentId === action.studentId
          && testProblem.problemId === action.problemId){
          return {...testProblem, isSolving: true }
        }
        if(testProblem.testId === action.testId 
          && testProblem.studentId === action.studentId){
            return {...testProblem, isSolving: false };
        }
        return testProblem;
      }),
    };
  }

  if (action.type === testStudentStatusChangedToSolvingType) {
    return {
      ...state,
      testStudents: state.testStudents.map(testStudent => {
        if(testStudent.studentId === action.studentId){
          return {...testStudent, isSolving: true }
        }
        return testStudent;
      }),
    };
  }

  
  
  if (action.type === homeworkStudentsAreChangedType) {
    return {
      ...state,
      homeworksAreUpdating: false,
      homeworks: state.homeworks.map(homework => {
        if(homework.id === action.id){
          return {...homework, students: action.students}
        }
        return homework;
      }),
    };
  }

  if(action.type === testUpdatedType){
    return {
      ...state,
      testIsUpdating: false,
      testsAreUpdatingType: false,
      tests: state.tests.map(test => {
        if(test.id === action.test.id){
          return {
            ...test,
            testTime: action.test.time,
            takes: action.test.takes,
            description: action.test.description,
            scoreA: action.test.scoreA,
            scoreB: action.test.scoreB,
            scoreC: action.test.scoreC
          };
        }
        return test;
      })
    }
  }

  if (action.type === homeworkGroupsAreChangedType) {
    return {
      ...state,
      homeworksAreUpdating: false,
      homeworks: state.homeworks.map(homework => {
        if(homework.id === action.id){
          return {...homework, students: state.studentGroups.filter(sg => sg.groupId == action.id).map(sg => sg.studentId)}
        }
        return homework;
      }),
    };
  }

  if (action.type === homeworkRatingChangedType) {
    return {
      ...state,
      homeworksAreUpdating: false,
      homeworks: state.homeworks.map(homework => {
        if(homework.id === action.id){
          return {...homework, neededRating: action.rating}
        }
        return homework;
      }),
    };
  }

  if (action.type === homeworkGroupsAreChangedType) {
    return {
      ...state,
      homeworksAreUpdating: false,
      homeworks: state.homeworks.map(homework => {
        if(homework.id === action.id){
          return {...homework, groups: action.groups}
        }
        return homework;
      }),
    };
  }

  if (action.type === homeworkProblemsAreChangedType) {
    return {
      ...state,
      homeworksAreUpdating: false,
      homeworks: state.homeworks.map(homework => {
        if(homework.id === action.id){
          return {...homework, problems: action.problems}
        }
        return homework;
      }),
    };
  }
  
  if (action.type === studentsInGroupUpdatedType) {
    let studentGroups = state.studentGroups.filter(sg => sg.groupId !== action.groupId);
    let studentsInGroup = action.students.map(s => { 
        return {
          groupId: action.groupId,
          studentId: s
        }
      })
    ;
    return {
      ...state,
      studentGroups: studentGroups.concat(studentsInGroup)
    };
  }

  if (action.type === groupDeletedType) {
    return {
      ...state,
      groups: state.groups.filter(group => group.id !== action.id)
    };
  } 
  
  if (action.type === homeworkDeletedType) {
    return {
      ...state,
      homeworks: state.homeworks.filter(h => h.id !== action.id),
      homeworksAreUpdating: false
    };
  } 

  if (action.type === testDeletedType) {
    return {
      ...state,
      tests: state.tests.filter(t => t.id !== action.id),
      testsAreUpdating: false
    };
  } 

  if (action.type === studentGroupsReceivedType) {
    return {
      ...state,
      studentGroups: action.studentGroups
    };
  }

  if (action.type === workStateReceivedType) {
    return {
      ...state,
      workState: workStateReducer(action.workState, action)
    };
  }

  if (action.type === workStateReceivedType) {
    return {
      ...state,
      workState: workStateReducer(action.workState, action)
    };
  }

  if (action.type === workStateChangedType) {
    return {
      ...state,
      workState: workStateReducer(state.workState, action)
    };
  }

  if (action.type === currentStudentChangedType) {
    return {
      ...state,
      students: state.students.map(student => {
        if (student.id === action.studentId) {
          return { ...student, isCurrent: true }
        } else {
          return { ...student, isCurrent: false }
        }
      })
    };
  }

  if (action.type === currentGroupChangedType) {
    return {
      ...state,
      groups: state.groups.map(group => {
        if (group.id === action.groupId) {
          return { ...group, isCurrent: true }
        } else {
          return { ...group, isCurrent: false }
        }
      })
    };
  }

  if (action.type === friendRequestSentType) {
    return {
      ...state,
      studentsAreUpdating: false,
      requestedStudents: 
      [
        ...state.requestedStudents, 
        {...action.student, relationStatus: 2}
      ]
    };
  }

  if (action.type === cancelRequestFromStudentType) {
    return {
      ...state,
      studentsAreUpdating: false,
      studentsAwaitingConfirmation: state.studentsAwaitingConfirmation.filter(student => student.id !== action.id)
    };
  }

  if (action.type === removeFromStudentsType) {
    return {
      ...state,
      studentsAreUpdating: false,
      students: state.students.filter(student => student.id !== action.id),
      studentGroups: state.studentGroups.filter(sg => sg.studentId !== action.id)
    };
  }

  if (action.type === cancelRequestToStudentType) {
    return {
      ...state,
      studentsAreUpdating: false,
      requestedStudents: state.requestedStudents.filter(student => student.id !== action.id)
    };
  }

  if (action.type === acceptRequestFromStudentType) {
    const studentToAccept = state.studentsAwaitingConfirmation.find(s => s.id === action.id);
    return {
      ...state,
      studentsAreUpdating: false,
      studentsAwaitingConfirmation: state.studentsAwaitingConfirmation.filter(student => student.id !== studentToAccept.id),
      students: [...state.students, studentToAccept]
    };
  }

  return state;
};