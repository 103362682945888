import React, { useEffect, useState } from 'react';
import {actionCreators} from 'src/reducers/common/generatedProblems'
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ProblemText from '../Solving/ProblemText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0)
  }
}));

const SimpleProblemBody = ({ problemId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.userContext.user);
  const generatedProblem = useSelector(state => state.common.generatedProblems.find(gp => gp.problemId == problemId && gp.language == user.language));
  
  useEffect(() => {
    console.log(generatedProblem);
    if(!generatedProblem){
      dispatch(actionCreators.requestProblem(problemId));
    }
  }, [dispatch, problemId, generatedProblem])


  return (
    <React.Fragment>
      <Grid container className={classes.formControl}>
        {generatedProblem && (
          <ProblemText generatedProblem={generatedProblem} />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default SimpleProblemBody;
