import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useParams } from 'react-router-dom';
import { actionCreators } from 'src/reducers/teacher';
import { CheckCircleOutline } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Avatar, Card, CardContent, Tooltip, CardHeader, CircularProgress, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import translate from 'src/helpers/translate';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TestTopBar from './TestTopBar';
import { colors } from '@mui/material';
import GeneratedTestProblemDialog from './GeneratedTestProblemDialog';
import GeneratedTestStudentResultDialog from './GeneratedTestStudentResultDialog';
import "./styles.css";
import { useNavigate } from "react-router-dom";
import * as apiCaller from 'src/helpers/apiCaller';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AvatarWithStatus from 'src/components/common/AvatarWithStatus';
import SimpleProblemBody from 'src/views/common/Problem/InTopic/SimpleProblemBody';

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -35
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    },
    createNewButton: {
        marginRight: 5
    },
    filterByGroupButton: {
        marginBottom: 10,
    },
    cancelButton: {
        color: red[500],
        borderColor: red[500]
    },
    newRequestButton: {
        backgroundColor: green[200]
    },
    addNewButton: {
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    }, button: {
        marginLeft: '-10px',
        fontSize: 10
    },
    deleteButton: {
        marginLeft: '-10px',
        fontSize: 10,
        color: colors.red[400]
    },
    grid: {
        cursor: 'pointer'
    },
    problemTooltip: {
        maxWidth: 'none'
    }
}));


export default function TestResultsGrid({ test }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const students = useSelector(state => state.teacher.students.filter(s => test?.students.includes(s.id)));
    const groups = useSelector(state => state.teacher.groups.filter(s => test?.groups.includes(s.id)));
    const problems = useSelector(state => state.common.problems.filter(s => test?.problems.includes(s.id)));
    const testProblems = useSelector(state => state.teacher.testProblems.filter(tp => tp.testId == test.id));
    const testStudents = useSelector(state => state.teacher.testStudents);
    const testIsUpdating = useSelector(state => state.teacher.testIsUpdating);
    const [generatedTestProblemDialogOpen, setGeneratedTestProblemDialogOpen] = useState(false);
    const [generatedTestProblem, setGeneratedTestProblem] = useState({});
    const [studentResultDialogIsOpen, setStudentResultDialogIsOpen] = useState(false);
    const [studentResultProblems, setStudentResultProblems] = useState([]);
    const [studentForTestResultDialog, setStudentForTestResultDialog] = useState({});

    useEffect(() => {
        dispatch(actionCreators.requestTestProblems(test.id));
        dispatch(actionCreators.requestTestStudents(test.id));
    }, [test.id, dispatch])

    const studentsResults = students.map(s => {
        return {
            id: s.id,
            wholeName: s.wholeName,
            avatarImage: s.avatarImage,
            isSolving: testStudents?.find(ts => ts.studentId == s.id)?.isSolving ?? false,
            problems: testProblems.filter(tp => tp.studentId == s.id)
        };
    });

    const handleStudentClick = (params) => {
        setStudentResultProblems(params.row.problems);
        const student = students.find(s => s.id === params.id);
        setStudentForTestResultDialog(student);
        setStudentResultDialogIsOpen(true);
    }

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'wholeName',
            flex: 2,
            minWidth: 200,
            headerName: translate('Students'),
            renderCell: (params) => (
                <React.Fragment>
                    {params.row.isSolving && (
                        <AvatarWithStatus
                            id={params.row.id}
                            studentName={params.row.wholeName}
                            src={params.row.avatarImage != null ? `data:image/jpg;base64, ${params.row.avatarImage}` : params.row.avatarImage}
                        />
                    )}
                    {!params.row.isSolving && (
                        <Avatar
                            key={params.row.id}
                            src={`data:image/jpg;base64, ${params.row.avatarImage}`}
                            className={classes.small}
                        />
                    )}
                    {params.value}
                </React.Fragment>
            )
        },
        {
            field: 'score',
            flex: 0.3,
            renderHeader: () => (
                <Tooltip title={`${translate("MyWorkResources_MaxScore")}: ${test.maxScore}`}>
                    <EqualizerIcon />
                </Tooltip>
            ),
            valueGetter: (params) => getStudentScore(params.row.problems),
            renderCell: (params) => (
                <Tooltip title={translate("MyWorkResources_Score")}>
                    <span>
                        {getStudentScore(params.row.problems)}
                    </span>
                </Tooltip>
            )
        }
    ];

    const getStudentScore = (testProblems) => {
        const takes = [...new Set(testProblems.map(item => item.take))];
        const lastTake = takes.sort((a, b) => b - a)[0];
        const problemsForCurrentTake = testProblems.filter(tp => tp.take === lastTake);
        const score = problemsForCurrentTake.reduce((result, pfct) => {
            const problem = problems.find(p => p.id === pfct.problemId);
            if (!problem) {
                return 0;
            }
            if (!pfct.answeredCorrectly) {
                return result;
            }
            if (problem.level === 1) {
                return result + test.scoreA;
            }
            if (problem.level === 2) {
                return result + test.scoreB;
            }
            if (problem.level === 3) {
                return result + test.scoreC;
            }
        }, 0);
        return score;
    }

    const onProblemRatingClicked = (params, problemId, event) => {
        const problem = getGeneratedProblemForProblem(params, problemId);
        setGeneratedTestProblem(problem);
        setGeneratedTestProblemDialogOpen(true);
        event.stopPropagation();
    }

    const getGeneratedProblemForProblem = (params, problemId) => {
        const problem = params.row.problems.filter(p => p.problemId == problemId).sort((a, b) => b.take - a.take)[0]
        return problem;
    }

    const getProblemStatus = (params, problemId) => {
        const problem = getGeneratedProblemForProblem(params, problemId);
        if (!problem || (problem && problem.generatedProblemId === 0)) {
            return 'not generated';
        }
        if (!problem.answered && !problem.isSolving) {
            return 'generated';
        }
        if (!problem.answered && problem.isSolving && params.row.isSolving) {
            return 'solving';
        }
        return problem.answeredCorrectly ? 'answeredCorrectly' : 'answeredInCorrectly';
    }

    const renderProblemResult = (status) => {
        if (status == 'not generated') {
            return "";
        }
        if (status == 'generated' && !test.isSent) {
            return (<CheckCircleOutline />);
        }
        if (status == 'solving' && test.isSent) {
            return (<CircularProgress size={30} />);
        }
        if (status == 'generated' && test.isSent) {
            return (<BlockIcon />);
        }
        if (status == 'answeredInCorrectly') {
            return (<CancelIcon color="error" />)
        }
        if (status == 'answeredCorrectly') {
            return (<CheckCircleIcon color="secondary" />)
        }
    }

    problems.sort((p1, p2) => p1.fullNumber - p2.fullNumber).forEach(problem => {
        columns.push({
            field: `problem_${problem.id}`,
            flex: 0.5,
            minWidth: 60,
            headerAlign: 'center',
            headerName: problem.id,
            renderHeader: (params) => (
                <Tooltip
                    classes={{ tooltip: classes.problemTooltip }}
                    title={
                        <Paper>
                            <SimpleProblemBody problemId={problem.id} />
                        </Paper>
                    }
                >
                    <strong>
                        {problem.id}
                    </strong>
                </Tooltip>
            ),
            sortable: false,
            valueGetter: (params) => getProblemStatus(params, problem.id),
            renderCell: (params) =>
            (
                <Tooltip title={translate('StudentProblemInTestTooltip')}>
                    <IconButton aria-label="delete" size="small" onClick={(event) => onProblemRatingClicked(params, problem.id, event)}>
                        <span className={classes.problemRating}>
                            {renderProblemResult(getProblemStatus(params, problem.id))}
                        </span>
                    </IconButton>
                </Tooltip>
            )
        })
    })


    return (
        <React.Fragment>
            {test && (
                <Card>
                    <CardHeader
                        title={test.description}
                        subheader={test.createdOnString}
                        avatar={
                            <Link to={"/app/tests"}>
                                <IconButton aria-label="delete" className={classes.margin} size="medium">
                                    <ArrowBackIcon fontSize="inherit" />
                                </IconButton>
                            </Link>
                        }
                    />
                    <CardContent>
                        <TestTopBar
                            test={test}
                            students={students}
                            shouldSelectStudentsOnOpen={students.length == 0}
                            shouldSelectProblemsOnOpen={problems.length == 0}
                            problems={problems}
                            groups={groups}
                            navigate={navigate}
                        />
                        {studentsResults.length > 0 && (
                            <DataGrid
                                loading={testIsUpdating}
                                rows={studentsResults}
                                density="compact"
                                columns={columns}
                                justifyContent="center"
                                pageSize={10}
                                onRowClick={(params) => handleStudentClick(params)}
                                autoHeight={true}
                                disableColumnMenu
                                className={classes.grid}
                                hideFooter={studentsResults.length <= 10}
                                hideFooterSelectedRowCount />
                        )}
                    </CardContent>
                    <GeneratedTestProblemDialog
                        testProblem={generatedTestProblem}
                        open={generatedTestProblemDialogOpen}
                        onCancel={() => setGeneratedTestProblemDialogOpen(false)}
                    />
                    <GeneratedTestStudentResultDialog
                        test={test}
                        student={studentForTestResultDialog}
                        studentResultProblems={studentResultProblems}
                        open={studentResultDialogIsOpen}
                        onCancel={() => setStudentResultDialogIsOpen(false)}
                    />
                </Card>
            )}
        </React.Fragment>
    );
}