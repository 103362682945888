import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { actionCreators } from 'src/reducers/common/boards';
import { green, red } from '@mui/material/colors';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Avatar, Card, CardContent, Tooltip, CardHeader, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import { useNavigate } from "react-router-dom";
import AddNewBoardDialog from '../AddNewBoardDialog';
import Add from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -35
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    cancelButton: {
        color: red[500],
        borderColor: red[500]
    },
    addNewButton: {
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    }, button: {
        marginLeft: '-10px',
        fontSize: 10
    },
    grid: {
        cursor: 'pointer'
    }
}));


export default function BoardsGrid({clients}) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [addingNewBoardDialogIsOpen, setAddingNewBoardDialogIsOpen] = useState(false);

    const boards = useSelector(state => state.common.boards);
    const user = useSelector(state => state.userContext.user);
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(actionCreators.requestBoards());
    }, [dispatch])

    const myBoards = boards && boards.filter(b => b.createdByCurrentUser).map(b => {
        return {
            ...b,
            members: clients.filter(s => b.clients.some(c => c.id === s.id))
        };
    }).sort((firstEl, secondEl) => secondEl.id - firstEl.id);

    const clientsBoards = boards && boards.filter(b => !b.createdByCurrentUser).map(b => {
        return {
            ...b,
            members: clients.filter(s => s.id == b.creatorId)
        };
    }).sort((firstEl, secondEl) => secondEl.id - firstEl.id)

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'name',
            flex: 2,
            headerName: translate('Description'),
            sortable: false
        },
        {
            field: 'members',
            flex: 2,
            headerName: translate(user.isTeacher ? 'Students' : 'MyWorkResources_Teacher'),
            renderCell: (params) => (
                <Tooltip title={params.value.map(s => s.wholeName).join(', ')} arrow>
                    <AvatarGroup max={5}>
                        {params.value.slice(0, 5).map(client => (
                            <Avatar
                                key={client?.id}
                                src={`data:image/jpg;base64, ${client?.avatarImage}`}
                                client={classes.small}
                            />
                        ))}
                    </AvatarGroup>
                </Tooltip>
            )
        },
        {
            field: 'createdDateString',
            flex: 1,
            headerName: translate('CreatedOn'),
            valueGetter: (params) => params.row.id,
            renderCell: (params) => <React.Fragment>{params.row.createdDateString}</React.Fragment>
        }
    ];
    const handleRowClick = (params) => {
        navigate(`/app/board/${params.id}`, { replace: true });
    };

    const addNewBoard = () => {
        setAddingNewBoardDialogIsOpen(true);
    }

    return (
        <React.Fragment>
            <Card>
                <CardHeader title={translate('MyBoards')} />
                <CardContent>
                    <Button
                        className={classes.addNewButton}
                        variant="contained"
                        color="primary"
                        onClick={() => addNewBoard()}
                    >
                        <Add className={classes.createNewButton} />
                        {translate('CreateWorkResources_AddNewGroupStudent')}
                    </Button>
                    {myBoards && myBoards.length > 0 && (
                        <DataGrid
                            loading={false}
                            rows={myBoards}
                            density="compact"
                            columns={columns}
                            pageSize={15}
                            autoHeight={true}
                            disableColumnMenu
                            className={classes.grid}
                            onRowClick={handleRowClick}
                            hideFooter={myBoards.length <= 15}
                            hideFooterSelectedRowCount />
                    )}
                </CardContent>
            </Card>
            {clientsBoards && clientsBoards.length > 0 && (
                <Card sx={{marginTop:3}}>
                    <CardHeader title={translate(user.isTeacher ? 'StudentsBoards' : 'TeacherBoards')} />
                    <CardContent>
                        <DataGrid
                            loading={false}
                            rows={clientsBoards}
                            density="compact"
                            columns={columns}
                            pageSize={15}
                            autoHeight={true}
                            disableColumnMenu
                            className={classes.grid}
                            onRowClick={handleRowClick}
                            hideFooter={clientsBoards.length <= 15}
                            hideFooterSelectedRowCount />
                    </CardContent>
                </Card>
            )}
            <AddNewBoardDialog
                open={addingNewBoardDialogIsOpen}
                setOpen={(open) => setAddingNewBoardDialogIsOpen(open)}
                onSubmit={(description) => dispatch(actionCreators.addNewBoard(description))}
            />
        </React.Fragment>
    );
}