import React from 'react';
import LevelResultTimelineDot from 'src/views/common/Problems/LevelResultTimelineDot';
import studentsService from '../../../services/students.service';
import { useSelector } from 'react-redux';

export default function TeacherLevelResultTimelineDot({ problemsForLevel, end }) {
    const workState = useSelector(state => state.teacher.workState);
    const clientProblems = useSelector(state => state.common.clientProblems.filter(cp => problemsForLevel.map(pfl => pfl.id).includes(cp.problemId)));
    return (
        <React.Fragment>
            {workState.isStudent && (<LevelResultTimelineDotForStudent problemsForLevel={problemsForLevel} clientProblems={clientProblems} end={end}/>)}
            {workState.isGroup && (<LevelResultTimelineDotForGroup problemsForLevel={problemsForLevel} clientProblems={clientProblems} end={end}/>)}
            {!workState.isStudent && !workState.isGroup && (<LevelResultTimelineDot problemsForLevel={problemsForLevel} clientProblems={clientProblems} isLastOne={end}/>)}
        </React.Fragment>
    );
}

function LevelResultTimelineDotForStudent({ problemsForLevel, clientProblems, end}) {
    const currentStudent = useSelector(state => state.teacher.students.find(s => s.isCurrent));
    return (
        <LevelResultTimelineDot problemsForLevel={problemsForLevel} clientProblems={clientProblems.filter(cp => cp.clientId === currentStudent?.id)} isLastOne={end}/>
    );
}

function LevelResultTimelineDotForGroup({ problemsForLevel, clientProblems, end }) {
    const studentsInGroup = useSelector(state => studentsService.getStudentIdsInCurrentGroup(state));
    return (
        <LevelResultTimelineDot problemsForLevel={problemsForLevel} clientProblems={clientProblems.filter(cp => studentsInGroup.includes(cp.clientId))} isLastOne={end}/>
    );
}