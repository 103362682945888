
import React, { useEffect, useState } from 'react';
import * as apiCaller from 'src/helpers/apiCaller';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import translate from 'src/helpers/translate';
import { Avatar, Divider, Fab, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import GeneratedProblem from '../../../common/GeneratedProblem/GeneratedProblem';
import { Add } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from 'src/reducers/teacher';

export default function GeneratedTestStudentResultDialog(props) {
    const { onCancel, open, studentResultProblems, test, student } = props;
    const testStudent = useSelector(state => state.teacher.testStudents.find(ts => ts.testId == test.Id && ts.studentId == student.id));
    const takes = [...new Set(studentResultProblems.map(item => item.take))];
    const dispatch = useDispatch();
    const [take, setTake] = useState(1);

    const handleTakeChange = (event) => {
        setTake(event.target.value);
    }

    const handleRetakeAdded = () => {
        dispatch(actionCreators.addTestRetakeToStudent(test.id, student.id));
    }

    useEffect(() => {
        setTake(testStudent?.take);
    },[testStudent])

    return (
        <Dialog maxWidth={"lg"} fullWidth={true} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle onClose={onCancel} id="form-dialog-title">{translate("TestResultFor")} {student.wholeName}</DialogTitle>
            <DialogContent diveders>
                <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-outlined-label">{translate("TestResources_TakeNumber")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={take}
                        onChange={handleTakeChange}
                        label={translate("TestResources_TakeNumber")}
                    >
                        {takes.map(e => (
                            <MenuItem key={e} value={e}>{e}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {testStudent && testStudent.retakesLeft == 0 && (
                    <Tooltip title={translate("AddRetake")} onClick={handleRetakeAdded}>
                        <Fab style={{ marginLeft: 15 }} color="primary" aria-label="add">
                            <Add />
                        </Fab>
                    </Tooltip>
                )}
                {testStudent && testStudent.retakesLeft > 0 && (
                    <Fab style={{ marginLeft: 15, marginTop: 4 }} variant="extended" disabled color="secondary" aria-label="takes">
                        {translate("MyWorkResources_TakesLeft")}: {testStudent.retakesLeft}
                    </Fab>
                )}
                <Grid item sm={12} xs={12}>
                    {studentResultProblems.filter(stp => stp.take == take).sort((a, b) => a.problemId - b.problemId).map((studentResultProblem) => (
                        <React.Fragment
                            key={studentResultProblem.generatedProblemId}
                        >
                            <GeneratedProblem
                                id={studentResultProblem.generatedProblemId}
                                problemId={studentResultProblem.problemId}
                                getGeneratedProblemAction={() => apiCaller.get(`/test/getGeneratedProblem?generatedProblemId=${studentResultProblem.generatedProblemId}`)}
                            />
                            <Divider variant="middle" />
                        </React.Fragment>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    color="primary">
                    {translate('ProfileResources_Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}