import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
import { actionCreators } from 'src/reducers/student/tests';
import { actionCreators as testProblemsActionCreators } from 'src/reducers/student/testProblems';
import { useDispatch, useSelector } from 'react-redux';
import translate from 'src/helpers/translate';
import TestSolvingBody from './TestSolvingBody';
import { Grid } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TestSolvingDialog({ test }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [takeToShow, setTakeToShow] = React.useState(test.take);

  const handleFinish = () => {
    dispatch(actionCreators.finish(test.id));
  }

  const handleClose = () => {
    dispatch(actionCreators.close(test.id));
    navigate('/app/tests', { replace: true });
  }

  React.useEffect(() => {
    dispatch(testProblemsActionCreators.requestTestsProblems(test.id));
  }, [dispatch, test.id])

  const handleTakToShowChange = (event, newValue) => {
    setTakeToShow(newValue);
  }

  const problemsForCurrentTest = useSelector(state => state.student.testProblems.filter(tp => tp.testId == test.id));

  const testProblems = problemsForCurrentTest.filter(tp => tp.take == takeToShow)
    .sort((a, b) => a.problemNumber - b.problemNumber);

  const takes = [...new Set(problemsForCurrentTest.map(item => item.take))].sort((a, b) => b - a);
  const problems = useSelector(state => state.common.problems);

  const calculateScore = (prev, current) => {
    if (!current.generatedProblem?.answeredCorrectly) {
      return prev;
    }
    let problem = problems.find(p => p.id === current.problemId);
    if (problem.level == 1) {
      return prev + test.scoreA;
    }
    if (problem.level == 2) {
      return prev + test.scoreB;
    }
    if (problem.level == 3) {
      return prev + test.scoreC;
    }
  }

  const takesWithScore = takes.map(t => {
    return {
      take: t, score: problemsForCurrentTest.filter(tp => tp.take == t).reduce((prev, current) => calculateScore(prev, current), 0)
    }
  })


  return (
    <Dialog
      fullScreen
      open={true}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {test.name}
          </Typography>
          {test.isSolving && (
            <Button color="inherit" onClick={handleFinish}>
              {translate('Finish')}
            </Button>
          )}
          {!test.isSolving && test.showResult && (
            <Button color="inherit" onClick={handleClose}>
              {translate('ProfileResources_Close')}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {testProblems && testProblems.length > 0 && test.showResult && !test.isSolving && (
        <React.Fragment>
          <TabContext value={takeToShow}>
            <Box>
              <Tabs
                value={takeToShow}
                onChange={handleTakToShowChange}
                aria-label="takes"
                variant="scrollable"
                scrollButtons="auto"
              >
                {takesWithScore.map(takeWithScore => (
                  <Tab
                    key={`take_${takeWithScore.take}`}
                    label={`${takeWithScore.score}/${test.maxScore}`}
                    value={takeWithScore.take}
                  />
                ))}
              </Tabs>
            </Box>
            <Divider />
            {takesWithScore.map(takeWithScore => (
              <React.Fragment>

                <TabPanel
                  key={`TabContentForProblemInHomework_${takeWithScore.take}`}
                  value={takeWithScore.take}>
                  {<TestSolvingBody testId={test.id} testProblems={testProblems} />}
                </TabPanel>
              </React.Fragment>
            ))}
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={1} md={7} />
              <Grid item xs={11} md={5}>
                <Typography variant="h2" >{`${translate('MyWorkResources_Score')}: ${takesWithScore.find(ts => ts.take == takeToShow).score} ${translate('OutOf')} ${test.maxScore}`}</Typography>
              </Grid>
            </Grid>
          </TabContext>
        </React.Fragment>
      )}
      {testProblems 
        && testProblems.length > 0 
        && !testProblems.some(tp => tp.generatedProblem == null)
        && !test.showResult 
        && test.isSolving 
        && <TestSolvingBody testId={test.id} testProblems={testProblems} />
      }
    </Dialog>
  );
}
