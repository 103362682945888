import * as apiCaller from 'src/helpers/apiCaller';
const topicsReceivedType = "TOPICS_RECEIVED";

function requestTopics() {
    return async dispatch => {
        await apiCaller.get('/topics', true).then(
            (topics) => {
                dispatch(success(topics));
            }
        );
        function success(topics) {
            return { type: topicsReceivedType, topics };
        }
    };
}


export const actionCreators = {
    requestTopics
}

const topics = (state = [], action) => {
    if (action.type === topicsReceivedType) {
        return action.topics;
    }

    return state;
};

export default topics