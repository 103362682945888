import React from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';
import HomeworksListView from 'src/views/common/homeworks/index';
import HomeworkSolvingView from 'src/views/student/homeworks/solving/HomeworkSolvingView';
import TestsListView from 'src/views/student/tests/index';
import BoardsListView from 'src/views/student/boards/index';
import TeachersListView from 'src/views/student/teachers/index';
import BoardView from 'src/views/student/board/index';

const studentRoutes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'homeworks', element: <HomeworksListView /> },
      { path: 'homework/:id', element: <HomeworkSolvingView /> },
      { path: 'tests', element: <TestsListView /> },
      { path: 'test/:id', element: <TestsListView /> },
      { path: 'boards', element: <BoardsListView /> },
      { path: 'board/:id', element: <BoardView /> },
      { path: 'teachers', element: <TeachersListView /> },
      
    ]
  }
];

export default studentRoutes;
