import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/reducers/teacher';
import { Add as AddIcon, Done, DoneAll, Group, Mood, Send } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Grid, Button, Switch, FormControlLabel, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from 'src/helpers/translate';
import ChangePropertyDialog from 'src/components/common/ChangePropertyDialog';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import ConfirmationDialog from 'src/components/common/ConfirmationDialog';
import { colors } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChooseEntitiesForWorkDialog from '../../workRelated/ChooseStudentsForWorkDialog'
import ChooseProblemsForWorkDialog from '../../workRelated/ChooseProblemsForWorkDialog';
import PersonIcon from '@mui/icons-material/Person';
import FilterNoneIcon from '@mui/icons-material/FilterNone';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 2,
        marginTop: -25
    },
    deleteButton: {
        color: colors.red[400]
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: '2px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    topActionBar: {
        marginTop: -55,
        marginBottom: 10
    },
    createNewButton: {
        marginRight: 5
    },
    filterByGroupButton: {
        marginBottom: 10,
    },
    cancelButton: {
        color: red[500],
        borderColor: red[500]
    },
    newRequestButton: {
        backgroundColor: green[200]
    },
    addNewButton: {
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        }
    }
}));

export default function HomeworkTopBar({ homework, students, groups, problems, navigate }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [changeHomeworkNameDialogIsOpen, setChangeHomeworkNameDialogIsOpen] = useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');
    const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
    const [changeHomeworkStudentsDialogIsOpen, setChangeHomeworkStudentsDialogIsOpen] = useState(students.length == 0 && problems.length > 0);
    const [changeHomeworkGroupsDialogIsOpen, setChangeHomeworkGroupsDialogIsOpen] = useState(false);
    const [changeHomeworkProblemsDialogIsOpen, setChangeHomeworkProblemsDialogIsOpen] = useState(problems.length == 0);
    const allStudents = useSelector(state => state.teacher.students);
    const allGroups = useSelector(state => state.teacher.groups);
    const allRatings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];


    const handleDelete = () => {
        setConfirmationDialogMessage(translate('Delete').toLowerCase() + ' ' + homework.description)
        setConfirmationDialogIsOpen(true);
    }

    const handleSend = () => {
        dispatch(actionCreators.sendHomework(homework.id))
    }

    const handleChangeNeededRating = (event) => {
        dispatch(actionCreators.changeNeededRating(homework.id, event.target.value));
    }

    const handleNameChange = () => {
        setChangeHomeworkNameDialogIsOpen(true);
    }

    const handleStudentsChange = () => {
        setChangeHomeworkStudentsDialogIsOpen(true);
    }

    const handleGroupsChange = () => {
        setChangeHomeworkGroupsDialogIsOpen(true);
    }

    const handleProblemsChange = () => {
        setChangeHomeworkProblemsDialogIsOpen(true);
    }

    const handleDuplicate = () => {
        dispatch(actionCreators.duplicateHomework(homework.id, navigate))
    }

    const onProblemsChangeSubmit = (selectedProblems) => {
        dispatch(actionCreators.changeProblemsInHomework(homework.id, selectedProblems.map(p => p.id))).then(() => {
            if (selectedProblems.length > 0 && homework.students.length == 0) {
                setChangeHomeworkStudentsDialogIsOpen(true);
            }
        })
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} sm={12} md={8}>
                    <Tooltip title={translate('ChangeName')} onClick={handleNameChange} arrow>
                        <IconButton
                            // variant="contained"
                            // color="primary"
                            size="medium"
                            component="span"

                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translate('Delete')} arrow>
                        <IconButton onClick={handleDelete} size="medium" className={classes.deleteButton} aria-label="delete homework" component="span">
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    {!homework.isSent && (
                        <React.Fragment>
                            <Tooltip title={translate('Students')} arrow>
                                <IconButton onClick={handleStudentsChange} size="medium" color="secondary" aria-label="change students" component="span">
                                    <PersonIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={translate('Groups')} arrow>
                                <IconButton onClick={handleGroupsChange} size="medium" color="secondary" aria-label="change students" component="span">
                                    <Group />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )}
                    <Tooltip title={translate('Topics')} arrow>
                        <IconButton onClick={handleProblemsChange} size="medium" color="secondary" aria-label="change problems" component="span">
                            <AssignmentIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translate('DuplicateWork')} arrow>
                        <IconButton onClick={handleDuplicate} size="medium" color="secondary" aria-label="change problems" component="span">
                            <FilterNoneIcon />
                        </IconButton>
                    </Tooltip>
                    {!homework.isSent && (
                        <Tooltip title={translate(homework.canBeSent ? 'Send' : 'HomeworkNeedsToBeCompletedBeforeSent')} arrow>
                            <span>
                                <IconButton
                                    size="medium"
                                    className={classes.button}
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={handleSend}
                                    disabled={!homework.canBeSent}
                                >
                                    <Send />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    <FormControl variant="standard">
                        <InputLabel id="demo-simple-select-outlined-label">{translate('NeededRating')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={homework.neededRating}
                            onChange={handleChangeNeededRating}
                            label={translate('NeededRating')}
                        >
                            {allRatings.map(t => (
                                <MenuItem key={t} value={t}>{t}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <ChangePropertyDialog
                open={changeHomeworkNameDialogIsOpen}
                onCancel={() => setChangeHomeworkNameDialogIsOpen(false)}
                title={translate("ChangeNameTitle")}
                initialValue={homework.description}
                onSubmit={(description) => dispatch(actionCreators.changeHomeworkName(homework.id, description))}
            />
            <ChooseEntitiesForWorkDialog
                open={changeHomeworkStudentsDialogIsOpen}
                onCancel={() => setChangeHomeworkStudentsDialogIsOpen(false)}
                alreadyAdded={students}
                title={translate('ChooseStudentsToAdd')}
                nameOfEntities={translate('Students')}
                all={allStudents}
                onSubmit={(students) => dispatch(actionCreators.changeStudentsInHomework(homework.id, students))}
            />
            <ChooseEntitiesForWorkDialog
                open={changeHomeworkGroupsDialogIsOpen}
                onCancel={() => setChangeHomeworkGroupsDialogIsOpen(false)}
                alreadyAdded={groups}
                title={translate('ChooseGroupsToAdd')}
                nameOfEntities={translate('Groups')}
                all={allGroups}
                onSubmit={(groups) => dispatch(actionCreators.changeGroupsInHomework(homework.id, groups))}
            />
            {changeHomeworkProblemsDialogIsOpen && (
                <ChooseProblemsForWorkDialog
                    open={changeHomeworkProblemsDialogIsOpen}
                    studentsInWork={students.map(s => s.id)}
                    onCancel={() => setChangeHomeworkProblemsDialogIsOpen(false)}
                    alreadyAdded={problems}
                    onSubmit={(selectedProblems) => onProblemsChangeSubmit(selectedProblems)}
                />
            )}
            <ConfirmationDialog
                open={confirmationDialogIsOpen}
                onCancel={() => setConfirmationDialogIsOpen(false)}
                onSubmit={() => {
                    dispatch(actionCreators.deleteHomework(homework.id, navigate));
                }}
                message={confirmationDialogMessage}
            />
        </React.Fragment>
    );
}